import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  isEdited: false,
  product: null,
  productCode: null,
  oldTags: [],
  tagsToAdd: [],
  tagsToDelete: [],
  variants: {
    productVariants: [],
    variantOptions: [],
    variantOptionsToAdd: [],
    optionToDelete: null,
    variantToDelete: null,
    isVariantOptionDeleteModalDisplayed: false,
    isProductVariantDeleteModalDisplayed: false,
    isNewOptionBoxDisplayed: false,
    isEditOptionModalDisplayed: false,
    isNewOptionValueBoxDisplayed: false,
    newOption: {
      name: '',
      values: [''],
    },
    editOption: {
      name: '',
      values: [''],
    },
    isNewProductVariantModalDisplayed: false,
    isEditProductVariantModalDisplayed: false,
    newProductVariant: {
      image_link: '',
      price: 0,
      stock_count: 0,
      variantValues: [],
    },
    editProductVariant: {
      id: null,
      image_link: '',
      price: 0,
      stock_count: 0,
      variantValues: [],
    },
  },
};

export const singleProductSlice = createSlice({
  name: 'singleProduct',
  initialState,
  reducers: {
    START_LOADING: (state) => {
      return { ...state, isLoading: true };
    },
    STOP_LOADING: (state) => {
      return { ...state, isLoading: false };
    },
    SET_IS_EDITED: (state, action) => {
      return { ...state, isEdited: action.payload };
    },
    SET_PRODUCT_CODE: (state, action) => {
      return { ...state, productCode: action.payload };
    },
    SET_PRODUCT: (state, action) => {
      return { ...state, product: action.payload };
    },
    SET_DESCRIPTION: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          description: action.payload,
        },
      };
    },
    SET_COST: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          cost: action.payload,
        },
      };
    },
    SET_SALE_PRICE: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          salePrice: action.payload,
        },
      };
    },
    SET_MAX_DISCOUNT_RATE: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          maxDiscountRate: action.payload,
        },
      };
    },
    SET_STOCK_COUNT: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          stock_count: action.payload,
        },
      };
    },
    SET_CATEGORY: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          product_categories_id: action.payload,
        },
      };
    },
    SET_DEFAULT_MANUFACTURER: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          defaultManufacturerId: action.payload,
        },
      };
    },
    SET_IMAGE_LINK: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          imageLink: action.payload,
        },
      };
    },
    SET_TAGS: (state, action) => {
      const _tempTagsToDelete = state.oldTags.filter(
        (item) => !action.payload.find((tag) => tag.id === item.id),
      );

      const _tempTagsToAdd = action.payload.filter(
        (tag) => !state.oldTags.find((item) => item.id === tag.id),
      );

      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          tags: action.payload,
        },
        tagsToAdd: _tempTagsToAdd,
        tagsToDelete: _tempTagsToDelete,
      };
    },
    SET_OLD_TAGS: (state, action) => {
      return {
        ...state,
        oldTags: action.payload,
      };
    },
    // Variant Actions
    SET_PRODUCT_VARIANTS: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          productVariants: action.payload,
        },
      };
    },
    SET_VARIANT_OPTIONS: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          variantOptions: action.payload,
        },
      };
    },
    SET_OPTION_TO_DELETE: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          optionToDelete: action.payload,
        },
      };
    },
    HIDE_OPTION_DELETE_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isVariantOptionDeleteModalDisplayed: false,
        },
      };
    },
    SHOW_OPTION_DELETE_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isVariantOptionDeleteModalDisplayed: true,
        },
      };
    },
    // ADD VARIANT OPTION
    HIDE_NEW_OPTION_BOX: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isNewOptionBoxDisplayed: false,
        },
      };
    },
    SHOW_NEW_OPTION_BOX: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isNewOptionBoxDisplayed: true,
        },
      };
    },
    SET_NEW_OPTION_NAME: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newOption: {
            ...state.variants.newOption,
            name: action.payload,
          },
        },
      };
    },
    SET_NEW_OPTION_VALUE: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newOption: {
            ...state.variants.newOption,
            values: state.variants.newOption.values.map((value, index) => {
              if (index === action.payload.index) {
                return action.payload.value;
              }
              return value;
            }),
          },
        },
      };
    },
    ADD_NEW_OPTION_VALUE: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newOption: {
            ...state.variants.newOption,
            values:
              state.variants.newOption.values[state.variants.newOption.values.length - 1] !== ''
                ? [...state.variants.newOption.values, '']
                : state.variants.newOption.values,
          },
        },
      };
    },
    DELETE_LAST_NEW_OPTION_VALUE: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newOption: {
            ...state.variants.newOption,
            values:
              state.variants.newOption.values.length > 1
                ? state.variants.newOption.values.filter(
                    (item, index) => index + 1 !== state.variants.newOption.values.length,
                  )
                : state.variants.newOption.values,
          },
        },
      };
    },
    ADD_VARIANT_OPTION_TO_ADD: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          variantOptionsToAdd: [...state.variants.variantOptionsToAdd, action.payload],
        },
      };
    },
    RESET_NEW_OPTION: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newOption: initialState.variants.newOption,
        },
      };
    },

    // EDIT VARIANT OPTION
    HIDE_EDIT_OPTION_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isEditOptionModalDisplayed: false,
        },
      };
    },
    SHOW_EDIT_OPTION_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isEditOptionModalDisplayed: true,
        },
      };
    },
    SET_EDIT_OPTION_NAME: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editOption: {
            ...state.variants.editOption,
            name: action.payload,
          },
        },
      };
    },
    SET_EDIT_OPTION_VALUE: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editOption: {
            ...state.variants.editOption,
            values: state.variants.editOption.values.map((value, index) => {
              if (index === action.payload.index) {
                return action.payload.value;
              }
              return value;
            }),
          },
        },
      };
    },
    ADD_EDIT_OPTION_VALUE: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editOption: {
            ...state.variants.editOption,
            values:
              state.variants.editOption.values[state.variants.editOption.values.length - 1] !== ''
                ? [...state.variants.editOption.values, { name: '', isAlreadySaved: false }]
                : state.variants.editOption.values,
          },
        },
      };
    },
    DELETE_LAST_EDIT_OPTION_VALUE: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editOption: {
            ...state.variants.editOption,
            values:
              state.variants.editOption.values.length > 1 &&
              !state.variants.editOption.values[state.variants.editOption.values.length - 1]
                .isAlreadySaved
                ? state.variants.editOption.values.filter(
                    (item, index) => index + 1 !== state.variants.editOption.values.length,
                  )
                : state.variants.editOption.values,
          },
        },
      };
    },
    SET_EDIT_OPTION: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editOption: action.payload,
        },
      };
    },
    RESET_EDIT_OPTION: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editOption: initialState.variants.editOption,
        },
      };
    },

    // New Variant
    SHOW_NEW_PRODUCT_VARIANT_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isNewProductVariantModalDisplayed: true,
          newProductVariant: {
            ...state.variants.newProductVariant,
            image_link: state.product.imageLink,
            price: state.product.salePrice,
          },
        },
      };
    },
    HIDE_NEW_PRODUCT_VARIANT_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isNewProductVariantModalDisplayed: false,
        },
      };
    },
    SET_VARIANT_VALUES_OF_NEW_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newProductVariant: {
            ...state.variants.newProductVariant,
            variantValues: state.variants.newProductVariant.variantValues.map((value) => {
              if (value.options_id === action.payload.options_id) {
                return action.payload;
              }
              return value;
            }),
          },
        },
      };
    },
    SET_IMAGE_SRC_OF_NEW_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newProductVariant: {
            ...state.variants.newProductVariant,
            image_link: action.payload,
          },
        },
      };
    },
    SET_PRICE_OF_NEW_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newProductVariant: {
            ...state.variants.newProductVariant,
            price: parseFloat(action.payload),
          },
        },
      };
    },
    SET_STOCK_COUNT_OF_NEW_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newProductVariant: {
            ...state.variants.newProductVariant,
            stock_count: parseInt(action.payload),
          },
        },
      };
    },
    RESET_VARIANT_VALUES_OF_NEW_VARIANT: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          newProductVariant: {
            ...state.variants.newProductVariant,
            variantValues: state.variants.variantOptions.map((option) => {
              return option.values[0];
            }),
            image_link: '',
            price: 0,
            stock_count: 0,
          },
        },
      };
    },

    // Edit Variant
    SHOW_EDIT_PRODUCT_VARIANT_MODAL: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isEditProductVariantModalDisplayed: true,
          editProductVariant: {
            id: action.payload.id,
            image_link: action.payload.image_link,
            price: action.payload.price,
            stock_count: action.payload.stock_count,
            variantValues: action.payload.variantValues.map((item) => ({
              id: item.optionValue.id,
              name: item.optionValue.name,
              options_id: item.optionValue.options_id,
            })),
          },
        },
      };
    },
    HIDE_EDIT_PRODUCT_VARIANT_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isEditProductVariantModalDisplayed: false,
        },
      };
    },
    SET_VARIANT_VALUES_OF_EDIT_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editProductVariant: {
            ...state.variants.editProductVariant,
            variantValues: state.variants.editProductVariant.variantValues.map((value) => {
              if (value.options_id === action.payload.options_id) {
                return action.payload;
              }
              return value;
            }),
          },
        },
      };
    },
    SET_IMAGE_SRC_OF_EDIT_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editProductVariant: {
            ...state.variants.editProductVariant,
            image_link: action.payload,
          },
        },
      };
    },
    SET_PRICE_OF_EDIT_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editProductVariant: {
            ...state.variants.editProductVariant,
            price: parseFloat(action.payload),
          },
        },
      };
    },
    SET_STOCK_COUNT_OF_EDIT_VARIANT: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editProductVariant: {
            ...state.variants.editProductVariant,
            stock_count: parseInt(action.payload),
          },
        },
      };
    },
    RESET_VARIANT_VALUES_OF_EDIT_VARIANT: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          editProductVariant: {
            ...state.variants.editProductVariant,
            id: null,
            image_link: '',
            price: 0,
            stock_count: 0,
            variantValues: [],
          },
        },
      };
    },

    // Delete Variant
    SHOW_PRODUCT_VARIANT_DELETE_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isProductVariantDeleteModalDisplayed: true,
        },
      };
    },
    HIDE_PRODUCT_VARIANT_DELETE_MODAL: (state) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          isProductVariantDeleteModalDisplayed: false,
        },
      };
    },
    SET_PRODUCT_VARIANT_TO_DELETE: (state, action) => {
      return {
        ...state,
        variants: {
          ...state.variants,
          variantToDelete: action.payload,
        },
      };
    },

    // ---------------------
    RESET: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  RESET,
  START_LOADING,
  STOP_LOADING,
  SET_PRODUCT_CODE,
  SET_PRODUCT,
  SET_CATEGORY,
  SET_DEFAULT_MANUFACTURER,
  SET_COST,
  SET_DESCRIPTION,
  SET_IMAGE_LINK,
  SET_IS_EDITED,
  SET_MAX_DISCOUNT_RATE,
  SET_STOCK_COUNT,
  SET_SALE_PRICE,
  SET_TAGS,
  SET_OLD_TAGS,
  SET_PRODUCT_VARIANTS,
  SET_VARIANT_OPTIONS,
  HIDE_NEW_OPTION_BOX,
  SHOW_NEW_OPTION_BOX,
  SET_NEW_OPTION_NAME,
  SET_NEW_OPTION_VALUE,
  ADD_NEW_OPTION_VALUE,
  DELETE_LAST_NEW_OPTION_VALUE,
  RESET_NEW_OPTION,
  ADD_EDIT_OPTION_VALUE,
  HIDE_EDIT_OPTION_MODAL,
  DELETE_LAST_EDIT_OPTION_VALUE,
  RESET_EDIT_OPTION,
  SET_EDIT_OPTION_NAME,
  SET_EDIT_OPTION_VALUE,
  SHOW_EDIT_OPTION_MODAL,
  SET_EDIT_OPTION,
  ADD_VARIANT_OPTION_TO_ADD,
  SET_OPTION_TO_DELETE,
  SHOW_OPTION_DELETE_MODAL,
  HIDE_OPTION_DELETE_MODAL,
  SHOW_NEW_PRODUCT_VARIANT_MODAL,
  HIDE_NEW_PRODUCT_VARIANT_MODAL,
  SET_VARIANT_VALUES_OF_NEW_VARIANT,
  RESET_VARIANT_VALUES_OF_NEW_VARIANT,
  SET_IMAGE_SRC_OF_NEW_VARIANT,
  SET_PRICE_OF_NEW_VARIANT,
  SET_STOCK_COUNT_OF_NEW_VARIANT,
  SHOW_EDIT_PRODUCT_VARIANT_MODAL,
  HIDE_EDIT_PRODUCT_VARIANT_MODAL,
  SET_VARIANT_VALUES_OF_EDIT_VARIANT,
  RESET_VARIANT_VALUES_OF_EDIT_VARIANT,
  SET_IMAGE_SRC_OF_EDIT_VARIANT,
  SET_PRICE_OF_EDIT_VARIANT,
  SET_STOCK_COUNT_OF_EDIT_VARIANT,
  SHOW_PRODUCT_VARIANT_DELETE_MODAL,
  HIDE_PRODUCT_VARIANT_DELETE_MODAL,
  SET_PRODUCT_VARIANT_TO_DELETE,
} = singleProductSlice.actions;

export default singleProductSlice.reducer;
