import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import {
  HIDE_PRODUCTION_EDIT_MODAL,
  SET_PRODUCTION_DETAILS_FOR_UPDATE,
  CLEAR_ERROR,
  START_LOADING,
  STOP_LOADING,
  RESET_PRODUCTION_DETAILS_FOR_UPDATE,
} from '../singleOrderSlice';

import { useDispatch, useSelector } from 'react-redux';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { Select } from '..';

const AtolyeAPI = new API();

const ProductionEditModal = ({ fetchOrder, isManufacturerOptionsLoading, manufacturerOptions }) => {
  // Redux
  const dispatch = useDispatch();
  const { order, isProductionStatusEditModalDisplayed, productionIdToUpdate } = useSelector(
    (state) => state.orders.singleOrder,
  );
  const { orders_id, productsTotal, discountTotal, subTotal, KDVtotal, total, totalDiff } =
    useSelector((state) => state.orders.singleOrder.recalculatedOrder);
  const { userPermissions } = useSelector((state) => state.general.user);

  const {
    price,
    quantity,
    manufacturers_id,
    material,
    glass,
    branch,
    lampholder,
    features,
    specialNotes,
    color,
    production_status_id,
  } = useSelector((state) => state.orders.singleOrder.productionDetailsForUpdate);

  // Functions

  const updateProductionStatus = async () => {
    const isPriceValidated = price || price === 0;
    const isQuantityValidated = quantity && quantity > 0;
    const isValidated = isPriceValidated && isQuantityValidated;

    if (!isValidated) {
      return alert('error', 'Lütfen ürün fiyatı ve adedini doğru girdiğinize emin olunuz...');
    }

    dispatch(CLEAR_ERROR());
    dispatch(START_LOADING());

    const body = {
      price,
      quantity,
      total: price * quantity,
      production_status_id,
      material,
      glass,
      branch,
      lampholder,
      features,
      specialNotes,
      color,
      manufacturers_id,
      orderFinancialDetails: {
        orders_id,
        productsTotal,
        discountTotal,
        subTotal,
        KDVtotal,
        total,
        totalDiff,
      },
    };

    try {
      await AtolyeAPI.put(`/productions/${productionIdToUpdate}`, body);
      alert('success', 'Ürün üretim detayları başarıyla güncellendi');
    } catch (error) {
      alert('error', 'Ürün üretim detayları güncellenirken hata oluştu');
      console.log(error);
    } finally {
      fetchOrder(order.id);
      dispatch(STOP_LOADING());
      dispatch(RESET_PRODUCTION_DETAILS_FOR_UPDATE());
      dispatch(HIDE_PRODUCTION_EDIT_MODAL());
    }
  };

  return (
    <Modal
      className="production-details-edit-modal"
      centered
      show={isProductionStatusEditModalDisplayed}
      onHide={() => dispatch(HIDE_PRODUCTION_EDIT_MODAL())}>
      <Modal.Header closeButton>
        <Modal.Title>Ürün Detaylarını Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {userPermissions?.includes(9) && (
          <>
            <Row className="my-2">
              <Col>
                <Form.Label>Ürün Fiyatı</Form.Label>
                <FormControl
                  type="number"
                  defaultValue={(price * 1.18).toFixed(2)}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({
                        key: 'price',
                        value: parseFloat(e.target.value / 1.18),
                      }),
                    )
                  }
                />
              </Col>
              <Col>
                <Form.Label>Ürün Adedi</Form.Label>
                <FormControl
                  type="number"
                  value={quantity}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({
                        key: 'quantity',
                        value: parseInt(e.target.value),
                      }),
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <Form.Label>Üretici</Form.Label>
                <Select
                  isLoading={isManufacturerOptionsLoading}
                  options={manufacturerOptions}
                  value={manufacturerOptions.find((option) => option.value == manufacturers_id)}
                  onChange={(option) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({
                        key: 'manufacturers_id',
                        value: option.value,
                      }),
                    )
                  }
                />
              </Col>
              <Col>
                <Form.Label>Materyal</Form.Label>
                <FormControl
                  value={material}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'material', value: e.target.value }),
                    )
                  }
                />
              </Col>
            </Row>

            <Row className="my-2">
              <Col>
                <Form.Label>Cam</Form.Label>
                <FormControl
                  value={glass}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'glass', value: e.target.value }),
                    )
                  }
                />
              </Col>
              <Col>
                <Form.Label>Kol Sayısı</Form.Label>
                <FormControl
                  value={branch}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'branch', value: e.target.value }),
                    )
                  }
                />
              </Col>
            </Row>

            <Row className="my-2">
              <Col>
                <Form.Label>Duy Tipi</Form.Label>
                <FormControl
                  value={lampholder}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({
                        key: 'lampholder',
                        value: e.target.value,
                      }),
                    )
                  }
                />
              </Col>
              <Col>
                <Form.Label>Renk</Form.Label>
                <FormControl
                  value={color}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'color', value: e.target.value }),
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <Form.Label>Ürün Özellikleri</Form.Label>
                <FormControl
                  as="textarea"
                  rows={5}
                  value={features}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'features', value: e.target.value }),
                    )
                  }
                />
              </Col>
              <Col>
                <Form.Label>Özel Notlar</Form.Label>
                <FormControl
                  as="textarea"
                  rows={5}
                  value={specialNotes}
                  onChange={(e) =>
                    dispatch(
                      SET_PRODUCTION_DETAILS_FOR_UPDATE({
                        key: 'specialNotes',
                        value: e.target.value,
                      }),
                    )
                  }
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="my-2">
          <Col>
            <Form.Label>Üretim Durumu</Form.Label>
            <Form.Select
              value={production_status_id}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCTION_DETAILS_FOR_UPDATE({
                    key: 'production_status_id',
                    value: e.target.value,
                  }),
                )
              }>
              <option value={0} disabled>
                Seçiniz
              </option>
              <option value={1}>Planlamada</option>
              <option value={2}>Üretimde</option>
              <option value={3}>Kontrol ve Paketleme</option>
              <option value={4}>Teslime Hazır</option>
              <option value={5}>Teslim Edildi</option>
            </Form.Select>
          </Col>
          <Col></Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => dispatch(HIDE_PRODUCTION_EDIT_MODAL())}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={updateProductionStatus}>
          Güncelle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductionEditModal;
