import { useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Col, Form, Row } from 'react-bootstrap';
import useTags from 'hooks/useTags';
import { useDispatch, useSelector } from 'react-redux';
import { SET_TAGS } from '../productsSlice';

const animatedComponents = makeAnimated();

const TagFilter = ({ setTagFilter, tagFilter }) => {
  // Redux
  const dispatch = useDispatch();
  const { tagsToAdd } = useSelector((state) => state.products.products);
  // Hooks
  const { tagOptions, isTagsLoading } = useTags();

  //Effects
  useEffect(() => {
    setTagFilter({ ...tagFilter, tagIds: tagsToAdd.map((tag) => tag.id) });
  }, [tagsToAdd]);

  return (
    <Row className="justify-content-center">
      <Col md={6} className="m-2">
        <Form.Group>
          <div className="d-flex">
            <Form.Label className="border-bottom border-dark mx-auto">
              Etikete Göre Filterele
            </Form.Label>
          </div>

          {isTagsLoading ? (
            'Yükleniyor...'
          ) : (
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder="Etiketleri seçin"
              value={tagsToAdd?.map((item) => ({
                label: item.name,
                value: item.id,
                color: item.color,
              }))}
              onChange={(values) => {
                dispatch(
                  SET_TAGS(
                    values.map((item) => ({
                      id: item.value,
                      name: item.label,
                      color: item.color,
                    })),
                  ),
                );
              }}
              isMulti
              options={tagOptions}
            />
          )}
        </Form.Group>
      </Col>
    </Row>
  );
};

export default TagFilter;
