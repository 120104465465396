import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useCustomerOptions = () => {
  // State
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isCustomerOptionsLoading, setIsCustomerOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getCustomerOptions();
  }, []);

  // Functions
  const getCustomerOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/customers/options/order');
      setCustomerOptions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCustomerOptionsLoading(false);
    }
  };

  return {
    isCustomerOptionsLoading,
    customerOptions,
    getCustomerOptions,
  };
};

export default useCustomerOptions;
