import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useUsers = () => {
  // State
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  // Effects
  useEffect(() => {
    getUsers();
  }, []);

  // Functions
  const getUsers = async () => {
    try {
      const { results } = await AtolyeAPI.get('/users');
      setUsers(results.filter((user) => !user.isDeleted));
    } catch (error) {
      console.log(error);
    } finally {
      setIsUsersLoading(false);
    }
  };

  return {
    isUsersLoading,
    users,
  };
};

export default useUsers;
