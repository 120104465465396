import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const CodeFilter = ({ codeSearch, setCodeSearch, getComponents }) => {
  // Functions
  // Handle search when the user breaks from typing for 1 second
  // This is to prevent spamming the server with requests
  // If the user continues typing, the timer resets
  const handleSearch = () => {
    clearTimeout(window.searchTimer);
    window.searchTimer = setTimeout(() => {
      getComponents();
    }, 1000);
  };

  return (
    <div className="mx-2 my-auto">
      <InputGroup>
        <Form.Control
          size="sm"
          type="text"
          placeholder="Parça Kodu"
          value={codeSearch}
          onChange={({ target }) => setCodeSearch(target.value)}
          onKeyUp={handleSearch}
        />
      </InputGroup>
    </div>
  );
};

export default CodeFilter;
