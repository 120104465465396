import { useSelector } from 'react-redux';

import { Form, Select } from '..';

const OrderSelect = ({ options, isLoading, onChange, placeholder }) => {
  // Redux
  const { order } = useSelector((state) => state.orders.singleOrder);
  return (
    <Form.Group className="col-md-6 mx-auto mb-5 mt-4">
      <Form.Label>Sipariş: </Form.Label>
      <Select
        options={options}
        isLoading={isLoading}
        onChange={onChange}
        placeholder={placeholder}
        value={options?.find((option) => option.value == order?.id)}
      />
    </Form.Group>
  );
};

export default OrderSelect;
