import '../Tags/Tags.scss';

import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { roundTo2Decimals } from 'utils/calcUtils';

const ProductImage = ({ value }) => {
  if (!value) {
    return <img src="http://placehold.jp/100x100.png" className="rounded" alt="" width={100} />;
  }
  let imgParts = value.split('.');
  let ext = imgParts.pop();
  let imgThumb = imgParts.join('.') + '-thumb.' + ext;
  imgThumb = imgThumb.replace(
    'https://dev.images.atolyestore.com/products/',
    'https://dev.images.atolyestore.com/product_thumbs/',
  );
  return (
    <img
      src={imgThumb}
      className="rounded"
      alt=""
      width={100}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = value || 'http://placehold.jp/100x100.png';
      }}
    />
  );
};

const ProductCategory = ({ value }) => (
  <Badge pill className="bg-light font-12 text-primary">
    {value}
  </Badge>
);

const BoldText = ({ value }) => <strong>{value}</strong>;

const ProductLink = ({ value }) => (
  <Link to={`/single-product?code=${value}`}>
    <Badge pill className="bg-success font-12 pointer">
      {value}
    </Badge>
  </Link>
);

const SalePriceCell = ({ value }) => (
  <strong className="text-danger">{roundTo2Decimals(value * 1.18).toLocaleString('tr')} ₺</strong>
);

const ProductTags = ({ value }) => (
  <div style={{ minWidth: 200 }}>
    {value.map((tag) => (
      <span key={`tag-${tag.id}`} className={`color-picker-item ${tag.color} m-1`}>
        <span className="color-picker-item-label">{tag.name}</span>
      </span>
    ))}
  </div>
);
export const columnsData = [
  {
    Header: 'ÜRÜN KODU',
    accessor: 'id',
    columnName: 'id',
    Cell: ProductLink,
  },
  {
    Header: 'GÖRSEL',
    accessor: 'imageLink',
    columnName: 'imageLink',
    Cell: ProductImage,
    disableFilters: true,
    disableSortBy: true,
  },

  {
    Header: 'AÇIKLAMA',
    accessor: 'description',
    columnName: 'description',
    Cell: BoldText,
  },
  {
    Header: 'KATEGORİ',
    accessor: 'product_category.name',
    columnName: 'product_category.name',
    Cell: ProductCategory,
    disableSortBy: true,
  },
  {
    Header: 'SATIŞ FİYATI (KDVli)',
    accessor: 'salePrice',
    columnName: 'salePrice',
    Cell: SalePriceCell,
    disableFilters: true,
  },
  {
    Header: 'ETİKETLER',
    accessor: 'tags',
    columnName: 'tags',
    Cell: ProductTags,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'SON GÜNCELLEME',
    accessor: 'updatedAt',
    columnName: 'updatedAt',
    Cell: ({ value }) => new Date(value).toLocaleString('tr'),
    disableFilters: true,
  },
  {
    Header: 'OLUŞTURULMA',
    accessor: 'createdAt',
    columnName: 'createdAt',
    Cell: ({ value }) => new Date(value).toLocaleString('tr'),
    disableFilters: true,
  },
];
