import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useProductOptions = () => {
  // State
  const [productOptions, setProductOptions] = useState([]);
  const [productOptionsOnlyInStock, setProductOptionsOnlyInStock] = useState([]);
  const [productOptionsWithVariants, setProductOptionsWithVariants] = useState([]);
  const [isProductOptionsLoading, setIsProductOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getProductOptions();
    getProductOptionsOnlyInStock();
  }, []);

  // Functions
  const getProductOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/products/options/order');
      setProductOptions(results);

      // Get product options with variants
      let productOptionsWithVariants = [];

      // add variants as additional options
      // Products with no variants will be added as label + "- Ana Ürün"
      // Products with variants will be added as label + "- ${variantName}"
      results.forEach((productOption) => {
        const { variants } = productOption.productDetails;
        if (variants.length === 0) {
          productOptionsWithVariants.push({
            label: `${productOption.label} - Ana Ürün`,
            imageLink: productOption.imageLink,
            products_id: productOption.value,
            variants_id: null,
          });
        } else {
          productOptionsWithVariants.push({
            label: `${productOption.label} - Ana Ürün`,
            imageLink: productOption.imageLink,
            products_id: productOption.value,
            variants_id: null,
          });
          variants.forEach((variant) => {
            productOptionsWithVariants.push({
              label: `${productOption.label} - ${variant.name}`,
              imageLink: variant.image_link,
              products_id: productOption.value,
              variants_id: variant.id,
            });
          });
        }
      });
      setProductOptionsWithVariants(productOptionsWithVariants);
    } catch (error) {
      console.log(error);
    } finally {
      setIsProductOptionsLoading(false);
    }
  };

  const getProductOptionsOnlyInStock = async () => {
    try {
      const { results } = await AtolyeAPI.get('/products/options/order/only-in-stock');
      setProductOptionsOnlyInStock(results);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    isProductOptionsLoading,
    productOptions,
    productOptionsOnlyInStock,
    productOptionsWithVariants,
  };
};

export default useProductOptions;
