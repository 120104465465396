import { Button } from 'react-bootstrap';
import AtolyeTable from 'components/AtolyeTable';
import ContentWrapper from 'components/shared/ContentWrapper';
import Footer from 'components/shared/Footer/Footer';
import Header from 'components/shared/Header';
import PageTitle from 'components/shared/PageTitle';
import PageWrapper from 'components/shared/PageWrapper';

import { useUser } from 'hooks';

import PaymentStatus from './PaymentStatus';

export { AtolyeTable, Button, ContentWrapper, Footer, Header, PageTitle, PageWrapper, useUser };

export default PaymentStatus;
