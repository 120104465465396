import { useSelector, useDispatch } from 'react-redux';

import { DatePicker, Select } from '..';
import {
  SET_ORDER_BALANCE_DATE,
  SET_ORDER_BALANCE_RECEIVED,
  SET_ORDER_BALANCE_REMAINDER,
  SET_ORDER_BALANCE_USER,
  SET_ORDER_PAYMENT_METHOD,
} from '../createOrderSlice';

const PaymentForm = ({ isUserOptionsLoading, userOptions }) => {
  // Redux
  const dispatch = useDispatch();
  const { balanceDate, balanceReceived, paymentMethod, balanceUserId } = useSelector(
    (state) => state.orders.createOrder.order,
  );

  // Hooks

  return (
    <div className="card card-dark">
      <div className="card-header bg-dark  font-weight-bold text-center ">Ödeme Bilgileri</div>
      <div className="card-body">
        <form className="row">
          <div className="form-group col-md-4">
            <label htmlFor="">Ödeme Tarihi</label>
            <DatePicker
              selected={Date.parse(balanceDate)}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              placeholder="Tarih seçin"
              withPortal
              onChange={(date) => dispatch(SET_ORDER_BALANCE_DATE(date))}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="">Ödeme Tutarı</label>
            <input
              type="number"
              className="form-control"
              onChange={(e) => {
                dispatch(SET_ORDER_BALANCE_RECEIVED(e.target.value));
                dispatch(SET_ORDER_BALANCE_REMAINDER(e.target.value));
              }}
              value={balanceReceived}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="">Ödeme Yöntemi</label>

            <select
              name="paymentMethod"
              id="paymentMethod"
              className="form-control"
              value={paymentMethod}
              onChange={(e) => dispatch(SET_ORDER_PAYMENT_METHOD(e.target.value))}>
              <option value="Belirlenmedi" disabled>
                Belirlenmedi
              </option>
              <option value="Kredi/Banka Kartı">Kredi/Banka Kartı</option>
              <option value="Havale">Havale</option>
              <option value="Nakit">Nakit</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="">Ödemeyi Alan Yetkili</label>
            <Select
              options={userOptions.map((item) => ({ label: item.fullName, value: item.id }))}
              isLoading={isUserOptionsLoading}
              placeholder="Yetkili Seçin"
              onChange={({ value }) => dispatch(SET_ORDER_BALANCE_USER(value))}
              value={{
                label: userOptions.find((item) => item.id == balanceUserId)?.fullName,
                value: userOptions.find((item) => item.id == balanceUserId)?.id,
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;
