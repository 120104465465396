import './TablePagination.scss';

import { Form, Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import { pageSizeOptions } from 'config/adminPanelSettings';

const TablePagination = ({ pageIndex, pageCount, pageSize, gotoPage, setPageSize, total }) => {
  return (
    <>
      <ReactPaginate
        breakLabel="..."
        containerClassName="pagination-container hide-on-print"
        activeClassName="active"
        forcePage={pageIndex}
        nextLabel=">"
        onPageChange={(event) => gotoPage(event.selected)}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />

      <div className="d-flex justify-content-between align-content-center hide-on-print">
        <span className="d-flex">
          <p className="mb-0 my-auto">
            <strong>{total}</strong> kayıt bulunmaktadır.
          </p>
        </span>

        <Form.Group className="mb-0" as={Row}>
          <Form.Label column sm="5">
            Sayfaya git:
          </Form.Label>
          <Col sm="4">
            <Form.Control
              type="number"
              size="sm"
              value={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group className="mb-0" as={Row}>
          <Form.Label column sm="4">
            Göster:
          </Form.Label>
          <Col sm="8">
            <Form.Control
              size="sm"
              as="select"
              className="font-12 p-1 pe-3"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}>
              {pageSizeOptions.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} kayıt
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </div>
    </>
  );
};

export default TablePagination;
