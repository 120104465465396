import '../Tags.scss';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { useState } from 'react';
import { tagColorOptions } from '../utils';

const AtolyeAPI = new API();

const CreateTagModal = ({ show, onDismiss, refresh }) => {
  // State

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [color, setColor] = useState('gray');

  // Functions

  const createTag = async () => {
    if (!name) {
      return alert('error', 'Etiket Adı boş bırakılamaz');
    }

    const body = {
      name,
      color,
    };

    setIsLoading(true);

    try {
      const { status } = await AtolyeAPI.post('/tags', body);

      if (status && status === 'SUCCESS') {
        return alert('success', 'Ürün Etiketi başarıyla oluşturuldu');
      }
      return alert('error', 'Ürün Etiketi oluşturulurken bir hata oluştu');
    } catch (error) {
      alert('error', 'Ürün Etiketi oluşturulurken bir hata oluştu');
      console.log(error);
    } finally {
      setIsLoading(false);
      setName('');
      setColor('');
      dismissHandler();
      refresh();
    }
  };

  const dismissHandler = () => {
    setName('');
    setColor('');
    onDismiss();
  };

  return (
    <Modal show={show} onHide={dismissHandler}>
      <Modal.Header className="bg-success" closeButton>
        <Modal.Title>Yeni Ürün Etiketi Oluştur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Etiket Adı</Form.Label>
          <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
          <Form.Text className="text-muted">Etiket Adı girin. Bu alan zorunludur</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Renk</Form.Label>
          {tagColorOptions.map((item, i) => (
            <Form.Check
              className="my-2"
              key={`color-${i}`}
              type="radio"
              label={
                <span className={`color-picker-item ${item}`}>
                  <span className="color-picker-item-label">{name}</span>
                </span>
              }
              onChange={() => setColor(item)}
              checked={item === color}
            />
          ))}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={dismissHandler}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={() => createTag()}>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            'Oluştur'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTagModal;
