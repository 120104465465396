import alert from 'js/sweetAlert';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { REMOVE_PRODUCT, SET_PRODUCT_DETAIL } from '../createOrderSlice';
import { roundTo2Decimals } from 'utils/calcUtils';

const OrderProduct = ({ product, index }) => {
  //Redux
  const dispatch = useDispatch();
  return (
    <div
      style={{
        backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
      }}>
      <div className="card-body row bg-light mx-0">
        <div className="col-md-6">
          <div className="product-image text-center">
            <img className="img-fluid" src={product.imageLink} width={300} alt="" />
          </div>
          <div className="form-group">
            <label>Ürün Kodu</label>
            <input
              type="text"
              className="form-control"
              defaultValue={product.id}
              disabled
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Açıklama</label>
            <input
              type="text"
              className="form-control"
              value={product.description}
              disabled
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Kategori</label>
            <input
              type="text"
              className="form-control"
              value={product.category}
              disabled
              readOnly
            />
          </div>
          <div className="form-group">
            <label className="text-danger">Ürün Varyantı</label>
            <Form.Select
              value={product?.selectedVariant?.id || ''}
              onChange={(e) => {
                dispatch(
                  SET_PRODUCT_DETAIL({
                    index,
                    value: product.variants.find((variant) => variant.id == e.target.value) || {
                      id: null,
                      price: product.basePrice,
                      imageLink: product.baseImageLink,
                      stock_count: product.stocks[0].stock,
                    },
                    field: 'selectedVariant',
                  }),
                );
                dispatch(
                  SET_PRODUCT_DETAIL({
                    index,
                    value: false,
                    field: 'isGivenByStock',
                  }),
                );
              }}>
              <option value="">Ana Ürün</option>
              {product?.variants &&
                product.variants.map((option, i) => (
                  <option key={'variant-' + i} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </Form.Select>
          </div>

          <div className="rounded p-2">
            <input
              type="checkbox"
              checked={product.isGivenByStock}
              onChange={(e) => {
                // Check if there is stock
                if (product.selectedVariant?.id && product.selectedVariant.stock_count < 1) {
                  alert('error', 'Bu ürün stokta bulunmamaktadır');
                  return;
                }
                if (!product.selectedVariant?.id && product.stocks[0].stock < 1) {
                  alert('error', 'Bu ürün stokta bulunmamaktadır');
                  return;
                }
                // Check if there is enough stock
                if (
                  product.selectedVariant?.id &&
                  product.selectedVariant.stock_count < product.quantity
                ) {
                  alert('error', 'Stokta yeterli miktarda ürün bulunmamaktadır');
                  return;
                }
                if (!product.selectedVariant?.id && product.stocks[0].stock < product.quantity) {
                  alert('error', 'Stokta yeterli miktarda ürün bulunmamaktadır');
                  return;
                }

                dispatch(
                  SET_PRODUCT_DETAIL({
                    index,
                    value: e.target.checked,
                    field: 'isGivenByStock',
                  }),
                );
              }}
            />
            <label className="ms-2">
              Stoktan Düşülsün -{' '}
              <span className="text-primary">
                {product.quantity} adet düşülecek
                <span className="text-danger ms-1">
                  (Mevcut Stok:
                  {product.selectedVariant?.id
                    ? product.selectedVariant.stock_count
                    : product.stocks[0].stock}
                  )
                </span>
              </span>
            </label>
            {product.isGivenByStock ? (
              <p className="alert alert-primary">
                Dikkat! <strong>{product.quantity} adet</strong> ürün stoktan verilecektir. Ürünler
                üretim planlamasına dahil edilmeden <strong>Teslime Hazır</strong> statüde siparişe
                eklenecektir. Mevcut stok bilgisini aşağıdaki tabloda bulabilirsiniz
              </p>
            ) : (
              <p className="alert alert-warning ">
                Bu kutucuğun işaretlenmesi durumunda <strong>{product.quantity} adet</strong> ürün
                stoktan düşülerek <strong>Teslime Hazır</strong> statüde siparişe eklenecektir.{' '}
              </p>
            )}
          </div>
          <hr />
          <div className="border border-dark rounded p-2">
            <label className="">Mevcut Stok Bilgisi</label>
            <ul>
              {product.stocks.map((stockData) => (
                <li key={'stok-' + stockData.id} className="p-2">
                  {product.description} <span className="text-primary ms-1">{stockData.name}</span>
                  <span
                    className={`badge badge-${
                      stockData.stock > 0 ? 'success' : 'danger'
                    } ms-3 font-14`}>
                    {stockData.stock}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Birim Fiyat <span className="badge badge-success">KDV Dahil</span>
            </label>
            <input
              type="number"
              className="form-control"
              min={0}
              step={0.01}
              value={roundTo2Decimals(product.price * 1.18) || ''}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCT_DETAIL({
                    index,
                    value: parseFloat(e.target.value) / 1.18 || 0,
                    field: 'price',
                  }),
                )
              }
            />
          </div>
          <div className="alert alert-warning">
            Birim fiyatı <strong>KDV Dahil</strong> olarak giriniz
          </div>
          <div className="form-group">
            <label>Adet</label>
            <input
              type="number"
              className="form-control"
              value={product.quantity}
              onChange={(e) =>
                dispatch(SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'quantity' }))
              }
            />
          </div>

          <div className="form-group">
            <label>Materyal</label>
            <input
              className="form-control"
              type="text"
              value={product.material}
              onChange={(e) =>
                dispatch(SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'material' }))
              }
            />
          </div>

          <div className="form-group">
            <label>Cam/Abajur</label>
            <input
              className="form-control"
              type="text"
              value={product.glass}
              onChange={(e) =>
                dispatch(SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'glass' }))
              }
            />
          </div>

          <div className="form-group">
            <label>Kol Sayısı</label>
            <input
              className="form-control"
              type="text"
              value={product.branch}
              onChange={(e) =>
                dispatch(SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'branch' }))
              }
            />
          </div>

          <div className="form-group">
            <label>Renk/Kaplama</label>
            <input
              type="text"
              className="form-control"
              value={product.color}
              onChange={(e) =>
                dispatch(SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'color' }))
              }
            />
          </div>

          <div className="form-group">
            <label>Duy Tipi</label>
            <input
              type="text"
              className="form-control"
              value={product.lampholder}
              onChange={(e) =>
                dispatch(SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'lampholder' }))
              }
            />
          </div>

          <div className="form-group">
            <label>Ürün Özellikleri</label>
            <textarea
              type="text"
              className="form-control"
              rows={4}
              value={product.features}
              placeholder="Ürünün ölçüleri, asansör/kablo bilgisi mutlaka girilecektir!!!"
              onChange={(e) =>
                dispatch(SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'features' }))
              }></textarea>
          </div>

          <div className="form-group">
            <label>Özel Notlar</label>
            <textarea
              type="text"
              className="form-control"
              rows={4}
              value={product.specialNotes}
              placeholder="Ürünle ilgili ekstra detaylar bu bölüme yazılacaktır!!!"
              onChange={(e) =>
                dispatch(
                  SET_PRODUCT_DETAIL({ index, value: e.target.value, field: 'specialNotes' }),
                )
              }></textarea>
          </div>

          {/* <div className="alert alert-success">
          KDV Hariç Toplam Tutar:{" "}
          {(this.state.salePrice * this.state.quantity).toFixed(1)} ₺
        </div> */}
          <div className="alert alert-info">
            KDV Dahil Toplam Tutar: {(product.price * product.quantity * 1.18).toFixed(1)} ₺
          </div>

          {/* Prdocu Delete */}

          <button
            className="btn btn-sm text-white btn-danger"
            onClick={() => {
              dispatch(REMOVE_PRODUCT(index));
              alert('success', 'Ürün başarıyla siparişten silindi');
            }}>
            Sil - {product.id} ({index + 1}. Ürün)
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderProduct;
