import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const EditableCell = ({
  value,
  column,
  row: {
    original: { id },
  },
  onSave,
}) => {
  // Redux
  const { userPermissions } = useSelector((state) => state.general.user);
  // State
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cellValue, setCellValue] = useState(value || '');

  useEffect(() => {
    setCellValue(value || '');
  }, [value]);

  // Functions

  const saveHandler = async () => {
    setIsLoading(true);

    const body = {
      [column.key]: cellValue,
    };

    try {
      await AtolyeAPI.put(`/productions/${id}`, body);
      onSave();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShow(false);
    }
  };
  return (
    column && (
      <div>
        {value || ''}
        {userPermissions?.includes(17) && (
          <Button variant="" size="sm" className="mx-2 py-1" onClick={() => setShow(true)}>
            <i className="fas fa-edit text-primary font-12"></i>
          </Button>
        )}
        {/* // Edit Modal */}
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Düzenle - {column.Header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <Form.Control
                as="textarea"
                rows={3}
                value={cellValue}
                onChange={(e) => setCellValue(e.target.value)}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="sm" onClick={() => setShow(false)}>
              Vazgeç
            </Button>
            <Button variant="success" className="text-white" size="sm" onClick={saveHandler}>
              Güncelle
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};

export default EditableCell;
