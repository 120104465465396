import {
  CustomerLastNameLink,
  CustomerNameLink,
  DateCell,
} from 'components/AtolyeTable/components/customCellComponents';
import { Badge, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OrderLink = ({ value }) => (
  <Link to={`/single-order?id=${value}`}>
    <Badge variant="light" className="font-12 pointer border">
      {value}
    </Badge>
  </Link>
);

const Status = ({ row: { original } }) => {
  const total = original.order.total;
  const paid = original.received_total;

  return total === 0 ? (
    <ProgressBar
      variant="warning"
      now={100}
      style={{ minWidth: 100, maxHeight: 20 }}
      label="Tutar yok"></ProgressBar>
  ) : (
    <ProgressBar style={{ minWidth: 100, maxHeight: 20 }}>
      <ProgressBar
        variant="success"
        now={(paid / total) * 100}
        label={`%${((paid / total) * 100).toFixed(0)}`}
      />
      <ProgressBar
        variant="light"
        now={((total - paid) / total) * 100}
        label={`%${(((total - paid) / total) * 100).toFixed(0)}`}
      />
    </ProgressBar>
  );
};

const RemainderCell = ({ row: { original } }) => {
  const total = original.order.total;
  const paid = original.received_total;

  const remainder = (total - paid).toLocaleString();
  return <strong className="text-danger">{remainder} ₺</strong>;
};

export const columnsData = [
  {
    Header: 'SİPARİŞ NO.',
    accessor: 'order.id',
    columnName: 'order.id',
    Cell: OrderLink,
    disableSortBy: true,
    disableFilters: false,
  },
  {
    Header: 'MÜŞTERİ ADI',
    accessor: 'order.customer.name',
    columnName: 'order.customer.name',
    Cell: CustomerNameLink,
    disableSortBy: true,
    // disableFilters: true
  },

  {
    Header: 'MÜŞTERİ SOYADI',
    accessor: 'order.customer.lastName',
    columnName: 'order.customer.lastName',
    Cell: CustomerLastNameLink,
    disableSortBy: true,
    // disableFilters: true
  },

  {
    Header: 'SATIŞ TARİHİ',
    accessor: 'order.saleDate',
    columnName: 'order.saleDate',
    Cell: DateCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'TAH.TES.TARİHİ',
    accessor: 'order.deliveryDate',
    columnName: 'order.deliveryDate',
    Cell: DateCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'SATICI',
    accessor: 'order.user.fullName',
    columnName: 'order.user.fullName',
    disableSortBy: true,
    // disableFilters: true,
  },
  {
    Header: 'SİPARİŞ TUTARI',
    accessor: 'order.total',
    Cell: ({ value }) => <strong className="text-dark">{value.toLocaleString()} ₺</strong>,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ÖDENEN MİKTAR',
    accessor: 'received_total',
    Cell: ({ value }) => <strong className="text-success">{value.toLocaleString()} ₺</strong>,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'KALAN MİKTAR',
    accessor: '',
    Cell: RemainderCell,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'DURUM',
    accessor: '',
    Cell: Status,
    disableSortBy: true,
    disableFilters: true,
  },
];
