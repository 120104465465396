import { Text, View } from '@react-pdf/renderer';

const DateSection = ({ order, translations, lang, isPriceOffer = false }) => {
  return (
    <View>
      <Text
        style={[
          { textAlign: 'right' },
          { fontSize: 10 },
          { marginRight: 3 },
          { marginTop: 10 },
          { fontFamily: 'Open Sans' },
          { color: '#8b0000' },
        ]}>
        <Text style={[{ color: '#000' }]}>{translations.date[lang]} : </Text>
        {order &&
          (isPriceOffer
            ? new Date(order.createdAt).toLocaleDateString('tr')
            : new Date(order.saleDate).toLocaleDateString('tr'))}
      </Text>
    </View>
  );
};

export default DateSection;
