import '../Authentication.scss';

import { SET_AUTH_STATUS, SET_USER, authStatusEnum } from 'app/state/generalSlice';
import { Auth } from 'aws-amplify';
import alert from 'js/sweetAlert';
import { useState } from 'react';
import { FormGroup, Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const SignIn = () => {
  // Redux
  const dispatch = useDispatch();
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Functions
  const signIn = () => {
    if (username === '' || password === '') {
      alert('error', 'Lütfen tüm alanları doldurunuz.');
      return;
    }

    setIsLoading(true);
    try {
      Auth.signIn(username, password)
        .then(async (user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            dispatch(SET_AUTH_STATUS(authStatusEnum.NewPassRequired));
            dispatch(SET_USER(user));
            setIsLoading(false);
            return;
          }

          if (user) {
            const body = {
              email: user.attributes.email,
            };
            console.log("Getting current user's data...");
            const { results } = await AtolyeAPI.post(`/users/email`, body);
            const userData = results;
            const { name, lastName, fullName, email, roles_id, id, permissions } = userData;
            const userPermissions = await permissions.map((permission) => permission.id);
            dispatch(
              SET_USER({
                userName: name,
                userLastName: lastName,
                userFullName: fullName,
                userEmail: email,
                userRole: roles_id,
                userId: id,
                userPermissions,
                cognitoAttributes: user.attributes,
              }),
            );
            dispatch(SET_AUTH_STATUS(authStatusEnum.LoggedIn));
            alert('success', 'Giriş başarılı.');
          }
        })
        .catch((err) => {
          console.log(err);
          alert('error', 'Kullanıcı adı veya şifreniz hatalı.');
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      alert('error', error.message);
    }
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo bg-dark p-2 rounded">
          <img
            src="https://cdn.shopify.com/s/files/1/0605/8056/1151/files/atolyestore_190x.png?v=1645870921"
            height="60"
            alt=""
          />
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Hesabınıza giriş yapın.</p>

            <FormGroup>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>

            <FormGroup className="pt-3">
              <Form.Label>Şifre</Form.Label>
              <Form.Control
                type="password"
                placeholder="Şifre"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>

            <Row className="pt-3">
              <Col xs={8}></Col>
              <Col xs={4}>
                <Button variant="primary" className="btn-block" onClick={signIn}>
                  Giriş
                </Button>
              </Col>
            </Row>

            <p className="mb-1">
              <Button
                className="link-btn"
                onClick={() => dispatch(SET_AUTH_STATUS(authStatusEnum.ForgotPassword))}>
                <small>Şifremi unuttum</small>
              </Button>
            </p>
          </div>

          {isLoading && (
            <div className="overlay">
              <i className="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
