import { styles } from '../styles';

import { Text, View, Image } from '@react-pdf/renderer';

const BrandSection = () => {
  return (
    <View style={styles.logoView}>
      <Image style={styles.image} src="/images/atolye-logo.png" />
      <Text style={styles.infoText}>Okçu Musa Caddesi, Bank Çarşı, No:11 Z:16</Text>
      <Text style={styles.infoText}>Şişhane / İSTANBUL</Text>
      <Text style={styles.infoText}>Tel: 0 (212) 249 25 13 - Faks: 0 (212) 249 25 14</Text>
    </View>
  );
};

export default BrandSection;
