import { useDispatch, useSelector } from 'react-redux';
import { SET_ORDER_BILL } from '../createOrderSlice';

const BillSelection = () => {
  //Redux
  const dispatch = useDispatch();
  const { bill } = useSelector((state) => state.orders.createOrder.order);
  return (
    <div className="card card-dark">
      <div className="card-header bg-dark  font-weight-bold text-center ">Fatura Bilgileri</div>
      <div className="card-body">
        <form className="row">
          <div className="form-group col-md-4">
            <label htmlFor="">Fatura Kesildi Mi?</label>

            <select
              name="bill"
              id="bill"
              className="form-control"
              value={bill}
              onChange={(e) => dispatch(SET_ORDER_BILL(e.target.value))}>
              <option value="Kesilmedi">Kesilmedi</option>
              <option value="Kesildi">Kesildi</option>
            </select>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BillSelection;
