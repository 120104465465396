import { Alert, Button, Table } from 'react-bootstrap';
import { ContentWrapper, Footer, Header, PageTitle, PageWrapper } from '.';
import { useUser } from '../Products';
import { useEffect, useState } from 'react';
import AddSoldProductModal from './components/AddSoldProductModal';
import AtolyeDatePicker from 'components/AtolyeTable/components/AtolyeDatePicker';

import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { Loading } from 'pages/Dashboard';
import moment from 'moment';

const AtolyeAPI = new API();

const SoldProducts = ({ props }) => {
  // Hooks
  const { user, userPermissions } = useUser();

  // States
  const [products, setProducts] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    startDate: moment('01-01-1970'),
    endDate: moment(),
  });
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Effects
  useEffect(() => {
    if (products.length > 0) {
      handleSubmit();
    }
  }, [dateFilter]);

  // Functions
  const handleSubmit = async () => {
    const body = {
      products: products.map((product) => product.value),
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };

    setIsLoading(true);

    try {
      const { results } = await AtolyeAPI.post('/products/sold', body);
      setData(results);
      if (results.length === 0) {
        alert('error', 'Aranan kriterlere uygun veri bulunmamaktadır');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      console.log(products);
    }
  };

  const handleClear = () => {
    setData([]);
    setProducts([]);
  };

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Ürün Satış Adedi Sorgulama" />
        {!userPermissions?.includes(33) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> Ürünleri satış adetlerini görüntülemek için yetkiniz
            bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            <AtolyeDatePicker
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              isPriceOffersDateFilter
            />
            <div className="p-5 text-center">
              <h2>Sorgulanacak ürünler</h2>
              {products.length === 0 ? (
                <Alert variant="warning">Sorgulanacak ürün eklenmedi.</Alert>
              ) : (
                products.map((product, index) => (
                  <div key={product.value} className="my-2">
                    {product.label}
                    <Button
                      variant="outline-danger"
                      className="border-0 ms-2"
                      size="sm"
                      onClick={() => {
                        const newProducts = [...products];
                        newProducts.splice(index, 1);
                        setProducts(newProducts);
                      }}>
                      <i className="fas fa-trash"></i>
                    </Button>
                  </div>
                ))
              )}
              <Button
                variant="secondary"
                className="me-2"
                size="sm"
                onClick={() => setIsAddProductModalOpen(true)}>
                Ürün Ekle
              </Button>
              {products.length > 0 && (
                <Button variant="success" className="text-white" size="sm" onClick={handleSubmit}>
                  Sorgula
                </Button>
              )}
              {products.length > 0 && (
                <Button variant="outline-dark" className="ms-2" size="sm" onClick={handleClear}>
                  Temizle
                </Button>
              )}
            </div>
            {
              <div className="p-5">
                {isLoading ? (
                  <Loading />
                ) : (
                  data.length > 0 && (
                    <div>
                      {!dateFilter?.startDate && !dateFilter?.endDate ? (
                        <p>
                          <strong>Tüm zamanlar</strong> ürün satış bilgileri gösterilmektedir
                        </p>
                      ) : (
                        <p>
                          <strong>
                            {new Date(dateFilter.startDate).toLocaleDateString('tr', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </strong>{' '}
                          ile{' '}
                          <strong>
                            {new Date(dateFilter.endDate).toLocaleDateString('tr', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </strong>{' '}
                          tarihleri arasındaki ürün satış bilgileri gösterilmektedir
                        </p>
                      )}

                      {data?.map((item, index) => (
                        <Table key={index} striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th>Görsel</th>
                              <th>Ürün</th>
                              <th>Varyant</th>
                              <th>Renk</th>
                              <th>Satış Adedi</th>
                              <th>Toplam Tutar</th>
                              <th>Ortalama Tutar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">
                                <img
                                  src={
                                    item.productVariantImageLink ??
                                    products.find((product) => product.value == item.id).imageLink
                                  }
                                  width={80}
                                />
                              </td>
                              <td>{products.find((product) => product.value == item.id).label}</td>
                              <td>{item.productVariantName ?? 'Ana Ürün'}</td>
                              <td>{item.color ?? 'Renk Yok'}</td>
                              <td>{item.totalQuantity}</td>
                              <td>{parseFloat(item.totalPrice).toLocaleString('tr')} ₺</td>
                              <td>{parseFloat(item.avgPrice).toLocaleString('tr')} ₺</td>
                            </tr>
                          </tbody>
                        </Table>
                      ))}
                    </div>
                  )
                )}
              </div>
            }
            <AddSoldProductModal
              isAddProductModalOpen={isAddProductModalOpen}
              setIsAddProductModalOpen={setIsAddProductModalOpen}
              setProducts={setProducts}
            />
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default SoldProducts;
