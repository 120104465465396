import { styles } from '../styles';

import { Text, View } from '@react-pdf/renderer';

const ProductDetailsHeaderSection = ({ translations, lang, isPriceless }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.prodImageTitle}>
        <Text style={styles.tableTitle}>{translations.productImage[lang]}</Text>
      </Text>
      <Text style={styles.prodCode}>
        <Text style={styles.tableTitle}>{translations.productCode[lang]}</Text>
      </Text>
      <Text style={[styles.description, { width: isPriceless ? '47.5%' : '35%' }]}>
        <Text style={styles.tableTitle}>{translations.description[lang]}</Text>
      </Text>
      <Text style={[styles.qty, { width: isPriceless ? '20%' : '7.5%' }]}>
        <Text style={styles.tableTitle}>{translations.quantity[lang]}</Text>
      </Text>
      {!isPriceless && (
        <>
          <Text style={styles.price}>
            <Text style={styles.tableTitle}>{translations.price[lang]}</Text>
          </Text>
          <Text style={styles.total}>
            <Text style={styles.tableTitle}>{translations.total[lang]}</Text>
          </Text>
        </>
      )}
    </View>
  );
};

export default ProductDetailsHeaderSection;
