import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageIndex: 0,
  pageSize: 10,
  pageRows: 0,
  totalRows: 0,
};

export const atolyeTableSlice = createSlice({
  name: 'atolyeTable',
  initialState,
  reducers: {
    SET_TABLE: (state, action) => {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    },
    RESET: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { RESET, SET_TABLE } = atolyeTableSlice.actions;

export default atolyeTableSlice.reducer;
