import './CreatePriceOffer.scss';

import {
  ContentWrapper,
  CustomerSelection,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
  ProductSelection,
} from '.';
import ResponsiveLoading from 'components/ResponsiveLoading';

import { useUser } from 'hooks';
import useCustomerOptions from 'hooks/useCustomerOptions';
import useProductOptions from 'hooks/useProductOptions';
import useUserOptions from 'hooks/useUserOptions';

import useCreatePriceOffer from './hooks/useCreatePriceOffer';
import PriceOfferTotalDetails from './components/PriceOfferTotalDetails';
import PriceOfferDetailsForm from './components/PriceOfferDetailsForm';
import PriceOfferNotes from './components/PriceOfferNotes';
import { useSelector } from 'react-redux';
import FloatingTotalBox from 'components/FloatingTotalBox/FloatingTotalBox';
import { Alert } from 'react-bootstrap';

const CreatePriceOffer = () => {
  //Redux

  const { total } = useSelector((state) => state.priceOffers.createPriceOffer.priceOffer);
  const { userPermissions } = useSelector((state) => state.general.user);

  // Hooks
  const { user } = useUser();
  const { isProductOptionsLoading, productOptions, productOptionsOnlyInStock } =
    useProductOptions();
  const { isCustomerOptionsLoading, customerOptions, getCustomerOptions } = useCustomerOptions();
  const { isUserOptionsLoading, userOptions } = useUserOptions();

  const { createPriceOfferHandler, clearPriceOfferHandler, isLoading } = useCreatePriceOffer();

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Fiyat Teklifi Oluştur" />
        {!userPermissions?.includes(4) ? (
          <Alert variant="danger" className="text-center">
            Bu sayfaya erişim yetkiniz bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            {isLoading ? (
              <ResponsiveLoading />
            ) : (
              <>
                <FloatingTotalBox title={'Teklif Toplamı'} total={total} />
                <CustomerSelection
                  title="Kayıtlı Müşteri Seçin : "
                  isCustomerOptionsLoading={isCustomerOptionsLoading}
                  customerOptions={customerOptions}
                  getCustomerOptions={getCustomerOptions}
                />
                <PriceOfferDetailsForm
                  title="Oluşturan Alan Yetkili"
                  isUserOptionsLoading={isUserOptionsLoading}
                  userOptions={userOptions}
                />
                <ProductSelection
                  title="Ürün Ekle"
                  isProductOptionsLoading={isProductOptionsLoading}
                  productOptions={productOptions}
                  productOptionsOnlyInStock={productOptionsOnlyInStock}
                />
                <PriceOfferNotes />
                <PriceOfferTotalDetails title="İskonto ve Toplam Tutar" />

                <div className="text-center my-3 d-flex justify-content-center">
                  <div
                    className="btn btn-success text-white mx-2"
                    onClick={createPriceOfferHandler}>
                    Teklifi Oluştur
                  </div>
                  <div className="btn btn-info text-white mx-2" onClick={clearPriceOfferHandler}>
                    Temizle
                  </div>
                </div>
              </>
            )}
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default CreatePriceOffer;
