import './Footer.scss';

const Footer = () => {
  return (
    <footer className="main-footer hide-on-print">
      <div className="float-right d-none d-sm-block">
        <b>by</b> Nuensoft
      </div>
      <strong>Atolye Admin © 2020-2023</strong>
    </footer>
  );
};

export default Footer;
