import { OrderLink } from 'components/AtolyeTable/components/customCellComponents';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

const UpcomingOrdersTable = ({ upcomingOrders, title, user }) => {
  const statusClassname = {
    1: 'text-danger',
    2: 'text-warning',
    3: 'text-info',
    4: 'text-success',
    5: 'text-muted',
  };

  const filterTypes = [
    { id: 'all', name: 'Tümü' },
    { id: 'user', name: 'Satış Sorumlusu Olduğum Siparişler' },
    { id: 'orderManager', name: 'Sipariş Sorumlusu Olduğum Siparişler' },
  ];

  // States
  const [filteredResults, setFilteredResults] = useState(null);
  const [filterType, setFilterType] = useState('all');

  // Effects
  useEffect(() => {
    if (upcomingOrders) {
      const filteredResults = upcomingOrders.filter((order) => {
        if (filterType === 'all') {
          return true;
        } else if (filterType === 'user') {
          return order?.user?.id === user?.id;
        } else if (filterType === 'orderManager') {
          return order?.orderManager?.id === user?.id;
        }
      });
      setFilteredResults(filteredResults);
    }
  }, [filterType, upcomingOrders]);

  return (
    <>
      <div className="text-center mt-5 pb-3">
        <h4 className="text-danger">{title}</h4>
        <div>
          <small className="text-muted">
            Bugün veya önümüzdeki bir hafta içinde teslim edilmeli
          </small>
        </div>
        <div>
          <small className="text-dark">
            {' '}
            <strong>{filteredResults?.length} adet </strong>
          </small>
        </div>
      </div>
      <div className="text-center my-2">
        <select
          className="form-control"
          style={{ maxWidth: '50%', margin: 'auto' }}
          onChange={(e) => setFilterType(e.target.value)}
          value={filterType}>
          {filterTypes.map((filterType) => (
            <option key={filterType.id} value={filterType.id}>
              {filterType.name}
            </option>
          ))}
        </select>
      </div>
      <div className="table-wrapper shadow">
        <Table bordered hover size="sm" className="bg-white ">
          <thead>
            <tr>
              <th>#</th>
              <th>Sipariş ID</th>
              <th>Müşteri</th>
              <th>Satış Tarihi</th>
              <th>Teslim Tarihi</th>
              <th>Sipariş Durumu</th>
              <th>Satış Sorumlusu</th>
              <th>Sipariş Sorumlusu</th>
            </tr>
          </thead>
          <tbody>
            {filteredResults?.length > 0 &&
              filteredResults.map((order, index) => (
                <tr key={`order-${order.id}-${index}`}>
                  <td>{index + 1}</td>
                  <td>
                    <OrderLink value={order?.id} />
                  </td>
                  <td>{order?.customer?.name + ' ' + order?.customer?.lastName}</td>
                  <td>{new Date(order?.saleDate).toLocaleDateString('tr-TR')}</td>
                  <td>{new Date(order?.deliveryDate).toLocaleDateString('tr-TR')}</td>
                  <td>
                    <strong className={statusClassname[order?.order_status?.id]}>
                      {order?.order_status?.name}
                    </strong>
                  </td>
                  <td>{order?.user?.fullName}</td>
                  <td>{order?.orderManager?.fullName}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default UpcomingOrdersTable;
