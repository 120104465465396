import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { roundTo2Decimals } from 'utils/calcUtils';
import {
  HIDE_EDIT_PRODUCT_VARIANT_MODAL,
  RESET_VARIANT_VALUES_OF_EDIT_VARIANT,
  SET_PRICE_OF_EDIT_VARIANT,
  SET_STOCK_COUNT_OF_EDIT_VARIANT,
  START_LOADING,
  STOP_LOADING,
} from '../../singleProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

import ProductImage from '../ProductImage';

const AtolyeAPI = new API();

const EditProductVariantModal = ({ getProduct, userId }) => {
  //Redux
  const { productCode } = useSelector((state) => state.products.singleProduct);
  const { isEditProductVariantModalDisplayed, variantOptions } = useSelector(
    (state) => state.products.singleProduct.variants,
  );

  const { variantValues, image_link, price, stock_count, id } = useSelector(
    (state) => state.products.singleProduct.variants.editProductVariant,
  );

  const dispatch = useDispatch();

  // Functions
  const handleDismiss = () => {
    dispatch(HIDE_EDIT_PRODUCT_VARIANT_MODAL());
  };

  const handleSave = async () => {
    dispatch(START_LOADING());
    const body = {
      image_link,
      price,
      stock_count,
      updatedBy: userId,
    };
    try {
      const { status } = await AtolyeAPI.put(`/product_variants/${id}`, body);
      if (status === 'SUCCESS') {
        await getProduct();
        return alert('success', 'Ürün Varyantı başarıyla güncellendi');
      }
      throw new Error('Ürün Varyantı güncellenirken bir hata oluştu');
    } catch (error) {
      console.log({ error });
      return alert('error', 'Ürün Varyantı güncellenirken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(RESET_VARIANT_VALUES_OF_EDIT_VARIANT());
      dispatch(HIDE_EDIT_PRODUCT_VARIANT_MODAL());
    }
  };

  return (
    <div className="text-center">
      <Modal
        show={isEditProductVariantModalDisplayed}
        onHide={handleDismiss}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title>Ürün Varyantı Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-light p-2 text-center">
            <strong className="me-3">{productCode}</strong>{' '}
            <span className="text-muted">{variantValues.map((item) => item.name).join(' / ')}</span>
          </div>
          <Row>
            <Col md>
              <Form className="p-3">
                {variantOptions.map((option) => (
                  <Row key={'edit-variant-o-' + option.id} className="my-2">
                    <Form.Label className="bg-light text-dark rounded border shadow-sm">
                      {option.name}
                    </Form.Label>
                    {option.values.map((value) => (
                      <Form.Check
                        disabled
                        key={value.id}
                        type="radio"
                        label={value.name}
                        defaultChecked={variantValues.find((item) => item.id === value.id)}
                      />
                    ))}
                  </Row>
                ))}
              </Form>
            </Col>
            <Col md>
              <ProductImage isVariant variantType="edit" imageSrc={image_link} />
            </Col>
          </Row>
          <Row>
            <Col md>
              <Form className="p-3">
                <Row>
                  <Form.Label className="bg-light text-danger rounded border shadow-sm">
                    Fiyat (KDVsiz)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={roundTo2Decimals(price)}
                    onChange={(e) => dispatch(SET_PRICE_OF_EDIT_VARIANT(e.target.value))}
                  />
                </Row>
              </Form>
            </Col>
            <Col md>
              {' '}
              <Form className="p-3">
                <Row>
                  <Form.Label className="bg-light text-success rounded border shadow-sm">
                    Fiyat (KDVli)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={roundTo2Decimals(price * 1.18)}
                    onChange={(e) =>
                      dispatch(SET_PRICE_OF_EDIT_VARIANT(e.target.value / 1.18 || ''))
                    }
                  />
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col md>
              <Form className="p-3">
                <Row>
                  <Form.Label className="bg-light text-primary rounded border shadow-sm">
                    Stok Adedi
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={stock_count}
                    onChange={(e) => dispatch(SET_STOCK_COUNT_OF_EDIT_VARIANT(e.target.value))}
                  />
                </Row>
              </Form>
            </Col>
            <Col md></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleDismiss}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={handleSave}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditProductVariantModal;
