import React, { useState } from 'react';
import { ContentWrapper, Footer, Header, PageTitle, useUser } from '../ProductionList';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import ManufacturComponentCard from './components/ManufacturComponentCard';
import useComponents from './hooks/useComponents';
import { Loading } from 'pages/Dashboard';
import AddManufactureComponentModal from './components/AddManufactureComponentModal';
import useComponentCategories from './hooks/useComponentCategories';
import EditManufactureComponentModal from './components/EditManufactureComponentModal';
import CodeFilter from './components/CodeFilter';
import CategoryFilter from './components/CategoryFilter';

const ManufactureComponents = (props) => {
  // State
  const [showAddManufactureComponentModal, setShowAddManufactureComponentModal] = useState(false);
  const [showEditManufactureComponentModal, setShowEditManufactureComponentModal] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);

  // Hooks
  const { user, userPermissions } = useUser();

  const {
    isComponentsLoading,
    components,
    getComponents,
    codeSearch,
    setCodeSearch,
    categorySearch,
    setCategorySearch,
  } = useComponents();
  const { componentCategories } = useComponentCategories();
  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Parçalar" />
        {!userPermissions?.includes(34) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> Parçaları görüntülemek için yetkiniz bulunmamaktadır.
          </Alert>
        ) : (
          <>
            <div className="d-flex justify-content-end mx-4">
              <Button
                variant="success"
                size="sm"
                className="mt-5 mb-5 font-14 text-white"
                onClick={() => setShowAddManufactureComponentModal(true)}>
                + Yeni Parça Ekle
              </Button>

              <AddManufactureComponentModal
                show={showAddManufactureComponentModal}
                onHide={() => setShowAddManufactureComponentModal(false)}
                getComponents={getComponents}
                componentCategories={componentCategories}
              />
              <EditManufactureComponentModal
                show={showEditManufactureComponentModal}
                onHide={() => setShowEditManufactureComponentModal(false)}
                component={selectedComponent}
                getComponents={getComponents}
                componentCategories={componentCategories}
              />
            </div>

            <div className="d-flex mx-2 ">
              <strong className="my-auto me-3">Filtrele:</strong>
              <CodeFilter
                codeSearch={codeSearch}
                setCodeSearch={setCodeSearch}
                getComponents={getComponents}
              />
              <CategoryFilter
                categorySearch={categorySearch}
                setCategorySearch={setCategorySearch}
                getComponents={getComponents}
                componentCategories={componentCategories}
              />
            </div>
            <Row className="my-3 py-3 mx-2">
              {isComponentsLoading ? (
                <Loading />
              ) : components.length === 0 ? (
                <Alert variant="warning" className="w-100">
                  <strong>Parça bulunamadı !!!</strong>
                </Alert>
              ) : (
                components.map((item) => (
                  <Col key={'manufactorComponent' + item.id} md={4} lg={3} className="my-2">
                    <ManufacturComponentCard
                      component={item}
                      setShowEditManufactureComponentModal={setShowEditManufactureComponentModal}
                      setSelectedComponent={setSelectedComponent}
                      getComponents={getComponents}
                    />
                  </Col>
                ))
              )}
            </Row>
          </>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default ManufactureComponents;
