import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'app/state/store';
import App from '../App';

// Page Imports
// ------------------------------------------------------------------
import Authentication from 'pages/Authentication';
import ComponentCategories from 'pages/Production/ComponentCategories';
import CreateCustomer from 'pages/Customer/CreateCustomer';
import CreatePriceOffer from 'pages/PriceOffer/CreatePriceOffer';
import CreateProduct from 'pages/Product/CreateProduct';
import CreateRepair from 'pages/Repair/CreateRepair';
// import CreateUser from 'pages/Settings/oldFiles/CreateUser';
import Customers from 'pages/Customer/Customers';
import Dashboard from 'pages/Dashboard';
// import GeneralSettings from 'pages/Settings/GeneralSettings';
import ManufactureProducts from 'pages/Production/ManufactureProducts';
import ManufactureComponents from 'pages/Production/ManufactureComponents';
import Operations from 'pages/Production/Operations';
import Orders from 'pages/Order/Orders';
import OrderPDF from 'pages/Order/OrderPDF/OrderPDF';
import OrderNotes from 'pages/Order/OrderNotes';
import PaymentStatus from 'pages/Order/PaymentStatus';
import Planning from 'pages/Production/ProductionPlanning';
import PriceOffers from 'pages/PriceOffer/PriceOffers';
import PriceOfferPDF from 'pages/PriceOffer/PriceOfferPDF/PriceOfferPDF';
import Products from 'pages/Product/Products';
// import ProductCategories from 'pages/Settings/ProductCategories';
import ProductionList from 'pages/Production/ProductionList';
// import ProductFeatures from 'pages/Settings/ProductFeatures';
import SingleCustomer from 'pages/Customer/SingleCustomer';
import SingleManufactureProduct from 'pages/Production/SingleManufactureProduct';
import SingleOrder from 'pages/Order/SingleOrder';
import SinglePriceOffer from 'pages/PriceOffer/SinglePriceOffer';
import SingleProduct from 'pages/Product/SingleProduct';
import SoldProducts from 'pages/Product/SoldProducts';
import Tags from 'pages/Product/Tags';
// import UserPermissions from 'pages/Settings/UserPermissions';
import CreateOrder from 'pages/Order/CreateOrder';
import ProductStockManagement from 'pages/StockManagement/ProductStockManagement';
import Depots from 'pages/StockManagement/Depot/Depots';
import SingleDepot from 'pages/StockManagement/Depot/SingleDepot';

import UserList from 'pages/Settings/UserList';
import User from 'pages/Settings/User';
import CreateUser from 'pages/Settings/CreateUser';

// import ToDo from 'pages/ToDo';
// import CreateOrder from 'pages/Order/CreateOrder';
// import Manufacturers from 'pages/Production/Manufacturers';
// import Repairs from 'pages/Repair/Repairs';
// import MontageCalendar from 'pages/Calendar';
// import Reports from 'pages/Reports';

/**
 * Routing Pages
 * Add all site pages here
 */

const PrivateRoute = ({ Component }) => {
  return (
    <Authentication>
      <Component />
    </Authentication>
  );
};

const routing = (
  <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute Component={App} />} />
        <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />
        {/* <Route path="/reports" element={<PrivateRoute Component={Reports} />} /> */}
        {/* <Route path="/to-do" element={<PrivateRoute Component={ToDo} />} /> */}
        {/* Orders */}
        <Route path="/single-order" element={<PrivateRoute Component={SingleOrder} />} />
        <Route path="/orders" element={<PrivateRoute Component={Orders} />} />
        <Route path="/order-pdf" element={<PrivateRoute Component={OrderPDF} />} />
        <Route path="/order-notes" element={<PrivateRoute Component={OrderNotes} />} />
        <Route path="/create-order" element={<PrivateRoute Component={CreateOrder} />} />
        <Route path="/payment-status" element={<PrivateRoute Component={PaymentStatus} />} />
        {/* Price Offers */}
        <Route path="/single-price-offer" element={<PrivateRoute Component={SinglePriceOffer} />} />
        <Route path="/price-offers" element={<PrivateRoute Component={PriceOffers} />} />
        <Route path="/price-offer-pdf" element={<PrivateRoute Component={PriceOfferPDF} />} />
        <Route path="/create-price-offer" element={<PrivateRoute Component={CreatePriceOffer} />} />
        {/* Production */}
        <Route path="/production" element={<PrivateRoute Component={ProductionList} />} />
        {/* <Route path="/manufacturers" element={<PrivateRoute Component={Manufacturers} />} /> */}
        <Route path="/planning" element={<PrivateRoute Component={Planning} />} />
        <Route
          path="/manufacture-products"
          element={<PrivateRoute Component={ManufactureProducts} />}
        />
        <Route
          path="/manufacture-components"
          element={<PrivateRoute Component={ManufactureComponents} />}
        />
        <Route
          path="/single-manufacture-product"
          element={<PrivateRoute Component={SingleManufactureProduct} />}
        />
        <Route
          path="/component-categories"
          element={<PrivateRoute Component={ComponentCategories} />}
        />
        <Route path="/operations" element={<PrivateRoute Component={Operations} />} />

        {/* Repairs */}
        {/* <Route path="/repairs" element={<PrivateRoute Component={Repairs} />} /> */}
        <Route path="/create-repair" element={<PrivateRoute Component={CreateRepair} />} />
        {/* Customers */}
        <Route path="/customers" element={<PrivateRoute Component={Customers} />} />
        <Route path="/create-customer" element={<PrivateRoute Component={CreateCustomer} />} />
        <Route path="/single-customer" element={<PrivateRoute Component={SingleCustomer} />} />
        {/* Product */}
        <Route path="/single-product" element={<PrivateRoute Component={SingleProduct} />} />
        <Route path="/products" element={<PrivateRoute Component={Products} />} />
        <Route path="/sold-products" element={<PrivateRoute Component={SoldProducts} />} />
        <Route path="/tags" element={<PrivateRoute Component={Tags} />} />
        <Route path="/create-product" element={<PrivateRoute Component={CreateProduct} />} />
        {/* Settings */}
        <Route path="/user-list" element={<PrivateRoute Component={UserList} />} />
        <Route path="/user" element={<PrivateRoute Component={User} />} />
        {/* <Route path="/general-settings" element={<PrivateRoute Component={GeneralSettings} />} /> */}
        {/* <Route path="/user-permissions" element={<PrivateRoute Component={UserPermissions} />} /> */}
        <Route path="/create-user" element={<PrivateRoute Component={CreateUser} />} />
        {/* <Route
          path="/product-categories"
          element={<PrivateRoute Component={ProductCategories} />}
        />
        <Route path="/product-features" element={<PrivateRoute Component={ProductFeatures} />} /> */}

        {/* Calendar */}
        {/* <Route path="/calendar" element={<PrivateRoute Component={MontageCalendar} />} /> */}

        {/* Stock Management */}
        <Route
          path="/product-stock-management"
          element={<PrivateRoute Component={ProductStockManagement} />}
        />
        {/* Depots  */}
        <Route
          path="/product-stock-management/depots"
          element={<PrivateRoute Component={Depots} />}
        />
        <Route
          path="/product-stock-management/depots/depot"
          element={<PrivateRoute Component={SingleDepot} />}
        />

        <Route
          path="*"
          element={
            <main style={{ padding: '1rem' }}>
              <p>Sayfa bulunamadı!</p>
            </main>
          }
        />
      </Routes>
    </Router>
  </Provider>
);

export default routing;
