import { styles } from '../styles';

import { Text, View } from '@react-pdf/renderer';

const OrderSummarySection = ({ order, translations, lang, isPriceless, currency }) => {
  return (
    <View wrap={false} style={[styles.row, { minHeight: 80 }]}>
      <View
        style={[styles.offerInfoNotes, { paddingLeft: 3, width: isPriceless ? '100%' : '67.5%' }]}>
        <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
          {translations.orderNotes[lang]} :
        </Text>
        <Text style={styles.tableText}>{order.description}</Text>
      </View>
      {!isPriceless && (
        <View style={styles.offerInfoColumnRight}>
          <View style={[styles.row, { minHeight: 20 }]}>
            <View style={styles.offerInfoLeft}>
              <Text style={[styles.tableText]}>{translations.productsTotal[lang]}</Text>
            </View>
            <View style={styles.offerInfoRight}>
              <Text style={[styles.tableText]}>
                {(order.productsTotal * 1.18).toFixed(2).toLocaleString()} {currency}
              </Text>
            </View>
          </View>
          <View style={[styles.row, { minHeight: 20 }]}>
            <View style={styles.offerInfoLeft}>
              <Text style={[styles.tableText]}>{translations.discountTotal[lang]}</Text>
            </View>
            <View style={styles.offerInfoRight}>
              <Text style={[styles.tableText]}>
                {(order.discountTotal * 1.18).toFixed(2).toLocaleString()} {currency}
              </Text>
            </View>
          </View>
          <View style={[styles.row, { minHeight: 20 }]}>
            <View style={styles.offerInfoLeft}>
              <Text style={[styles.tableText]}>{translations.subTotal[lang]}</Text>
            </View>
            <View style={styles.offerInfoRight}>
              <Text style={[styles.tableText]}>
                {(order.subTotal * 1.18).toFixed(2).toLocaleString()} {currency}
              </Text>
            </View>
          </View>

          <View style={[styles.row, { minHeight: 20 }]}>
            <View style={[styles.offerInfoLeft, { backgroundColor: '#e6ddc4' }, { color: '#333' }]}>
              <Text style={[styles.tableText]}>{translations.totalAmount[lang]}</Text>
            </View>
            <View style={styles.offerInfoRight}>
              <Text style={[styles.tableText, { color: '#333' }]}>
                {order.total.toLocaleString()} {currency}
              </Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default OrderSummarySection;
