import alert from 'js/sweetAlert';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const EditComponentQuantityModal = ({
  show,
  setShow,
  selectedComponent,
  getManufactureProductDetails,
}) => {
  // State
  const [quantity, setQuantity] = useState(1);
  const saveHandler = async () => {
    const body = {
      quantity: parseFloat(quantity) || 0,
    };

    try {
      await AtolyeAPI.post(
        `/manufacture_products/${selectedComponent.productId}/components/${selectedComponent.componentId}`,
        body,
      );
      alert('success', 'Parça Adedi Güncellendi');
      getManufactureProductDetails(selectedComponent.productId);
    } catch (error) {
      console.log(error);
      alert('error', 'Parça Adedi Güncellenemedi');
    } finally {
      setShow(false);
    }
  };

  // Effects
  useEffect(() => {
    selectedComponent && setQuantity(selectedComponent.quantity);
  }, [selectedComponent]);

  useEffect(() => {
    console.log('quantity', quantity);
  }, [quantity]);

  // Functions
  const quantityFormatOnChange = (e) => {
    const value = e.target.value;
    // Number can contain comma or dot
    // We need to format the value to be a valid number
    // 1. Remove all non-numeric characters
    // 2. Replace the first comma or dot with a dot
    // 3. Remove all other commas and dots
    // 4. Add a leading zero if the first character is a dot
    // 5. If the last character is a dot, add a zero after it

    // Remember that the value is a string and we need to return a string
    const formattedValue = value
      .replace(/[^0-9.,]/g, '')
      .replace(/(,|\.)(?=[.,])/, '')
      .replace(/(,|\.)/, '.')
      .replace(/(,|\.)(?=[.,])/, '')
      .replace(/^(\.|,)/, '0$1')
      .replace(/(\.|,)$/, '$10');

    setQuantity(formattedValue);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Parça Adedi Düzenle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Adet</Form.Label>
          <Form.Control
            style={{ maxWidth: 100 }}
            type="number"
            lang="en-US"
            min={0}
            value={quantity}
            onChange={(e) => quantityFormatOnChange(e)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={() => setShow(false)}>
          Vazgeç
        </Button>
        <Button variant="success" className="text-white" size="sm" onClick={saveHandler}>
          Güncelle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditComponentQuantityModal;
