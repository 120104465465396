import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  product: {
    id: '',
    cost: 0,
    salePrice: 0,
    description: '',
    product_categories_id: 0,
    maxDiscountRate: 0,
    stock_count: 0,
    imageLink: 'https://via.placeholder.com/200',
    createdBy: null,
  },
  tagsToAdd: [],
};

export const createProductSlice = createSlice({
  name: 'createProduct',
  initialState,
  reducers: {
    START_LOADING: (state) => {
      return { ...state, isLoading: true };
    },
    STOP_LOADING: (state) => {
      return { ...state, isLoading: false };
    },
    SET_PRODUCT: (state, action) => {
      return { ...state, product: action.payload };
    },
    SET_ID: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          id: action.payload,
        },
      };
    },
    SET_DESCRIPTION: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          description: action.payload,
        },
      };
    },
    SET_COST: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          cost: action.payload,
        },
      };
    },
    SET_SALE_PRICE: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          salePrice: action.payload,
        },
      };
    },
    SET_MAX_DISCOUNT_RATE: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          maxDiscountRate: action.payload,
        },
      };
    },
    SET_STOCK_COUNT: (state, action) => {
      return {
        ...state,
        isEdited: true,
        product: {
          ...state.product,
          stock_count: action.payload,
        },
      };
    },
    SET_CATEGORY: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          product_categories_id: action.payload,
        },
      };
    },
    SET_IMAGE_LINK: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          imageLink: action.payload,
        },
      };
    },
    SET_CREATED_BY: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          createdBy: action.payload,
        },
      };
    },
    SET_TAGS: (state, action) => {
      return {
        ...state,
        isEdited: true,
        tagsToAdd: action.payload,
      };
    },
    RESET: (state) => {
      return {
        ...initialState,
        product: {
          ...initialState.product,
          createdBy: state.product.createdBy,
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  RESET,
  START_LOADING,
  STOP_LOADING,
  SET_PRODUCT,
  SET_ID,
  SET_CATEGORY,
  SET_COST,
  SET_DESCRIPTION,
  SET_IMAGE_LINK,
  SET_MAX_DISCOUNT_RATE,
  SET_STOCK_COUNT,
  SET_SALE_PRICE,
  SET_CREATED_BY,
  SET_TAGS,
} = createProductSlice.actions;

export default createProductSlice.reducer;
