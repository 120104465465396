import '../Authentication.scss';

const NightBlockingMessage = () => {
  return (
    <div className="authentication__night">
      <div className="authentication__night__content">
        <h1 className="mb-3">
          Akşam 20:00 ile sabah 08:00 saatleri arasında giriş yapmanız engellenmiştir.
        </h1>
      </div>
    </div>
  );
};

export default NightBlockingMessage;
