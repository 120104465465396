import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  imageLink: '/images/placeholder-image.png',
  productToDelete: null,
  priceOffer: null,
  editInfoDefaults: null,
  productOptions: null,
  descriptionEditText: '',
  selectedPriceOfferId: null,
  tempFinancialDetailsWithKDV: {
    productsTotalWithKDV: 0,
    discountTotalWithKDV: 0,
    discountRate: 0,
    subTotalWithKDV: 0,
    total: 0,
  },
  sendMailTo: '',
  showUpload: false,
  invoiceLink: null,
  isProductionStatusEditModalDisplayed: false,
  isProductionDeleteModalDisplayed: false,
  isAddProductModalDisplayed: false,
  productionIdToUpdate: null,
  productionDetailsForUpdate: {
    price: null,
    quantity: null,
    features: '',
    glass: null,
    material: null,
    branch: null,
    lampholder: null,
    color: null,
    specialNotes: null,
  },

  pdfType: 'default',
  pdfLanguage: 'tr',
  pdfCurrency: 'TL',
  isPDFModalDisplayed: false,
  recalculatedPriceOffer: {
    priceOffers_id: null,
    productsTotal: null,
    discountTotal: null,
    subTotal: null,
    KDVtotal: null,
    total: null,
  },
  selectedProduct: null,
  productToAdd: null,
};

export const singlePriceOfferSlice = createSlice({
  name: 'singlePriceOffer',
  initialState,
  reducers: {
    START_LOADING: (state) => {
      return { ...state, isLoading: true };
    },
    STOP_LOADING: (state) => {
      return { ...state, isLoading: false };
    },
    SELECT_PRICE_OFFER: (state, action) => {
      return { ...state, selectedPriceOfferId: action.payload };
    },
    SET_PRICE_OFFER: (state, action) => {
      return { ...state, priceOffer: action.payload };
    },
    SET_ERROR: (state) => {
      return { ...state, isError: true };
    },
    CLEAR_ERROR: (state) => {
      return { ...state, isError: false };
    },
    SET_MAIL_TO: (state, action) => {
      return { ...state, sendMailTo: action.payload };
    },
    SET_CREATED_AT: (state, action) => {
      return { ...state, priceOffer: { ...state.priceOffer, createdAt: action.payload } };
    },
    SET_CREATED_BY: (state, action) => {
      return { ...state, priceOffer: { ...state.priceOffer, createdBy: action.payload } };
    },
    RESET_PRICE_OFFER: (state) => {
      return { ...state, priceOffer: state.editInfoDefaults };
    },
    SET_EDITING_PRODUCT: (state, action) => {
      return {
        ...state,
        productEditing: action.payload,
      };
    },
    SET_PRODUCT_TO_DELETE: (state, action) => {
      return {
        ...state,
        productToDelete: action.payload,
      };
    },
    SET_DESCRIPTION_EDIT_TEXT: (state, action) => {
      return {
        ...state,
        descriptionEditText: action.payload,
      };
    },
    SET_EDIT_INFO_DEFAULTS: (state, action) => {
      return {
        ...state,
        editInfoDefaults: action.payload,
      };
    },
    SHOW_PRODUCTION_DELETE_MODAL: (state) => {
      return {
        ...state,
        isProductionDeleteModalDisplayed: true,
      };
    },
    HIDE_PRODUCTION_DELETE_MODAL: (state) => {
      return {
        ...state,
        isProductionDeleteModalDisplayed: false,
      };
    },
    SHOW_PRODUCTION_EDIT_MODAL: (state, action) => {
      return {
        ...state,
        productionIdToUpdate: action.payload.id,
        recalculatedPriceOffer: {
          priceOffers_id: state.priceOffer.id,
          productsTotal: state.priceOffer.productsTotal,
          discountTotal: state.priceOffer.discountTotal,
          subTotal: state.priceOffer.subTotal,
          KDVtotal: state.priceOffer.KDVtotal,
          total: state.priceOffer.total,
        },
        isProductionStatusEditModalDisplayed: true,
      };
    },
    HIDE_PRODUCTION_EDIT_MODAL: (state) => {
      return {
        ...state,
        isProductionStatusEditModalDisplayed: false,
        recalculatedPriceOffer: {
          priceOffers_id: null,
          productsTotal: null,
          discountTotal: null,
          subTotal: null,
          KDVtotal: null,
          total: null,
        },
      };
    },
    SET_PRODUCTION_DETAILS_FOR_UPDATE: (state, action) => {
      return {
        ...state,
        productionDetailsForUpdate: {
          ...state.productionDetailsForUpdate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    SET_PRODUCTION_DETAILS_FOR_UPDATE_ALL: (state, action) => {
      return {
        ...state,
        productionDetailsForUpdate: action.payload,
      };
    },
    RESET_PRODUCTION_DETAILS_FOR_UPDATE: (state) => {
      return {
        ...state,
        productionDetailsForUpdate: {
          price: null,
          quantity: null,
          features: '',
          glass: null,
          material: null,
          branch: null,
          lampholder: null,
          color: null,
          specialNotes: null,
        },
      };
    },
    SET_PDF_TYPE: (state, action) => {
      return {
        ...state,
        pdfType: action.payload,
      };
    },
    SET_PDF_LANGUAGE: (state, action) => {
      return {
        ...state,
        pdfLanguage: action.payload,
      };
    },
    SET_PDF_CURRENCY: (state, action) => {
      return {
        ...state,
        pdfCurrency: action.payload,
      };
    },
    SHOW_PDF_MODAL: (state) => {
      return {
        ...state,
        isPDFModalDisplayed: true,
      };
    },
    HIDE_PDF_MODAL: (state) => {
      return {
        ...state,
        isPDFModalDisplayed: false,
      };
    },

    SET_TEMP_FINANCIAL_DETAILS: (state, action) => {
      return {
        ...state,
        tempFinancialDetailsWithKDV: {
          productsTotalWithKDV: action.payload.productsTotal * 1.18,
          discountTotalWithKDV: action.payload.discountTotal * 1.18,
          discountRate: action.payload.discountRate,
          subTotalWithKDV: action.payload.subTotal * 1.18,
          total: action.payload.total,
        },
      };
    },
    SET_TEMP_FINANCIAL_DETAILS_AFTER_RECALCULATE: (state, action) => {
      return {
        ...state,
        tempFinancialDetailsWithKDV: {
          ...state.tempFinancialDetailsWithKDV,
          discountTotalWithKDV: action.payload.discountTotal,
          discountRate: action.payload.discountRate,
          subTotalWithKDV: action.payload.subTotal,
          total: action.payload.total,
        },
      };
    },
    SET_RECALCULATED_PRICE_OFFER: (state, action) => {
      return {
        ...state,
        recalculatedPriceOffer: {
          priceOffers_id: state.priceOffer.id,
          productsTotal: action.payload.productsTotal,
          discountTotal: action.payload.discountTotal,
          subTotal: action.payload.subTotal,
          KDVtotal: action.payload.total - action.payload.subTotal,
          total: action.payload.total,
        },
      };
    },
    RESET_RECALCULATED_PRICE_OFFER: (state) => {
      return {
        ...state,
        recalculatedPriceOffer: {
          priceOffers_id: null,
          productsTotal: null,
          discountTotal: null,
          subTotal: null,
          KDVtotal: null,
          total: null,
        },
      };
    },
    PICK_PRODUCT: (state, action) => {
      return { ...state, selectedProduct: action.payload };
    },
    SET_IMAGE_LINK: (state, action) => {
      return { ...state, imageLink: action.payload };
    },
    SET_PRODUCT_TO_ADD: (state, action) => {
      return {
        ...state,
        productToAdd: {
          ...action.payload,
          quantity: 1,
          material: '',
          glass: '',
          branch: '',
          color: '',
          lampholder: '',
          features: '',
          specialNotes: '',
          total: 0,
        },
      };
    },
    SET_VALUE_ON_PRODUCT_TO_ADD: (state, action) => {
      return {
        ...state,
        productToAdd: {
          ...state.productToAdd,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    CLEAR_PRODUCT_TO_ADD: (state) => {
      return {
        ...state,
        productToAdd: null,
        selectedProduct: null,
        imageLink: initialState.imageLink,
      };
    },
    SHOW_ADD_PRODUCT_MODAL: (state) => {
      return {
        ...state,
        isAddProductModalDisplayed: true,
      };
    },
    HIDE_ADD_PRODUCT_MODAL: (state) => {
      return {
        ...state,
        isAddProductModalDisplayed: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  START_LOADING,
  STOP_LOADING,
  SELECT_PRICE_OFFER,
  SET_PRICE_OFFER,
  SET_ERROR,
  CLEAR_ERROR,
  SET_MAIL_TO,
  SET_CREATED_AT,
  SET_CREATED_BY,
  RESET_PRICE_OFFER,
  SET_EDITING_PRODUCT,
  SET_PRODUCT_TO_DELETE,
  SET_DESCRIPTION_EDIT_TEXT,
  SET_EDIT_INFO_DEFAULTS,
  SHOW_PRODUCTION_DELETE_MODAL,
  HIDE_PRODUCTION_DELETE_MODAL,
  SHOW_PRODUCTION_EDIT_MODAL,
  HIDE_PRODUCTION_EDIT_MODAL,
  SET_PRODUCTION_DETAILS_FOR_UPDATE,
  RESET_PRODUCTION_DETAILS_FOR_UPDATE,
  SET_PRODUCTION_DETAILS_FOR_UPDATE_ALL,
  SET_PDF_TYPE,
  SET_PDF_LANGUAGE,
  SET_PDF_CURRENCY,
  SHOW_PDF_MODAL,
  HIDE_PDF_MODAL,
  SET_TEMP_FINANCIAL_DETAILS,
  SET_TEMP_FINANCIAL_DETAILS_AFTER_RECALCULATE,
  SET_RECALCULATED_PRICE_OFFER,
  RESET_RECALCULATED_PRICE_OFFER,
  PICK_PRODUCT,
  SET_IMAGE_LINK,
  SET_PRODUCT_TO_ADD,
  CLEAR_PRODUCT_TO_ADD,
  SET_VALUE_ON_PRODUCT_TO_ADD,
  SHOW_ADD_PRODUCT_MODAL,
  HIDE_ADD_PRODUCT_MODAL,
} = singlePriceOfferSlice.actions;

export default singlePriceOfferSlice.reducer;
