/* eslint-disable no-undef */

import { Amplify } from 'aws-amplify';

if (process.env.REACT_APP_ENV === 'prod') {
  Amplify.configure({
    Auth: {
      identityPoolId: 'eu-central-1:8971dd0c-4daa-40a1-9a73-08b978108c16',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_27QC5LAwx',
      userPoolWebClientId: '22t2k982nu18k2qmh7curfk65e',
    },
  });
} else if (process.env.REACT_APP_ENV === 'dev') {
  Amplify.configure({
    Auth: {
      identityPoolId: 'eu-central-1:1dcb2a83-47fa-4bba-ac35-f8255e7a7ea8',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_5KZp51rup',
      userPoolWebClientId: '6olr7a6fvku9vb85vtcolvgrh9',
    },
  });
} else if (process.env.REACT_APP_ENV === 'local') {
  Amplify.configure({
    Auth: {
      identityPoolId: 'eu-central-1:1dcb2a83-47fa-4bba-ac35-f8255e7a7ea8',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_5KZp51rup',
      userPoolWebClientId: '6olr7a6fvku9vb85vtcolvgrh9',
    },
  });
}

console.log('ENV: ', process.env.REACT_APP_ENV);
