import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_TOGGLE } from 'app/state/generalSlice';

const NavbarHorizontal = () => {
  //Redux
  const { isMenuOpen } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  return (
    <nav
      className={`main-header menu-${
        isMenuOpen ? 'open' : 'close'
      } navbar navbar-expand navbar-white`}>
      <div className="container justify-content-start">
        <div className="pl-1">
          <button className="btn " onClick={() => dispatch(MENU_TOGGLE())}>
            <i className={`fas fa-${isMenuOpen ? 'times' : 'bars'}`}></i>
          </button>
        </div>
        <div className="pl-1">
          <Link className="nav-link p-0" to="/dashboard">
            <img src="/images/atolye-logo.png" width={100} />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavbarHorizontal;
