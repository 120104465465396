import { useSelector } from 'react-redux';
import SubTotalEditModal from './SubTotalEditModal';

const PriceOfferFinancialDetails = ({
  priceOffer,
  tempFinancialDetails,
  updateFinancialDetails,
}) => {
  const { userPermissions } = useSelector((state) => state.general.user);
  return (
    <div className="col-md-6 p-0 order-1 order-md-2">
      <div className="row mx-0">
        <div className="col-6 text-center py-2 border bg-secondary">Ürünler Toplamı</div>
        <div className="col-6 text-center py-2 border">
          {(priceOffer.productsTotal * 1.18).toFixed(2).toLocaleString()} ₺
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-6 text-center py-2 border bg-secondary">İskonto Oranı (%)</div>
        <div className="col-6 text-center py-2 border">
          <div className="d-inline-block">% {priceOffer.discountRate}</div>
          {userPermissions?.includes(11) && (
            <button
              type="button"
              className="text-primary rounded border-primary ms-1"
              data-toggle="modal"
              data-target="#editSubtotalModal">
              <i className="fas fa-edit"></i>
            </button>
          )}
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-6 text-center py-2 border bg-secondary">İskonto Tutarı</div>
        <div className="col-6 text-center py-2 border">
          {(priceOffer.discountTotal * 1.18).toFixed(2).toLocaleString()} ₺
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-6 text-center py-2 border bg-secondary">
          Ara Toplam
          {/* <span>(KDV Hariç)</span> */}
        </div>
        <div className="col-6  py-2 border ">
          <div className="text-center">
            <div className="d-inline-block">
              {(priceOffer.subTotal * 1.18).toFixed(2).toLocaleString()} ₺{' '}
            </div>
            {userPermissions?.includes(11) && (
              <button
                type="button"
                className="text-primary rounded border-primary ms-1"
                data-toggle="modal"
                data-target="#editSubtotalModal">
                <i className="fas fa-edit"></i>
              </button>
            )}

            {/* Edit Subtotal Modal */}

            <SubTotalEditModal
              tempFinancialDetails={tempFinancialDetails}
              updateFinancialDetails={updateFinancialDetails}
            />
          </div>
        </div>
      </div>

      <div className="row mx-0">
        <div className="col-6 text-center py-2 border border bg-info">
          Fiyat
          {/* <span>(KDV Dahil)</span> */}
        </div>
        <div className="col-6 text-center py-2 border border-info">
          {priceOffer?.total?.toLocaleString()} ₺
        </div>
      </div>
    </div>
  );
};

export default PriceOfferFinancialDetails;
