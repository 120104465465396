import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { useEffect, useState } from 'react';
import { tagColorOptions } from '../utils';

const AtolyeAPI = new API();

const EditTagModal = ({ show, onDismiss, tag, refresh }) => {
  // State

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [color, setColor] = useState('');

  // Effects

  useEffect(() => {
    if (tag) {
      setName(tag.name);
      setColor(tag.color);
    }
  }, [tag]);

  // Functions

  const editTag = async () => {
    if (!name) {
      return alert('error', 'Etiket Adı boş bırakılamaz');
    }

    const body = {
      name,
      color,
    };

    setIsLoading(true);

    try {
      const { status } = await AtolyeAPI.put(`/tags/${tag.id}`, body);

      if (status && status === 'SUCCESS') {
        return alert('success', 'Ürün Etiketi başarıyla güncellendi');
      }
      return alert('error', 'Ürün Etiketi güncellenirken bir hata oluştu');
    } catch (error) {
      alert('error', 'Ürün Etiketi güncellenirken bir hata oluştu');
      console.log(error);
    } finally {
      setIsLoading(false);
      setName('');
      setColor('');
      dismissHandler();
      refresh(true);
    }
  };

  const dismissHandler = () => {
    setName(tag.name);
    setColor(tag.color);
    onDismiss();
  };

  return (
    <Modal show={show} onHide={dismissHandler}>
      <Modal.Header className="bg-primary" closeButton>
        <Modal.Title>Etiket No: {tag.id} - Düzenle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Etiket Adı</Form.Label>
          <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
          <Form.Text className="text-muted">Etiket Adı girin. Bu alan zorunludur</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Etiket Rengi</Form.Label>
          {tagColorOptions.map((item, i) => (
            <Form.Check
              className="my-2"
              key={`color-${i}`}
              type="radio"
              label={
                <span className={`color-picker-item ${item}`}>
                  <span className="color-picker-item-label">{name}</span>
                </span>
              }
              onChange={() => setColor(item)}
              checked={item === color}
            />
          ))}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={dismissHandler}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={() => editTag()}>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            'Güncelle'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTagModal;
