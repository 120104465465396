import { Link } from 'react-router-dom';

export const columns = [
  {
    name: 'id',
    title: 'ID',
    key: 'id',
    Cell: ({ value, rowData: { roles_id } }) => {
      return [4, 5].includes(roles_id) ? (
        <span className="badge badge-primary text-decoration-none">{value}</span>
      ) : (
        <Link className="badge badge-primary text-decoration-none" to={`/user?id=${value}`}>
          {value}
        </Link>
      );
    },
  },
  {
    name: 'name',
    title: 'Adı',
    key: 'name',
  },
  {
    name: 'lastName',
    title: 'Soyadı',
    key: 'lastName',
  },
  {
    name: 'email',
    title: 'E-posta',
    key: 'email',
  },
  {
    name: 'role',
    title: 'Rolü',
    key: 'role',
    Cell: ({ value }) => value.name,
  },
  {
    name: 'isActive',
    title: 'Durum',
    key: 'isActive',
    Cell: ({ value }) =>
      value ? (
        <span className="badge badge-success">Aktif</span>
      ) : (
        <span className="badge badge-danger">Pasif</span>
      ),
  },
  {
    name: 'createdAt',
    title: 'Oluşturulma Tarihi',
    key: 'createdAt',
    Cell: ({ value }) => new Date(value).toLocaleString('tr-TR'),
  },
  {
    name: 'updatedAt',
    title: 'Güncellenme Tarihi',
    key: 'updatedAt',
    Cell: ({ value }) => new Date(value).toLocaleString('tr-TR'),
  },
];
