import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

// Filter Components
export const DefaultColumnFilter = ({ column: { filterValue, setFilter, columnName } }) => {
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 600);

  return (
    <Form.Control
      id={`${columnName}-filter`}
      type="text"
      className="form-control default-filter"
      defaultValue={filterValue || ''}
      onChange={(e) => {
        onChange(e.target.value); // Set undefined to remove the filter entirely
      }}
    />
  );
};

export const OrderStatusFilter = ({ column: { filterValue, setFilter } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    return [
      'SİPARİŞ ALINDI',
      'ÜRETİMDE',
      'DEPODAN TESLİME HAZIR',
      'TESLİM EDİLDİ',
      'HATALI/EKSİK/ARIZALI',
    ];
  }, []);

  return (
    <Form.Select
      className="default-filter py-0 w-100"
      style={{ minWidth: 180 }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">Tümü</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
};

export const DeliveryOptionFilter = ({ column: { filterValue, setFilter } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    return ['Belirlenmedi', 'Adrese Teslim', 'Adrese Kargo', 'Mağazadan Teslim'];
  }, []);

  return (
    <Form.Select
      className="default-filter py-0 w-100"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">Tümü</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
};

export const SalesChannelFilter = ({ column: { filterValue, setFilter } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    return ['Belirlenmedi', 'İnternet', 'İhracat Satışı', 'Münferit Mağaza', 'Proje'];
  }, []);

  return (
    <Form.Select
      className="default-filter py-0 w-100"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">Tümü</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
};

export const BillFilter = ({ column: { filterValue, setFilter } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    return ['Kesilmedi', 'Kesildi'];
  }, []);

  return (
    <Form.Select
      className="default-filter py-0 w-100"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">Tümü</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
};
