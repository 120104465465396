import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ProductionEditModal } from '..';
import {
  SET_PRODUCTION_DETAILS_FOR_UPDATE_ALL,
  SET_PRODUCTION_TO_DELETE,
  SET_RECALCULATED_ORDER,
  SHOW_PRODUCTION_DELETE_MODAL,
  SHOW_PRODUCTION_EDIT_MODAL,
} from '../singleOrderSlice';

import { roundTo2Decimals } from 'utils/calcUtils';

const ProductsDetails = ({ fetchOrder, isManufacturerOptionsLoading, manufacturerOptions }) => {
  //Redux
  const dispatch = useDispatch();
  const { order, productionIdToUpdate } = useSelector((state) => state.orders.singleOrder);
  const { price, quantity } = useSelector(
    (state) => state.orders.singleOrder.productionDetailsForUpdate,
  );
  const { userPermissions } = useSelector((state) => state.general.user);

  // Functions

  const recalculateOrderBeforeUpdate = () => {
    const productsWithoutEditingProduct = order?.productions?.filter(
      (product) => product.id !== productionIdToUpdate,
    );
    const productsOnlyWithFinancialDataWithoutEditingProduct = productsWithoutEditingProduct.map(
      (product) => ({ id: product.id, price: product.price, quantity: product.quantity }),
    );

    const productsOnlyWithFinancialData = [
      ...productsOnlyWithFinancialDataWithoutEditingProduct,
      { id: productionIdToUpdate, price, quantity },
    ];

    const _tempProductsTotal = productsOnlyWithFinancialData.reduce((acc, product) => {
      return acc + parseFloat(product.price * parseInt(product.quantity));
    }, 0);
    const _tempDiscountTotal =
      _tempProductsTotal - _tempProductsTotal / (1 + order.discountRate / 100);
    const _tempSubTotal = _tempProductsTotal - _tempDiscountTotal;
    const _tempTotal = _tempSubTotal * 1.18;
    const _tempKDVtotal = _tempTotal - _tempSubTotal;

    dispatch(
      SET_RECALCULATED_ORDER({
        orders_id: order.id,
        productsTotal: _tempProductsTotal,
        discountTotal: _tempDiscountTotal,
        subTotal: _tempSubTotal,
        KDVtotal: _tempKDVtotal,
        total: roundTo2Decimals(_tempTotal),
        totalDiff: roundTo2Decimals(order.total - _tempTotal),
      }),
    );
  };

  // Effects

  useEffect(() => {
    productionIdToUpdate && recalculateOrderBeforeUpdate();
  }, [price, quantity, productionIdToUpdate]);

  // Functions

  const showProductDeleteModalHandler = (product) => {
    dispatch(SET_PRODUCTION_TO_DELETE(order.productions.find((item) => item.id === product.id)));
    dispatch(SHOW_PRODUCTION_DELETE_MODAL());
  };

  return (
    <>
      <div className="bg-dark p-2 rounded">
        <span className="h6 mt-1 text-center d-inline-block">Ürünler</span>
      </div>
      <div className="product-info my-3">
        <div className="row mx-0 my-5">
          {order?.productions.length > 0
            ? order?.productions.map((product) => {
                return (
                  <div key={product.id} className="col-md-6 col-lg-4">
                    <div className="product card card-outline card-warning collapsed-card">
                      <div className="card-header bg-light">
                        <h3 className="card-title">Ürün Kodu : {product.products_code}</h3>

                        <div className="card-tools ">
                          {((userPermissions?.includes(8) && userPermissions?.includes(9)) ||
                            userPermissions?.includes(1)) && (
                            <>
                              <Button
                                size="sm"
                                variant=""
                                className="p-0 ms-3 text-primary"
                                onClick={() => {
                                  dispatch(SHOW_PRODUCTION_EDIT_MODAL({ id: product.id }));
                                  dispatch(
                                    SET_PRODUCTION_DETAILS_FOR_UPDATE_ALL({
                                      price: product.price,
                                      quantity: product.quantity,
                                      manufacturers_id: product.manufacturers_id,
                                      material: product.material,
                                      color: product.color,
                                      lampholder: product.lampholder,
                                      branch: product.branch,
                                      glass: product.glass,
                                      features: product.features,
                                      specialNotes: product.specialNotes,
                                      production_status_id: product.production_status_id,
                                    }),
                                  );
                                }}>
                                <i className="fas fa-edit"></i>
                              </Button>
                              {userPermissions?.includes(9) && (
                                <button
                                  type="button"
                                  className="btn btn-tool text-danger mx-3 p-0"
                                  onClick={() => showProductDeleteModalHandler(product)}>
                                  <i className="fas fa-trash"></i>
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="bg-light border-top p-2">
                        <p className="mb-0 ms-3">
                          {product.description}{' '}
                          <span className="text-muted">
                            {product.productVariant && ` - ${product.productVariant.name}`}
                          </span>
                        </p>
                      </div>

                      <div className="row mx-0 bg-light">
                        <div className="col-4 px-0 text-center">
                          <img
                            src={
                              product.productVariant
                                ? product.productVariant.image_link
                                : product.product.imageLink
                            }
                            alt=""
                            className=" product-img-thumb"
                          />
                        </div>
                        {!userPermissions?.includes(8) ? (
                          <div className="col-8 px-0 h-100">
                            <div className="bg-light h5 text-center text-danger mt-4">
                              {product.quantity} Adet
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-4 px-0">
                              <div className="small-box bg-light mx-0 my-0">
                                <div className="inner">
                                  <h3>{(product.price * 1.18).toFixed(2).toLocaleString()} ₺</h3>

                                  <p>
                                    X{' '}
                                    <span className="badge badge-warning px-2">
                                      {product.quantity} Adet
                                    </span>
                                  </p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                                <a href="#" className="small-box-footer">
                                  Birim Fiyat{' '}
                                </a>
                              </div>
                            </div>
                            <div className="col-4 px-0">
                              <div className="small-box bg-light mx-0 my-0">
                                <div className="inner">
                                  <h3>{(product.total * 1.18).toFixed(2).toLocaleString()} ₺</h3>

                                  <p className="invisible">KDV Dahil</p>
                                </div>
                                <div className="icon">
                                  <i className="ion ion-bag"></i>
                                </div>
                                <a href="#" className="small-box-footer">
                                  Toplam
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="d-flex align-items-center mx-0  py-1 px-2 text-white bg-success mt-2">
                        <strong className="me-2">Üretici: </strong>
                        <span className="">{product.manufacturer?.name}</span>
                      </div>

                      <div className="p-2 bg-light">
                        <p className="mb-0 border-bottom bg-atolye rounded px-2">
                          Ürün Özellikleri
                        </p>
                        <div className="rounded bg-white mb-2 border p-2">
                          <p className="mb-0">
                            {' '}
                            {product.features === null || product.features === ''
                              ? '-'
                              : product.features}
                          </p>
                        </div>
                        <p className="mb-0 border-bottom bg-atolye rounded px-2">Materyal</p>
                        <div className="rounded bg-white mb-2 border p-2">
                          <p className="mb-0">
                            {product.material !== null ? product.material : '-'}
                          </p>
                        </div>
                        <p className="mb-0 border-bottom bg-atolye rounded px-2">Cam/Abajur</p>
                        <div className="rounded bg-white mb-2 border p-2">
                          <p className="mb-0">{product.glass !== null ? product.glass : '-'}</p>
                        </div>
                        <p className="mb-0 border-bottom bg-atolye rounded px-2">Kol Sayısı</p>
                        <div className="rounded bg-white mb-2 border p-2">
                          <p className="mb-0">{product.branch !== null ? product.branch : '-'}</p>
                        </div>
                        <p className="mb-0 border-bottom bg-atolye rounded px-2">Renk/Kaplama</p>
                        <div className="rounded bg-white mb-2 border p-2">
                          <p className="mb-0">{product.color !== null ? product.color : '-'}</p>
                        </div>
                        <p className="mb-0 border-bottom bg-atolye rounded px-2">Duy Tipi</p>
                        <div className="rounded bg-white mb-2 border p-2">
                          <p className="mb-0">
                            {product.lampholder !== null ? product.lampholder : '-'}
                          </p>
                        </div>
                        <p className="mb-0 border-bottom bg-atolye rounded px-2">Özel Notlar</p>
                        <div className="rounded bg-white mb-2 border p-2">
                          <p className="mb-0">
                            {product.specialNotes !== null ? product.specialNotes : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mx-0 bg-light p-3 text-center">
                        <strong className="">Durum: </strong>
                        <span
                          className={`border-0 text-${
                            product.production_status_id > 2 ? 'success' : 'danger'
                          }   py-1 px-2  ms-2 font-12`}>
                          <strong>{product.productionStatus.name}</strong>
                        </span>

                        <ProductionEditModal
                          fetchOrder={fetchOrder}
                          isManufacturerOptionsLoading={isManufacturerOptionsLoading}
                          manufacturerOptions={manufacturerOptions}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
};

export default ProductsDetails;
