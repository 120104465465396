import CreatePriceOffer from './CreatePriceOffer';

import { Button, Form, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import ContentWrapper from 'components/shared/ContentWrapper';
import DatePicker from 'react-datepicker';
import Footer from 'components/shared/Footer/Footer';
import Header from 'components/shared/Header';
import Loading from 'components/shared/Loading';
import PageTitle from 'components/shared/PageTitle';
import PageWrapper from 'components/shared/PageWrapper';
import Select from 'react-select';

import { useUser, useAtolyeAPI } from 'hooks';

import CustomerSelection from './components/CustomerSelection';
import ProductSelection from './components/ProductSelection';

export {
  Button,
  CustomerSelection,
  ContentWrapper,
  DatePicker,
  Footer,
  Form,
  Header,
  Loading,
  Modal,
  PageTitle,
  PageWrapper,
  ProgressBar,
  ProductSelection,
  Select,
  Spinner,
  useUser,
  useAtolyeAPI,
};

export default CreatePriceOffer;
