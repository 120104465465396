import { combineReducers } from 'redux';

import createProductReducer from './CreateProduct/createProductSlice';
import singleProductReducer from './SingleProduct/singleProductSlice';
import productsReducer from './Products/productsSlice';

export default combineReducers({
  createProduct: createProductReducer,
  singleProduct: singleProductReducer,
  products: productsReducer,
});
