import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Select } from '..';
import { SET_CUSTOMER } from '../createPriceOfferSlice';
import CustomerModal from './CustomerModal';
import CustomerForm from './CustomerForm';

const CustomerSelection = ({
  title,
  isCustomerOptionsLoading,
  customerOptions,
  getCustomerOptions,
}) => {
  // Redux
  const dispatch = useDispatch();
  const { editingCustomer } = useSelector((state) => state.priceOffers.createPriceOffer);
  return (
    <>
      <div className="card card-dark my-4 p-0">
        <div className="card-header bg-dark  font-weight-bold text-center">Müşteri Bilgileri</div>
        <form id="formGetCustomer" className=" bg-lightPrimary">
          <div className="form-group form-inline  col-md-4 mx-auto p-2">
            <label htmlFor="">{title}</label>
            <div className="w-100 mt-2">
              <Select
                options={customerOptions}
                isLoading={isCustomerOptionsLoading}
                onChange={({ customerDetails }) => dispatch(SET_CUSTOMER(customerDetails))}
                placeholder="Müşteri adı girin"
                value={customerOptions.find((item) => item.value === editingCustomer.id) || ''}
              />
            </div>
          </div>
        </form>
        <div className="alert alert-warning my-3 mx-2 col-md-6 mx-auto">
          Yeni bir müşteri ile sipariş oluşturmak için önce{' '}
          <Link className="font-weight-bold text-primary" to="/create-customer">
            Yeni Müşteri Kaydı
          </Link>
          bölümünden müşteri kaydı oluşturunuz.
        </div>
        <CustomerForm />
        <CustomerModal customerOptions={customerOptions} getCustomerOptions={getCustomerOptions} />
        <hr />
      </div>
    </>
  );
};

export default CustomerSelection;
