import { useSelector } from 'react-redux';

import OrderPDFDocument from 'templates/OrderPDFDocument';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useWindowDimensions from 'hooks/useWindowDimensions';

// import { PDFDownloadLink } from '@react-pdf/renderer';

const OrderPDF = () => {
  //Redux
  const { order, isLoading, pdfType, pdfLanguage, pdfCurrency } = useSelector(
    (state) => state.orders.singleOrder,
  );

  // Hooks
  const { width } = useWindowDimensions();

  const images = order?.productions.map((item) => {
    return item.productVariant ? item.productVariant.image_link : item.product.imageLink;
  });

  return !order ? (
    <div className="m-5">
      <Alert variant="danger">Sipariş Bulunamadığı için PDF Oluşturulamadı!!!</Alert>
      <Link to="/orders" className="btn btn-primary">
        Sipariş Listesine Geri Dön
      </Link>
    </div>
  ) : isLoading ? (
    'PDF oluşturuluyor'
  ) : width < 768 ? (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      <div className="btn btn-light border text-white">
        <PDFDownloadLink
          document={
            <OrderPDFDocument
              isPriceless={pdfType === 'priceless'}
              lang={pdfLanguage}
              currency={pdfCurrency}
              order={order}
              products={order?.productions}
              customer={order?.customer}
              balances={order?.order_balances}
              user={order?.user}
              images={images}
            />
          }
          fileName={`Atolye Sipariş Detay - ${order.id}`}>
          {({ loading }) =>
            loading ? 'Oluşturuluyor...' : 'Sipariş Detay PDF İndirmek İçin Tıkla'
          }
        </PDFDownloadLink>
      </div>
    </div>
  ) : (
    <PDFViewer className="w-100 vh-100">
      <OrderPDFDocument
        isPriceless={pdfType === 'priceless'}
        lang={pdfLanguage}
        currency={pdfCurrency}
        order={order}
        products={order?.productions}
        customer={order?.customer}
        balances={order?.order_balances}
        user={order?.user}
        images={images}
      />
    </PDFViewer>
  );
};

export default OrderPDF;
