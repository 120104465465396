import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { START_LOADING, STOP_LOADING } from '../singleOrderSlice';

const AtolyeAPI = new API();

const DeleteOrderModal = () => {
  //Redux
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.orders.singleOrder);

  // Hooks
  const navigate = useNavigate();

  // Functions
  const deleteOrderHandler = async () => {
    dispatch(START_LOADING());
    try {
      await AtolyeAPI.get(`/orders/${order.id}/delete`);
      alert('success', 'Sipariş başarıyla silindi');
      navigate('/orders');
    } catch (error) {
      alert('error', 'Sipariş silinirken bir hata oluştu');
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <div
      className="modal fade delete-order-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div
          className="modal-content"
          style={{
            backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
          }}>
          <div className="modal-header">
            <h5 className="modal-title">Siparişi Sil</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <strong>{order.id}</strong> numaralı siparişi silmek istediğinize emin misiniz?
            </p>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={deleteOrderHandler}>
              Sil
            </button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteOrderModal;
