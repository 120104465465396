/* eslint-disable no-undef */
import axios from 'axios';
import { aws4Interceptor } from 'aws4-axios';

const API_ENDPOINT =
  process.env[`REACT_APP_API_ENDPOINT_${process.env.REACT_APP_ENV.toUpperCase()}`];

const interceptor = aws4Interceptor({
  options: {
    region: 'eu-central-1',
    service: 'execute-api',
  },
  credentials: {
    accessKeyId: process.env.REACT_APP_API_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_API_SECRET_KEY,
  },
});

axios.interceptors.request.use(interceptor);

// To fix unsafe headers error, we need to delete some headers
// const axiosConfig = {
//   transformRequest: (data, headers) => {
//     delete headers['Host'];
//     delete headers['Content-Length'];
//     headers['Content-Type'] = 'application/json';
//     return JSON.stringify(data);
//   },
// };

// console.log({ API_ENDPOINT });

class API {
  // Method for GET Requests
  async get(path) {
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}${path}`,
        //  axiosConfig
      );

      return data;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  // Method for GET FILE Requests
  async getFile(path) {
    try {
      await axios.get(`${API_ENDPOINT}${path}`).then((response) => {
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = response.data.signedUrl;
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
      });

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  // Method for get file with POST Requests
  async getFileWithPost(path, body = {}) {
    try {
      await axios
        .post(`${API_ENDPOINT}${path}`, body)
        .then((response) => {
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = response.data.signedUrl;
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  // Method for POST Requests

  async post(path, body = {}) {
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}${path}`,
        body,
        // axiosConfig
      );

      return data;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  async put(path, body = {}) {
    try {
      const { data } = await axios.put(
        `${API_ENDPOINT}${path}`,
        body,
        // axiosConfig
      );

      return data;
    } catch (error) {
      console.error(error);
      return;
    }
  }
}

export default API;
