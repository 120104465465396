import { Button, Form, Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  ADD_EDIT_OPTION_VALUE,
  DELETE_LAST_EDIT_OPTION_VALUE,
  HIDE_EDIT_OPTION_MODAL,
  RESET_EDIT_OPTION,
  SET_EDIT_OPTION_NAME,
  SET_EDIT_OPTION_VALUE,
  START_LOADING,
  STOP_LOADING,
} from '../../singleProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const EditVariantOptionModal = ({ getProduct }) => {
  //Redux
  const { productCode } = useSelector((state) => state.products.singleProduct);
  const { isEditOptionModalDisplayed } = useSelector(
    (state) => state.products.singleProduct.variants,
  );
  const { id, name, values } = useSelector(
    (state) => state.products.singleProduct.variants.editOption,
  );
  const dispatch = useDispatch();

  // Functions
  const handleDismiss = () => {
    dispatch(RESET_EDIT_OPTION());
    dispatch(HIDE_EDIT_OPTION_MODAL());
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    dispatch(START_LOADING());

    const oldValues = values.filter((value) => value.isAlreadySaved);
    const newValues = values.filter((value) => !value.isAlreadySaved);

    const body = { name, newValues, oldValues, products_id: productCode };
    try {
      const { status } = await AtolyeAPI.put(`/product_variants/options/${id}`, body);
      if (status === 'SUCCESS') {
        await getProduct();
        return alert('success', 'Variant Seçeneği başarıyla güncellendi');
      }
      throw new Error('Seçenek güncellenirken bir hata oluştu');
    } catch (error) {
      console.log({ error });
      return alert('error', 'Seçenek güncellenirken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(RESET_EDIT_OPTION());
      dispatch(HIDE_EDIT_OPTION_MODAL());
    }
  };

  const validateForm = () => {
    if (!name) {
      alert('error', 'Seçenek adı boş bırakılamaz!');
      return false;
    }
    if (values.map((value) => value.name).includes('')) {
      alert('error', 'Seçenek Değerlerinin tamamı doldurulmalıdır!');
      return false;
    }
    return true;
  };

  return (
    <div className="text-center">
      <Modal
        show={isEditOptionModalDisplayed}
        onHide={handleDismiss}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-success" closeButton>
          <Modal.Title>Varyant Seçeneği Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-3">
            <Row>
              <Form.Label>Seçenek Adı</Form.Label>
              <Form.Control
                style={{ maxWidth: 300, fontSize: 14 }}
                type="text"
                placeholder="Ör: Renk"
                value={name}
                onChange={(e) => dispatch(SET_EDIT_OPTION_NAME(e.target.value))}
              />
            </Row>
            <Row className="mt-4 mb-2">
              <Form.Label>Değerler</Form.Label>
              {values.map((value, index) => (
                <Row key={`value-${index}`}>
                  <Form.Control
                    className="my-1"
                    style={{ maxWidth: 300, fontSize: 14 }}
                    type="text"
                    placeholder="Ör: Beyaz"
                    value={value.name}
                    onChange={(e) =>
                      dispatch(
                        SET_EDIT_OPTION_VALUE({ index, value: { ...value, name: e.target.value } }),
                      )
                    }
                  />
                </Row>
              ))}
            </Row>
            <span
              className={values[values.length - 1] !== '' ? `text-primary` : 'text-muted'}
              role={'button'}
              onClick={() => dispatch(ADD_EDIT_OPTION_VALUE())}>
              + Yeni Değer
            </span>
            <span
              className={
                values.length > 1 && !values[values.length - 1].isAlreadySaved
                  ? `text-danger ms-4`
                  : 'text-muted ms-4'
              }
              role={'button'}
              onClick={() => dispatch(DELETE_LAST_EDIT_OPTION_VALUE())}>
              - Son Değeri Sil
            </span>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleDismiss}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={handleSave}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditVariantOptionModal;
