import './BasicTable.scss';

import { Table } from 'react-bootstrap';

const BasicTable = ({ data, columns }) => {
  return (
    <div className="d-flex justify-content-center rounded">
      <Table striped bordered hover variant="" responsive>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={'column-' + column.name}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem, index) => (
            <tr key={'row-' + index}>
              {columns.map((column) =>
                column.Cell ? (
                  <td key={'column-' + column.name}>
                    <column.Cell value={dataItem[column.key]} rowData={dataItem} />
                  </td>
                ) : (
                  <td key={'column-' + column.name}>{dataItem[column.key]}</td>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BasicTable;
