import { useState, useEffect } from 'react';
import alert from 'js/sweetAlert';

import { useAtolyeAPI } from 'hooks';
const usePaymentStatus = () => {
  // States
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [isSortingUpdated, setIsSortingUpdated] = useState(false);
  const [areFiltersUpdated, setAreFiltersUpdated] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);

  // Hooks
  const { postTo, getFileWithPost } = useAtolyeAPI();

  // Effects
  useEffect(() => {
    setIsSortingUpdated(false);
  }, [isSortingUpdated]);

  useEffect(() => {
    setAreFiltersUpdated(false);
  }, [areFiltersUpdated]);

  // Functions

  const getRows = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const { data, totalCount, totalPages } = await postTo('/payment_status/table', {
        limit: pageSize,
        offset: isSortingUpdated ? 0 : pageIndex * pageSize,
        search: filters || {},
        dates: dateFilter || null,
        order: sorting.length === 0 ? [['orders_id', 'DESC']] : sorting,
      });
      setRows(data);
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Ödeme kayıtları getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };

  const exportAllPayments = async ({ startDate, endDate }) => {
    setLoading(true);
    try {
      await getFileWithPost('/payment_status/export', {
        startDate,
        endDate,
      });
    } catch (error) {
      console.log(error);
      alert('error', 'Ödeme Kayıtları indirilirken bir sorun oluştu!');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    pageCount,
    rows,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    getRows,
    exportAllPayments,
    setFilters,
    setDateFilter,
    setSorting,
    setIsSortingUpdated,
    setAreFiltersUpdated,
  };
};

export default usePaymentStatus;
