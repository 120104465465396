import { Button, Col, Modal, Row, Table } from 'react-bootstrap';

const EditManufactureProductModal = ({
  show,
  onHide,
  getManufactureProducts,
  manufactureProduct,
}) => {
  // Functions
  const handleOnHide = () => {
    onHide();
    getManufactureProducts();
  };

  console.log(manufactureProduct);

  return (
    <Modal fullscreen show={show} onHide={handleOnHide}>
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title>
          {manufactureProduct.data.product.id}{' '}
          <small>
            - {manufactureProduct.name} {manufactureProduct.variantName}
          </small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4}>
            <img
              className="manufacture-product-card-image bg-light"
              src={manufactureProduct.imageLink}
              alt={manufactureProduct.name}
            />
            {/* <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Parça</th>
                  <th>Adet</th>
                </tr>
              </thead>
              <tbody>
                {manufactureProduct.data.components.length === 0 ? (
                  <tr className="text-center">
                    <td>Parça yok</td>
                  </tr>
                ) : (
                  manufactureProduct.data.components.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.count}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table> */}
          </Col>
          <Col md={8}>
            {/* Manufacture Product General Data */}

            <Table bordered>
              <tbody>
                <tr>
                  <td className="bg-light">
                    <strong>Ürün Kodu</strong>
                  </td>
                  <td>{manufactureProduct.data.product.id}</td>
                </tr>
                <tr>
                  <td className="bg-light">
                    <strong>Ürün Açıklaması</strong>
                  </td>
                  <td>{manufactureProduct.name}</td>
                </tr>
                <tr>
                  <td className="bg-light">
                    <strong>Varyant</strong>
                  </td>
                  <td>{manufactureProduct.variantName}</td>
                </tr>

                <tr>
                  <td className="bg-light">
                    <strong>Kapasite</strong>
                  </td>
                  <td>{manufactureProduct.capacity}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-5 px-2">
          {/* Manufacture Product Components */}
          <Table bordered>
            <thead>
              <tr>
                <th className="bg-light">
                  <strong>Görsel</strong>
                </th>
                <th>Parça Kodu</th>
                <th>Adet</th>
                <th>Birim Maliyet</th>
                <th>Toplam Maliyet</th>
              </tr>
            </thead>
            <tbody>
              {manufactureProduct.data.components.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
              {/* {Add Button} */}
              <tr>
                <td colSpan={6} className="text-center">
                  <Button variant="outline-success" size="sm">
                    + Parça Ekle
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnHide}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={() => {}}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditManufactureProductModal;
