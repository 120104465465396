import { useSelector, useDispatch } from 'react-redux';

import { DatePicker, Spinner, useUser } from '..';
import useUserOptions from 'hooks/useUserOptions';
import {
  SET_USER,
  SET_SALE_DATE,
  SET_DELIVERY_DATE,
  SET_DELIVERY_OPTION,
  SET_SALES_CHANNEL,
  SET_BILL,
  SET_BILLING_ADDRESS,
  RESET_ORDER,
  START_LOADING,
  STOP_LOADING,
  SET_ORDER_STATUS,
  SET_ORDER_MANAGER,
} from '../singleOrderSlice';
import API from 'utils/atolyeAPI';
import useOrderStatusOptions from 'hooks/useOrderStatusOptions';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const EditOrderModal = ({ fetchOrder, user }) => {
  //Redux
  const { order, isLoading } = useSelector((state) => state.orders.singleOrder);
  const dispatch = useDispatch();

  // Hooks
  const { userPermissions } = useUser();
  const { userOptions, isUserOptionsLoading } = useUserOptions();
  const { orderStatusOptions } = useOrderStatusOptions();

  // Functions
  const updateOrder = async () => {
    dispatch(START_LOADING());
    const body = {
      order_status_id: order.order_status_id,
      users_id: order.users_id,
      orderManagerId: order?.orderManagerId,
      salesChannel: order.salesChannel,
      saleDate: order.saleDate,
      deliveryDate: order.deliveryDate,
      deliveryOption: order.deliveryOption,
      bill: order.bill,
      billingAddress: order.billingAddress,
      updatedByUser: user,
    };
    try {
      await AtolyeAPI.put(`/orders/${order.id}`, body);
      fetchOrder(order.id);
      alert('success', 'Sipariş Güncellendi');
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <div
      className="modal fade edit-info-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div
          className="modal-content"
          style={{
            backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
          }}>
          <div className="modal-header">
            <h5 className="modal-title">Sipariş Genel Bilgi - Düzenle</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <>
                {' '}
                <div className="form-group">
                  <label htmlFor="">Sipariş Durumu: </label>

                  <select
                    name="orderStatus"
                    id="orderStatus"
                    className="form-control"
                    value={order.order_status_id}
                    onChange={(event) => dispatch(SET_ORDER_STATUS(event.target.value))}>
                    {orderStatusOptions.map((status) => {
                      return (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {userPermissions?.includes(9) && (
                  <>
                    <div className="form-group">
                      <label htmlFor="">Yetkili: </label>
                      {isUserOptionsLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <select
                          name="orderUser"
                          id="orderUser"
                          className="form-control"
                          value={order.users_id}
                          onChange={(event) => dispatch(SET_USER(event.target.value))}>
                          {userOptions.map((user) => {
                            if (user.roles_id !== 4) {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.fullName}
                                </option>
                              );
                            }
                          })}
                        </select>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Sipariş Sorumlusu: </label>
                      {isUserOptionsLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <select
                          name="orderManager"
                          id="orderManager"
                          className="form-control"
                          value={order?.orderManagerId}
                          onChange={(event) => dispatch(SET_ORDER_MANAGER(event.target.value))}>
                          <option value={null}>Belirlenmedi</option>
                          {userOptions.map((user) => {
                            if (user.roles_id !== 4) {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.fullName}
                                </option>
                              );
                            }
                          })}
                        </select>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Satış Tarihi: </label>
                      <DatePicker
                        selected={Date.parse(order.saleDate)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        minTime={'03:00:00'}
                        maxTime={'03:00:00'}
                        withPortal
                        onChange={(date) => dispatch(SET_SALE_DATE(date))}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Tahmini Teslim Tarihi: </label>
                      <DatePicker
                        selected={Date.parse(order.deliveryDate)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        minTime={'03:00:00'}
                        maxTime={'03:00:00'}
                        withPortal
                        onChange={(date) => dispatch(SET_DELIVERY_DATE(date))}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Teslimat Şekli: </label>
                      <select
                        name="deliveryOption"
                        id="deliveryOption"
                        className="form-control"
                        value={order.deliveryOption}
                        onChange={(event) => dispatch(SET_DELIVERY_OPTION(event.target.value))}>
                        <option value="Belirlenmedi">Belirlenmedi</option>
                        <option value="Mağazadan Teslim">Mağazadan Teslim</option>
                        <option value="Adrese Teslim">Adrese Teslim</option>
                        <option value="Adrese Kargo">Adrese Kargo</option>
                        <option value="Adrese Kasa">Adrese Kasa</option>
                        <option value="Montaj">Montaj</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Satış Kanalı : </label>
                      <select
                        name="salesChannel"
                        id="salesChannel"
                        className="form-control"
                        value={order.salesChannel}
                        onChange={(event) => dispatch(SET_SALES_CHANNEL(event.target.value))}>
                        <option value="Belirlenmedi">Belirlenmedi</option>
                        <option value="Münferit Mağaza">Münferit Mağaza</option>
                        <option value="İnternet">İnternet</option>
                        <option value="Proje">Proje</option>
                        <option value="İhracat Satışı">İhracat Satışı</option>
                        <option value="Diğer">Diğer</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Fatura Kesildi Mi?: </label>
                      <select
                        name="bill"
                        id="bill"
                        className="form-control"
                        value={order.bill}
                        onChange={(event) => dispatch(SET_BILL(event.target.value))}>
                        <option value="Kesilmedi">Kesilmedi</option>
                        <option value="Kesildi">Kesildi</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Fatura Adresi : </label>
                      <textarea
                        name="billingAddress"
                        id="billingAddress"
                        className="form-control"
                        value={order.billingAddress}
                        onChange={(event) =>
                          dispatch(SET_BILLING_ADDRESS(event.target.value))
                        }></textarea>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={updateOrder}>
              Kaydet
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => dispatch(RESET_ORDER())}>
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrderModal;
