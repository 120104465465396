import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { Select, useAtolyeAPI } from '..';

import useProductOptions from 'hooks/useProductOptions';
import {
  CLEAR_PRODUCT_TO_ADD,
  HIDE_ADD_PRODUCT_MODAL,
  PICK_PRODUCT,
  SET_IMAGE_LINK,
  SET_PRODUCT_TO_ADD,
  SET_RECALCULATED_PRICE_OFFER,
  SET_VALUE_ON_PRODUCT_TO_ADD,
  START_LOADING,
  STOP_LOADING,
} from '../singlePriceOfferSlice';
import { useEffect } from 'react';
import { roundTo2Decimals } from 'utils/calcUtils';
import alert from 'js/sweetAlert';

const AddProductModal = ({ fetchPriceOffer }) => {
  //Redux
  const dispatch = useDispatch();
  const { priceOffer, selectedProduct, imageLink, productToAdd, isAddProductModalDisplayed } =
    useSelector((state) => state.priceOffers.singlePriceOffer);
  const { productsTotal, discountTotal, subTotal, KDVtotal, total } = useSelector(
    (state) => state.priceOffers.singlePriceOffer.recalculatedPriceOffer,
  );

  // Hooks
  const { productOptions, isProductOptionsLoading } = useProductOptions();
  const { fetchFrom, postTo } = useAtolyeAPI();

  // Functions

  const getProductDetails = async () => {
    try {
      fetchFrom(`/products/${selectedProduct}`).then((results) =>
        dispatch(SET_PRODUCT_TO_ADD(results)),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const salePriceOnChangeHandler = (e) => {
    const _tempSalePrice = e.target.value / 1.18;
    dispatch(SET_VALUE_ON_PRODUCT_TO_ADD({ key: 'salePrice', value: _tempSalePrice }));
  };

  const recalculatePriceOfferBeforeAddProduct = () => {
    const _currentProductsOnlyWithFinancialData = priceOffer.products.map((product) => ({
      price: product.price,
      quantity: product.quantity,
    }));

    const _tempProductsOnlyWithFinancialData = [
      ..._currentProductsOnlyWithFinancialData,
      { price: productToAdd.salePrice, quantity: productToAdd.quantity },
    ];

    const _tempProductsTotal = _tempProductsOnlyWithFinancialData.reduce((acc, product) => {
      return acc + parseFloat(product.price * parseInt(product.quantity));
    }, 0);
    const _tempDiscountTotal =
      _tempProductsTotal - _tempProductsTotal / (1 + priceOffer.discountRate / 100);
    const _tempSubTotal = _tempProductsTotal - _tempDiscountTotal;
    const _tempTotal = _tempSubTotal * 1.18;
    const _tempKDVtotal = _tempTotal - _tempSubTotal;

    dispatch(
      SET_RECALCULATED_PRICE_OFFER({
        priceOffers_id: priceOffer.id,
        productsTotal: _tempProductsTotal,
        discountTotal: _tempDiscountTotal,
        subTotal: _tempSubTotal,
        KDVtotal: _tempKDVtotal,
        total: roundTo2Decimals(_tempTotal),
        totalDiff: roundTo2Decimals(priceOffer.total - _tempTotal),
      }),
    );
  };

  const handleTotalAfterPriceChange = () => {
    dispatch(
      SET_VALUE_ON_PRODUCT_TO_ADD({
        key: 'total',
        value: productToAdd?.salePrice
          ? roundTo2Decimals(productToAdd.salePrice * productToAdd.quantity * 1.18)
          : 0,
      }),
    );

    recalculatePriceOfferBeforeAddProduct();
  };

  const handleTotalAfterQuantityChange = () => {
    dispatch(
      SET_VALUE_ON_PRODUCT_TO_ADD({
        key: 'total',
        value: productToAdd?.quantity
          ? roundTo2Decimals(productToAdd.salePrice * productToAdd.quantity * 1.18)
          : 0,
      }),
    );

    recalculatePriceOfferBeforeAddProduct();
  };

  const addProductOnPriceOfferHandler = async () => {
    if (!productToAdd.quantity) {
      alert('error', 'Ürün adedini girmediniz');
      return;
    }
    if (!productToAdd.features) {
      alert('error', 'Ürün Özellikleri boş bırakılamaz');
      return;
    }

    const body = {
      product: {
        products_code: productToAdd.id,
        quantity: productToAdd.quantity,
        features: productToAdd.features,
        description: productToAdd.description,
        price: productToAdd.salePrice,
        total: roundTo2Decimals(productToAdd.total / 1.18),
        glass: productToAdd.glass,
        material: productToAdd.material,
        branch: productToAdd.branch,
        lampholder: productToAdd.lampholder,
        color: productToAdd.color,
        specialNotes: productToAdd.specialNotes,
      },
      priceOfferDetailsToUpdate: {
        productsTotal,
        discountTotal,
        subTotal,
        KDVtotal,
        total,
      },
    };

    dispatch(START_LOADING());

    postTo(`/price_offers/${priceOffer.id}/add-product`, body)
      .then(() => {
        alert('success', 'Ürün başarıyla eklendi');
        dispatch(HIDE_ADD_PRODUCT_MODAL());
        fetchPriceOffer(priceOffer.id);
      })
      .catch((err) => {
        console.log(err);
        alert('error', 'Ürün eklenirken bir hata oluştu');
      })
      .finally(() => dispatch(STOP_LOADING()));
  };

  const onHideHandler = () => {
    dispatch(CLEAR_PRODUCT_TO_ADD());
    dispatch(HIDE_ADD_PRODUCT_MODAL());
  };

  // Effects

  useEffect(() => {
    selectedProduct && getProductDetails();
  }, [selectedProduct]);

  useEffect(() => {
    productToAdd && handleTotalAfterQuantityChange();
  }, [productToAdd?.quantity]);

  useEffect(() => {
    productToAdd && handleTotalAfterPriceChange();
  }, [productToAdd?.salePrice]);

  return (
    <Modal fullscreen show={isAddProductModalDisplayed} onHide={onHideHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Ürün Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <Row className="h-100 font-12">
          <Col md={6} className="border-right">
            <div className="h-50 d-flex flex-column">
              <label htmlFor="">Ürün Kodu: </label>
              <div className="select-container mb-4">
                <div className="select-wrapper mx-auto" style={{ maxWidth: 500 }}>
                  <Select
                    className="text-dark mx-2"
                    id="input-product"
                    options={productOptions}
                    isLoading={isProductOptionsLoading}
                    onChange={({ value, imageLink }) => {
                      dispatch(PICK_PRODUCT(value));
                      dispatch(SET_IMAGE_LINK(imageLink));
                    }}
                    value={productOptions.find((item) => item.value === selectedProduct) || ''}
                    placeholder="Ürün Kodu veya Açıklama girin"
                  />
                </div>
              </div>
              <div className="text-center my-auto">
                <img
                  src={imageLink}
                  alt="Product Image"
                  className="img-fluid my-2"
                  style={{ minWidth: 150, maxHeight: 300 }}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            {productToAdd && (
              <div className="product-to-add-infos mx-2">
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Kategori</label>
                      <input
                        type="text"
                        className="form-control"
                        value={productToAdd?.product_category?.name}
                        disabled
                        readOnly
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Materyal</label>
                      <input
                        type="text"
                        className="form-control"
                        value={productToAdd?.material}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({ key: 'material', value: e.target.value }),
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>
                        Fiyatı
                        <span className="ms-2 badge badge-primary">₺</span>
                        <span className="ms-2 badge badge-warning text-dark">KDV Dahil</span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        defaultValue={roundTo2Decimals(productToAdd.salePrice * 1.18) || 0}
                        onChange={salePriceOnChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Adet</label>
                      <input
                        type="number"
                        min={1}
                        className="form-control"
                        value={productToAdd.quantity}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({ key: 'quantity', value: e.target.value }),
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Cam/Abajur</label>
                      <input
                        type="text"
                        className="form-control"
                        value={productToAdd.glass}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({ key: 'glass', value: e.target.value }),
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Kol Sayısı</label>
                      <input
                        type="text"
                        className="form-control"
                        value={productToAdd.branch}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({ key: 'branch', value: e.target.value }),
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Renk</label>
                      <input
                        type="text"
                        className="form-control"
                        value={productToAdd.color}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({ key: 'color', value: e.target.value }),
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Duy Tipi</label>
                      <input
                        type="text"
                        className="form-control"
                        value={productToAdd.lampholder}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({
                              key: 'lampholder',
                              value: e.target.value,
                            }),
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label>Ürün Özellikleri</label>
                      <textarea
                        className="form-control"
                        value={productToAdd.features}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({ key: 'features', value: e.target.value }),
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label>Özel Notlar</label>
                      <textarea
                        className="form-control"
                        value={productToAdd.specialNotes}
                        onChange={(e) =>
                          dispatch(
                            SET_VALUE_ON_PRODUCT_TO_ADD({
                              key: 'specialNotes',
                              value: e.target.value,
                            }),
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-primary me-auto">
          <strong>Toplam Tutar : </strong>
          <span className="badge border border-primary text-primary font-14">
            {productToAdd ? productToAdd.total.toLocaleString('tr') : '0'}₺
          </span>
        </div>
        <Button variant="light" onClick={onHideHandler}>
          Vazgeç
        </Button>
        <Button variant="success" className="text-white" onClick={addProductOnPriceOfferHandler}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
