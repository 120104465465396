import './SingleCustomer.scss';

import { useState, useEffect } from 'react';
import { useUser, useAtolyeAPI } from 'hooks';
import {
  alert,
  ContentWrapper,
  CustomerDetailsTable,
  CustomerOrdersTable,
  CustomerOrderBalancesTable,
  CustomerPriceOffersTable,
  Footer,
  Form,
  Header,
  PageTitle,
  PageWrapper,
  Select,
  Tabs,
  Tab,
} from '.';
import useSingleCustomer from './hooks/useSingleCustomer';
import { useSearchParams, useNavigate } from 'react-router-dom';

const SingleCustomer = (props) => {
  // State
  const [id, setId] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [isCustomerDetailsLoading, setIsCustomerDetailsLoading] = useState(false);
  const [orderBalances, setOrderBalances] = useState([]);
  // Hooks
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { user, userPermissions } = useUser();
  const { fetchFrom } = useAtolyeAPI();
  const { isLoading, customerOptions } = useSingleCustomer();

  // Effects

  useEffect(() => {
    selectedCustomer && getCustomerDetails(selectedCustomer.value);
  }, [selectedCustomer]);

  useEffect(() => {
    id
      ? customerOptions.length > 0 &&
        setSelectedCustomer(customerOptions?.find((customer) => customer.value == id))
      : setSelectedCustomer(null);
  }, [customerOptions, id]);

  useEffect(() => {
    return setId(searchParams?.get('id'));
  }, [searchParams]);

  useEffect(() => {
    if (customerDetails) {
      let _tempOrderBalances = [];
      customerDetails?.orders.map((order) =>
        order.order_balances.map((item) => _tempOrderBalances.push(item)),
      );
      setOrderBalances(_tempOrderBalances);
    }
  }, [customerDetails]);

  // Functions

  const getCustomerDetails = () => {
    setIsCustomerDetailsLoading(true);

    fetchFrom(`/customers/${id}`)
      .then((results) => setCustomerDetails(results))
      .catch((err) => console.log(err))
      .finally(setIsCustomerDetailsLoading(false));
  };

  const deleteCustomerHandler = () => {
    setIsCustomerDetailsLoading(true);

    fetchFrom(`/customers/${id}/delete`)
      .then(() => {
        setId('');
        alert('success', 'Müşteri kaydı başarıyla silindi');
        navigate('/customers');
      })
      .catch((err) => {
        console.log(err);
        alert('error', 'Müşteri kaydı silinemedi');
      })
      .finally(setIsCustomerDetailsLoading(false));
  };

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Müşteri Bilgileri" />
        <PageWrapper>
          {!userPermissions?.includes(29) ? (
            <div className="alert alert-danger">
              <strong>Yetkiniz yok!</strong> Müşteri bilgilerini görüntülemek için yetkiniz
              bulunmamaktadır.
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center my-5">
                <Form.Group>
                  <Form.Label>Müşteri: </Form.Label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedCustomer}
                    onChange={(option) => setSearchParams(option ? { id: option.value } : {})}
                    isDisabled={false}
                    isLoading={isLoading}
                    isClearable
                    isSearchable
                    placeholder="Müşteri adı girin.."
                    options={customerOptions}
                  />
                </Form.Group>
              </div>
              {selectedCustomer && (
                <div className="row">
                  <div className="col-md-4">
                    {' '}
                    {isCustomerDetailsLoading && 'Yükleniyor...'}
                    {customerDetails && (
                      <CustomerDetailsTable
                        customerDetails={customerDetails}
                        onDelete={deleteCustomerHandler}
                      />
                    )}
                  </div>
                  <div className="col-md-8">
                    {customerDetails && (
                      <div className="row my-4 mt-md-0">
                        <div className="col-md-4 col-6">
                          <div className="small-box border border-danger">
                            <div className="inner text-danger">
                              <h3>
                                {customerDetails.orders
                                  .reduce((partialSum, item) => partialSum + item.total, 0)
                                  .toFixed(2)
                                  .toLocaleString('tr')}{' '}
                                ₺
                              </h3>
                              <strong className="text-dark">Sipariş Toplamı</strong>
                            </div>
                            <div className="icon">
                              <i className="ion ion-bag"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          {' '}
                          <div className="small-box border border-primary">
                            <div className="inner text-primary">
                              <h3>
                                {customerDetails.price_offers
                                  .reduce((partialSum, item) => partialSum + item.total, 0)
                                  .toFixed(2)
                                  .toLocaleString('tr')}{' '}
                                ₺
                              </h3>
                              <strong className="text-dark">Fiyat Teklifi Toplamı</strong>
                            </div>
                            <div className="icon">
                              <i className="ion ion-bag"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          {' '}
                          <div className="small-box border border-success">
                            <div className="inner text-success">
                              <h3>
                                {orderBalances
                                  .reduce((partialSum, item) => partialSum + item.received, 0)
                                  .toFixed(2)
                                  .toLocaleString('tr')}{' '}
                                ₺
                              </h3>
                              <strong className="text-dark">Alınan Ödeme Toplamı</strong>
                            </div>
                            <div className="icon">
                              <i className="ion ion-bag"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {customerDetails && (
                      <Tabs defaultActiveKey="orders" id="uncontrolled-tab-example">
                        <Tab eventKey="orders" title="Siparişler">
                          <p className="my-4">
                            Müşteriye ait tüm siparişler{' '}
                            <strong className="text-success">
                              {customerDetails.orders.length} adet{' '}
                            </strong>
                            olup aşağıda listelenmektedir.
                          </p>
                          <CustomerOrdersTable orders={customerDetails.orders} />
                        </Tab>
                        <Tab eventKey="priceOffers" title="Fiyat Teklifleri">
                          <p className="my-4">
                            Müşteriye ait tüm fiyat teklifleri{' '}
                            <strong className="text-success">
                              {customerDetails.price_offers.length} adet{' '}
                            </strong>
                            olup aşağıda listelenmektedir.
                          </p>
                          <CustomerPriceOffersTable priceOffers={customerDetails.price_offers} />
                        </Tab>
                        <Tab eventKey="balances" title="Ödemeler">
                          <p className="my-4">
                            Müşteriye ait tüm ödeme kayıtları{' '}
                            <strong className="text-success">{orderBalances.length} adet </strong>
                            olup aşağıda listelenmektedir.
                          </p>
                          <CustomerOrderBalancesTable orderBalances={orderBalances} />
                        </Tab>
                      </Tabs>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </PageWrapper>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default SingleCustomer;
