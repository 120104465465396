import { useState, useEffect } from 'react';
import alert from '../js/sweetAlert';

import { useAtolyeAPI } from '.';

const useOrders = () => {
  // States
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [isSortingUpdated, setIsSortingUpdated] = useState(false);
  const [areFiltersUpdated, setAreFiltersUpdated] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);

  // Hooks
  const { postTo, getFileWithPost } = useAtolyeAPI();

  // Effects
  useEffect(() => {
    setIsSortingUpdated(false);
  }, [isSortingUpdated]);

  useEffect(() => {
    setAreFiltersUpdated(false);
  }, [areFiltersUpdated]);

  // Functions

  const getOrders = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const { data, totalCount, totalPages } = await postTo('/orders/table', {
        limit: pageSize,
        offset: isSortingUpdated ? 0 : pageIndex * pageSize,
        search: filters || {},
        dates: dateFilter || null,
        order: sorting.length === 0 ? [['id', 'DESC']] : sorting,
      });
      setOrders(data);
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Siparişler getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };

  const exportAllOrders = async ({ startDate, endDate }) => {
    setLoading(true);
    try {
      await getFileWithPost('/orders/export', { startDate, endDate });
    } catch (error) {
      console.log(error);
      alert('error', 'Siparişler indirilirken bir sorun oluştu!');
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    pageCount,
    orders,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    getOrders,
    exportAllOrders,
    setFilters,
    setDateFilter,
    setSorting,
    setIsSortingUpdated,
    setAreFiltersUpdated,
  };
};

export default useOrders;
