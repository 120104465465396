import { roundTo2Decimals } from 'utils/calcUtils';

const FloatingTotalBox = ({ title, total = 0 }) => {
  return (
    <div className="floating-total-box d-flex flex-column justify-content-center align-content-center">
      <div className="text-light text-center">
        <strong>{title}</strong>
      </div>
      <hr className="my-0" />
      <div className="text-center font-18">{roundTo2Decimals(total).toLocaleString('tr')} ₺</div>
    </div>
  );
};

export default FloatingTotalBox;
