import React, { useState, useEffect } from 'react';
import Header from './components/shared/Header';
import Footer from './components/shared/Footer/Footer';
import { Auth } from 'aws-amplify';
import Loading from './components/shared/Loading';
import ContentWrapper from 'components/shared/ContentWrapper';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    user && setLoading(false);
  }, [user]);

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    user && setUser(user);
  };

  return (
    <>
      <Header />
      <ContentWrapper>
        <div className="m-auto text-center pt-5">
          <img
            src="/images/atolye-logo.png"
            alt="Atolye Logo"
            className="mb-5 bg-white p-3 shadow-sm rounded"
            width="300"
          />
          <p className="h2 text-secondary font-weight-light">ADMIN</p>
          <p className="mb-5">v2.0.0</p>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <p className=" d-inline-block  border border-dark rounded-sm px-5 py-1">
                <span className="">{user.attributes.name}</span>
              </p>
              <p className="mb-5">kullanıcı adıyla giriş yapıldı.</p>

              <a href="/dashboard" className="btn btn-success">
                Yönetim Paneline Devam Et
              </a>
            </div>
          )}
        </div>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default App;
