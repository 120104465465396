import { useDispatch, useSelector } from 'react-redux';
import { SET_DESCRIPTION_EDIT_TEXT } from '../../singlePriceOfferSlice';

import {
  PriceOfferFinancialDetails,
  PriceOfferNotesEditModal,
  PriceOfferNotesInfo,
  ProductsInfoHeader,
  ProductsInfoSingleRow,
} from '.';
import useSinglePriceOffer from '../../hooks/useSinglePriceOffer';

const PriceOfferDetails = ({ fetchPriceOffer }) => {
  //Redux
  const dispatch = useDispatch();
  const { priceOffer, tempFinancialDetailsWithKDV, descriptionEditText } = useSelector(
    (state) => state.priceOffers.singlePriceOffer,
  );
  const { userPermissions } = useSelector((state) => state.general.user);

  // Hooks
  const { updateDescription, updateFinancialDetails } = useSinglePriceOffer(fetchPriceOffer);

  return (
    <>
      <div className="bg-dark p-2 rounded">
        <span className="h6 mt-1 text-center d-inline-block">Teklif Detayları</span>
      </div>
      {userPermissions?.includes(10) && (
        <div className="offer-details mx-2 mt-5">
          <ProductsInfoHeader />
          {priceOffer.products.length > 0
            ? priceOffer.products.map((product) => {
                return <ProductsInfoSingleRow key={product.id} product={product} />;
              })
            : ''}
        </div>
      )}

      <div className="row mx-2 p-0 mb-5">
        <PriceOfferNotesInfo description={priceOffer.description} />

        {priceOffer && (
          <PriceOfferNotesEditModal
            descriptionEditText={descriptionEditText}
            descriptionOnChange={(event) => dispatch(SET_DESCRIPTION_EDIT_TEXT(event.target.value))}
            onUpdate={updateDescription}
          />
        )}
        {userPermissions?.includes(10) && (
          <PriceOfferFinancialDetails
            priceOffer={priceOffer}
            tempFinancialDetails={tempFinancialDetailsWithKDV}
            updateFinancialDetails={updateFinancialDetails}
          />
        )}
      </div>
    </>
  );
};

export default PriceOfferDetails;
