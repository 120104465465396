import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SHOW_DELETE_MODAL,
  SHOW_EDIT_STATUS_MODAL,
  SHOW_NEW_ROLE_MODAL,
  SHOW_RESET_PASSWORD_MODAL,
} from '../userSlice';
import { Button, Row } from 'react-bootstrap';

const UserInfo = () => {
  // Redux
  const { userDetails, isLoading } = useSelector((state) => state.settings.user);
  const { userPermissions } = useSelector((state) => state.general.user);
  const dispatch = useDispatch();
  return (
    <>
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card mb-4">
            <div className="card-header d-flex">
              <h6 className="m-0 font-weight-bold text-primary me-auto">
                Kullanıcı Temel Bilgileri
              </h6>
              <button
                className="btn btn-outline-danger btn-sm px-1 py-0 ms-auto"
                onClick={() => dispatch(SHOW_DELETE_MODAL())}>
                <i className="fas fa-trash-alt me-1"></i>
                <span className="font-12">Sil</span>
              </button>
            </div>
            <div className="card-body font-12">
              {!isLoading ? (
                <div className="row">
                  <div className="col">
                    <p className="text-muted mb-1">Adı</p>
                    <p className="text-muted mb-1">Soyadı</p>
                    <p className="text-muted mb-1">E-posta</p>
                    <p className="text-muted mb-1">Kayıt Tarihi</p>
                    <p className="text-muted mb-1">Son Günc. Tarihi</p>
                    <p className="text-muted mb-2">Rolü</p>
                    <p className="text-muted mb-1">Durum</p>
                  </div>
                  <div className="col">
                    <p className="text-dark mb-1">{userDetails?.name || '-'}</p>
                    <p className="text-dark mb-1">{userDetails?.lastName || '-'}</p>
                    <p className="text-dark mb-1">{userDetails?.email || '-'}</p>
                    <p className="text-dark mb-1">
                      {new Date(userDetails?.createdAt).toLocaleString('tr') || '-'}
                    </p>
                    <p className="text-dark mb-1">
                      {new Date(userDetails?.updatedAt).toLocaleString('tr') || '-'}
                    </p>
                    <p className="text-danger mb-1">
                      {userDetails?.role || '-'}{' '}
                      {userPermissions?.includes(15) && (
                        <button
                          className="btn btn-sm p-0"
                          onClick={() => dispatch(SHOW_NEW_ROLE_MODAL())}>
                          <i className="fas fa-pen fa-sm ms-1"></i>
                        </button>
                      )}
                    </p>
                    <p className="text-danger mb-1 font-14">
                      {userDetails?.isActive ? (
                        <span className="badge badge-success">Aktif</span>
                      ) : (
                        <span className="badge badge-danger">Pasif</span>
                      )}
                      {userPermissions?.includes(15) && (
                        <button
                          className="btn btn-sm p-0"
                          onClick={() => dispatch(SHOW_EDIT_STATUS_MODAL())}>
                          <i className="fas fa-pen fa-sm ms-1"></i>
                        </button>
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                // Loading
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Yükleniyor...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Row>
        <div className="text-center">
          <Button
            variant="outline-primary"
            className="btn-sm"
            onClick={() => dispatch(SHOW_RESET_PASSWORD_MODAL())}>
            Kullanıcı Parolasını Sıfırla
          </Button>
        </div>
      </Row>
    </>
  );
};

export default UserInfo;
