import useProductCategories from 'hooks/useProductCategories';
import alert from 'js/sweetAlert';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { roundTo2Decimals } from 'utils/calcUtils';
import {
  SET_CATEGORY,
  SET_COST,
  SET_DESCRIPTION,
  SET_ID,
  SET_MAX_DISCOUNT_RATE,
  SET_SALE_PRICE,
  SET_STOCK_COUNT,
} from '../createProductSlice';

const ProductGeneralDetails = ({ product }) => {
  //Redux
  const dispatch = useDispatch();

  // Hooks
  const { isProductCategoriesLoading, productCategories } = useProductCategories();

  // Functions

  const checkMaxDiscountRate = (value) => {
    const number = parseFloat(value);
    if (number > 100) {
      alert('error', "Maksimum İskonto Oranı 100'den büyük olamaz");
      return false;
    }
    if (number < 0) {
      alert('error', "Maksimum İskonto Oranı 0'dan küçük olamaz");
      return false;
    }
    return true;
  };

  return (
    <div className="m-3">
      <Row>
        <Col md={3} className="my-2">
          <Form.Group>
            <Form.Label>Ü. Kodu</Form.Label>
            <Form.Control
              className="font-14"
              type="text"
              value={product.id}
              onChange={(e) => dispatch(SET_ID(e.target.value))}
            />
          </Form.Group>
        </Col>
        <Col md={9} className="my-2">
          <Form.Group>
            <Form.Label>Ürün Adı / Açıklaması</Form.Label>
            <Form.Control
              className="font-14"
              type="text"
              value={product.description}
              onChange={(e) => dispatch(SET_DESCRIPTION(e.target.value))}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} className="my-2">
          <Form.Group>
            <Form.Label>Ürün Kategorisi</Form.Label>
            {/* <Form.Control className="font-14" type="text" value={product?.product_category?.name} /> */}
            {isProductCategoriesLoading ? (
              'Yükleniyor'
            ) : (
              <Form.Select
                className="font-14"
                value={product.product_categories_id}
                onChange={(e) => dispatch(SET_CATEGORY(e.target.value))}>
                <option value={0} disabled>
                  Seçiniz
                </option>
                {productCategories.map((item, index) => (
                  <option key={`category-${index}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
        </Col>
        <Col md={4} className="my-2">
          <Form.Group>
            <Form.Label>İmalat Maliyeti</Form.Label>
            <Form.Control
              className="font-14"
              type="number"
              value={product.cost}
              onChange={(e) => dispatch(SET_COST(parseFloat(e.target.value)))}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="my-2">
          <Form.Group>
            <Form.Label>
              Satış Fiyatı <small className="text-danger">KDVsiz</small>
            </Form.Label>
            <Form.Control
              className="font-14"
              type="number"
              value={product.salePrice}
              onChange={(e) => dispatch(SET_SALE_PRICE(parseFloat(e.target.value)))}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="my-2">
          <Form.Group>
            <Form.Label>
              Satış Fiyatı <small className="text-success">KDVli</small>{' '}
            </Form.Label>
            <Form.Control
              className="font-14"
              type="number"
              value={roundTo2Decimals(product.salePrice * 1.18)}
              onChange={(e) => dispatch(SET_SALE_PRICE(parseFloat(e.target.value) / 1.18))}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="my-2">
          <Form.Group>
            <Form.Label>Maks. İskonto Or.(%)</Form.Label>
            <Form.Control
              className="font-14"
              type="number"
              min={0}
              max={100}
              value={product.maxDiscountRate}
              onChange={(e) =>
                checkMaxDiscountRate(e.target.value) &&
                dispatch(SET_MAX_DISCOUNT_RATE(parseFloat(e.target.value)))
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Label className="text-danger mt-3 rounded " style={{ width: 100 }}>
              Stok Adedi
            </Form.Label>
            <hr className="mt-0" />
            <Form.Control
              className="font-14"
              style={{ maxWidth: 100 }}
              type="number"
              min={0}
              max={100}
              value={product.stock_count}
              onChange={(e) => dispatch(SET_STOCK_COUNT(parseFloat(e.target.value)))}
            />
            <Form.Text className="p-1" muted>
              Varyantsız orijinal ürünün stok miktarını ifade etmektedir. Ürün varyantlarının stok
              adetleri aşağıdaki Varyantlar kısmından veya{' '}
              <Link to="/product-stock-management">Ürün Stok Yönetimi</Link> menüsünden
              değiştirilebilir.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ProductGeneralDetails;
