import { Button, Card } from 'react-bootstrap';
import '../ManufactureProducts.scss';
import alert, { alertDialog } from 'js/sweetAlert';
import API from 'utils/atolyeAPI';
import { Link } from 'react-router-dom';
import ImageUpload, { uploadTypes } from 'components/ImageUpload/ImageUpload';
import PrintImageButton from 'components/PrintImage';

const AtolyeAPI = new API();

const ManufactureProductCard = ({ manufactureProduct, getManufactureProducts }) => {
  const deleteHandler = () => {
    alertDialog({
      text: 'İmalat Ürününü kaldırmak istediğinize emin misiniz?',
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
      onConfirm: onDelete,
    });
  };

  const onDelete = async () => {
    try {
      const { status } = await AtolyeAPI.get(
        `/manufacture_products/${manufactureProduct.id}/delete`,
      );
      if (status === 'SUCCESS') {
        alert('success', 'İmalat Ürünü Silindi');
        getManufactureProducts();
      } else {
        alert('error', 'İmalat Ürünü Silinemedi');
      }
    } catch (error) {
      alert('error', 'İmalat Ürünü Silinemedi');
      console.log(error);
    }
  };

  const calculateCapacity = (components) => {
    if (!components) return 0;
    if (components.length === 0) return 0;
    // Every manufacture product consists of components
    // First we need to find the minimum capacity of the components
    // Then we can calculate the capacity of the manufacture product
    // Component capacity and manufacture product capacity are different
    // Component capacity is the minimum of the stock count and quantity
    // Manufacture product capacity is the minimum of the component capacities
    // We need to find the minimum of the component capacities
    // And we need to find the minimum of the manufacture product capacitie

    let manufactureProductCapacity = 0;
    let componentCapacities = [];
    // Every component has a stock count and quantity
    // We need to calculate how many times the stock responds to the quantity
    // And we need to find the minimum of these values

    components.forEach((component) => {
      const { stock_count, manufactureProductHasComponent } = component;
      const { quantity } = manufactureProductHasComponent;
      const capacity = Math.floor(stock_count / quantity);
      componentCapacities.push(capacity);
    });
    manufactureProductCapacity = Math.min(...componentCapacities);
    return manufactureProductCapacity;
  };

  const calculatePotentialCapacity = (components) => {
    const capacity = calculateCapacity(components);
    const requestQuantity = manufactureProduct.data.requestQuantity;
    const potentialCapacity = capacity - requestQuantity;
    return potentialCapacity;
  };

  const onGuidelineUploadFinish = async (link) => {
    try {
      const { status } = await AtolyeAPI.post(`/manufacture_products/${manufactureProduct.id}`, {
        guidelineLink: link,
      });
      if (status === 'SUCCESS') {
        alert('success', 'Yönerge Güncellendi');
        getManufactureProducts();
      } else {
        alert('error', 'Yönerge Güncellenemedi');
      }
    } catch (error) {
      alert('error', 'Yönerge Güncellenemedi');
      console.log(error);
    }
  };

  const onTagUploadFinish = async (link) => {
    try {
      const { status } = await AtolyeAPI.post(`/manufacture_products/${manufactureProduct.id}`, {
        tagLink: link,
      });
      if (status === 'SUCCESS') {
        alert('success', 'Etiket Güncellendi');
        getManufactureProducts();
      }
    } catch (error) {
      alert('error', 'Etiket Güncellenemedi');
      console.log(error);
    }
  };

  return (
    <Card className="manufacture-product-card">
      <Card.Body className="p-0">
        <img
          className="manufacture-product-card-image bg-light"
          src={manufactureProduct.imageLink}
          alt={manufactureProduct.name}
        />

        <hr className="my-0" />
        <div className="text-center my-3">
          <div>
            <strong>{manufactureProduct.name}</strong>
          </div>
          <div>
            <small>{manufactureProduct.variantName}</small>
          </div>
        </div>
      </Card.Body>
      <Card.Footer
        className={`d-flex justify-content-center ${
          calculatePotentialCapacity(manufactureProduct.components) > 0
            ? 'bg-success'
            : calculatePotentialCapacity(manufactureProduct.components) === 0
            ? 'bg-warning'
            : 'bg-danger'
        }`}>
        <strong>
          {calculatePotentialCapacity(manufactureProduct.components) > 0 ? '+' : ''}{' '}
          {calculatePotentialCapacity(manufactureProduct.components)}
        </strong>
      </Card.Footer>
      <Card.Footer className="d-flex align-items-center justify-content-around bg-atolye">
        <div className="me-1">Yönerge: </div>
        <ImageUpload
          uploadType={uploadTypes.manufactureProductGuideline}
          buttonVariant="outline-dark"
          buttonClassName="btn-sm me-1"
          link={manufactureProduct.data.guidelineLink}
          onUploadFinish={(link) => {
            onGuidelineUploadFinish(link);
          }}
        />
        <PrintImageButton
          imageUrl={manufactureProduct.data.guidelineLink}
          buttonVariant="outline-dark"
          buttonClassName="btn-sm me-2"
        />
        <div className="me-1">Etiket: </div>
        <ImageUpload
          uploadType={uploadTypes.manufactureProductTag}
          buttonVariant="outline-dark"
          buttonClassName="btn-sm me-1"
          link={manufactureProduct.data.tagLink}
          onUploadFinish={(link) => {
            onTagUploadFinish(link);
          }}
        />
        <PrintImageButton
          imageUrl={manufactureProduct.data.tagLink}
          buttonVariant="outline-dark"
          buttonClassName="btn-sm me-2"
        />
      </Card.Footer>
      <Card.Footer className="d-flex">
        <Button variant="outline-danger" size="sm" onClick={deleteHandler}>
          <strong>Kaldır</strong>
        </Button>
        <Link
          to={'/single-manufacture-product?id=' + manufactureProduct.id}
          variant="outline-primary"
          className="ms-auto btn btn-sm btn-outline-primary">
          <strong>Ayarla</strong>
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default ManufactureProductCard;
