import { styles } from './styles';

import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import OpenSans from 'fonts/open-sans/OpenSans-Regular.ttf';
import { Alert } from 'react-bootstrap';
import { translations } from './translations';
import BrandSection from './components/BrandSection';
import CustomerDetailsSection from './components/CustomerDetailsSection';
import DateSection from './components/DateSection';
import ProductDetailsHeaderSection from './components/ProductDetailsHeaderSection';
import ProductDetailsItem from './components/ProductDetailsItem';
import OrderSummarySection from './components/OrderSummarySection';

const PriceOfferPDFDocument = ({
  priceOffer,
  products,
  customer,
  images,
  isPriceless,
  lang,
  currency,
}) => {
  // Font Registration
  Font.register({
    family: 'Open Sans',
    src: OpenSans,
  });

  const PDFDoc = () => (
    <Document>
      <Page size="A4" style={styles.body} wrap={false}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <BrandSection />
          <View style={styles.headerBlankView}>
            <Text></Text>
          </View>
          <CustomerDetailsSection customer={customer} translations={translations} lang={lang} />
        </View>

        {/* Date */}
        <DateSection isPriceOffer order={priceOffer} translations={translations} lang={lang} />

        {/* Order Details */}
        <View style={styles.tableContainer}>
          <ProductDetailsHeaderSection
            isPriceless={isPriceless}
            translations={translations}
            lang={lang}
          />

          {products.length > 0 &&
            products.map((product, index) => {
              return (
                <ProductDetailsItem
                  key={`product-details-item-${index}`}
                  product={product}
                  index={index}
                  images={images}
                  isPriceless={isPriceless}
                  translations={translations}
                  lang={lang}
                  currency={currency}
                />
              );
            })}
          {/* Order Summary */}
          {products.length > 0 && (
            <OrderSummarySection
              isPriceless={isPriceless}
              order={priceOffer}
              translations={translations}
              lang={lang}
              currency={currency}
            />
          )}
        </View>

        {/* Footer */}
        {/* <Text style={styles.footerText}>{translations.sincerely[lang]},</Text> */}

        <Text style={styles.pageNumber} fixed>
          {translations.warning[lang]}
        </Text>
      </Page>
    </Document>
  );

  return priceOffer ? (
    <PDFDoc />
  ) : (
    <Alert variant="danger">Fiyat Teklifi Bulunamadığı için PDF Oluşturulamadı!!!</Alert>
  );
};

export default PriceOfferPDFDocument;
