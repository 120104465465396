// import alert from 'js/sweetAlert';
import useTags from 'hooks/useTags';
import { useEffect, useState } from 'react';
import {
  Modal,
  // Form,
  Button,
  Form,
  Table,
  Spinner,
  //  Row,
  //  Col
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { roundTo2Decimals } from 'utils/calcUtils';
import {
  HIDE_BULK_EDIT_PRICES_MODAL,
  RESET,
  SET_COST,
  SET_SALE_PRICE,
  SET_TAG_PRODUCTS,
  SHOW_BULK_EDIT_PRICES_MODAL,
} from '../productsSlice';
// import Select from 'react-select';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const BulkEditPricesModal = ({ update }) => {
  // State
  const [selectedTag, setSelectedTag] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const { isBulkEditPricesModalDisplayed, tagProducts } = useSelector(
    (state) => state.products.products,
  );

  // Hooks
  const { tagOptions, isTagsLoading, tags } = useTags();

  // Effects
  useEffect(() => {
    if (selectedTag) {
      const products = tags.find((tag) => tag.id == selectedTag)?.productsWithTag;
      const productsPriceDetails = products.map((item) => ({
        id: item.id,
        imageLink: item.imageLink,
        description: item.description,
        cost: item.cost,
        salePrice: item.salePrice,
        isTouched: false,
      }));
      dispatch(SET_TAG_PRODUCTS(productsPriceDetails));
    }
  }, [selectedTag]);

  // Functions
  const onDismiss = () => {
    setSelectedTag('');
    dispatch(RESET());
    dispatch(HIDE_BULK_EDIT_PRICES_MODAL());
  };

  const updateTagProductPrices = async () => {
    setIsLoading(true);

    const body = {
      products: tagProducts
        .filter((item) => item.isTouched)
        .map((item) => ({ id: item.id, salePrice: item.salePrice, cost: item.cost })),
    };

    try {
      const result = await AtolyeAPI.put(`/products/bulk-update-prices`, body);
      if (result) {
        alert('success', 'Ürün fiyat bilgileri güncellendi');
        onDismiss();
        return update(0, 10);
      }

      throw new Error('Ürün fiyat bilgileri güncellenirken bir hata oluştu');
    } catch (error) {
      console.log({ error });
      alert('error', 'Ürün fiyat bilgileri güncellenirken bir hata oluştu');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="ms-2">
        <Button variant="warning" size="sm" onClick={() => dispatch(SHOW_BULK_EDIT_PRICES_MODAL())}>
          <i className="fas fa-dollar-sign"></i> Toplu Ürün Fiyatı Güncelle
        </Button>
      </div>
      <Modal show={isBulkEditPricesModalDisplayed} fullscreen onHide={onDismiss}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>Ürün Fiyatlarını Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Form.Group className="mb-3">
            <Form.Label>Etiket Seçin</Form.Label>
            {isTagsLoading ? (
              'Yükleniyor...'
            ) : (
              <div style={{ maxWidth: 600 }} className="select-wrapper ">
                <Form.Select value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
                  <option label="Seçiniz" value={''} disabled />
                  {tagOptions?.map((option, i) => (
                    <option key={`option-${i}`} label={option.label} value={option.value} />
                  ))}
                </Form.Select>
              </div>
            )}

            <Form.Text className="text-muted">İşlem yapmak istediğiniz etiketi seçiniz.</Form.Text>
          </Form.Group>
          <Table striped bordered hover variant="" className="table-responsive">
            <thead>
              <tr>
                <th style={{ width: 20 }}>
                  <i className="fas fa-edit"></i>
                </th>
                <th>Ürün Kodu</th>
                <th>Görsel</th>
                <th>Ürün Açıklaması</th>
                <th>Maliyet</th>
                <th>
                  Satış Fiyatı<span className="text-danger"> (KDV Hariç)</span>
                </th>
                <th>
                  Satış Fiyatı<span className="text-success"> (KDV Dahil)</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {tagProducts.map((product) => (
                <tr key={`order-${product.id}`}>
                  <td>
                    {' '}
                    {product.isTouched ? (
                      <i className="fas fa-pen text-success"></i>
                    ) : (
                      <i className="fas fa-pen text-muted"></i>
                    )}
                  </td>
                  <td> {product.id}</td>
                  <td>
                    <img
                      src={product.imageLink}
                      alt={product.description}
                      style={{ width: 70, height: 70 }}
                    />
                  </td>
                  <td> {product.description}</td>
                  <td>
                    <Form.Control
                      style={{ minWidth: 120 }}
                      type="text"
                      className="form-control"
                      value={roundTo2Decimals(product.cost)}
                      onChange={(e) =>
                        dispatch(
                          SET_COST({ id: product.id, value: parseFloat(e.target.value) || 0 }),
                        )
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      style={{ minWidth: 120 }}
                      type="text"
                      className="form-control"
                      value={roundTo2Decimals(product.salePrice)}
                      onChange={(e) =>
                        dispatch(
                          SET_SALE_PRICE({
                            id: product.id,
                            value: parseFloat(e.target.value) || 0,
                          }),
                        )
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      style={{ minWidth: 120 }}
                      type="text"
                      className="form-control"
                      value={roundTo2Decimals(product.salePrice * 1.18)}
                      onChange={(e) =>
                        dispatch(
                          SET_SALE_PRICE({
                            id: product.id,
                            value: parseFloat(e.target.value) / 1.18 || 0,
                          }),
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onDismiss}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={updateTagProductPrices}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              'Güncelle'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkEditPricesModal;
