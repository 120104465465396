import { useSelector } from 'react-redux';

import PriceOfferPDFDocument from 'templates/PriceOfferPDFDocument';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useWindowDimensions from 'hooks/useWindowDimensions';

// import { PDFDownloadLink } from '@react-pdf/renderer';

const PriceOfferPDF = () => {
  //Redux
  const { priceOffer, isLoading, pdfType, pdfLanguage, pdfCurrency } = useSelector(
    (state) => state.priceOffers.singlePriceOffer,
  );

  // Hooks
  const { width } = useWindowDimensions();

  return !priceOffer ? (
    <div className="m-5">
      <Alert variant="danger">Fiyat Teklifi Bulunamadığı için PDF Oluşturulamadı!!!</Alert>
      <Link to="/price-offers" className="btn btn-primary">
        Fiyat Teklifleri Listesine Geri Dön
      </Link>
    </div>
  ) : isLoading ? (
    'PDF oluşturuluyor'
  ) : width < 768 ? (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      <div className="btn btn-light border text-white">
        <PDFDownloadLink
          document={
            <PriceOfferPDFDocument
              isPriceless={pdfType === 'priceless'}
              lang={pdfLanguage}
              currency={pdfCurrency}
              priceOffer={priceOffer}
              products={priceOffer?.products}
              customer={priceOffer?.customer}
              user={priceOffer?.createdBy_user}
              images={priceOffer?.products.map((item) => item.product.imageLink)}
            />
          }
          fileName={`Atolye Fiyat Teklifi Detay - ${priceOffer.id}`}>
          {({ loading }) => (loading ? 'Oluşturuluyor...' : 'Teklif Detay PDF İndirmek İçin Tıkla')}
        </PDFDownloadLink>
      </div>
    </div>
  ) : (
    <PDFViewer className="w-100 vh-100">
      <PriceOfferPDFDocument
        isPriceless={pdfType === 'priceless'}
        lang={pdfLanguage}
        currency={pdfCurrency}
        priceOffer={priceOffer}
        products={priceOffer?.products}
        customer={priceOffer?.customer}
        user={priceOffer?.user}
        images={priceOffer?.products.map((item) => item.product.imageLink)}
      />
    </PDFViewer>
  );
};

export default PriceOfferPDF;
