import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';
import getThumbnail from 'utils/getThumbnail';

const AtolyeAPI = new API();

const useComponentOptions = () => {
  // State
  const [componentOptions, setComponentOptions] = useState([]);
  const [isComponentOptionsLoading, setIsComponentOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getComponentOptions();
  }, []);

  // Functions
  const getComponentOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/components/options');
      // Replace images with thumbnails
      results.forEach((result) => {
        result.imageLink = getThumbnail(result.imageLink);
      });
      setComponentOptions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsComponentOptionsLoading(false);
    }
  };

  return {
    isComponentOptionsLoading,
    componentOptions,
    getComponentOptions,
  };
};

export default useComponentOptions;
