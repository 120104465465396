const ProductsInfoHeader = () => {
  return (
    <div className="row mx-0">
      <div className="col-3 text-center border bg-atolye  py-2">
        <span>Ürün Kodu</span>
      </div>
      <div className="col-3 text-center border bg-atolye  py-2">Birim Fiyat</div>
      <div className="col-3 text-center border bg-atolye  py-2">Adet</div>
      <div className="col-3 text-center border bg-atolye  py-2">Toplam</div>
    </div>
  );
};

export default ProductsInfoHeader;
