import {
  orderPermissionIds,
  priceOfferPermissionIds,
  productPermissionIds,
  productionPermissionIds,
  financialDataPermissionIds,
  adminPermissionIds,
  customerPermissionsIds,
  stockManagementPermissionsIds,
} from 'config/permissionsConfig';
import { useSelector } from 'react-redux';
import PermissionGroup from './PermissionGroup';
import { useEffect, useState } from 'react';

const UserPermissions = ({ permissions, isPermissionsLoading }) => {
  // Redux
  const { isLoading } = useSelector((state) => state.settings.user);

  // State
  const [orderPermissions, setOrderPermissions] = useState([]);
  const [priceOfferPermissions, setPriceOfferPermissions] = useState([]);
  const [productPermissions, setProductPermissions] = useState([]);
  const [productionPermissions, setProductionPermissions] = useState([]);
  const [financialDataPermissions, setFinancialDataPermissions] = useState([]);
  const [adminPermissions, setAdminPermissions] = useState([]);
  const [customerPermissions, setCustomerPermissions] = useState([]);
  const [stockManagementPermissions, setStockManagementPermissions] = useState([]);

  // Effects
  useEffect(() => {
    if (permissions?.length > 0) {
      setOrderPermissions(
        permissions?.filter((permission) => orderPermissionIds.includes(permission.id)),
      );
      setPriceOfferPermissions(
        permissions?.filter((permission) => priceOfferPermissionIds.includes(permission.id)),
      );
      setProductPermissions(
        permissions?.filter((permission) => productPermissionIds.includes(permission.id)),
      );
      setProductionPermissions(
        permissions?.filter((permission) => productionPermissionIds.includes(permission.id)),
      );
      setFinancialDataPermissions(
        permissions?.filter((permission) => financialDataPermissionIds.includes(permission.id)) ||
          [],
      );
      setAdminPermissions(
        permissions?.filter((permission) => adminPermissionIds.includes(permission.id)),
      );
      setCustomerPermissions(
        permissions?.filter((permission) => customerPermissionsIds.includes(permission.id)),
      );
      setStockManagementPermissions(
        permissions?.filter((permission) => stockManagementPermissionsIds.includes(permission.id)),
      );
    }
  }, [permissions]);

  return (
    <div>
      <h5 className="text-center mt-5 mb-3">Kullanıcı Yetkileri</h5>

      {isPermissionsLoading || isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Yükleniyor...</span>
          </div>
        </div>
      ) : (
        <div className="row mx-0 bg-white rounded shadow-sm">
          {/* Order Permissions */}
          <PermissionGroup label="Sipariş İzinleri" permissionOptions={orderPermissions} />

          {/* Price Offer Permissions */}
          <PermissionGroup
            label="Fiyat Teklifi İzinleri"
            permissionOptions={priceOfferPermissions}
          />

          {/* Production Permissions */}
          <PermissionGroup
            label="Üretim Takibi ve Planlama İzinleri"
            permissionOptions={productionPermissions}
          />

          {/* Financial Data Permissions */}
          <PermissionGroup
            label="Finansal Veri İzinleri"
            permissionOptions={financialDataPermissions}
          />

          {/* Admin Permissions */}
          <PermissionGroup label="Admin İzinleri" permissionOptions={adminPermissions} />

          {/* Customer Permissions */}
          <PermissionGroup label="Müşteri İzinleri" permissionOptions={customerPermissions} />

          {/* Product Permissions */}
          <PermissionGroup label="Ürün İzinleri" permissionOptions={productPermissions} />

          {/* Stock Management Permissions */}
          <PermissionGroup
            label="Stok Yönetimi İzinleri"
            permissionOptions={stockManagementPermissions}
          />
        </div>
      )}
    </div>
  );
};

export default UserPermissions;
