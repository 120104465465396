import '../CreateProduct.scss';

import { Button, Form } from 'react-bootstrap';
import { useRef, useState } from 'react';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import ResponsiveLoading from 'components/ResponsiveLoading';
import { useDispatch } from 'react-redux';
import { SET_IMAGE_LINK } from '../createProductSlice';
import placeholderImg from 'assets/placeholderImg.png';

const AtolyeAPI = new API();

const ProductImage = ({ imageSrc, setImageLink, title = 'Ürün Görseli', imageStyle }) => {
  // Redux
  const dispatch = useDispatch();
  // State
  const [loading, setLoading] = useState(false);
  // Hooks
  const imgRef = useRef();
  const uploadFileRef = useRef();

  // Functions
  const handleUploadClick = () => {
    uploadFileRef.current?.click();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleUpload = async (e) => {
    const _image = await toBase64(e.target.files[0]);

    setLoading(true);

    const body = { image: _image };

    try {
      const { response } = await AtolyeAPI.post(`/uploadImage`, body);
      if (response.status === 'ERROR') {
        throw new Error(response.msg);
      }
      let tempImageLink =
        'https://' +
        response.body.Bucket +
        '.s3.eu-central-1.amazonaws.com' +
        '/' +
        response.body.key;
      // https://atolyeadminimages.s3.eu-central-1.amazonaws.com/products/92714039549.9172_521689492.9106384.jpg
      console.log({ tempImageLink });
      setImageLink ? setImageLink(tempImageLink) : dispatch(SET_IMAGE_LINK(tempImageLink));
    } catch (error) {
      console.log({ error });
      alert('error', 'Görsel yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="m-3">
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <div className="position-relative">
          {loading && <ResponsiveLoading />}
          {imageSrc ? (
            <img
              ref={imgRef}
              className="img-fluid"
              style={imageStyle}
              src={imageSrc}
              alt="Ürün Görseli"
              onError={() => {
                imgRef.current.src = placeholderImg;
              }}
            />
          ) : (
            <img style={imageStyle} src={placeholderImg} />
          )}
          <Button variant="primary" className="image-edit-button" onClick={handleUploadClick}>
            <i className="fas fa-upload"></i>
          </Button>
          <input
            ref={uploadFileRef}
            className="d-none"
            type="file"
            accept="image/*"
            onChange={handleUpload}
          />
        </div>
      </Form.Group>
    </div>
  );
};

export default ProductImage;
