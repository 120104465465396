import ResponsiveLoading from 'components/ResponsiveLoading';
import { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

export const uploadTypes = {
  manufactureProductGuideline: {
    url: '/uploadImage/manufacture-product/guideline',
    title: 'İmal Edilen Ürün Yönergesi Yükle',
  },
  manufactureProductTag: {
    url: '/uploadImage/manufacture-product/tag',
    title: 'İmal Edilen Ürün Etiketi Yükle',
  },
};

const ImageUpload = ({ onUploadFinish, buttonVariant, buttonClassName, uploadType, link }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(link);
  const [showModal, setShowModal] = useState(false);
  const [imageLink, setImageLink] = useState(null);

  // Hooks
  const imgRef = useRef();
  const uploadFileRef = useRef();

  // Functions
  const handleUploadClick = () => {
    uploadFileRef.current?.click();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleUpload = async (e) => {
    const _image = await toBase64(e.target.files[0]);

    setLoading(true);

    const body = { image: _image };

    try {
      const { response } = await AtolyeAPI.post(uploadType.url, body);
      if (response.status === 'ERROR') {
        throw new Error(response.msg);
      }
      let tempImageLink =
        'https://' +
        response.body.Bucket +
        '.s3.eu-central-1.amazonaws.com' +
        '/' +
        response.body.key;
      console.log({ tempImageLink });
      setImageSrc(tempImageLink);
      setImageLink(tempImageLink);
    } catch (error) {
      console.log({ error });
      alert('error', 'Görsel yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const saveClickHandler = () => {
    setShowModal(false);
    onUploadFinish(imageLink);
  };

  return (
    <div>
      <Button
        variant={buttonVariant}
        className={buttonClassName}
        onClick={() => setShowModal(true)}>
        <i className="fas fa-upload"></i>
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{uploadType.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="position-relative text-center">
            {loading && <ResponsiveLoading />}
            {imageSrc ? (
              <img
                ref={imgRef}
                className="img-fluid"
                src={imageSrc}
                alt="Ürün Görseli"
                onError={() => {
                  imgRef.current.src = 'https://via.placeholder.com/200';
                }}
              />
            ) : (
              <img src="https://via.placeholder.com/200" />
            )}
            <Button variant="primary" className="image-edit-button" onClick={handleUploadClick}>
              <i className="fas fa-upload"></i>
            </Button>

            <input
              ref={uploadFileRef}
              className="d-none"
              type="file"
              accept="image/*"
              onChange={handleUpload}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModal(false)}>
            Vazgeç
          </Button>
          <Button variant="success" onClick={saveClickHandler}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImageUpload;
