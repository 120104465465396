import { useState, useEffect } from 'react';
import alert from '../js/sweetAlert';

import { useAtolyeAPI } from '.';

const usePriceOffers = () => {
  // States
  const [priceOffers, setPriceOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [isSortingUpdated, setIsSortingUpdated] = useState(false);
  const [areFiltersUpdated, setAreFiltersUpdated] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);

  // Hooks
  const { postTo, getFileFrom } = useAtolyeAPI();

  // Effects
  useEffect(() => {
    setIsSortingUpdated(false);
  }, [isSortingUpdated]);

  useEffect(() => {
    setAreFiltersUpdated(false);
  }, [areFiltersUpdated]);

  // Functions

  const getPriceOffers = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const { data, totalCount, totalPages } = await postTo('/price_offers/table', {
        limit: pageSize,
        offset: isSortingUpdated ? 0 : pageIndex * pageSize,
        search: filters || {},
        dates: dateFilter || null,
        order:
          sorting.length === 0
            ? [
                // ['salesChannel', 'ASC']
                ['id', 'DESC'],
              ]
            : sorting,
      });
      setPriceOffers(data);
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Fiyat Teklifleri getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };

  const exportAllPriceOffers = async () => {
    setLoading(true);
    try {
      await getFileFrom('/price_offers/export');
    } catch (error) {
      console.log(error);
      alert('error', 'Fiyat Teklifleri indirilirken bir sorun oluştu!');
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    pageCount,
    priceOffers,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    getPriceOffers,
    exportAllPriceOffers,
    setFilters,
    setDateFilter,
    setSorting,
    setIsSortingUpdated,
    setAreFiltersUpdated,
  };
};

export default usePriceOffers;
