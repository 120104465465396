import { useSelector, useDispatch } from 'react-redux';
import { ADD_PRODUCT, PICK_PRODUCT, SET_IMAGE_LINK } from '../createOrderSlice';
import { Form, Select } from '..';
import OrderProduct from './OrderProduct';
import alert from 'js/sweetAlert';
import { useRef, useState } from 'react';
import { useEffect } from 'react';

const ProductSelection = ({
  title,
  productOptions,
  productOptionsOnlyInStock,
  isProductOptionsLoading,
}) => {
  // Redux
  const dispatch = useDispatch();
  const { products, imageLink, selectedProduct } = useSelector((state) => state.orders.createOrder);

  // State
  const [isOnlyInStock, setIsOnlyInStock] = useState(false);

  // Refs
  const productSelectRef = useRef(null);

  // Functions

  const productAddingHandler = () => {
    const product = productOptions.find((item) => item.value === selectedProduct).productDetails;
    dispatch(
      ADD_PRODUCT({
        ...product,
        price: product.salePrice.toFixed(2),
        selectedVariant: {
          id: null,
          price: product.basePrice,
          imageLink: product.baseImageLink,
          stock_count: product.stocks[0].stock,
        },
        basePrice: product.salePrice.toFixed(2),
        baseImageLink: product.imageLink,
        isGivenByStock: false,
        manufacturers_id: product.defaultManufacturerId,
      }),
    );
    dispatch(SET_IMAGE_LINK('https://via.placeholder.com/150'));
    dispatch(PICK_PRODUCT(null));
    alert('success', 'Ürün başarıyla siparişe eklendi!');
  };

  useEffect(() => {
    if (productSelectRef?.current) {
      if (isOnlyInStock) {
        dispatch(PICK_PRODUCT(null));
        dispatch(SET_IMAGE_LINK('https://via.placeholder.com/150'));
      }
    }
  }, [productSelectRef, isOnlyInStock]);
  return (
    <>
      <div className="card card-dark my-4" id="product-card-1">
        <div className="card-header bg-dark  font-weight-bold text-center">{title}</div>

        {/* Products Section */}

        {products.length > 0 &&
          products.map((product, index) => {
            return (
              <>
                <OrderProduct product={product} index={index} key={`product-${index}`} />
                <hr />
              </>
            );
          })}

        <div className=" p-2 bg-light rounded border   mb-0">
          <div className="col-md-6 mx-auto">
            <label htmlFor="">Ürün Kodu: </label>

            <Select
              ref={productSelectRef}
              className="text-dark"
              id="input-product"
              options={isOnlyInStock ? productOptionsOnlyInStock : productOptions}
              isLoading={isProductOptionsLoading}
              onChange={({ value, imageLink }) => {
                dispatch(PICK_PRODUCT(value));
                dispatch(SET_IMAGE_LINK(imageLink));
              }}
              value={productOptions.find((item) => item.value === selectedProduct) || ''}
              placeholder="Ürün Kodu veya Açıklama girin"
            />
            {/* Checkbox for product options that are only in stock */}
            <Form.Check
              className="my-2"
              type="switch"
              id="custom-switch"
              label="Sadece Stokta Olanları Göster"
              value={isOnlyInStock}
              onChange={(e) => setIsOnlyInStock(e.target.checked)}
            />
            <div className="text-center my-3">
              <img src={imageLink} alt="Product Image" className="img-fluid" width="200" />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="my-2 btn btn-primary h-100"
                disabled={!selectedProduct}
                onClick={productAddingHandler}>
                Ürünü Siparişe Ekle
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSelection;
