import { useEffect, useState } from 'react';
import alert from '../js/sweetAlert';

import { useAtolyeAPI } from '../hooks';

const useProducts = () => {
  // States
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [tagFilter, setTagFilter] = useState({ tagIds: [], operator: 'and' });
  const [isSortingUpdated, setIsSortingUpdated] = useState(false);
  const [areFiltersUpdated, setAreFiltersUpdated] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);

  // Hooks
  const { postTo } = useAtolyeAPI();

  // Effects
  useEffect(() => {
    setIsSortingUpdated(false);
  }, [isSortingUpdated]);

  useEffect(() => {
    setAreFiltersUpdated(false);
  }, [areFiltersUpdated]);

  useEffect(() => {
    console.log({ tagFilter });
  }, [tagFilter]);

  // Functions

  const getProducts = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const { data, totalCount, totalPages } = await postTo('/products/table', {
        limit: pageSize,
        offset: isSortingUpdated ? 0 : pageIndex * pageSize,
        search: tagFilter.tagIds.length > 0 ? { ...filters, tags: tagFilter } : filters || {},
        order: sorting.length === 0 ? [['createdAt', 'DESC']] : sorting,
      });
      setProducts(data);
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Ürünler getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };
  return {
    loading,
    pageCount,
    products,
    filters,
    tagFilter,
    sorting,
    totalRowsCount,
    getProducts,
    setFilters,
    setSorting,
    setIsSortingUpdated,
    setAreFiltersUpdated,
    setTagFilter,
  };
};

export default useProducts;
