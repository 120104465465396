import SingleProduct from './SingleProduct';

import Header from 'components/shared/Header';
import Footer from 'components/shared/Footer/Footer';
import ContentWrapper from 'components/shared/ContentWrapper';
import PageTitle from 'components/shared/PageTitle';
import PageWrapper from 'components/shared/PageWrapper';

export { ContentWrapper, Footer, Header, PageTitle, PageWrapper };

export default SingleProduct;
