import CreateOrder from './CreateOrder';

import { Button, Form, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import ContentWrapper from 'components/shared/ContentWrapper';
import DatePicker from 'react-datepicker';
import Footer from 'components/shared/Footer/Footer';
import Header from 'components/shared/Header';
import Loading from 'components/shared/Loading';
import PageTitle from 'components/shared/PageTitle';
import PageWrapper from 'components/shared/PageWrapper';
import Select from 'react-select';

import { useUser, useAtolyeAPI } from 'hooks';

import FromPriceOffer from './components/FromPriceOffer';
import CustomerSelection from './components/CustomerSelection';
import OrderDetailsForm from './components/OrderDetailsForm';
import ProductSelection from './components/ProductSelection';
import OrderNotes from './components/OrderNotes';
import ManufacturerSelection from './components/ManufacturerSelection';
import OrderTotalDetails from './components/OrderTotalDetails';
import PaymentForm from './components/PaymentForm';
import BillSelection from './components/BillSelection';

export default CreateOrder;
export {
  BillSelection,
  Button,
  CustomerSelection,
  ContentWrapper,
  DatePicker,
  FromPriceOffer,
  Footer,
  Form,
  Header,
  Loading,
  ManufacturerSelection,
  Modal,
  PageTitle,
  PageWrapper,
  PaymentForm,
  ProgressBar,
  ProductSelection,
  OrderDetailsForm,
  OrderTotalDetails,
  OrderNotes,
  Select,
  Spinner,
  useUser,
  useAtolyeAPI,
};
