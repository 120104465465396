import AtolyeNumberInput from 'components/AtolyeNumberInput';
import { useAtolyeAPI } from 'hooks';
import useProductOptions from 'hooks/useProductOptions';
import alert from 'js/sweetAlert';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const AddDepotRecord = ({ getDepot, show, setShow, depots_id }) => {
  //Edit Depot Record Modal

  const { postTo } = useAtolyeAPI();
  const { isProductOptionsLoading, productOptions } = useProductOptions();

  const [loading, setLoading] = useState(false);
  const [stockCount, setStockCount] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState('');
  const [imageLink, setImageLink] = useState('https://via.placeholder.com/150');

  const handleAddDepotRecord = async () => {
    if (!selectedProduct) {
      alert('warning', 'Lütfen bir ürün seçiniz!');
      return;
    }

    if (stockCount < 1) {
      alert('warning', 'Lütfen stok adedini giriniz!');
      return;
    }

    setLoading(true);

    try {
      await postTo(`/depots/product-add`, {
        depots_id,
        isVariant: selectedVariant !== '',
        productId: selectedProduct.value,
        variantId: selectedVariant,
        stock_count: stockCount,
      });
      setLoading(false);
      handleDismiss();
      getDepot();
    } catch (error) {
      console.log(error);
      alert('error', 'Depo kaydı eklenirken bir sorun oluştu!');
    } finally {
      setLoading(false);
    }
  };

  const handleDismiss = () => {
    setShow(false);
    setSelectedProduct(null);
    setSelectedVariant('');
    setStockCount(0);
    setImageLink('https://via.placeholder.com/150');
  };

  useEffect(() => {
    setStockCount(parseInt(selectedProduct?.stock_count) || 0);
  }, [selectedProduct]);

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-success" closeButton>
          <Modal.Title>Depo Stok Kaydı Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* Add Product Input */}
            <div className="text-center my-3">
              <img src={imageLink} alt="Product Image" className="img-fluid" width="200" />
            </div>

            <label htmlFor="">Ürün: </label>
            <Select
              className="text-dark mb-3"
              id="input-product"
              options={productOptions}
              isLoading={isProductOptionsLoading}
              onChange={(value) => {
                const { imageLink, productDetails } = value;
                setSelectedProduct(value);
                setImageLink(imageLink || 'https://via.placeholder.com/150');
                setStockCount(productDetails.stocks.find((item) => item.id === 0).stock);
              }}
              placeholder="Ürün Kodu veya Açıklama girin"
            />

            {/* Add Variant Input */}
            {selectedProduct && (
              <>
                <label htmlFor="">Varyant: </label>
                <Form.Select
                  className="font-14"
                  onChange={(e) => {
                    setSelectedVariant(e.target.value);

                    const _tempImageLink = e.target.value
                      ? e.target.selectedOptions[0].getAttribute('data-imageLink')
                      : selectedProduct.imageLink;

                    setImageLink(_tempImageLink);
                    const _tempStockCount = parseInt(e.target.value)
                      ? parseInt(e.target.selectedOptions[0].getAttribute('data-stock'))
                      : parseInt(
                          selectedProduct.productDetails.stocks.find((item) => item.id === 0).stock,
                        );

                    setStockCount(parseInt(_tempStockCount));
                  }}
                  value={selectedVariant}>
                  <option value="">Ana Ürün</option>
                  {selectedProduct?.productDetails?.variants &&
                    selectedProduct.productDetails.variants
                      .filter((item) => !item.isDeleted)
                      .map((option, i) => (
                        <option
                          key={'variant-' + i}
                          value={option.id}
                          data-imageLink={option.image_link}
                          data-stock={option.stock_count}>
                          {option.name}
                        </option>
                      ))}
                </Form.Select>
              </>
            )}
          </div>
          {/* Input Stock Count */}
          {selectedProduct && (
            <AtolyeNumberInput title={'Stok Adedi'} value={stockCount} setValue={setStockCount} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleDismiss}>
            Vazgeç
          </Button>
          <Button
            variant="success"
            className="text-white"
            onClick={handleAddDepotRecord}
            disabled={!selectedProduct || !depots_id}>
            {loading ? 'Ekleniyor...' : 'Ekle'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddDepotRecord;
