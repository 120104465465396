import { useAtolyeAPI } from 'hooks';
import alert from 'js/sweetAlert';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useSingleDepot = () => {
  const { fetchFrom } = useAtolyeAPI();
  const [searchParams] = useSearchParams();

  // State
  const [id, setId] = useState(null);
  const [depot, setDepot] = useState(null);
  const [stocks, setStocks] = useState(null);
  const [selectedStock, setSelectedStock] = useState(null); // {id, isVariant, name,stock_count}
  const [showDeleteDepotRecordModal, setShowDeleteDepotRecordModal] = useState(false);
  const [showEditDepotRecordModal, setShowEditDepotRecordModal] = useState(false);
  const [showAddDepotRecordModal, setShowAddDepotRecordModal] = useState(false);

  // Functions
  const getDepot = async () => {
    try {
      const data = await fetchFrom(`/depots/${id}`);
      setDepot(data);
    } catch (error) {
      console.log(error);
      alert('error', 'Depo bilgileri getirilirken bir sorun oluştu!');
    }
  };

  const showDeleteDepotRecord = (id, isVariant, name, stock_count) => {
    setSelectedStock({ id, isVariant, name, stock_count });
    setShowDeleteDepotRecordModal(true);
  };

  const showEditDepotRecord = (id, isVariant, name, stock_count) => {
    setSelectedStock({ id, isVariant, name, stock_count });
    setShowEditDepotRecordModal(true);
  };

  // Effects
  useEffect(() => {
    setId(searchParams.get('id'));
  }, [searchParams]);

  useEffect(() => {
    if (id) {
      getDepot();
    }
  }, [id]);

  useEffect(() => {
    if (depot) {
      let stockList = [];
      depot.products.map((product) => {
        stockList.push({
          id: product.id,
          name: product.description,
          variantName: '',
          stock_count: product.stock_count,
          image: product.imageLink,
          isVariant: false,
        });
      });
      depot.productVariants
        .filter((item) => !item.isDeleted)
        .map((variant) => {
          stockList.push({
            id: variant.products_id,
            name: variant.product.description,
            variantName: variant.name,
            stock_count: variant.stock_count,
            image: variant.image_link,
            isVariant: true,
            variantId: variant.id,
          });
        });
      setStocks(stockList);
    }
  }, [depot]);

  return {
    id,
    setId,
    depot,
    getDepot,
    stocks,
    showDeleteDepotRecord,
    selectedStock,
    showDeleteDepotRecordModal,
    setShowDeleteDepotRecordModal,
    showEditDepotRecordModal,
    setShowEditDepotRecordModal,
    showEditDepotRecord,
    showAddDepotRecordModal,
    setShowAddDepotRecordModal,
  };
};

export default useSingleDepot;
