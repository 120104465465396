import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import StockCountEditModal from './components/StockCountEditModal';
import DepotEditModal from './components/DepotEditModal';

const ProductImage = ({ value }) => {
  if (!value) {
    return <img src="http://placehold.jp/100x100.png" className="rounded" alt="" width={80} />;
  }
  let imgParts = value.split('.');
  let ext = imgParts.pop();
  let imgThumb = imgParts.join('.') + '-thumb.' + ext;
  imgThumb = imgThumb.replace(
    'https://dev.images.atolyestore.com/products/',
    'https://dev.images.atolyestore.com/product_thumbs/',
  );
  return (
    <img
      src={imgThumb}
      className="rounded"
      alt=""
      width={80}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = value || 'http://placehold.jp/80x80.png';
      }}
    />
  );
};

const ProductCategory = ({ value }) => (
  <Badge pill className="bg-light font-12 text-dark">
    {value}
  </Badge>
);

const ProductLink = ({ value, row }) =>
  row.values.isSubRowItem ? (
    value
  ) : (
    <Link to={`/single-product?code=${value}`}>
      <Badge pill className="bg-light font-12 pointer text-primary">
        {value}
      </Badge>
    </Link>
  );

const BoldText = ({ value, row }, refreshData, userId) => (
  <div className="position-relative">
    <div
      style={{
        width: 40,
        height: 40,
        lineHeight: '40px',
        textAlign: 'center',
        boxShadow: '0 3px 6px rgba(0,0,0,.3)',
      }}
      className={`font-12 text-light ${
        parseInt(value) > 0
          ? row.values.isSubRowItem
            ? 'bg-primary'
            : 'bg-success'
          : 'bg-warning text-dark'
      }  rounded-circle`}>
      {value}
    </div>
    <StockCountEditModal data={row} onSave={refreshData} userId={userId} />
  </div>
);

const DepotLink = ({ value, row }, refreshData, userId) =>
  value ? (
    <div className="d-flex">
      <Link to={`/product-stock-management/depots/depot?id=${value?.id}`}>
        <Badge pill className="bg-light font-12 pointer text-primary">
          {value?.name}
        </Badge>
      </Link>
      <DepotEditModal data={row} onSave={refreshData} userId={userId} />
    </div>
  ) : (
    <div className="d-flex">
      <Badge pill className="bg-light font-12 text-danger">
        -
      </Badge>
      <DepotEditModal data={row} onSave={refreshData} userId={userId} />
    </div>
  );

export const columnsData = (refreshData, userId) => {
  return [
    {
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <div {...getToggleAllRowsExpandedProps()} style={{ minWidth: 100 }}>
          {isAllRowsExpanded ? (
            <span className=" d-block">
              <i className="fas fa-chevron-circle-down fa-lg me-1"></i>
              <span className="font-12">Varyantlar</span>
            </span>
          ) : (
            <span className=" d-block">
              <i className="fas fa-chevron-circle-right fa-lg me-1"></i>
              <span className="font-12">Varyantlar</span>
            </span>
          )}
        </div>
      ),
      Cell: ({ row }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
                minWidth: 150,
              },
            })}>
            {row.isExpanded ? (
              <span className="text-danger d-block">
                <i className="fas fa-chevron-circle-down fa-lg me-1"></i>
                <span className="font-12">Varyantlar</span>
              </span>
            ) : (
              <span className="text-danger d-block">
                <i className="fas fa-chevron-circle-right fa-lg me-1"></i>
                <span className="font-12">Varyantlar</span>
              </span>
            )}
          </span>
        ) : null,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Görsel',
      accessor: 'imageLink',
      columnName: 'imageLink',
      Cell: ProductImage,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Ürün Kodu',
      accessor: 'id',
      columnName: 'id',
      Cell: ProductLink,
      disableSortBy: true,
    },

    {
      Header: 'Kategori',
      accessor: 'product_category.name',
      columnName: 'product_category.name',
      Cell: ProductCategory,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Ürün Açıklaması',
      accessor: 'description',
      columnName: 'description',
      // Cell: CustomerNameLink,
      disableSortBy: true,
      // disableFilters: true
    },
    {
      Header: 'Stok Adedi',
      accessor: 'stock_count',
      columnName: 'stock_count',
      Cell: (props) => BoldText(props, refreshData, userId),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Depo',
      accessor: 'depot',
      columnName: 'depot',
      Cell: (props) => DepotLink(props, refreshData, userId),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Son Güncelleme',
      accessor: 'updatedAt',
      columnName: 'updatedAt',
      Cell: ({ value }) => new Date(value).toLocaleString('tr'),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Son Güncelleyen',
      accessor: 'updatedByUser.fullName',
      columnName: 'updatedByUser.fullName',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'isSubRowItem',
      accessor: 'isSubRowItem',
      columnName: 'isSubRowItem',
      isHidden: true,
    },
    {
      Header: 'isVariant',
      accessor: 'isVariant',
      columnName: 'isVariant',
      isHidden: true,
    },
    {
      Header: 'variantId',
      accessor: 'variantId',
      columnName: 'variantId',
      isHidden: true,
      Cell: () => null,
    },
  ];
};
