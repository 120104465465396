import './SinglePriceOffer.scss';

import { useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import API from 'utils/atolyeAPI';
const AtolyeAPI = new API();

import {
  ContentWrapper,
  // EmailSending,
  Header,
  Footer,
  PriceOfferHeader,
  PriceOfferInfo,
  PriceOfferSelect,
  PageTitle,
  PageWrapper,
  PDFButtons,
  useUser,
  EditPriceOfferModal,
  DeleteProductModal,
  ProductsDetails,
  PriceOfferDetails,
  DeletePriceOfferModal,
  ResponsiveLoading,
} from '.';
import usePriceOfferOptions from 'hooks/usePriceOfferOptions';

import {
  CLEAR_ERROR,
  SELECT_PRICE_OFFER,
  SET_DESCRIPTION_EDIT_TEXT,
  SET_EDIT_INFO_DEFAULTS,
  SET_ERROR,
  SET_PRICE_OFFER,
  START_LOADING,
  STOP_LOADING,
} from './singlePriceOfferSlice';

import AddProductButton from './components/AddProductButton';
import AddProductModal from './components/AddProductModal';

const SinglePriceOffer = () => {
  //Redux
  const { isLoading, isError, priceOffer } = useSelector(
    (state) => state.priceOffers.singlePriceOffer,
  );
  const { userPermissions } = useSelector((state) => state.general.user);
  const dispatch = useDispatch();

  // Hooks
  const { user } = useUser();
  const { isPriceOfferOptionsLoading, priceOfferOptions } = usePriceOfferOptions(user);
  const [searchParams, setSearchParams] = useSearchParams();
  // const navigate = useNavigate();

  useEffect(() => {
    const id = searchParams?.get('id');
    if (id) {
      dispatch(START_LOADING());
      getPriceOfferDetails(id);
      dispatch(SELECT_PRICE_OFFER(id));
    }
  }, [searchParams]);

  // Functions

  const getPriceOfferDetails = async (id) => {
    dispatch(CLEAR_ERROR());
    dispatch(START_LOADING());

    try {
      const { results } = await AtolyeAPI.get(`/price_offers/${id}`);
      dispatch(SET_PRICE_OFFER(results));
      dispatch(SET_EDIT_INFO_DEFAULTS(results));
      dispatch(SET_DESCRIPTION_EDIT_TEXT(results.description));
    } catch (err) {
      console.log(err);
      dispatch(SET_ERROR());
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <>
      <Header user={user} />
      {!userPermissions?.includes(3) ? (
        <Alert variant="danger" className="text-center">
          Bu sayfaya erişim yetkiniz bulunmamaktadır.
        </Alert>
      ) : (
        <>
          <PriceOfferSelect
            options={priceOfferOptions}
            isLoading={isPriceOfferOptionsLoading}
            onChange={(option) => setSearchParams(option ? { id: option.value } : {})}
            placeholder="Müşteri Adı veya Teklif No girin"
          />
          <ContentWrapper>
            <PageTitle title="Fiyat Teklifi Sorgulama" />
            <PageWrapper>
              {isLoading && <ResponsiveLoading />}

              {isError ? (
                <Alert variant="danger">
                  <strong>{searchParams?.get('id')}</strong> numaralı fiyat teklifi bilgilerinin
                  getirilmesi sırasında hata oluştu. Teklifin silinmediğinden emin olun.
                </Alert>
              ) : priceOffer && !isLoading ? (
                <div className="order-info mt-5">
                  <PriceOfferHeader title="Teklif Genel Bilgi" />
                  <PriceOfferInfo />
                  <hr />
                  <div className="row mx-0 mt-5">
                    {/* <EmailSending /> */}
                    {userPermissions?.includes(10) && <PDFButtons />}
                  </div>
                  <EditPriceOfferModal fetchPriceOffer={getPriceOfferDetails} />
                  <ProductsDetails fetchPriceOffer={getPriceOfferDetails} />
                  {userPermissions?.includes(10) && userPermissions?.includes(11) && (
                    <>
                      <AddProductButton />
                      <DeleteProductModal fetchPriceOffer={getPriceOfferDetails} />
                      <AddProductModal fetchPriceOffer={getPriceOfferDetails} />
                    </>
                  )}
                  <PriceOfferDetails fetchPriceOffer={getPriceOfferDetails} />
                  <DeletePriceOfferModal />
                  <div className="text-right mx-3">
                    <Link to="/price-offers" className="text-primary m-2">
                      Tüm Fiyat Teklifleri
                    </Link>
                    {userPermissions?.includes(11) && (
                      <a
                        href=""
                        className=" text-danger m-2"
                        data-toggle="modal"
                        data-target=".delete-order-modal-lg">
                        Teklifi Sil
                      </a>
                    )}
                  </div>
                </div>
              ) : (
                <Alert variant="info">
                  Fiyat Teklifi detaylarını görüntülemek için bir teklif seçiniz.
                </Alert>
              )}
            </PageWrapper>
          </ContentWrapper>
        </>
      )}

      <Footer />
    </>
  );
};

export default SinglePriceOffer;
