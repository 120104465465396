import './ManufacturerFilter.scss';
import { Form, Spinner } from 'react-bootstrap';

const ManufacturerFilter = ({ filter, setFilter, options, loading }) => {
  return (
    <div className="my-2 text-center d-flex mx-0 px-2 py-2 manufacturers-filter hide-on-print">
      <Form.Group className="mx-auto">
        <Form.Label>İmalatçıya göre filtrele: </Form.Label>
        {loading ? (
          <div>
            <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Form.Control
            className="mx-auto manufacturer-select"
            as="select"
            onChange={(e) =>
              setFilter(e.target.value !== 'all' ? { manufacturers_id: e.target.value } : null)
            }
            value={filter?.manufacturers_id}>
            <option value="all">Tümü</option>
            {options?.map((option) => (
              <option key={`manufacturer-${option.id}`} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Control>
        )}
      </Form.Group>
    </div>
  );
};

export default ManufacturerFilter;
