import { useEffect, useState } from 'react';
import alert from 'js/sweetAlert';

import { useAtolyeAPI } from 'hooks';

const useDepots = () => {
  // States
  const [depots, setDepots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isSortingUpdated, setIsSortingUpdated] = useState(false);
  const [areFiltersUpdated, setAreFiltersUpdated] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);

  // Hooks
  const { postTo } = useAtolyeAPI();

  // Effects
  useEffect(() => {
    setIsSortingUpdated(false);
  }, [isSortingUpdated]);

  useEffect(() => {
    setAreFiltersUpdated(false);
  }, [areFiltersUpdated]);

  // Functions

  const getDepots = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const { data, totalCount, totalPages } = await postTo('/depots/table', {
        limit: pageSize,
        offset: pageIndex * pageSize,
        // order: [['id', 'DESC']],
      });
      setDepots(data);
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Depo bilgileri getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };
  return {
    loading,
    pageCount,
    depots,
    filters,
    sorting,
    totalRowsCount,
    getDepots,
    setFilters,
    setSorting,
    setIsSortingUpdated,
    setAreFiltersUpdated,
  };
};

export default useDepots;
