import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const usePriceOfferOptions = () => {
  // State
  const [priceOfferOptions, setPriceOfferOptions] = useState([]);
  const [isPriceOfferOptionsLoading, setIsPriceOfferOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getPriceOfferOptions();
  }, []);

  // Functions
  const getPriceOfferOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/price_offers/options');
      setPriceOfferOptions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsPriceOfferOptionsLoading(false);
    }
  };

  return {
    isPriceOfferOptionsLoading,
    priceOfferOptions,
  };
};

export default usePriceOfferOptions;
