import { Alert, Button, Col, Row } from 'react-bootstrap';
import { ContentWrapper, PageTitle, Header, Footer, useUser } from '../ProductionList';
import './ManufactureProducts.scss';
// import ManufacturComponentCard from './components/ManufacturComponentCard';
import ManufactureProductCard from './components/ManufactureProductCard';
import useManufactureProducts from './hooks/useManufactureProducts';
import { Loading } from 'pages/Dashboard';
import { useState } from 'react';
import AddManufactureProductModal from './components/AddManufactureProductModal';
import EditManufactureProductModal from './components/EditManufactureProductModal';

const ManufactureProducts = (props) => {
  // State
  const [showAddManufactureProductModal, setShowAddManufactureProductModal] = useState(false);
  const [showEditManufactureProductModal, setShowEditManufactureProductModal] = useState(false);
  const [selectedManufactureProduct, setSelectedManufactureProduct] = useState(null);

  // Hooks
  const { user, userPermissions } = useUser();

  const { isManufactureProductsLoading, manufactureProducts, getManufactureProducts } =
    useManufactureProducts();

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="İmal Edilen Ürünler" />
        {!userPermissions?.includes(34) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> İmal Edilen Ürünleri görüntülemek için yetkiniz
            bulunmamaktadır.
          </Alert>
        ) : (
          <>
            <div className="d-flex justify-content-end mx-4">
              <Button
                variant="primary"
                size="sm"
                className="mt-5 font-14"
                onClick={() => setShowAddManufactureProductModal(true)}>
                + Yeni Ürün Ekle
              </Button>
              <AddManufactureProductModal
                show={showAddManufactureProductModal}
                onHide={() => setShowAddManufactureProductModal(false)}
                getManufactureProducts={getManufactureProducts}
              />
              {selectedManufactureProduct && (
                <EditManufactureProductModal
                  manufactureProduct={selectedManufactureProduct}
                  show={showEditManufactureProductModal}
                  onHide={() => setShowEditManufactureProductModal(false)}
                  getManufactureProducts={getManufactureProducts}
                />
              )}
            </div>
            <Row className="my-3 py-3 mx-2">
              {isManufactureProductsLoading ? (
                <Loading />
              ) : (
                manufactureProducts.length === 0 && (
                  <Alert variant="warning" className="w-100">
                    Henüz bir ürün eklenmedi!
                  </Alert>
                )
              )}
              {manufactureProducts?.map((item) => (
                <Col key={'manufactorComponent' + item.id} md={4} lg={3} className="my-2">
                  <ManufactureProductCard
                    getManufactureProducts={getManufactureProducts}
                    manufactureProduct={{
                      id: item.id,
                      name: item.product.description,
                      hasVariant: item.variant ? true : false,
                      variantName: item.variant ? item.variant.name : 'Ana Ürün',
                      imageLink: item.variant
                        ? item.variant.image_link ?? item.product.imageLink
                        : item.product.imageLink,
                      capacity: item.capacity ?? 0,
                      components: item.components,
                      data: item,
                    }}
                    setSelectedManufactureProduct={setSelectedManufactureProduct}
                    setShowEditManufactureProductModal={setShowEditManufactureProductModal}
                  />
                </Col>
              ))}
            </Row>
          </>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default ManufactureProducts;
