import { Alert, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_RESET_PASSWORD_MODAL, START_LOADING, STOP_LOADING } from '../userSlice';

import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { useState } from 'react';

const AtolyeAPI = new API();

const ResetUserPasswordModal = () => {
  // Redux
  const { isResetPasswordModalOpen, userDetails } = useSelector((state) => state.settings.user);
  const dispatch = useDispatch();

  // States
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Functions

  const resetUserPassword = async () => {
    dispatch(START_LOADING());
    try {
      const result = await AtolyeAPI.post(`/admin/user/set-password`, {
        email: userDetails.email,
        password,
      });

      if (result.status === 'ERROR') {
        alert('error', 'Kullanıcı şifresi sıfırlanırken bir hata oluştu.');
        return;
      }
      dispatch(HIDE_RESET_PASSWORD_MODAL());
      alert('success', 'Kullanıcı şifresi sıfırlandı.');
    } catch (error) {
      console.log(error);
      alert('error', 'Kullanıcı şifresi sıfırlanırken bir hata oluştu.');
    } finally {
      dispatch(STOP_LOADING());
      setPassword('');
      setConfirmPassword('');
    }
  };

  return (
    <Modal show={isResetPasswordModalOpen} onHide={() => dispatch(HIDE_RESET_PASSWORD_MODAL())}>
      <Modal.Header className="bg-dark" closeButton>
        <Modal.Title>Kullanıcı Parolasını Sıfırla</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Password and Confirm Password Inputs */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Parola</Form.Label>
          <Form.Control
            type="password"
            placeholder="Parola"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Parola Tekrar</Form.Label>
          <Form.Control
            type="password"
            placeholder="Parola Tekrar"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>
        <Alert variant="warning" className="font-14">
          Parola en az <strong>8 karakter</strong> olmalıdır. Belirlenen parola{' '}
          <strong>geçici</strong> olacaktır. Kullanıcı geçici parola ile giriş yaptıktan sonra kendi
          parolasını belirleyerek giriş yapabilecektir.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-light btn-sm"
          onClick={() => dispatch(HIDE_RESET_PASSWORD_MODAL())}>
          <span className="font-14">Vazgeç</span>
        </button>
        <button
          className="btn btn-success btn-sm text-white"
          disabled={password !== confirmPassword || password.length < 8}
          onClick={resetUserPassword}>
          <i className="fas fa-check  me-1"></i>
          <span className="font-14">Değiştir</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetUserPasswordModal;
