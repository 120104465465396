import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { HIDE_OPTION_DELETE_MODAL, START_LOADING, STOP_LOADING } from '../../singleProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const VariantOptionDeleteModal = ({ getProduct }) => {
  //Redux
  const { isVariantOptionDeleteModalDisplayed, optionToDelete } = useSelector(
    (state) => state.products.singleProduct.variants,
  );
  const dispatch = useDispatch();

  // Functions
  const handleOptionDelete = async () => {
    dispatch(START_LOADING());

    try {
      const { status } = await AtolyeAPI.get(
        `/product_variants/options/${optionToDelete.id}/delete`,
      );
      if (status === 'SUCCESS') {
        await getProduct();
        return alert('success', 'Variant Seçeneği başarıyla silindi');
      }
      throw new Error('Seçenek oluşturulurken bir hata oluştu');
    } catch (error) {
      console.log({ error });
      return alert('error', 'Seçenek silinirken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(HIDE_OPTION_DELETE_MODAL());
    }
  };

  return (
    <Modal
      show={isVariantOptionDeleteModalDisplayed}
      onHide={() => dispatch(HIDE_OPTION_DELETE_MODAL())}
      size="sm"
      centered>
      <Modal.Body>
        <strong className="text-danger">{optionToDelete?.name}</strong> varyant seçeneğini bu
        üründen silmek istediğinize emin misiniz?
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="light" onClick={() => dispatch(HIDE_OPTION_DELETE_MODAL())}>
          Vazgeç
        </Button>
        <Button size="sm" variant="danger" className="text-white" onClick={handleOptionDelete}>
          Sil
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VariantOptionDeleteModal;
