import { useUser } from 'hooks';
import {
  AtolyeTable,
  ContentWrapper,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
} from '../ProductStockManagement';
import { useMemo } from 'react';
import useDepots from './useDepots';
import { columnsData } from './utils';
import CreateDepotModal from './components/CreateDepotModal';

const Depots = (props) => {
  // Hooks
  const { user, userPermissions } = useUser();
  const { loading, pageCount, depots, totalRowsCount, getDepots } = useDepots();

  // Declarations
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => depots, [depots]);
  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Depolar" />
        <PageWrapper>
          {!userPermissions?.includes(31) ? (
            <div className="alert alert-danger">
              <strong>Yetkiniz yok!</strong> Stok ve Depo kayıtları ekleme/düzenleme/silme için
              yetkiniz bulunmamaktadır.
            </div>
          ) : (
            <>
              <CreateDepotModal getDepots={getDepots} />
              <AtolyeTable
                columns={columns}
                data={data}
                fetchData={getDepots}
                loading={loading}
                pageCount={pageCount}
                total={totalRowsCount}
              />
            </>
          )}
        </PageWrapper>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default Depots;
