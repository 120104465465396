import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  depotOptions: [],
  stockFilterType: 'all',
};

export const productStockManagementSlice = createSlice({
  name: 'productStockManagement',
  initialState,
  reducers: {
    SET_DEPOT_OPTIONS: (state, action) => {
      return {
        ...state,
        depotOptions: action.payload,
      };
    },
    SET_STOCK_FILTER_TYPE: (state, action) => {
      return {
        ...state,
        stockFilterType: action.payload,
      };
    },
    RESET: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { RESET, SET_DEPOT_OPTIONS, SET_STOCK_FILTER_TYPE } =
  productStockManagementSlice.actions;

export default productStockManagementSlice.reducer;
