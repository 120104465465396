import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SET_PRICE_OFFER_DESCRIPTION } from '../createPriceOfferSlice';

import API from 'utils/atolyeAPI';
const AtolyeAPI = new API();

const PriceOfferNotes = () => {
  //States
  const [loading, setLoading] = useState(true);
  const [orderNotes, setOrderNotes] = useState([]);
  const [selectedOrderNote, setSelectedOrderNote] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const { priceOffer } = useSelector((state) => state.priceOffers.createPriceOffer);

  // Effects
  useEffect(() => {
    getOrderNotes();
  }, []);

  useEffect(() => {
    selectedOrderNote != 0
      ? dispatch(
          SET_PRICE_OFFER_DESCRIPTION(
            orderNotes.find((item) => item.id == selectedOrderNote).description,
          ),
        )
      : dispatch(SET_PRICE_OFFER_DESCRIPTION(''));
  }, [selectedOrderNote]);

  // Functions

  const getOrderNotes = async () => {
    setLoading(true);
    try {
      const { results } = await AtolyeAPI.get('/order_notes');
      setOrderNotes(results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card card-dark">
      <div className="card-header bg-dark  font-weight-bold text-center">Teklif Notları</div>
      <div className="card-body ">
        <div className="form-group">
          <label className="w-100">Sipariş Notu Taslağı seçin: </label>
          {loading ? (
            'Seçenekler yükleniyor...'
          ) : (
            <select
              style={{ maxWidth: 400 }}
              className="form-control"
              value={selectedOrderNote}
              onChange={(e) => setSelectedOrderNote(e.target.value)}>
              <option value={0}>Yeni Not</option>
              {orderNotes.map((item, index) => (
                <option key={`orderNote-${index}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="form-group">
          <label className="w-100">Notlar</label>
          <textarea
            className="w-100 form-control"
            name="description"
            id="description"
            rows="8"
            onChange={(e) => dispatch(SET_PRICE_OFFER_DESCRIPTION(e.target.value))}
            value={priceOffer.description}></textarea>
        </div>
      </div>
    </div>
  );
};

export default PriceOfferNotes;
