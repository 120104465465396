import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useProductCategories = () => {
  // State
  const [productCategories, setProductCategories] = useState([]);
  const [isProductCategoriesLoading, setIsProductCategoriesLoading] = useState(true);

  // Effects
  useEffect(() => {
    getProductCategories();
  }, []);

  // Functions
  const getProductCategories = async () => {
    try {
      const { results } = await AtolyeAPI.get('/product_categories');
      setProductCategories(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsProductCategoriesLoading(false);
    }
  };

  return {
    isProductCategoriesLoading,
    productCategories,
  };
};

export default useProductCategories;
