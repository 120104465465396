import { Button, Form } from 'react-bootstrap';

const AtolyeNumberInput = ({ value, setValue, title }) => {
  return (
    <div className="text-center mt-4">
      <h4 className="text-danger">{title}</h4>
      <div className="d-flex justify-content-center align-content-center">
        <Button
          variant="primary"
          className="rounded-circle"
          onClick={() => setValue(parseInt(value) + 1)}>
          +
        </Button>
        <Form.Control
          style={{ maxWidth: 100 }}
          className="font-16 d-inline-block mx-2"
          type="number"
          min={0}
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value))}
        />
        <Button
          variant="light"
          className="rounded-circle"
          onClick={() => setValue(parseInt(value) - 1 > -1 ? parseInt(value) - 1 : 0)}>
          -
        </Button>
      </div>
    </div>
  );
};

export default AtolyeNumberInput;
