import './Tags.scss';
import { useState, useEffect } from 'react';
import { useUser } from 'hooks';

import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

import { ContentWrapper, Footer, Header, PageTitle, PageWrapper } from '.';
import { Alert, Button, Table } from 'react-bootstrap';
import CreateTagModal from './components/CreateTagModal';
import ResponsiveLoading from 'components/ResponsiveLoading';
import EditTagModal from './components/EditTagModal';
import DeleteTagModal from './components/DeleteTagModal';

const Tags = () => {
  //States
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isCreateTagModalDisplayed, setIsCreateTagModalDisplayed] = useState(false);
  const [isEditTagModalDisplayed, setIsEditTagModalDisplayed] = useState(false);
  const [isDeleteTagModalDisplayed, setIsDeleteTagModalDisplayed] = useState(false);
  // Hooks
  const { user, userPermissions } = useUser();

  // Effects

  useEffect(() => {
    getTags();
  }, []);

  // Functions

  const getTags = async (isAfterAction = false) => {
    setLoading(true);
    try {
      const { results } = await AtolyeAPI.get('/tags');
      return setTags(results);
    } catch (error) {
      console.log(error);
      return !isAfterAction && alert('error', 'Ürün Etiketleri getirilirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Ürün Etiketleri" />
        {!userPermissions?.includes(26) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> Ürün Etiketlerini oluşturmak ve düzenlemek için yetkiniz
            bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            <div className="text-end">
              <Button
                className="mb-5"
                variant="outline-success"
                size="sm"
                onClick={() => setIsCreateTagModalDisplayed(true)}>
                + Yeni Etiket Oluştur
              </Button>
            </div>
            {loading ? (
              <ResponsiveLoading />
            ) : (
              <Table className="table overflow-x-scroll mw-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Etiket Adı</th>
                    <th>Renk</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  {tags.map((tag, index) => (
                    <tr key={`tag-${index}`}>
                      <td>{tag.id}</td>
                      <td>
                        <strong>{tag.name}</strong>
                      </td>
                      <td>
                        <span className={`color-picker-item ${tag.color}`}>
                          <span className="color-picker-item-label">{tag.name}</span>
                        </span>
                      </td>
                      <td>
                        <Button
                          className="me-2 my-1 border-0"
                          variant="outline-primary"
                          size="sm"
                          onClick={() => {
                            setSelectedTag(tag);
                            setIsEditTagModalDisplayed(true);
                          }}>
                          Düzenle
                        </Button>
                        <Button
                          className="border-0"
                          variant="outline-danger"
                          size="sm"
                          onClick={() => {
                            setSelectedTag(tag);
                            setIsDeleteTagModalDisplayed(true);
                          }}>
                          Sil
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <CreateTagModal
              show={isCreateTagModalDisplayed}
              onDismiss={() => setIsCreateTagModalDisplayed(false)}
              refresh={() => getTags()}
            />
            {selectedTag && (
              <>
                <EditTagModal
                  show={isEditTagModalDisplayed}
                  onDismiss={() => setIsEditTagModalDisplayed(false)}
                  tag={selectedTag}
                  refresh={getTags}
                />
                <DeleteTagModal
                  show={isDeleteTagModalDisplayed}
                  onDismiss={() => setIsDeleteTagModalDisplayed(false)}
                  tag={selectedTag}
                  refresh={getTags}
                />
              </>
            )}
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default Tags;
