import { Badge, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const CustomerNameLink = ({ value }) => (
  // <Link to={`/single-customer?id=${value.id}`}>{value}</Link>
  <strong className="text-dark">{value}</strong>
);

export const CustomerLastNameLink = ({ value }) => (
  // <Link to={`/single-customer?id=${value.id}`}>{value}</Link>
  <strong className="text-dark">{value}</strong>
);

export const OrderLink = ({ value }) => (
  <Link to={`/single-order?id=${value}`}>
    <Badge pill bg="warning" className="font-12" style={{ minWidth: 50 }}>
      {value}
    </Badge>
  </Link>
);

export const PriceOfferLink = ({ value }) => (
  <Link to={`/single-price-offer?id=${value}`}>
    <Badge pill bg="secondary" className="font-12" style={{ minWidth: 50 }}>
      {value}
    </Badge>
  </Link>

  // </Link>
);

export const DateCell = ({ value }) => {
  const cell = new Date(value)?.toLocaleDateString('tr');
  return cell;
};

export const BoldText = ({ value }) => <strong>{value}</strong>;

export const TotalCell = ({ value }) => <strong>{value?.toLocaleString()} ₺</strong>;

export const BillCell = ({ value }) => {
  return (
    <div style={{ minWidth: 90 }}>
      <i
        className={`fas fa-circle font-10 me-1 ${
          value === 'Kesildi' ? 'text-success' : 'text-danger'
        }`}></i>{' '}
      {value}
    </div>
  );
};

export const DeliveryOptionCell = ({ value }) => {
  return <div style={{ minWidth: 140 }}>{value}</div>;
};

export const ProductLink = ({ value }) => (
  <Link to={`/single-product?code=${value}`}>
    <Badge pill className="font-12 pointer bg-success">
      {value}
    </Badge>
  </Link>
);
export const ProductImage = ({ value }) => {
  if (!value) {
    return <img src="http://placehold.jp/100x100.png" className="rounded" alt="" width={100} />;
  }
  let imgParts = value.split('.');
  let ext = imgParts.pop();
  let imgThumb = imgParts.join('.') + '-thumb.' + ext;
  imgThumb = imgThumb.replace(
    'https://dev.images.atolyestore.com/products/',
    'https://dev.images.atolyestore.com/product_thumbs/',
  );
  return (
    <img
      src={imgThumb}
      className="rounded"
      alt=""
      width={100}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = 'http://placehold.jp/100x100.png';
      }}
    />
  );
};

export const SalesChannelCell = ({ value }) => {
  return <div style={{ minWidth: 130 }}>{value}</div>;
};

export const Status = ({ value }) => {
  const totalCount = value.length;
  const readyCount = value.filter((item) => item.production_status_id > 2).length;
  return (
    <ProgressBar style={{ minWidth: 100, maxHeight: 20 }}>
      <ProgressBar
        striped
        variant="success"
        now={(readyCount / totalCount) * 100}
        label={`${readyCount}/${totalCount}`}
      />
      <ProgressBar
        striped
        variant="danger"
        now={((totalCount - readyCount) / totalCount) * 100}
        label={`${totalCount - readyCount}/${totalCount}`}
      />
    </ProgressBar>
  );
};
