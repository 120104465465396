import './Orders.scss';

import { useMemo } from 'react';

import {
  AtolyeTable,
  ContentWrapper,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
  useOrders,
  useUser,
} from '.';

import { columnsData } from './utils';
import { Link } from 'react-router-dom';
import AtolyeDatePicker from 'components/AtolyeTable/components/AtolyeDatePicker';
import ExportButton from './components/ExportButton';

const Orders = (props) => {
  // Hooks
  const {
    loading,
    pageCount,
    orders,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    getOrders,
    exportAllOrders,
    setFilters,
    setAreFiltersUpdated,
    setDateFilter,
    setSorting,
    setIsSortingUpdated,
  } = useOrders();
  const { user, userRole, userPermissions } = useUser();

  // Declarations

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => orders, [orders]);

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Siparişler" />
        <PageWrapper>
          {!userPermissions?.includes(7) ? (
            <div className="alert alert-danger">
              <strong>Yetkiniz yok!</strong> Siparişleri görüntülemek için yetkiniz bulunmamaktadır.
            </div>
          ) : (
            <>
              <div className="my-4 text-right pr-4">
                <Link to="/create-order" className="text-success">
                  <strong>+ Yeni Sipariş</strong>
                </Link>
              </div>
              <div className="d-flex">
                <AtolyeDatePicker dateFilter={dateFilter} setDateFilter={setDateFilter} />
                {userRole !== 2 && (
                  <ExportButton loading={loading} exportExcel={(range) => exportAllOrders(range)} />
                )}
              </div>
              <AtolyeTable
                columns={columns}
                data={data}
                fetchData={getOrders}
                filters={filters}
                dateFilter={dateFilter}
                setFilters={setFilters}
                setAreFiltersUpdated={setAreFiltersUpdated}
                sorting={sorting}
                setSorting={setSorting}
                setIsSortingUpdated={setIsSortingUpdated}
                loading={loading}
                pageCount={pageCount}
                total={totalRowsCount}
              />
            </>
          )}
        </PageWrapper>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default Orders;
