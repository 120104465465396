import { useEffect, useState } from 'react';

import { useAtolyeAPI } from 'hooks';

const useManufactureProducts = () => {
  // States
  const [isManufactureProductsLoading, setManufactureProductsLoading] = useState(true);
  const [manufactureProducts, setManufactureProducts] = useState([]);
  // Hooks
  const { fetchFrom } = useAtolyeAPI();
  // Effects
  useEffect(() => {
    getManufactureProducts();
  }, []);

  // Functions
  const getManufactureProducts = async () => {
    setManufactureProductsLoading(true);
    try {
      const results = await fetchFrom('/manufacture_products');
      // console.log(results);
      setManufactureProducts(results);
    } catch (error) {
      console.log(error);
      alert('error', 'İmalatçı seçenekleri getirilirken bir sorun oluştu!');
    }

    setManufactureProductsLoading(false);
  };

  return { isManufactureProductsLoading, manufactureProducts, getManufactureProducts };
};

export default useManufactureProducts;
