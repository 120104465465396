import './CreateProduct.scss';

import { Header, Footer, ContentWrapper, PageTitle, PageWrapper } from '.';
import { useSelector, useDispatch } from 'react-redux';
import { useUser } from '../Products';
import { Alert, Col, Row } from 'react-bootstrap';
import ResponsiveLoading from 'components/ResponsiveLoading';
import ProductGeneralDetails from './components/ProductGeneralDetails';
import ProductImage from './components/ProductImage';
import ActionButtonsSection from './components/ActionButtonsSection';
import { useEffect } from 'react';
import { SET_CREATED_BY } from './createProductSlice';
import ProductTags from './components/ProductTags';

const CreateProduct = () => {
  //Redux
  const dispatch = useDispatch();
  const { isLoading, product } = useSelector((state) => state.products.createProduct);

  // Hooks
  const { user, userPermissions } = useUser();

  useEffect(() => {
    user && dispatch(SET_CREATED_BY(user.id));
  }, [user]);

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Yeni Ürün Oluştur" />
        <div className="text-center">
          <img width={125} src="/images/product-development.png" />
        </div>
        {!userPermissions?.includes(6) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok! </strong>Yeni Ürün oluşturmak için yetkiniz bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            {isLoading && <ResponsiveLoading />}

            {/* Page Components */}

            <Row>
              <Col md={8} className="border-right">
                <ProductGeneralDetails product={product} />
                <ProductTags />
              </Col>
              <Col md={4}>
                <ProductImage imageSrc={product.imageLink} />
              </Col>
            </Row>

            <ActionButtonsSection reset={() => {}} />
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default CreateProduct;
