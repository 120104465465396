import { Table } from '..';
import { Link } from 'react-router-dom';

const CustomerOrdersTable = ({ orders }) => {
  return (
    <Table striped bordered hover size="sm" variant="secondary">
      <thead>
        <tr>
          <th style={{ width: 80 }}>#</th>
          <th>Sipariş Tarihi</th>
          <th>Teslim Tarihi</th>
          <th>Satış Kanalı</th>
          <th>Tutar</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr key={`order-${order.id}`}>
            <td className="text-center">
              <Link to={`/single-order?id=${order.id}`}>
                <span className="badge badge-danger  px-2">{order.id}</span>
              </Link>
            </td>
            <td> {new Date(order.saleDate).toLocaleDateString('tr')}</td>
            <td> {new Date(order.deliveryDate).toLocaleDateString('tr')}</td>
            <td>{order.salesChannel}</td>
            <td> {order.total.toLocaleString()} ₺</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CustomerOrdersTable;
