import { useEffect, useState } from 'react';

import { useAtolyeAPI } from 'hooks';

const useManufacturerOptions = () => {
  // States
  const [optionsLoading, setLoading] = useState(true);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  // Hooks
  const { fetchFrom } = useAtolyeAPI();
  // Effects
  useEffect(() => {
    getManufacturerOptions();
  }, []);

  // Functions
  const getManufacturerOptions = async () => {
    setLoading(true);
    try {
      const results = await fetchFrom('/manufacturers');
      console.log(results);
      setManufacturerOptions(results);
    } catch (error) {
      console.log(error);
      alert('error', 'İmalatçı seçenekleri getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };

  return { optionsLoading, manufacturerOptions };
};

export default useManufacturerOptions;
