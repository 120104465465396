import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { SHOW_ADD_PRODUCT_MODAL } from '../singleOrderSlice';

const AddProductButton = () => {
  // Redux
  const dispatch = useDispatch();
  return (
    <div className="text-center my-3">
      <Button
        variant="success"
        className="text-white"
        onClick={() => dispatch(SHOW_ADD_PRODUCT_MODAL())}>
        + Ürün Ekle
      </Button>
    </div>
  );
};

export default AddProductButton;
