import { useEffect, useState } from 'react';

import { useAtolyeAPI } from 'hooks';

const useOperationOptions = () => {
  // States
  const [isOperationOptionsLoading, setOperationOptionsLoading] = useState(true);
  const [operationOptions, setOperationOptions] = useState([]);
  // Hooks
  const { fetchFrom } = useAtolyeAPI();
  // Effects
  useEffect(() => {
    getOperationOptions();
  }, []);

  // Functions
  const getOperationOptions = async () => {
    setOperationOptionsLoading(true);
    try {
      const results = await fetchFrom('/operations/options');
      // console.log(results);
      setOperationOptions(results);
    } catch (error) {
      console.log(error);
      alert('error', 'İş seçenekleri getirilirken bir sorun oluştu!');
    }

    setOperationOptionsLoading(false);
  };

  return { isOperationOptionsLoading, operationOptions, getOperationOptions };
};

export default useOperationOptions;
