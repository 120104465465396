import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { SHOW_CUSTOMER_MODAL } from 'pages/PriceOffer/CreatePriceOffer/createPriceOfferSlice';

const CustomerForm = () => {
  // Redux
  const dispatch = useDispatch();
  const { editingCustomer } = useSelector((state) => state.priceOffers.createPriceOffer);
  return (
    <div className="card-body row">
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Müşteri Adı</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={editingCustomer.name}
            disabled
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Müşteri Soyadı</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={editingCustomer.lastName}
            disabled
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Firma</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={editingCustomer.company}
            disabled
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">E-Posta</label>
          <input
            type="email"
            className="form-control"
            value={editingCustomer.email}
            disabled
            readOnly
          />
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Telefon</label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            value={editingCustomer.phone}
            disabled
            readOnly
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Adres </label>
          <textarea
            type="text"
            className="form-control"
            id="address"
            name="address"
            rows="4"
            value={editingCustomer.address}
            disabled
            readOnly></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Fatura Adresi</label>
          <textarea
            type="text"
            className="form-control"
            id="billingAddress"
            name="billingAddress"
            rows="4"
            value={editingCustomer.billingAddress}
            disabled
            readOnly></textarea>
        </div>
        <div className="form-group">
          <Button
            variant="success"
            size="sm"
            disabled={!editingCustomer?.id}
            onClick={() => dispatch(SHOW_CUSTOMER_MODAL())}>
            Müşteri Bilgilerini Düzenle
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerForm;
