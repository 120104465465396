import { useSelector } from 'react-redux';

const PriceOfferInfo = () => {
  //Redux
  const { priceOffer } = useSelector((state) => state.priceOffers.singlePriceOffer);
  const { userPermissions } = useSelector((state) => state.general.user);
  return (
    <>
      <div className="row mx-0 mt-5 mb-3">
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-truck-loading"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Teklif No</span>
              <span id="id" className="info-box-number">
                {priceOffer?.id}
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-users"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Müşteri Adı</span>
              <span id="customer" className="info-box-number">
                {priceOffer.customer && (
                  <a href={'/single-customer?id=' + priceOffer.customer.id} className="">
                    {priceOffer.customer.name + ' ' + priceOffer.customer.lastName}
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-info"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Firma</span>
              <span id="description" className="info-box-number">
                {priceOffer?.customer?.company}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-user"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Yetkili</span>
              <span id="user" className="info-box-number">
                {priceOffer?.createdBy_user?.fullName}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="far fa-calendar-alt"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Teklif Tarihi</span>
              <span id="createdAt" className="info-box-number">
                {priceOffer && new Date(priceOffer.createdAt).toLocaleDateString('tr')}
              </span>
            </div>
          </div>
        </div>

        {userPermissions?.includes(10) && (
          <div className="col-md-3">
            <div className="info-box mb-3 bg-light">
              <span className="info-box-icon">
                <i className="fas fa-wallet"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Teklif Tutarı</span>
                <span id="total" className="info-box-number">
                  {priceOffer && priceOffer.total?.toLocaleString() + ' ₺'}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PriceOfferInfo;
