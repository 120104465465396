import {
  SET_TEMP_FINANCIAL_DETAILS_AFTER_RECALCULATE,
  SET_TEMP_FINANCIAL_DETAILS,
} from '../../../singlePriceOfferSlice';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { roundTo2Decimals } from 'utils/calcUtils';

const SubTotalEditModal = ({ tempFinancialDetails, updateFinancialDetails }) => {
  // Hooks

  // State
  const [subTotalInputValue, setSubTotalInputValue] = useState(0);
  const [discountRateInputValue, setDiscountRateInputValue] = useState(0);
  // Redux

  const dispatch = useDispatch();
  const { priceOffer } = useSelector((state) => state.priceOffers.singlePriceOffer);

  // Effects

  useEffect(() => {
    setSubTotalInputValue(roundTo2Decimals(tempFinancialDetails.subTotalWithKDV));
  }, [tempFinancialDetails.subTotalWithKDV]);

  useEffect(() => {
    setDiscountRateInputValue(tempFinancialDetails.discountRate);
  }, [tempFinancialDetails.discountRate]);

  // Functions
  const subTotalOnChangeHandler = (e) => {
    const _tempSubTotalWithKDV = parseFloat(e.target.value);
    setSubTotalInputValue(_tempSubTotalWithKDV);
    const { _tempDiscountRate, _tempDiscountTotalWithKDV } =
      calcAfterSubTotalChange(_tempSubTotalWithKDV);

    const _tempFinancialDetails = {
      discountTotal: roundTo2Decimals(_tempDiscountTotalWithKDV),
      discountRate: roundTo2Decimals(_tempDiscountRate),
      subTotal: roundTo2Decimals(_tempSubTotalWithKDV),
      total: roundTo2Decimals(_tempSubTotalWithKDV),
    };

    dispatch(SET_TEMP_FINANCIAL_DETAILS_AFTER_RECALCULATE(_tempFinancialDetails));
  };

  const discountRateOnChangeHandler = (e) => {
    const _tempDiscountRate = parseFloat(e.target.value);
    setDiscountRateInputValue(_tempDiscountRate);
    const { _tempSubTotalWithKDV, _tempDiscountTotalWithKDV } =
      calcAfterDiscountRateChange(_tempDiscountRate);

    const _tempFinancialDetails = {
      discountTotal: roundTo2Decimals(_tempDiscountTotalWithKDV),
      discountRate: roundTo2Decimals(_tempDiscountRate),
      subTotal: roundTo2Decimals(_tempSubTotalWithKDV),
      total: roundTo2Decimals(_tempSubTotalWithKDV),
    };

    dispatch(SET_TEMP_FINANCIAL_DETAILS_AFTER_RECALCULATE(_tempFinancialDetails));
  };

  const calcAfterSubTotalChange = (_subTotal) => {
    const _tempDiscountTotalWithKDV = tempFinancialDetails.productsTotalWithKDV - _subTotal;
    const _tempDiscountRate =
      100 *
      (tempFinancialDetails.productsTotalWithKDV /
        (tempFinancialDetails.productsTotalWithKDV - _tempDiscountTotalWithKDV) -
        1);
    return {
      _tempDiscountTotalWithKDV,
      _tempDiscountRate,
    };
  };

  const calcAfterDiscountRateChange = (_discountRate) => {
    const _tempDiscountTotalWithKDV =
      tempFinancialDetails.productsTotalWithKDV -
      tempFinancialDetails.productsTotalWithKDV / (1 + _discountRate / 100);
    const _tempSubTotalWithKDV =
      tempFinancialDetails.productsTotalWithKDV - _tempDiscountTotalWithKDV;

    return {
      _tempDiscountTotalWithKDV,
      _tempSubTotalWithKDV,
    };
  };

  const resetForm = () =>
    dispatch(
      SET_TEMP_FINANCIAL_DETAILS({
        productsTotal: priceOffer.productsTotal,
        discountTotal: priceOffer.discountTotal,
        discountRate: priceOffer.discountRate,
        subTotal: priceOffer.subTotal,
        total: priceOffer.total,
      }),
    );

  return (
    <div className="modal fade" id="editSubtotalModal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Ara Toplam - Düzenle
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => resetForm()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row mx-0">
              <div className="col-6 text-center py-2 border bg-secondary">Ürünler Toplamı</div>
              <div className="col-6 text-center py-2 border">
                {tempFinancialDetails.productsTotalWithKDV.toFixed(2).toLocaleString()} ₺
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-6 text-center py-2 border bg-secondary">İskonto Oranı (%)</div>
              <div className="col-6 text-center py-2 border bg-danger">
                <input
                  type="number"
                  name="discountRate"
                  id="discountRate"
                  className="form-control bg-white text-center border-success"
                  value={discountRateInputValue}
                  onChange={discountRateOnChangeHandler}
                />
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-6 text-center py-2 border bg-secondary">İskonto Tutarı</div>
              <div className="col-6 text-center py-2 border">
                {tempFinancialDetails.discountTotalWithKDV} ₺
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-6 text-center py-2 border bg-secondary">Ara Toplam (₺)</div>
              <div className="col-6 text-center py-2 border bg-primary">
                <input
                  type="number"
                  name="subTotal"
                  id="subTotal"
                  className="form-control bg-white text-center border-success"
                  value={subTotalInputValue}
                  onChange={subTotalOnChangeHandler}
                />
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-6 text-center py-2 border border bg-info">
                Fiyat
                {/* <span>(KDV Dahil)</span> */}
              </div>
              <div className="col-6 text-center py-2 border border-info">
                {tempFinancialDetails?.total?.toLocaleString()} ₺
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => resetForm()}>
              Vazgeç
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={() => updateFinancialDetails()}>
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubTotalEditModal;
