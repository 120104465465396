import './OrderNotes.scss';
import { useState, useEffect } from 'react';
import { useUser } from 'hooks';

import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

import { ContentWrapper, Footer, Header, PageTitle, PageWrapper } from '.';
import { Button, Table, Form, Alert } from 'react-bootstrap';
import CreateOrderNoteModal from './components/CreateOrderNoteModal';
import { ResponsiveLoading } from '../SingleOrder';
import EditOrderNoteModal from './components/EditOrderNoteModal';
import DeleteOrderNoteModal from './components/DeleteOrderNoteModal';

const OrderNotes = () => {
  //States
  const [loading, setLoading] = useState(true);
  const [orderNotes, setOrderNotes] = useState([]);
  const [selectedOrderNote, setSelectedOrderNote] = useState(null);
  const [isCreateOrderNoteModalDisplayed, setIsCreateOrderNoteModalDisplayed] = useState(false);
  const [isEditOrderNoteModalDisplayed, setIsEditOrderNoteModalDisplayed] = useState(false);
  const [isDeleteOrderNoteModalDisplayed, setIsDeleteOrderNoteModalDisplayed] = useState(false);
  // Hooks
  const { user, userPermissions } = useUser();

  // Effects

  useEffect(() => {
    getOrderNotes();
  }, []);

  // Functions

  const getOrderNotes = async (isAfterAction = false) => {
    setLoading(true);
    try {
      const { results } = await AtolyeAPI.get('/order_notes');
      setOrderNotes(results);
      return !isAfterAction && alert('success', 'Sipariş Notu Taslakları başarıyla getirildi');
    } catch (error) {
      console.log(error);
      return (
        !isAfterAction && alert('error', 'Sipariş Notu Taslakları getirilirken bir hata oluştu')
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Sipariş Taslakları" />
        {!userPermissions?.includes(19) ? (
          <Alert variant="danger">Bu sayfaya erişim yetkiniz bulunmamaktadır.</Alert>
        ) : (
          <PageWrapper>
            <div className="text-end">
              <Button
                className="mb-5"
                variant="outline-success"
                size="sm"
                onClick={() => setIsCreateOrderNoteModalDisplayed(true)}>
                + Yeni Taslak Oluştur
              </Button>
            </div>
            {loading ? (
              <ResponsiveLoading />
            ) : (
              <Table className="table overflow-x-scroll mw-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Taslak Adı</th>
                    <th>Taslak Metni</th>
                    <th>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {orderNotes.map((note, index) => (
                    <tr key={`note-${index}`}>
                      <td>{note.id}</td>
                      <td>
                        <strong>{note.name}</strong>
                      </td>
                      <td>
                        <Form.Control
                          style={{ minWidth: 400 }}
                          as="textarea"
                          rows={8}
                          defaultValue={note.description}
                          readOnly></Form.Control>
                      </td>
                      <td>
                        <Button
                          className="me-2 my-1"
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            setSelectedOrderNote(note);
                            setIsEditOrderNoteModalDisplayed(true);
                          }}>
                          Düzenle
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setSelectedOrderNote(note);
                            setIsDeleteOrderNoteModalDisplayed(true);
                          }}>
                          Sil
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <CreateOrderNoteModal
              show={isCreateOrderNoteModalDisplayed}
              onDismiss={() => setIsCreateOrderNoteModalDisplayed(false)}
            />
            {selectedOrderNote && (
              <>
                <EditOrderNoteModal
                  show={isEditOrderNoteModalDisplayed}
                  onDismiss={() => setIsEditOrderNoteModalDisplayed(false)}
                  note={selectedOrderNote}
                  refresh={getOrderNotes}
                />
                <DeleteOrderNoteModal
                  show={isDeleteOrderNoteModalDisplayed}
                  onDismiss={() => setIsDeleteOrderNoteModalDisplayed(false)}
                  note={selectedOrderNote}
                  refresh={getOrderNotes}
                />
              </>
            )}
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default OrderNotes;
