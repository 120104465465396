import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '..';
import useUserOptions from 'hooks/useUserOptions';
import {
  ADD_BALANCE,
  RESET_ADD_BALANCE,
  SET_BALANCE_DATE,
  SET_BALANCE_USER,
  SET_PAYMENT_METHOD,
  START_LOADING,
  STOP_LOADING,
} from '../singleOrderSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const AddBalanceModal = ({ fetchOrder }) => {
  //Redux
  const dispatch = useDispatch();
  const { addBalance, order } = useSelector((state) => state.orders.singleOrder);

  const { userOptions } = useUserOptions();

  // Functions
  const handleAddBalance = async () => {
    const lastRemainder = parseFloat(
      order.order_balances[order.order_balances.length - 1]?.remainder,
    );
    const body = {
      orders_id: order.id,
      received: addBalance.balanceReceived,
      users_id: addBalance.balanceUser,
      date: addBalance.balanceDate,
      paymentMethod: addBalance.paymentMethod,
      remainder: lastRemainder
        ? lastRemainder - addBalance.balanceReceived
        : order.total - addBalance.balanceReceived,
    };

    if (
      !body.orders_id ||
      !body.received ||
      !body.users_id ||
      !body.date ||
      !body.paymentMethod ||
      !body.remainder
    ) {
      return alert('error', 'Lütfen tüm alanları doldurunuz');
    }
    dispatch(START_LOADING());
    try {
      await AtolyeAPI.post(`/order_balances/order/${order.id}`, body);
      fetchOrder(order.id);
      alert('success', 'Ödeme kayıtları Güncellendi');
      dispatch(RESET_ADD_BALANCE());
    } catch (error) {
      alert('error', 'Ödeme kayıtları güncellenirken hata oluştu');
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };
  return (
    <div
      className="modal fade add-balance-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div
          className="modal-content"
          style={{
            backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
          }}>
          <div className="modal-header">
            <h5 className="modal-title">Ödeme Kaydı Ekle</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="">Alınan Bakiye </label>
              <input
                type="number"
                name="balanceReceivedInput"
                id="balanceReceivedInput"
                className="form-control"
                value={addBalance.balanceReceived}
                onChange={(e) => dispatch(ADD_BALANCE(e.target.value))}
              />
            </div>
            {/* <div className="alert alert-warning">
              Alınan Bakiye girilirken bakiye miktarı ondalık kısmı nokta(.) ile ayrılacak şekilde
              yazılmalıdır. Para birimi içermemelidir. <br />
              <br />
              *** Virgül(,) kullanıldığı takdirde hata ile karşılaşılabilir. <br />
              <br />
              Doğru : 1255.87 <br />
              Yanlış : 1.255,87 <br />
              Yanlış : 1255,87 <br />
              Yanlış : 1255.87 ₺
            </div> */}
            <div className="form-group">
              <label htmlFor="">Ödeme Yöntemi: </label>
              <select
                name="paymentMethod"
                id="paymentMethod"
                className="form-control"
                value={addBalance.paymentMethod}
                onChange={(event) => dispatch(SET_PAYMENT_METHOD(event.target.value))}>
                <option value="Belirlenmedi" disabled>
                  Belirlenmedi
                </option>
                <option value="Kredi/Banka Kartı">Kredi/Banka Kartı</option>
                <option value="Havale">Havale</option>
                <option value="Nakit">Nakit</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="">Alan Yetkili: </label>
              <select
                name="orderUser"
                id="orderUser"
                className="form-control"
                value={addBalance.balanceUser !== null ? addBalance.balanceUser : 'Seçiniz'}
                onChange={(event) => dispatch(SET_BALANCE_USER(event.target.value))}>
                <option value="Seçiniz" disabled>
                  Seçiniz
                </option>
                {userOptions?.map((user) => {
                  if (user.roles_id !== 4) {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.fullName}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="">Tarih: </label>
              <DatePicker
                selected={addBalance.balanceDate && Date.parse(addBalance.balanceDate)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                minTime={'03:00:00'}
                maxTime={'03:00:00'}
                withPortal
                placeholderText="Tarih seçin"
                onChange={(date) => dispatch(SET_BALANCE_DATE(date))}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={handleAddBalance}>
              Kaydet
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => alert('handle add balance reset')}>
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBalanceModal;
