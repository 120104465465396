import alert from 'js/sweetAlert';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from 'utils/atolyeAPI';
import { START_LOADING, STOP_LOADING, SET_TEMP_FINANCIAL_DETAILS } from '../singlePriceOfferSlice';

const AtolyeAPI = new API();

const useSinglePriceOffer = (fetch) => {
  //Redux
  const dispatch = useDispatch();
  const { priceOffer, descriptionEditText, tempFinancialDetailsWithKDV } = useSelector(
    (state) => state.priceOffers.singlePriceOffer,
  );

  // Effects
  useEffect(() => {
    priceOffer &&
      dispatch(
        SET_TEMP_FINANCIAL_DETAILS({
          productsTotal: priceOffer.productsTotal,
          discountTotal: priceOffer.discountTotal,
          discountRate: priceOffer.discountRate,
          subTotal: priceOffer.subTotal,
          total: priceOffer.total,
        }),
      );
  }, [priceOffer]);

  // Functions
  const updateDescription = async () => {
    dispatch(START_LOADING());
    const body = { description: descriptionEditText };
    try {
      await AtolyeAPI.put(`/price_offers/${priceOffer.id}`, body);
      fetch(priceOffer.id);
      alert('success', 'Özel Notlar Güncellendi');
    } catch (error) {
      console.log(error);
      alert('error', 'Özel Notlar Güncellenirken Hata Oluştu');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const updateFinancialDetails = async () => {
    if (tempFinancialDetailsWithKDV.discountRate > 100) {
      return alert('error', 'İskonto oranı 100den büyük olamaz...');
    }

    if (!validateFinancialDetailsUpdate()) {
      return alert('error', 'Lütfen girilen değerlerin geçerli olduğundan emin olunuz...');
    }

    dispatch(START_LOADING());
    const body = {
      ...tempFinancialDetailsWithKDV,
      totalDiff: priceOffer.total - tempFinancialDetailsWithKDV.total,
    };
    try {
      const result = await AtolyeAPI.put(
        `/price_offers/${priceOffer.id}/update-financial-details`,
        body,
      );
      if (!result) {
        throw new Error('Teklif Finansal Detayları Güncellenirken Hata Oluştu');
      }
      alert('success', 'Teklif Finansal Detayları Güncellendi');
    } catch (error) {
      console.log(error);
      alert('error', 'Teklif Finansal Detayları Güncellenirken Hata Oluştu');
    } finally {
      await fetch(priceOffer.id);
      dispatch(STOP_LOADING());
    }
  };

  const validateFinancialDetailsUpdate = () => {
    const isAnyValueNull =
      Object.values(tempFinancialDetailsWithKDV).filter((item) => !item && item !== 0).length > 0;

    const isAnyValueNaN =
      Object.values(tempFinancialDetailsWithKDV).filter((item) => isNaN(item)).length > 0;

    const isNotValidated = isAnyValueNull || isAnyValueNaN;

    return !isNotValidated;
  };

  return { updateDescription, updateFinancialDetails };
};

export default useSinglePriceOffer;
