import './CreateCustomer.scss';

import { useState } from 'react';
import { useUser, useAtolyeAPI } from 'hooks';
import { ContentWrapper, Footer, Header, Loading, PageTitle, PageWrapper } from '.';
import { Link, useNavigate } from 'react-router-dom';
import alert from 'js/sweetAlert';

const CreateCustomer = () => {
  // States
  const [customerForm, setCustomerForm] = useState({
    name: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    billingAddress: '',
  });

  // Hooks
  const { user, userPermissions } = useUser();
  const { postTo, isLoading } = useAtolyeAPI();
  const navigate = useNavigate();

  // Functions
  const createCustomerHandler = () => {
    console.log({ customerForm });
    // validation
    if (!customerForm.name || !customerForm.lastName) {
      alert('error', 'Müşteri adı ve soyadı boş bırakılamaz !');
      return;
    }
    postTo(`/customers`, customerForm)
      .then(() => alert('success', 'Müşteri kaydı başarıyla oluşturuldu'))
      .then(() => navigate('/customers'))
      .catch((err) => console.log(err));
  };

  const onChangeHandler = (event) => {
    event.preventDefault();
    setCustomerForm({
      ...customerForm,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Yeni Müşteri Kaydı" />
        <PageWrapper>
          {!userPermissions?.includes(29) ? (
            <div className="alert alert-danger">
              <strong>Yetkiniz yok!</strong> yeni müşteri oluşturmak için yetkiniz bulunmamaktadır.
            </div>
          ) : (
            <>
              {isLoading && <Loading />}
              <div className="mx-2">
                <div className="card card-secondary col-md-6 mx-auto my-4 p-0 bg-light">
                  <div
                    className="card-body"
                    style={{
                      backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
                    }}>
                    <div className="form-group">
                      <label htmlFor="">Müşteri Adı</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder=""
                        required
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Müşteri Soyadı</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        placeholder=""
                        required
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">
                        Firma <small className="text-muted">(opsiyonel)</small>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">
                        E-Posta<small className="text-muted">(opsiyonel)</small>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="ornek@musteri.com"
                        onChange={onChangeHandler}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">
                        Telefon <small className="text-muted">(opsiyonel)</small>
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Ör: 0555 111 11 11"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">
                        Adres <small className="text-muted">(opsiyonel)</small>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        placeholder="Adresi yazınız"
                        onChange={onChangeHandler}
                        rows="4"></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">
                        Fatura Adresi <small className="text-muted">(opsiyonel)</small>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="billingAddress"
                        name="billingAddress"
                        placeholder="Fatura Adresini yazınız"
                        onChange={onChangeHandler}
                        rows="4"></textarea>
                    </div>
                  </div>

                  <div className="card-footer">
                    <button
                      id="createCustomerBtn"
                      className="btn btn-success"
                      onClick={createCustomerHandler}>
                      Kaydet
                    </button>

                    <Link to="/customers" className="float-right mt-2">
                      Tüm Müşteriler
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </PageWrapper>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default CreateCustomer;
