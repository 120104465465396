import SoldProducts from './SoldProducts';

import Header from 'components/shared/Header';
import Footer from 'components/shared/Footer/Footer';
import ContentWrapper from 'components/shared/ContentWrapper';
import PageWrapper from 'components/shared/PageWrapper';
import PageTitle from 'components/shared/PageTitle';

export default SoldProducts;

export { Header, Footer, ContentWrapper, PageWrapper, PageTitle };
