import Select from 'react-select';
import { useSelector } from 'react-redux';
import useProductOptions from 'hooks/useProductOptions';

const ProductSelect = ({ setSearchParams }) => {
  //Redux

  const { productCode } = useSelector((state) => state.products.singleProduct);

  const { productOptions, isProductOptionsLoading } = useProductOptions();

  return (
    <div className="d-flex my-3">
      <div
        className="select-wrapper mx-auto"
        style={{ width: '100%', maxWidth: 500, minWidth: 300 }}>
        <Select
          classNamePrefix="select"
          value={productOptions.find((item) => item.value === productCode)}
          onChange={(option) => setSearchParams(option ? { code: option.value } : {})}
          isDisabled={false}
          isLoading={isProductOptionsLoading}
          isClearable
          isSearchable
          placeholder="Ürün kodu veya açıklaması girin.."
          options={productOptions}
        />
      </div>
    </div>
  );
};

export default ProductSelect;
