import useProductOptions from 'hooks/useProductOptions';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';

const AddSoldProductModal = ({ isAddProductModalOpen, setIsAddProductModalOpen, setProducts }) => {
  // State
  const [product, setProduct] = useState('');

  // Hooks
  const { productOptions, isProductOptionsLoading } = useProductOptions();

  const handleSave = () => {
    setProducts((prevProducts) => [...prevProducts, product]);
    setIsAddProductModalOpen(false);
    setProduct('');
  };

  return (
    <Modal show={isAddProductModalOpen} onHide={() => setIsAddProductModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Ürün Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex my-3">
          <div
            className="select-wrapper mx-auto"
            style={{ width: '100%', maxWidth: 500, minWidth: 300 }}>
            <Select
              classNamePrefix="select"
              value={productOptions.find((item) => item.value === product.value)}
              onChange={(option) => setProduct(option)}
              isDisabled={false}
              isLoading={isProductOptionsLoading}
              isSearchable
              placeholder="Ürün kodu veya açıklaması girin.."
              options={productOptions}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => setIsAddProductModalOpen(false)}>
          Kapat
        </Button>
        <Button variant="success" onClick={handleSave}>
          Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSoldProductModal;
