import ProductStockManagement from './ProductStockManagement';

import { Alert } from 'react-bootstrap';

import AtolyeTable from 'components/AtolyeTable';
import ContentWrapper from 'components/shared/ContentWrapper';
import DatePicker from 'react-datepicker';
import Footer from 'components/shared/Footer/Footer';
import Header from 'components/shared/Header';
import Loading from 'components/shared/Loading';
import ResponsiveLoading from 'components/ResponsiveLoading';
import PageTitle from 'components/shared/PageTitle';
import PageWrapper from 'components/shared/PageWrapper';
import Select from 'react-select';

export {
  Alert,
  AtolyeTable,
  Footer,
  Header,
  Loading,
  ContentWrapper,
  DatePicker,
  ResponsiveLoading,
  PageTitle,
  PageWrapper,
  Select,
};

export default ProductStockManagement;
