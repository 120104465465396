import alert from 'js/sweetAlert';
import { Button } from 'react-bootstrap';

const PrintImageButton = ({ imageUrl, buttonVariant, buttonClassName }) => {
  const printImage = () => {
    if (!imageUrl) {
      return alert('error', 'Dosya bulunamadı');
    }
    const newWin = window.open('', 'Print-Window');
    newWin.document.write(`<body onload="window.print()">
    <img src="${imageUrl}" />
    </body>`);
    newWin.document.close();
  };
  return (
    <div>
      <Button variant={buttonVariant} className={buttonClassName} onClick={() => printImage()}>
        <i className="fas fa-print"></i>
      </Button>
    </div>
  );
};

export default PrintImageButton;
