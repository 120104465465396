import React from 'react';
import { Table } from 'react-bootstrap';

const StocksTable = ({ stocks, showDeleteDepotRecord, showEditDepotRecord }) => {
  return (
    <Table bordered hover size="sm" className="bg-white">
      <thead>
        <tr>
          <th>#</th>
          <td>Görsel</td>
          <th>Ürün Kodu</th>
          <th>Ürün Adı / Varyantı</th>
          <th>Stok Miktarı</th>
          <th>Düzenle</th>
          <th>Sil</th>
        </tr>
      </thead>
      <tbody>
        {stocks?.length > 0 &&
          stocks.map((stock, index) => (
            <tr key={`stock-${stock.id}-${index}`}>
              <td>{index + 1}</td>
              <td
                style={{
                  width: 100,
                  height: 100,
                  backgroundImage: `url(${stock.image})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}></td>
              <td>{stock.id}</td>
              <td>
                <span>{stock.name}</span>
                {stock.variantName && (
                  <>
                    <i className="fas fa-arrow-circle-right text-muted fa-sm mx-3"></i>
                    <span className="text-muted">{stock.variantName}</span>
                  </>
                )}
              </td>
              <td>{stock.stock_count}</td>
              <td>
                <button
                  className="btn btn-sm text-primary mx-2"
                  onClick={() =>
                    showEditDepotRecord(
                      stock.isVariant ? stock.variantId : stock.id,
                      stock.isVariant,
                      stock.variantName ? stock.name + '( ' + stock.variantName + ' )' : stock.name,
                      stock.stock_count,
                    )
                  }>
                  <i className="fas fa-edit"></i>
                </button>
              </td>
              <td>
                <button
                  className="btn btn-sm text-danger mx-2"
                  onClick={() =>
                    showDeleteDepotRecord(
                      stock.isVariant ? stock.variantId : stock.id,
                      stock.isVariant,
                      stock.variantName ? stock.name + '( ' + stock.variantName + ' )' : stock.name,
                      stock.stock_count,
                    )
                  }>
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default StocksTable;
