import useProductCategories from 'hooks/useProductCategories';
import alert from 'js/sweetAlert';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { roundTo2Decimals } from 'utils/calcUtils';
import {
  SET_CATEGORY,
  SET_COST,
  SET_DEFAULT_MANUFACTURER,
  SET_DESCRIPTION,
  SET_MAX_DISCOUNT_RATE,
  SET_SALE_PRICE,
  SET_STOCK_COUNT,
} from '../singleProductSlice';
import { useState } from 'react';
import API from 'utils/atolyeAPI';
import useManufacturerOptions from 'hooks/useManufacturerOptions';

const AtolyeAPI = new API();

const ProductGeneralDetails = ({ product }) => {
  //Redux
  const dispatch = useDispatch();
  const { userPermissions } = useSelector((state) => state.general.user);

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tempProductCode, setTempProductCode] = useState(product.id);

  // Hooks
  const { isProductCategoriesLoading, productCategories } = useProductCategories();
  const { isManufacturerOptionsLoading, manufacturerOptions } = useManufacturerOptions();
  const [, setSearchParams] = useSearchParams();

  // Functions

  const handleClose = () => {
    setShow(false);
    setTempProductCode('');
  };

  const handleShow = () => {
    setShow(true);
    setTempProductCode(product.id);
  };

  const changeProductCode = async () => {
    if (tempProductCode === '') {
      alert('error', 'Ürün Kodu boş bırakılamaz');
      return;
    }
    if (tempProductCode === product.id) {
      alert('error', 'Ürün Kodu değişmedi');
      return;
    }

    setIsLoading(true);
    try {
      await AtolyeAPI.put(`/products/update-id/${product.id}`, { newId: tempProductCode });
      alert('success', 'Ürün Kodu değiştirildi');
      // dispatch(SET_PRODUCT_CODE(tempProductCode));
      setSearchParams({ code: tempProductCode });
      handleClose();
    } catch (err) {
      alert('error', 'Ürün Kodu değiştirilemedi');
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const checkMaxDiscountRate = (value) => {
    const number = parseFloat(value);
    if (number > 100) {
      alert('error', "Maksimum İskonto Oranı 100'den büyük olamaz");
      return false;
    }
    if (number < 0) {
      alert('error', "Maksimum İskonto Oranı 0'dan küçük olamaz");
      return false;
    }
    return true;
  };

  return (
    <div className="m-3">
      <Row>
        <Col md={3} className="my-2">
          <Form.Group>
            <Form.Label>
              Ü. Kodu
              {userPermissions?.includes(27) && (
                <>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="ms-3 py-0"
                    onClick={handleShow}>
                    Değiştir
                  </Button>
                  {/* Modal for changing product code */}
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Ürün Kodu Değiştir</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form.Group>
                        <Form.Label>Yeni Ürün Kodu</Form.Label>
                        <Form.Control
                          className="font-14"
                          type="text"
                          value={tempProductCode}
                          onChange={(e) => setTempProductCode(e.target.value)}
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="light" onClick={handleClose}>
                        Kapat
                      </Button>
                      <Button variant="success" className="text-white" onClick={changeProductCode}>
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm me-2"></span>
                        ) : (
                          'Kaydet'
                        )}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </Form.Label>
            <Form.Control className="font-14" type="text" value={product.id} readOnly />
          </Form.Group>
        </Col>
        <Col md={9} className="my-2">
          <Form.Group>
            <Form.Label>Ürün Adı / Açıklaması</Form.Label>
            <Form.Control
              className="font-14"
              type="text"
              value={product.description}
              onChange={(e) => dispatch(SET_DESCRIPTION(e.target.value))}
              disabled={!userPermissions?.includes(22)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} className="my-2">
          <Form.Group>
            <Form.Label>Ürün Kategorisi</Form.Label>
            {/* <Form.Control className="font-14" type="text" value={product?.product_category?.name} /> */}
            {isProductCategoriesLoading ? (
              'Yükleniyor'
            ) : (
              <Form.Select
                className="font-14"
                disabled={!userPermissions?.includes(22)}
                value={product.product_categories_id}
                onChange={(e) => dispatch(SET_CATEGORY(e.target.value))}>
                <option value={0} disabled>
                  Seçiniz
                </option>
                {productCategories.map((item, index) => (
                  <option key={`category-${index}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
        </Col>
        {userPermissions?.includes(28) && (
          <Col md={4} className="my-2">
            <Form.Group>
              <Form.Label>İmalat Maliyeti</Form.Label>
              <Form.Control
                className="font-14"
                type="number"
                disabled={!userPermissions?.includes(22)}
                value={product.cost}
                onChange={(e) => dispatch(SET_COST(parseFloat(e.target.value)))}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={4} className="my-2">
          <Form.Group>
            <Form.Label>
              Satış Fiyatı <small className="text-danger">KDVsiz</small>
            </Form.Label>
            <Form.Control
              className="font-14"
              type="number"
              disabled={!userPermissions?.includes(22)}
              value={product.salePrice}
              onChange={(e) => dispatch(SET_SALE_PRICE(parseFloat(e.target.value)))}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="my-2">
          <Form.Group>
            <Form.Label>
              Satış Fiyatı <small className="text-success">KDVli</small>{' '}
            </Form.Label>
            <Form.Control
              className="font-14"
              type="number"
              disabled={!userPermissions?.includes(22)}
              value={roundTo2Decimals(product.salePrice * 1.18)}
              onChange={(e) => dispatch(SET_SALE_PRICE(parseFloat(e.target.value) / 1.18))}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="my-2">
          <Form.Group>
            <Form.Label>Maks. İskonto Or.(%)</Form.Label>
            <Form.Control
              className="font-14"
              type="number"
              disabled={!userPermissions?.includes(22)}
              min={0}
              max={100}
              value={product.maxDiscountRate}
              onChange={(e) =>
                checkMaxDiscountRate(e.target.value) &&
                dispatch(SET_MAX_DISCOUNT_RATE(parseFloat(e.target.value)))
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} className="my-2">
          <Form.Group>
            <Form.Label>Varsayılan İmalatçı</Form.Label>

            {isManufacturerOptionsLoading ? (
              'Yükleniyor'
            ) : (
              <Form.Select
                className="font-14"
                disabled={!userPermissions?.includes(22)}
                value={product.defaultManufacturerId}
                onChange={(e) => dispatch(SET_DEFAULT_MANUFACTURER(e.target.value))}>
                {manufacturerOptions.map((item, index) => (
                  <option key={`manufacturer-${index}`} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label className="text-danger mt-3 rounded " style={{ width: 100 }}>
              Stok Adedi
            </Form.Label>
            <hr className="mt-0" />
            <Form.Control
              className="font-14"
              style={{ maxWidth: 100 }}
              type="number"
              disabled={!userPermissions?.includes(22)}
              min={0}
              max={100}
              value={product.stock_count}
              onChange={(e) => dispatch(SET_STOCK_COUNT(parseFloat(e.target.value)))}
            />
            <Form.Text className="p-1" muted>
              Varyantsız orijinal ürünün stok miktarını ifade etmektedir. Ürün varyantlarının stok
              adetleri aşağıdaki Varyantlar kısmından veya{' '}
              <Link to="/product-stock-management">Ürün Stok Yönetimi</Link> menüsünden
              değiştirilebilir.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ProductGeneralDetails;
