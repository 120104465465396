import {
  useSelector,
  // useDispatch
} from 'react-redux';

const PaymentInfo = () => {
  //Redux
  const { order } = useSelector((state) => state.orders.singleOrder);
  const { userPermissions } = useSelector((state) => state.general.user);
  return (
    <>
      <div className="bg-dark p-2 rounded">
        <span className="h6 mt-1 my-1">Ödeme Bilgileri</span>
      </div>
      <div className="table-responsive">
        <table
          id="myTable"
          className="display nowrap table table-striped table-bordered my-5"
          style={{ width: '100%' }}>
          <thead>
            <tr className="">
              <th>Tarih</th>
              <th>Alınan Bakiye</th>

              <th>Kalan Bakiye</th>
              <th>Ödeme Yöntemi</th>
              <th>Alan Yetkili</th>
            </tr>
          </thead>
          <tbody>
            {order.order_balances.map((balance) => {
              return (
                <tr key={balance.id}>
                  <td>{new Date(balance.date).toLocaleDateString('tr')}</td>
                  <td>{balance.received.toLocaleString()} ₺</td>
                  <td>{balance.remainder.toLocaleString()} ₺</td>
                  <td>{balance.paymentMethod}</td>
                  <td>{balance.user.fullName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {userPermissions?.includes(9) && (
        <div className="text-center">
          <button
            className="btn btn-sm btn-success text-white my-3 mx-1"
            data-toggle="modal"
            data-target=".add-balance-modal-lg">
            <i className="fas fa-plus me-2"></i>
            Ödeme Kaydı Ekle
          </button>
          <button
            className="btn btn-sm btn-danger text-white my-3 mx-1"
            data-toggle="modal"
            data-target=".delete-balance-modal-lg">
            <i className="fas fa-minus me-2"></i>
            Son Kaydı Sil
          </button>
        </div>
      )}
      <hr className="my-5" />
    </>
  );
};

export default PaymentInfo;
