import './Header.scss';

import { NavbarHorizontal, SideNavbar } from '.';

const Header = () => {
  return (
    <header className="hide-on-print">
      <NavbarHorizontal />
      <SideNavbar />
    </header>
  );
};

export default Header;
