const ProductsInfoSingleRow = ({ product }) => {
  return (
    <div className="row mx-0" key={product.id}>
      <div className="col-3 text-center border py-2">
        <span className="badge badge-primary">{product.products_code}</span>
      </div>
      <div className="col-3 text-center border py-2">
        {(product.price * 1.18).toFixed(2).toLocaleString()} ₺
      </div>
      <div className="col-3 text-center border py-2">{product.quantity}</div>
      <div className="col-3 text-center border py-2">
        {(product.total * 1.18).toFixed(2).toLocaleString()} ₺
      </div>
    </div>
  );
};

export default ProductsInfoSingleRow;
