import '../Settings.scss';

import { ContentWrapper, Footer, Header, Loading, PageTitle, PageWrapper, useUser } from '..';

import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import BasicTable from 'components/BasicTable';
import useUsers from 'hooks/useUsers';
import { columns } from './utils';

const UserList = (props) => {
  // Hooks

  const { user, userPermissions } = useUser();
  const { users, isUsersLoading } = useUsers();

  // Declarations

  const data = isUsersLoading ? [] : users;

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        {isUsersLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loading />
          </div>
        ) : userPermissions?.includes(12) ? (
          <>
            <PageTitle title="Kullanıcılar" />
            <PageWrapper>
              {userPermissions?.includes(13) && (
                <div className="my-4 text-right pr-4">
                  <Link to="/create-user" className="text-success">
                    <strong>+ Yeni Kullanıcı</strong>
                  </Link>
                </div>
              )}
              <BasicTable data={data} columns={columns} />
            </PageWrapper>
          </>
        ) : (
          <>
            <Alert variant="danger">Bu sayfaya erişim yetkiniz bulunmamaktadır.</Alert>
          </>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default UserList;
