import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  HIDE_PRODUCT_VARIANT_DELETE_MODAL,
  START_LOADING,
  STOP_LOADING,
} from '../../singleProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const ProductVariantDeleteModal = ({ getProduct }) => {
  //Redux
  const { isProductVariantDeleteModalDisplayed, variantToDelete } = useSelector(
    (state) => state.products.singleProduct.variants,
  );
  const dispatch = useDispatch();

  // Functions
  const handleOptionDelete = async () => {
    dispatch(START_LOADING());

    try {
      const { status } = await AtolyeAPI.get(`/product_variants/${variantToDelete.id}/delete`);
      if (status === 'SUCCESS') {
        await getProduct();
        return alert('success', 'Ürün Varyantı başarıyla silindi');
      }
      throw new Error('Varyant silinirken bir hata oluştu');
    } catch (error) {
      console.log({ error });
      return alert('error', 'Varyant silinirken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(HIDE_PRODUCT_VARIANT_DELETE_MODAL());
    }
  };

  return (
    <Modal
      show={isProductVariantDeleteModalDisplayed}
      onHide={() => dispatch(HIDE_PRODUCT_VARIANT_DELETE_MODAL())}
      size="sm"
      centered>
      <Modal.Body>
        <div className="text-center">
          <i className="fas fa-exclamation-triangle fa-3x my-4 text-warning "></i>
        </div>
        <strong className="text-danger">
          {variantToDelete?.name} <span className="text-dark">- ID: {variantToDelete?.id}</span>
        </strong>{' '}
        ürün varyantını bu üründen silmek istediğinize <strong>emin misiniz?</strong>
        <p className="text-muted font-italic mt-3">
          &quot;Ürün varyantı silinse de daha önce oluşturulan sipariş / fiyat teklifi detaylarında
          ürün varyantına ait bilgiler görüntülenmeye devam edecektir.&quot;
        </p>
        <p className="text-muted font-italic mt-3">
          &quot;Ancak yeni oluşturulacak siparişlerde / fiyat tekliflerinde veya Stok Yönetimi
          tablosunda silinen varyantla işlem yapılamayacaktır.&quot;
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="light"
          onClick={() => dispatch(HIDE_PRODUCT_VARIANT_DELETE_MODAL())}>
          Vazgeç
        </Button>
        <Button size="sm" variant="danger" className="text-white" onClick={handleOptionDelete}>
          Sil
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductVariantDeleteModal;
