import './ProductionPlanning.scss';

import { useMemo, useEffect, useState } from 'react';
import useManufacturerOptions from '../ProductionList/hooks/useManufacturerOptions';
import { columnsData } from './utils';

import {
  AtolyeTable,
  Button,
  ContentWrapper,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
  ManufacturerFilter,
  useProductionList,
} from '.';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

const ProductionPlanning = () => {
  // Redux
  const { userPermissions } = useSelector((state) => state.general.user);

  // State
  const [customFilter, setCustomFilter] = useState({});
  // Hooks
  const {
    loading,
    pageCount,
    productionList,
    totalRowsCount,
    manufacturerFilter,
    filters,
    sorting,
    getProductions,
    setFilters,
    setAreFiltersUpdated,
    setSorting,
    setIsSortingUpdated,
    setManufacturerFilter,
  } = useProductionList({ isOnlyPlanning: true });

  const { manufacturerOptions, optionsLoading } = useManufacturerOptions();

  // Effects
  useEffect(() => {
    setCustomFilter(manufacturerFilter);
  }, [manufacturerFilter]);

  useEffect(() => {
    customFilter && Object.keys(customFilter) < 1
      ? setFilters({})
      : setFilters({ ...filters, ...customFilter });
  }, [customFilter]);

  // Declarations

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => productionList, [productionList]);

  return (
    <>
      <Header />
      <div className="printable-table-planning">
        <ContentWrapper>
          <PageTitle title="Üretim Planlama" />
          {!userPermissions?.includes(16) ? (
            <Alert variant="danger" className="text-center">
              Bu sayfaya erişim yetkiniz bulunmamaktadır.
            </Alert>
          ) : (
            <PageWrapper>
              <ManufacturerFilter
                filter={manufacturerFilter}
                setFilter={setManufacturerFilter}
                options={manufacturerOptions}
                loading={optionsLoading}
              />
              <div className="d-flex pb-2 pe-2">
                <Button
                  variant="success"
                  size="sm"
                  className="ms-auto text-white hide-on-print"
                  onClick={() => print()}>
                  <i className="fas fa-print"></i> Sayfayı Yazdır
                </Button>
              </div>
              <AtolyeTable
                columns={columns}
                data={data}
                fetchData={getProductions}
                filters={filters}
                setFilters={setFilters}
                setAreFiltersUpdated={setAreFiltersUpdated}
                sorting={sorting}
                setSorting={setSorting}
                setIsSortingUpdated={setIsSortingUpdated}
                loading={loading}
                pageCount={pageCount}
                total={totalRowsCount}
                options={{
                  manufacturerOptions: {
                    options: manufacturerOptions,
                    isLoading: optionsLoading,
                  },
                }}
              />
            </PageWrapper>
          )}
        </ContentWrapper>
      </div>
      <Footer />
    </>
  );
};

export default ProductionPlanning;
