import useManufacturerOptions from 'hooks/useManufacturerOptions';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MANUFACTURER } from '../createOrderSlice';

const ManufacturerSelection = () => {
  // Redux
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.orders.createOrder);

  // Hooks
  const { manufacturerOptions, isManufacturerOptionsLoading } = useManufacturerOptions();
  return (
    <div className="card card-dark">
      <div className="card-header bg-dark  font-weight-bold text-center ">Üreticiler</div>
      <div className="card-body ">
        {products.length > 0 && manufacturerOptions !== null
          ? products.map((product, index) => {
              return (
                <div key={index} className="col-md-6 mx-auto">
                  <div className="row">
                    <div className="col d-flex flex-wrap justify-content-center align-content-center">
                      <img src={product.imageLink} className="img-fluid" width={100} alt="" />
                      <span className="mt-2 px-3 py-2 w-100 text-center">
                        Ürün Kodu: {product.id}
                      </span>
                    </div>
                    <div className="col p-0">
                      <select
                        className="form-control"
                        name=""
                        id={index}
                        value={products[index].manufacturers_id || 'Seçiniz'}
                        onChange={(e) =>
                          dispatch(SET_MANUFACTURER({ index, value: e.target.value }))
                        }>
                        <option defaultValue="Seçiniz" disabled>
                          Seçiniz
                        </option>
                        {!isManufacturerOptionsLoading &&
                          manufacturerOptions.map((manufacturer) => {
                            return (
                              <option key={manufacturer.id} value={manufacturer.value}>
                                {manufacturer.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })
          : ''}
      </div>
    </div>
  );
};

export default ManufacturerSelection;
