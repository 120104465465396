import { RESET, START_LOADING, STOP_LOADING } from '../createPriceOfferSlice';
import alert from 'js/sweetAlert';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from 'utils/atolyeAPI';
import moment from 'moment';
const AtolyeAPI = new API();

const useCreatePriceOffer = () => {
  // Redux
  const dispatch = useDispatch();
  const { priceOffer, products, editingCustomer, isLoading } = useSelector(
    (state) => state.priceOffers.createPriceOffer,
  );

  // Hooks
  const navigate = useNavigate();

  // Functions
  const createPriceOfferHandler = async () => {
    const isValid = checkValidation();

    if (isValid) {
      const body = {
        customers_id: editingCustomer.id,
        createdBy: priceOffer.createdBy,
        createdAt: moment(priceOffer.createdAt).add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        description: priceOffer.description,
        discountRate: priceOffer.discountRate,
        discountTotal: priceOffer.discountTotal,
        productsTotal: priceOffer.productsTotal,
        subTotal: priceOffer.subTotal,
        KDV: priceOffer.kdv,
        KDVtotal: priceOffer.total - priceOffer.subTotal,
        total: priceOffer.total,
        billingAddress: priceOffer.billingAddress,
        products: products.map((product) => ({
          products_code: product.id,
          quantity: product.quantity,
          status: 1,
          features: product.features,
          description: product.description,
          price: parseFloat(product.price),
          total: parseFloat(product.price) * parseInt(product.quantity),
          glass: product.glass,
          material: product.material,
          branch: product.branch,
          color: product.color,
          lampholder: product.lampholder,
          specialNotes: product.specialNotes,
          // manufacturers_id: product?.manufacturers_id,
          variantId: product.selectedVariant.id ? product.selectedVariant.id : null,
        })),
      };
      console.log({ body });

      dispatch(START_LOADING());

      try {
        const { id } = await AtolyeAPI.post(`/price_offers`, body);
        alert('success', `Fiyat Teklifi ${id} numarası ile  başarıyla oluşturuldu`);
        navigate(`/price-offers`);
        dispatch(RESET());
      } catch (error) {
        alert('error', 'Fiyat Teklifi oluşturulurken bir hata oluştu');
        console.log(error);
      } finally {
        dispatch(STOP_LOADING());
      }
    }
  };

  const clearPriceOfferHandler = () => {
    dispatch(RESET());
    alert('success', 'Fiyat Teklifi bilgileri temizlendi');
  };

  const checkValidation = () => {
    if (!editingCustomer.id) {
      alert('error', 'Müşteri seçmediniz!!!');
      return false;
    }
    if (products.length < 1) {
      alert('error', 'Ürün eklemediniz!!!');
      return false;
    }
    if (!priceOffer.createdBy) {
      alert('error', 'Oluşturan Yetkiliyi seçmediniz');
      return false;
    }
    if (!priceOffer.createdAt) {
      alert('error', 'Teklif Tarihi boş olamaz!!!');
      return false;
    }

    return true;
  };

  return { createPriceOfferHandler, clearPriceOfferHandler, isLoading };
};

export default useCreatePriceOffer;
