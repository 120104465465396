import SingleCustomer from './SingleCustomer';
import CustomerDetailsTable from './components/CustomerDetailsTable';
import CustomerOrdersTable from './components/CustomerOrdersTable';
import CustomerOrderBalancesTable from './components/CustomerOrderBalancesTable';
import CustomerPriceOffersTable from './components/CustomerPriceOffersTable';

import ContentWrapper from 'components/shared/ContentWrapper';
import Footer from 'components/shared/Footer/Footer';
import Header from 'components/shared/Header';
import PageTitle from 'components/shared/PageTitle';
import PageWrapper from 'components/shared/PageWrapper';
import { Button, Card, Form, ListGroup, Table, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import alert from 'js/sweetAlert';

export default SingleCustomer;

export {
  alert,
  Button,
  Card,
  ContentWrapper,
  CustomerDetailsTable,
  CustomerOrdersTable,
  CustomerOrderBalancesTable,
  CustomerPriceOffersTable,
  Footer,
  Form,
  Header,
  ListGroup,
  PageTitle,
  PageWrapper,
  Select,
  Table,
  Tabs,
  Tab,
};
