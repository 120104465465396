import { Table } from '..';
import { Link } from 'react-router-dom';

const CustomerOrderBalancesTable = ({ orderBalances }) => {
  return (
    <Table striped bordered hover size="sm" variant="secondary">
      <thead>
        <tr>
          <th style={{ width: 80 }}>Sipariş ID</th>
          <th>Ödeme Tarihi</th>
          <th>Ödemeyi Alan</th>
          <th>Ödeme Yöntemi</th>
          <th>Ödeme Tutarı</th>
          <th>Kalan Tutar</th>
        </tr>
      </thead>
      <tbody>
        {orderBalances &&
          orderBalances.map((orderBalance) => (
            <tr key={`orderBalance-${orderBalance.id}`}>
              <td className="text-center">
                <Link to={`/single-order?id=${orderBalance.orders_id}`}>
                  <span className="badge badge-success px-2">{orderBalance.orders_id}</span>
                </Link>
              </td>
              <td> {new Date(orderBalance.date).toLocaleDateString('tr')}</td>
              <td> {orderBalance?.user?.fullName}</td>
              <td> {orderBalance.paymentMethod}</td>
              <td>{orderBalance.received.toLocaleString()} ₺</td>
              <td> {orderBalance.remainder.toLocaleString()} ₺</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default CustomerOrderBalancesTable;
