export const orderPermissionIds = [1, 2, 7, 8, 9, 19];

export const priceOfferPermissionIds = [3, 4, 10, 11, 30];

export const productPermissionIds = [23, 5, 6, 22, 24, 25, 26, 27, 28, 33];

export const productionPermissionIds = [16, 17, 21, 32, 34];

export const financialDataPermissionIds = [18, 20];

export const adminPermissionIds = [12, 13, 14, 15];

export const customerPermissionsIds = [29];

export const stockManagementPermissionsIds = [31];
