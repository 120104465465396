export const filterOptions = [
  {
    id: 1,
    caption: 'Planlamada',
    variant: 'primary',
  },
  {
    id: 2,
    caption: 'Üretimde',
    variant: 'primary',
  },
  {
    id: 3,
    caption: 'Kontrol ve Paketleme',
    variant: 'primary',
  },
  {
    id: 4,
    caption: 'Teslime Hazır',
    variant: 'primary',
  },
  {
    id: 5,
    caption: 'Teslim Edildi',
    variant: 'primary',
  },
];
