import { useSelector } from 'react-redux';

const OrderInfo = () => {
  //Redux
  const { order } = useSelector((state) => state.orders.singleOrder);
  const { userPermissions } = useSelector((state) => state.general.user);
  return (
    <>
      <div className="row mx-0 mt-5 mb-3">
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-truck-loading"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Sipariş No</span>
              <span id="id" className="info-box-number">
                {order?.id}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="far fa-question-circle"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text text-dark">Durum</span>
              <span id="orderStatus" className="info-box-number">
                {order?.order_status.name}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-users"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Müşteri Adı</span>
              <span id="customer" className="info-box-number">
                {order.customer && (
                  <a href={'/single-customer?id=' + order.customer.id} className="">
                    {order.customer.name + ' ' + order.customer.lastName}
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-info"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Firma</span>
              <span id="description" className="info-box-number">
                {order?.customer?.company}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-user text-primary"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Yetkili</span>
              <span id="user" className="info-box-number">
                {order?.user?.fullName}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-user text-warning"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Sipariş Sorumlusu</span>
              <span id="orderManager" className="info-box-number">
                {order?.orderManager?.fullName}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="far fa-calendar-alt"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Satış Tarihi</span>
              <span id="saleDate" className="info-box-number">
                {order && new Date(order.saleDate).toLocaleDateString('tr')}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="far fa-calendar-alt"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Tah. Tes. Tarihi</span>
              <span id="deliveryDate" className="info-box-number">
                {order && new Date(order.deliveryDate).toLocaleDateString('tr')}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-truck"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Teslimat</span>
              <span id="total" className="info-box-number">
                {order && order.deliveryOption}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-file-invoice"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Satış Kanalı</span>
              <span id="total" className="info-box-number">
                {order && order.salesChannel}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="info-box mb-3 bg-light">
            <span className="info-box-icon">
              <i className="fas fa-file-invoice"></i>
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Fatura</span>
              <span id="total" className="info-box-number">
                {order && order.bill}
              </span>
            </div>
          </div>
        </div>
        {userPermissions?.includes(8) && (
          <div className="col-md-3">
            <div className="info-box mb-3 bg-light">
              <span className="info-box-icon">
                <i className="fas fa-wallet"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Sipariş Tutarı</span>
                <span id="total" className="info-box-number">
                  {order && order.total.toLocaleString() + ' ₺'}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="row mx-2  rounded border border-dark">
        <p className="w-100 mx-2 p-2 mb-0">
          <strong>Fatura Adresi: </strong>
        </p>
        <p className="font-italic mx-2 p-2">{order && order.billingAddress}</p>
      </div>
    </>
  );
};

export default OrderInfo;
