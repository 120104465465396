import { Table } from 'react-bootstrap';
import {
  useDispatch,
  useSelector,
  //  useDispatch
} from 'react-redux';
import { roundTo2Decimals } from 'utils/calcUtils';
import {
  SET_PRODUCT_VARIANT_TO_DELETE,
  SHOW_PRODUCT_VARIANT_DELETE_MODAL,
  SHOW_EDIT_PRODUCT_VARIANT_MODAL,
} from '../../singleProductSlice';

const VariantsTable = () => {
  //Redux
  const { productVariants } = useSelector((state) => state.products.singleProduct.variants);
  const { userPermissions } = useSelector((state) => state.general.user);
  const dispatch = useDispatch();

  // Functions
  const handleDeleteVariantClick = (variant) => {
    dispatch(SET_PRODUCT_VARIANT_TO_DELETE(variant));
    dispatch(SHOW_PRODUCT_VARIANT_DELETE_MODAL());
  };

  const handleEditVariantClick = (variant) => {
    dispatch(SHOW_EDIT_PRODUCT_VARIANT_MODAL(variant));
  };

  return (
    <div>
      {productVariants.length > 0 ? (
        <Table bordered hover size="sm" variant="light">
          <thead>
            <tr>
              <th style={{ width: 120 }}>Görsel</th>
              <th>Varyant Adı</th>
              <th>Fiyat (KDVsiz)</th>
              <th>Fiyat (KDVli)</th>
              <th>Stok Adedi</th>
              {userPermissions?.includes(22) && <th>İşlem</th>}
            </tr>
          </thead>
          <tbody>
            {productVariants.map((variant) => (
              <tr key={variant.id}>
                <td className="text-center align-middle">
                  {!variant.image_link ? (
                    <img
                      src={'http://placehold.jp/80x80.png'}
                      className="rounded"
                      alt={variant.name}
                      width={80}
                    />
                  ) : (
                    <img
                      src={variant.image_link}
                      className="rounded"
                      alt={variant.name}
                      width={80}
                    />
                  )}
                </td>
                <td className="align-middle"> {variant.name}</td>
                <td className="align-middle">
                  {roundTo2Decimals(variant.price).toLocaleString('tr')} ₺
                </td>
                <td className="align-middle text-danger">
                  <strong> {roundTo2Decimals(variant.price * 1.18).toLocaleString('tr')} ₺</strong>
                </td>
                <td className="align-middle">
                  <span
                    className={`stock-count-indicator font-14 text-light bg-${
                      variant.stock_count == 0
                        ? 'danger'
                        : variant.stock_count < 4
                        ? 'warning text-dark'
                        : 'success'
                    } rounded-circle mx-1`}>
                    {variant.stock_count}
                  </span>
                </td>
                {userPermissions?.includes(22) && (
                  <td className="align-middle">
                    <i
                      className="fas fa-pen text-primary me-2"
                      role="button"
                      onClick={() => handleEditVariantClick(variant)}></i>
                    <i
                      className="fas fa-trash text-danger ms-2"
                      role="button"
                      onClick={() => handleDeleteVariantClick(variant)}></i>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center text-muted">
          Bu ürüne ait <strong>Ürün Varyantı</strong> bulunmamaktadır
        </p>
      )}
    </div>
  );
};

export default VariantsTable;
