import { Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_EDIT_STATUS_MODAL, SET_NEW_STATUS, START_LOADING, STOP_LOADING } from '../userSlice';
import useUserDetails from '../hooks/useUserDetails';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const EditUserStatusModal = () => {
  // Redux
  const { isEditStatusModalOpen, newStatus, userDetails } = useSelector(
    (state) => state.settings.user,
  );
  const dispatch = useDispatch();

  const { getUserDetails } = useUserDetails();

  const handleStatusUpdate = async () => {
    dispatch(START_LOADING());
    try {
      let endpoint = '';
      if (parseInt(newStatus)) {
        endpoint = 'enable';
      } else {
        endpoint = 'disable';
      }
      await AtolyeAPI.post(`/admin/user/${userDetails.id}/${endpoint}`, {
        email: userDetails.email,
      });
      dispatch(HIDE_EDIT_STATUS_MODAL());
      getUserDetails();
    } catch (error) {
      console.log(error);
      alert('error', 'Kullanıcı durumu güncellenirken bir hata oluştu.');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <Modal show={isEditStatusModalOpen} onHide={() => dispatch(HIDE_EDIT_STATUS_MODAL())}>
      <Modal.Header closeButton>
        <Modal.Title>Kullanıcı Durumunu Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-0">
          <span className="font-14">Yeni Durum</span>
          <Form.Select
            value={newStatus}
            onChange={(e) => dispatch(SET_NEW_STATUS(e.target.value))}
            className="form-select-sm mt-2">
            <option value="" disabled>
              Seçiniz
            </option>
            <option value="1">Aktif</option>
            <option value="0">Pasif</option>
          </Form.Select>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light btn-sm" onClick={() => dispatch(HIDE_EDIT_STATUS_MODAL())}>
          <span className="font-14">Vazgeç</span>
        </button>
        <button
          className="btn btn-success btn-sm text-white"
          disabled={parseInt(newStatus) === userDetails.isActive}
          onClick={handleStatusUpdate}>
          <i className="fas fa-check  me-1"></i>
          <span className="font-14">Güncelle</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserStatusModal;
