import './ProductStockManagement.scss';
import { useMemo } from 'react';

import {
  //  Alert,
  AtolyeTable,
  Footer,
  Header,
  // Loading,
  ContentWrapper,
  // DatePicker,
  // ResponsiveLoading,
  PageTitle,
  PageWrapper,
  // Select,
} from '.';

// import AtolyeDatePicker from 'components/AtolyeTable/components/AtolyeDatePicker';

import { useUser } from 'hooks';
import useProductStocks from './useProductStocks';

import { columnsData } from './utils';
// import StockFilter from './components/StockFilter';

const ProductStockManagement = () => {
  // Hooks
  const { user, userPermissions } = useUser();

  const {
    loading,
    pageCount,
    productStocks,
    filters,
    sorting,
    totalRowsCount,
    getProductStocks,
    // exportAllProductStocks,
    setFilters,
    setAreFiltersUpdated,
    // setDateFilter,
    setSorting,
    setIsSortingUpdated,
  } = useProductStocks();

  // Declarations

  const columns = useMemo(() => columnsData(getProductStocks, user?.id), [user]);
  const data = useMemo(() => productStocks, [productStocks]);

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Ürün Stok Yönetimi" />
        <div className="text-center">
          <img width={125} src="/images/stock.png" />
        </div>
        <PageWrapper>
          {!userPermissions?.includes(31) ? (
            <div className="alert alert-danger">
              <strong>Yetkiniz yok!</strong> Stok ve Depo kayıtları ekleme/düzenleme/silme için
              yetkiniz bulunmamaktadır.
            </div>
          ) : (
            <>
              {/* <StockFilter /> */}
              <AtolyeTable
                columns={columns}
                data={data}
                fetchData={getProductStocks}
                filters={filters}
                setFilters={setFilters}
                setAreFiltersUpdated={setAreFiltersUpdated}
                sorting={sorting}
                setSorting={setSorting}
                setIsSortingUpdated={setIsSortingUpdated}
                loading={loading}
                pageCount={pageCount}
                total={totalRowsCount}
              />
            </>
          )}
        </PageWrapper>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default ProductStockManagement;
