import { Button, Form, Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  ADD_NEW_OPTION_VALUE,
  DELETE_LAST_NEW_OPTION_VALUE,
  HIDE_NEW_OPTION_BOX,
  RESET_NEW_OPTION,
  SET_NEW_OPTION_NAME,
  SET_NEW_OPTION_VALUE,
  SHOW_NEW_OPTION_BOX,
  START_LOADING,
  STOP_LOADING,
} from '../../singleProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const NewVariantOptionModal = ({ getProduct }) => {
  //Redux
  const { productCode } = useSelector((state) => state.products.singleProduct);
  const { isNewOptionBoxDisplayed } = useSelector((state) => state.products.singleProduct.variants);
  const { name, values } = useSelector((state) => state.products.singleProduct.variants.newOption);
  const dispatch = useDispatch();

  // Functions
  const handleDismiss = () => {
    dispatch(RESET_NEW_OPTION());
    dispatch(HIDE_NEW_OPTION_BOX());
  };

  const handleShow = () => {
    dispatch(SHOW_NEW_OPTION_BOX());
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    dispatch(START_LOADING());

    const body = { name, values, products_id: productCode };

    try {
      const { status } = await AtolyeAPI.post(`/product_variants/options`, body);
      if (status === 'SUCCESS') {
        await getProduct();
        return alert('success', 'Variant Seçeneği başarıyla oluşturuldu');
      }
      throw new Error('Seçenek oluşturulurken bir hata oluştu');
    } catch (error) {
      console.log({ error });
      return alert('error', 'Seçenek oluşturulurken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(RESET_NEW_OPTION());
      dispatch(HIDE_NEW_OPTION_BOX());
    }
  };

  const validateForm = () => {
    if (!name) {
      alert('error', 'Seçenek adı boş bırakılamaz!');
      return false;
    }
    if (values.includes('')) {
      alert('error', 'Seçenek Değerlerinin tamamı doldurulmalıdır!');
      return false;
    }
    return true;
  };

  return (
    <div className="text-center">
      <Button variant="outline-primary" className="btn-sm my-2" onClick={handleShow}>
        + Yeni Seçenek Ekle
      </Button>
      <Modal
        show={isNewOptionBoxDisplayed}
        onHide={handleDismiss}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-success" closeButton>
          <Modal.Title>Varyant Seçeneği Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-3">
            <Row>
              <Form.Label>Seçenek Adı</Form.Label>
              <Form.Control
                style={{ maxWidth: 300, fontSize: 14 }}
                type="text"
                placeholder="Ör: Renk"
                value={name}
                onChange={(e) => dispatch(SET_NEW_OPTION_NAME(e.target.value))}
              />
            </Row>
            <Row className="mt-4 mb-2">
              <Form.Label>Değerler</Form.Label>
              {values.map((value, index) => (
                <Row key={`value-${index}`}>
                  <Form.Control
                    className="my-1"
                    style={{ maxWidth: 300, fontSize: 14 }}
                    type="text"
                    placeholder="Ör: Beyaz"
                    value={value}
                    onChange={(e) =>
                      dispatch(SET_NEW_OPTION_VALUE({ index, value: e.target.value }))
                    }
                  />
                  {index + 1 === values.length && <div className="text-end"></div>}
                </Row>
              ))}
            </Row>
            <span
              className={values[values.length - 1] !== '' ? `text-primary` : 'text-muted'}
              role={'button'}
              onClick={() => dispatch(ADD_NEW_OPTION_VALUE())}>
              + Yeni Değer
            </span>
            <span
              className={values.length > 1 ? `text-danger ms-4` : 'text-muted ms-4'}
              role={'button'}
              onClick={() => dispatch(DELETE_LAST_NEW_OPTION_VALUE())}>
              - Son Değeri Sil
            </span>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleDismiss}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={handleSave}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewVariantOptionModal;
