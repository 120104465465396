import { Select } from 'pages/Settings';
import React, { useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import useComponentOptions from '../hooks/useComponentOptions';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const AddComponentOnProductModal = ({
  show,
  onHide,
  getManufactureProduct,
  manufactureProduct,
}) => {
  // State
  const [component, setComponent] = useState(null);
  const [quantity, setQuantity] = useState(1);

  // Hooks
  const { componentOptions, isComponentOptionsLoading } = useComponentOptions();

  const handleAdd = async () => {
    const body = {
      manufacture_products_id: manufactureProduct.id,
      components_id: component.value,
      quantity,
    };

    try {
      await AtolyeAPI.post(`/manufacture_products/components`, body);
      alert('success', 'Parça Eklendi');
      getManufactureProduct(body.manufacture_products_id);
    } catch (error) {
      console.log(error);
      alert('error', 'Parça Eklenemedi');
    } finally {
      onHide();
      setComponent(null);
      setQuantity(1);
    }
  };

  const handleOnHide = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={handleOnHide}>
      <Modal.Header closeButton>
        <Modal.Title>Yeni Parça Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="info">
          Parçalar görselleri ile beraber aşağıda listelenmektedir. Ürüne eklemek istediğiniz
          parçayı seçiniz.
        </Alert>
        {component && (
          <div className="py-4 text-center">
            <img src={component.imageLink} alt={component.label} style={{ width: 150 }} />
          </div>
        )}
        <Select
          className="text-dark mx-2"
          components={{
            Option: ({ data, innerProps, innerRef }) => {
              return (
                <div ref={innerRef} {...innerProps}>
                  <img
                    src={data.imageLink}
                    alt={data.label}
                    style={{ width: 80, marginRight: 10 }}
                  />
                  {data.label}
                  <hr className="my-1" />
                </div>
              );
            },
          }}
          options={componentOptions}
          isLoading={isComponentOptionsLoading}
          //   onInputChange={(value) => setSearch(value)}
          onChange={(option) => {
            setComponent(option);
          }}
          value={component || ''}
          placeholder="Parça kodu girin"
        />

        {component && (
          <InputGroup size="sm" className="mt-3 mx-2">
            <InputGroup.Text>Parça Adedi</InputGroup.Text>
            <Form.Control
              style={{ maxWidth: 65 }}
              type="number"
              placeholder="Parça Adedi"
              value={quantity}
              onChange={(e) => setQuantity(parseFloat(e.target.value))}
              min={0}
            />
          </InputGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnHide}>
          Vazgeç
        </Button>
        <Button variant="success" disabled={!component} onClick={handleAdd}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddComponentOnProductModal;
