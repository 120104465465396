import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const StockCountEditModal = ({ data, onSave, userId }) => {
  // Redux
  const { pageIndex, pageSize } = useSelector((state) => state.atolyeTable);

  // States
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [stockCount, setStockCount] = useState(0);
  const [isLoading, setIsLoading] = useState(0);

  const handleDismiss = () => {
    setStockCount(parseInt(data.values.stock_count));
    setIsModalDisplayed(false);
  };

  const handleShow = () => {
    setStockCount(parseInt(data.values.stock_count));
    setIsModalDisplayed(true);
  };

  const updateStockCount = async () => {
    setIsLoading(true);

    const body = {
      stock_count: stockCount,
      updatedBy: userId,
    };

    try {
      if (data.values.isVariant) {
        await AtolyeAPI.put(`/product_stocks/variant/${data.values.variantId}`, body);
      } else {
        await AtolyeAPI.put(`/product_stocks/${data.values.id}`, body);
      }

      onSave(pageIndex, pageSize);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleDismiss();
    }
  };

  return (
    <div>
      {!data.original?.subRows && (
        <i
          style={{ left: 45, bottom: 12, color: '#859fc7' }}
          className="fas fa-pen position-absolute"
          role="button"
          onClick={handleShow}></i>
      )}

      <Modal
        show={isModalDisplayed}
        onHide={handleDismiss}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-dark" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Stok Adedini Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={data.values.imageLink}
            className="rounded"
            alt=""
            width={100}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = data.values.imageLink || 'http://placehold.jp/100x100.png';
            }}
          />
          <h5 className="mt-4">
            {data.values.id} - {data.values.description}
          </h5>
          {data.values.stock_count > 0 ? (
            <p>
              * Bu üründen stokta şu an{' '}
              <strong className="text-white bg-success rounded p-1">
                {data.values.stock_count}
              </strong>{' '}
              adet bulunmaktadır.
            </p>
          ) : (
            <p className="text-danger">Bu ürün stokta bulunmamaktadır!!!</p>
          )}
          <hr />

          {isLoading ? (
            'Güncelleniyor'
          ) : (
            <div className="text-center mt-4">
              <h4 className="text-danger">Stok Adedi</h4>
              <div className="d-flex justify-content-center align-content-center">
                <Button
                  variant="primary"
                  className="rounded-circle"
                  onClick={() => setStockCount(parseInt(stockCount) + 1)}>
                  +
                </Button>
                <Form.Control
                  style={{ maxWidth: 100 }}
                  className="font-16 d-inline-block mx-2"
                  type="number"
                  min={0}
                  value={stockCount}
                  onChange={(e) => setStockCount(parseInt(e.target.value))}
                />
                <Button
                  variant="light"
                  className="rounded-circle"
                  onClick={() =>
                    setStockCount(parseInt(stockCount) - 1 > -1 ? parseInt(stockCount) - 1 : 0)
                  }>
                  -
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleDismiss}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={updateStockCount}>
            Güncelle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StockCountEditModal;
