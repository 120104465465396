import { Button } from 'react-bootstrap';
import AtolyeTable from '../../../components/AtolyeTable';
import ContentWrapper from '../../../components/shared/ContentWrapper';
import Footer from '../../../components/shared/Footer/Footer';
import Header from '../../../components/shared/Header';
import PageTitle from '../../../components/shared/PageTitle';
import PageWrapper from '../../../components/shared/PageWrapper';

import { useProductionList, useUser } from '../../../hooks';

import ProductionList from './ProductionList';
import ProductionListFilter from './components/ProductionListFilter';
import ManufacturerFilter from './components/ManufacturerFilter';

export default ProductionList;
export {
  AtolyeTable,
  Button,
  ContentWrapper,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
  ProductionListFilter,
  ManufacturerFilter,
  useProductionList,
  useUser,
};
