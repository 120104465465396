import {
  CustomerNameLink,
  CustomerLastNameLink,
  DateCell,
  PriceOfferLink,
  TotalCell,
} from 'components/AtolyeTable/components/customCellComponents';

export const columnsData = [
  {
    Header: 'FİYAT TEKLİFİ NO',
    accessor: 'id',
    columnName: 'id',
    Cell: PriceOfferLink,
  },

  {
    Header: 'MÜŞTERİ ADI',
    accessor: 'customer.name',
    columnName: 'customer.name',
    Cell: CustomerNameLink,
    disableSortBy: true,
    // disableFilters: true
  },

  {
    Header: 'MÜŞTERİ SOYADI',
    accessor: 'customer.lastName',
    columnName: 'customer.lastName',
    Cell: CustomerLastNameLink,
    disableSortBy: true,
    // disableFilters: true
  },
  {
    Header: 'TARİH',
    accessor: 'createdAt',
    columnName: 'createdAt',
    disableFilters: true,
    Cell: DateCell,
  },
  {
    Header: 'YETKİLİ',
    accessor: 'createdBy_user.fullName',
    columnName: 'createdBy_user.fullName',
    disableSortBy: true,
    // disableFilters: true
  },
  {
    Header: 'TOPLAM TUTAR',
    accessor: 'total',
    columnName: 'total',
    Cell: TotalCell,
    disableFilters: true,
  },
];
