import { createSlice } from '@reduxjs/toolkit';

// Create enum for authStatus
export const authStatusEnum = {
  SignIn: 'SignIn',
  NewPassRequired: 'NewPassRequired',
  ForgotPassword: 'ForgotPassword',
  ResetPassword: 'ResetPassword',
  LoggedIn: 'LoggedIn',
};

const initialState = {
  isMenuOpen: false,
  isAuthLoading: true,
  authStatus: authStatusEnum.SignIn,
  user: {
    userName: '',
    userLastName: '',
    userFullName: '',
    userEmail: '',
    userRole: null,
    userId: null,
    userPermissions: null,
    cognitoAttributes: null,
  },
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    MENU_TOGGLE: (state) => {
      return { ...state, isMenuOpen: !state.isMenuOpen };
    },
    SET_USER: (state, action) => {
      return { ...state, user: action.payload };
    },
    CLEAR_USER: (state) => {
      return { ...state, user: initialState.user };
    },
    SET_AUTH_STATUS: (state, action) => {
      return { ...state, authStatus: action.payload };
    },
    SET_IS_AUTH_LOADING: (state, action) => {
      return { ...state, isAuthLoading: action.payload };
    },
    RESET: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { MENU_TOGGLE, SET_USER, CLEAR_USER, SET_AUTH_STATUS, SET_IS_AUTH_LOADING, RESET } =
  generalSlice.actions;

export default generalSlice.reducer;
