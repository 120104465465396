import useProductOptions from 'hooks/useProductOptions';
import useTags from 'hooks/useTags';
import alert from 'js/sweetAlert';
import { useState, useEffect } from 'react';
import { Modal, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const AddTagOnProductsModal = ({ update }) => {
  //State
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const [selectedTagProducts, setSelectedTagProducts] = useState([]);
  const [productsToRemove, setProductsToRemove] = useState([]);
  const [productToAdd, setProductToAdd] = useState(null);

  // Hooks
  const { tags, tagOptions, isTagsLoading, getTags } = useTags();
  const { isProductOptionsLoading, productOptions } = useProductOptions();

  // Effects
  useEffect(() => {
    if (selectedTag) {
      setProductsToRemove([]);
      const oldTags = tags.find((tag) => tag.id == selectedTag)?.productsWithTag;
      setSelectedTagProducts(
        oldTags.map((item) => ({ id: item.id, description: item.description, status: 'old' })),
      );
    }
  }, [selectedTag]);

  //Functions
  const addTagOnProductsHandler = async () => {
    setIsLoading(true);

    const body = {
      productsToAdd: selectedTagProducts.filter((item) => item.status !== 'old'),
      productsToRemove,
    };

    try {
      const { status } = await AtolyeAPI.put(`/tags/${selectedTag}/products`, body);
      if (status === 'SUCCESS') {
        onDismiss();
        update(0, 10);
        getTags();
        return alert('success', 'Ürün etiketleri güncellendi');
      }
      throw new Error('Ürün etiketleri güncellenirken bir sorun oluştu');
    } catch (error) {
      console.log(error);
      alert('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addProductOnSelectedTagsHandler = () => {
    if (!productToAdd) {
      return alert('error', 'Lütfen bir ürün seçiniz');
    }

    if (selectedTagProducts.find((item) => item.id == productToAdd.value)) {
      return alert('error', 'Bu ürün daha önce eklenmiş, tekrar eklenemez');
    }

    setSelectedTagProducts([
      ...selectedTagProducts,
      { id: productToAdd.value, description: productToAdd.label, status: 'new' },
    ]);
    setProductToAdd(null);
  };

  const removeProductOnSelectedTagProductsHandler = (product) => {
    if (product.status === 'new') {
      return setSelectedTagProducts(selectedTagProducts.filter((item) => item.id !== product.id));
    }
    !productsToRemove.find((item) => item.id === product.id) &&
      setProductsToRemove([...productsToRemove, product]);
    setSelectedTagProducts(selectedTagProducts.filter((item) => item.id !== product.id));
  };

  const onDismiss = () => {
    setIsLoading(false);
    setSelectedTag('');
    setSelectedTagProducts([]);
    setProductsToRemove([]);
    setProductToAdd(null);
    setShow(false);
  };
  return (
    <>
      <div className="">
        <Button variant="primary" size="sm" onClick={() => setShow(true)}>
          <i className="fas fa-edit"></i> Toplu Ürün Etiketi Düzenle
        </Button>
      </div>
      <Modal show={show} fullscreen onHide={onDismiss}>
        <Modal.Header className="bg-dark" closeButton>
          <Modal.Title>Toplu Etiket Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Form.Group className="mb-3">
            <Form.Label>Etiket Seçin</Form.Label>
            {isTagsLoading ? (
              'Yükleniyor...'
            ) : (
              <div style={{ maxWidth: 600 }} className="select-wrapper ">
                <Form.Select value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
                  <option label="Seçiniz" value={''} disabled />
                  {tagOptions?.map((option, i) => (
                    <option key={`option-${i}`} label={option.label} value={option.value} />
                  ))}
                </Form.Select>
              </div>
            )}

            <Form.Text className="text-muted">İşlem yapmak istediğiniz etiketi seçiniz.</Form.Text>
          </Form.Group>

          {tagOptions?.find((item) => item.value == selectedTag) && (
            <>
              <div style={{ maxWidth: 600 }}>
                <Form.Label>Ürün Ekle</Form.Label>
                <Select
                  className="text-dark mb-2"
                  id="input-product"
                  options={productOptions}
                  isLoading={isProductOptionsLoading}
                  onChange={({ value }) => {
                    setProductToAdd(productOptions.find((item) => item.value === value));
                  }}
                  value={productToAdd}
                  placeholder="Ürün Kodu veya Açıklama girin"
                />
              </div>
              <Button
                className="mx-auto"
                variant="primary"
                size="sm"
                onClick={addProductOnSelectedTagsHandler}>
                Ekle
              </Button>

              <Row className="my-4">
                <Col md={6}>
                  <div className="mt-3 text-success mb-2">
                    <Form.Label>
                      &quot;{tagOptions?.find((item) => item.value == selectedTag)?.label}&quot;
                      <span className="text-dark ">
                        {' '}
                        Etiketi Eklenecek Ürünler ({selectedTagProducts.length})
                      </span>
                    </Form.Label>
                  </div>
                  <div
                    style={{ height: 200 }}
                    className="mb-3 p-2 border overflow-scroll bg-white d-flex flex-wrap justify-content-start align-content-start">
                    {selectedTagProducts?.map((product) => (
                      <div
                        style={{ height: 30 }}
                        key={product.id}
                        className="position-relative my-1 mx-2 border d-inline-block rounded bg-atolye shadow-sm font-12 p-1">
                        <strong>{product.id}</strong> - {product.description}
                        <i
                          style={{ top: -10, right: -8 }}
                          className="fas fa-times position-absolute bg-danger text-white rounded p-1 font-10"
                          role="button"
                          onClick={() => removeProductOnSelectedTagProductsHandler(product)}></i>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mt-3 text-danger mb-2">
                    <Form.Label>
                      &quot;{tagOptions?.find((item) => item.value == selectedTag)?.label}&quot;
                      <span className="text-dark ">
                        {' '}
                        Etiketi Kaldırılacak Ürünler ({productsToRemove.length})
                      </span>
                    </Form.Label>
                  </div>
                  <div
                    style={{ minHeight: 200 }}
                    className="mb-3 p-2 border overflow-scroll bg-white d-flex flex-wrap justify-content-start align-content-start">
                    {productsToRemove?.map((product) => (
                      <div
                        style={{ height: 30 }}
                        key={product.id}
                        className="position-relative my-1 mx-2 border d-inline-block rounded bg-atolye shadow-sm font-12 p-1">
                        <strong>{product.id}</strong> - {product.description}
                        <i
                          style={{ top: -10, right: -8 }}
                          className="fas fa-undo position-absolute bg-primary text-white rounded p-1 font-10"
                          role="button"
                          onClick={() => {
                            setProductsToRemove(
                              productsToRemove.filter((item) => item.id !== product.id),
                            );

                            !selectedTagProducts.find((item) => item.id === product.id) &&
                              setSelectedTagProducts([...selectedTagProducts, product]);
                          }}></i>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onDismiss}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={addTagOnProductsHandler}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              'Kaydet'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTagOnProductsModal;
