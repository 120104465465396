import '../Authentication.scss';

import { SET_AUTH_STATUS, SET_USER, authStatusEnum } from 'app/state/generalSlice';
import { Auth } from 'aws-amplify';
import alert from 'js/sweetAlert';
import { useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const ForgotPassword = () => {
  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.general);

  // State
  const [username, setUsername] = useState('');

  // Functions
  const handleForgotPassword = () => {
    Auth.forgotPassword(username)
      .then(() => {
        dispatch(SET_AUTH_STATUS(authStatusEnum.ResetPassword));
        dispatch(
          SET_USER({
            ...user,
            userName: username,
          }),
        );
      })
      .catch((err) => {
        console.log(err);
        alert('error', err.message);
      });
  };

  const goToSignIn = () => {
    dispatch(SET_AUTH_STATUS(authStatusEnum.SignIn));
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo bg-dark p-2 rounded">
          <img
            src="https://cdn.shopify.com/s/files/1/0605/8056/1151/files/atolyestore_190x.png?v=1645870921"
            height="60"
            alt=""
          />
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">
              Şifrenizi mi unuttunuz? Buradan kolaylıkla şifrenizi alabilirsiniz.
            </p>

            <FormGroup>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Kayıtlı e-posta adresiniz"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Button className="btn btn-primary btn-block mt-3" onClick={handleForgotPassword}>
                Yeni şifre talep edin
              </Button>
            </FormGroup>

            <p className="mt-3 mb-1">
              <button type="button" className="link-btn" onClick={goToSignIn}>
                <i className="fas fa-chevron-left"></i> Giriş
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
