import React from 'react';

const DepotTitle = ({ title }) => {
  return (
    <div className="p-3">
      <div className="text-center p-3">
        <div>
          <img src="/images/warehouse.png" alt="depot" width={100} height={100} />
        </div>
        <div>
          <strong className="text-danger font-18">{title}</strong>
        </div>
      </div>
    </div>
  );
};

export default DepotTitle;
