import { useSelector } from 'react-redux';

import { Form, Select } from '..';

const PriceOfferSelect = ({ options, isLoading, onChange, placeholder }) => {
  // Redux
  const { priceOffer } = useSelector((state) => state.priceOffers.singlePriceOffer);

  return (
    <Form.Group className="col-md-6 mx-auto mb-5 mt-4">
      <Form.Label>Fiyat Teklifi: </Form.Label>
      <Select
        options={options}
        isLoading={isLoading}
        onChange={onChange}
        placeholder={placeholder}
        value={options?.find((option) => option.value == priceOffer?.id) || ''}
      />
    </Form.Group>
  );
};

export default PriceOfferSelect;
