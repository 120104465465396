import './Products.scss';

import { useMemo } from 'react';
import { columnsData } from './utils';

import {
  AtolyeTable,
  ContentWrapper,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
  useProducts,
  useUser,
} from '.';

import { Link } from 'react-router-dom';
import TagFilter from './components/TagFilter';
import AddTagOnProductsModal from './components/AddTagOnProductsModal';
import BulkEditPriceModal from './components/BulkEditPricesModal';
import { Alert } from 'react-bootstrap';

const Products = (props) => {
  // Hooks
  const {
    loading,
    pageCount,
    products,
    filters,
    tagFilter,
    sorting,
    totalRowsCount,
    getProducts,
    setFilters,
    setSorting,
    setAreFiltersUpdated,
    setIsSortingUpdated,
    setTagFilter,
  } = useProducts();
  const { user, userPermissions } = useUser();

  // Declarations

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => products, [products]);

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Ürünler" />
        {!userPermissions?.includes(23) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> Ürünleri görüntülemek için yetkiniz bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            <div className="my-4 d-flex flex-wrap align-content-center pr-4">
              <div className="me-auto d-flex">
                {userPermissions?.includes(24) && <AddTagOnProductsModal update={getProducts} />}
                {userPermissions?.includes(25) && <BulkEditPriceModal update={getProducts} />}
              </div>

              {userPermissions?.includes(6) && (
                <Link to="/create-product" className="text-success ms-auto">
                  <strong>+ Yeni Ürün</strong>
                </Link>
              )}
            </div>
            <TagFilter tagFilter={tagFilter} setTagFilter={setTagFilter} />
            <AtolyeTable
              columns={columns}
              data={data}
              fetchData={getProducts}
              filters={filters}
              tagFilter={tagFilter}
              setFilters={setFilters}
              setAreFiltersUpdated={setAreFiltersUpdated}
              sorting={sorting}
              setSorting={setSorting}
              setIsSortingUpdated={setIsSortingUpdated}
              loading={loading}
              pageCount={pageCount}
              total={totalRowsCount}
            />
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default Products;
