import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isError: false,
  isDeleteModalOpen: false,
  isEditStatusModalOpen: false,
  isResetPasswordModalOpen: false,
  isNewRoleModalOpen: false,
  userDetails: {
    id: '',
    name: '',
    lastName: '',
    fullName: '',
    email: '',
    createdAt: '',
    updatedAt: '',
    role: '',
    roles_id: '',
    isActive: false,
  },
  userPermissions: [],
  currentPermissions: [],
  permissionsToAdd: [],
  permissionsToRemove: [],
  newRole: '',
  newStatus: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    START_LOADING: (state) => {
      return { ...state, isLoading: true };
    },
    STOP_LOADING: (state) => {
      return { ...state, isLoading: false };
    },

    SET_ERROR: (state) => {
      return { ...state, isError: true };
    },
    CLEAR_ERROR: (state) => {
      return { ...state, isError: false };
    },
    SHOW_DELETE_MODAL: (state) => {
      return { ...state, isDeleteModalOpen: true };
    },
    HIDE_DELETE_MODAL: (state) => {
      return { ...state, isDeleteModalOpen: false };
    },
    SHOW_EDIT_STATUS_MODAL: (state) => {
      return { ...state, isEditStatusModalOpen: true, newStatus: state.userDetails.isActive };
    },
    HIDE_EDIT_STATUS_MODAL: (state) => {
      return { ...state, isEditStatusModalOpen: false, newStatus: '' };
    },
    SHOW_NEW_ROLE_MODAL: (state) => {
      return { ...state, isNewRoleModalOpen: true, newRole: state.userDetails.roles_id };
    },
    HIDE_NEW_ROLE_MODAL: (state) => {
      return { ...state, isNewRoleModalOpen: false, newRole: '' };
    },
    SHOW_RESET_PASSWORD_MODAL: (state) => {
      return { ...state, isResetPasswordModalOpen: true };
    },
    HIDE_RESET_PASSWORD_MODAL: (state) => {
      return { ...state, isResetPasswordModalOpen: false };
    },
    SET_USER_DETAILS: (state, action) => {
      return { ...state, userDetails: action.payload };
    },
    SET_USER_PERMISSIONS: (state, action) => {
      return { ...state, userPermissions: action.payload, currentPermissions: action.payload };
    },
    ADD_PERMISSION: (state, action) => {
      // If permission is already added, do nothing
      if (state.currentPermissions.includes(action.payload)) return state;

      // If permission is already removed, remove it from permissionsToRemove
      if (state.permissionsToRemove.includes(action.payload)) {
        return {
          ...state,
          permissionsToAdd:
            // If permission is in already user permissions, do nothing, otherwise add it to permissionsToAdd
            state.userPermissions?.includes(action.payload)
              ? state.permissionsToAdd
              : [...state.permissionsToAdd, action.payload],
          permissionsToRemove: state.permissionsToRemove.filter((item) => item !== action.payload),
          currentPermissions: [...state.currentPermissions, action.payload],
        };
      }

      // If permission is not in user permissions, add it to permissionsToAdd and currentPermissions
      if (!state.userPermissions?.includes(action.payload)) {
        return {
          ...state,
          permissionsToAdd: [...state.permissionsToAdd, action.payload],
          currentPermissions: [...state.currentPermissions, action.payload],
        };
      }
    },
    REMOVE_PERMISSION: (state, action) => {
      // If permission is already removed, do nothing
      if (state.permissionsToRemove.includes(action.payload)) return state;

      // If permission is already added, remove it from permissionsToAdd
      if (state.permissionsToAdd.includes(action.payload)) {
        return {
          ...state,
          permissionsToAdd: state.permissionsToAdd.filter((item) => item !== action.payload),
          permissionsToRemove:
            // If permission is not in user permissions, do nothing, otherwise add it to permissionsToRemove
            !state.userPermissions?.includes(action.payload)
              ? state.permissionsToRemove
              : [...state.permissionsToRemove, action.payload],
          currentPermissions: state.currentPermissions.filter((item) => item !== action.payload),
        };
      }

      // If permission is in user permissions, add it to permissionsToRemove and remove it from currentPermissions
      if (state.userPermissions?.includes(action.payload)) {
        return {
          ...state,
          permissionsToRemove: [...state.permissionsToRemove, action.payload],
          currentPermissions: state.currentPermissions.filter((item) => item !== action.payload),
        };
      }
    },

    SET_NEW_ROLE: (state, action) => {
      return { ...state, newRole: action.payload };
    },

    SET_NEW_STATUS: (state, action) => {
      return { ...state, newStatus: action.payload };
    },

    RESET_CURRENT_PERMISSIONS: (state) => {
      return {
        ...state,
        permissionsToAdd: [],
        permissionsToRemove: [],
        currentPermissions: state.userPermissions,
      };
    },

    RESET: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  START_LOADING,
  STOP_LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  RESET,
  SET_USER_DETAILS,
  SET_USER_PERMISSIONS,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  RESET_CURRENT_PERMISSIONS,
  SET_NEW_ROLE,
  SET_NEW_STATUS,
  SHOW_DELETE_MODAL,
  HIDE_DELETE_MODAL,
  SHOW_EDIT_STATUS_MODAL,
  HIDE_EDIT_STATUS_MODAL,
  SHOW_NEW_ROLE_MODAL,
  HIDE_NEW_ROLE_MODAL,
  SHOW_RESET_PASSWORD_MODAL,
  HIDE_RESET_PASSWORD_MODAL,
} = userSlice.actions;

export default userSlice.reducer;
