import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import {
  HIDE_PRODUCTION_EDIT_MODAL,
  SET_PRODUCTION_DETAILS_FOR_UPDATE,
  CLEAR_ERROR,
  START_LOADING,
  STOP_LOADING,
  RESET_PRODUCTION_DETAILS_FOR_UPDATE,
} from '../singlePriceOfferSlice';

import { useDispatch, useSelector } from 'react-redux';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const ProductionEditModal = ({ fetchPriceOffer }) => {
  // Redux
  const dispatch = useDispatch();
  const { priceOffer, isProductionStatusEditModalDisplayed, productionIdToUpdate } = useSelector(
    (state) => state.priceOffers.singlePriceOffer,
  );

  const { priceOffers_id, productsTotal, discountTotal, subTotal, KDVtotal, total } = useSelector(
    (state) => state.priceOffers.singlePriceOffer.recalculatedPriceOffer,
  );

  const { price, quantity, material, glass, branch, lampholder, features, color } = useSelector(
    (state) => state.priceOffers.singlePriceOffer.productionDetailsForUpdate,
  );

  // Functions

  const updateProductionStatus = async () => {
    const isPriceValidated = price || price === 0;
    const isQuantityValidated = quantity && quantity > 0;
    const isValidated = isPriceValidated && isQuantityValidated;

    if (!isValidated) {
      return alert('error', 'Lütfen ürün fiyatı ve adedini doğru girdiğinize emin olunuz...');
    }

    dispatch(CLEAR_ERROR());
    dispatch(START_LOADING());

    const body = {
      price,
      quantity,
      total: price * quantity,
      material,
      glass,
      branch,
      lampholder,
      features,
      color,
      priceOfferFinancialDetails: {
        price_offers_id: priceOffers_id,
        productsTotal,
        discountTotal,
        subTotal,
        KDVtotal,
        total,
      },
    };

    try {
      await AtolyeAPI.put(`/price_offer_products/${productionIdToUpdate}`, body);
      fetchPriceOffer(priceOffer.id);
      alert('success', 'Ürün detayları güncellendi');
    } catch (error) {
      alert('error', 'Ürün detayları güncellenirken hata oluştu');
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
      dispatch(RESET_PRODUCTION_DETAILS_FOR_UPDATE());
      dispatch(HIDE_PRODUCTION_EDIT_MODAL());
    }
  };

  return (
    <Modal
      className="production-details-edit-modal"
      centered
      show={isProductionStatusEditModalDisplayed}
      onHide={() => dispatch(HIDE_PRODUCTION_EDIT_MODAL())}>
      <Modal.Header closeButton>
        <Modal.Title>Üretim Detaylarını Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="my-2">
          <Col>
            <Form.Label>Ürün Fiyatı</Form.Label>
            <FormControl
              type="number"
              defaultValue={(price * 1.18).toFixed(2)}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCTION_DETAILS_FOR_UPDATE({
                    key: 'price',
                    value: parseFloat(e.target.value / 1.18),
                  }),
                )
              }
            />
          </Col>
          <Col>
            <Form.Label>Ürün Adedi</Form.Label>
            <FormControl
              type="number"
              value={quantity}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCTION_DETAILS_FOR_UPDATE({
                    key: 'quantity',
                    value: parseInt(e.target.value),
                  }),
                )
              }
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col>
            <Form.Label>Materyal</Form.Label>
            <FormControl
              value={material}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'material', value: e.target.value }),
                )
              }
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col>
            <Form.Label>Cam</Form.Label>
            <FormControl
              value={glass}
              onChange={(e) =>
                dispatch(SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'glass', value: e.target.value }))
              }
            />
          </Col>
          <Col>
            <Form.Label>Kol Sayısı</Form.Label>
            <FormControl
              value={branch}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'branch', value: e.target.value }),
                )
              }
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col>
            <Form.Label>Duy Tipi</Form.Label>
            <FormControl
              value={lampholder}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'lampholder', value: e.target.value }),
                )
              }
            />
          </Col>
          <Col>
            <Form.Label>Renk</Form.Label>
            <FormControl
              value={color}
              onChange={(e) =>
                dispatch(SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'color', value: e.target.value }))
              }
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <Form.Label>Ürün Özellikleri</Form.Label>
            <FormControl
              as="textarea"
              rows={5}
              value={features}
              onChange={(e) =>
                dispatch(
                  SET_PRODUCTION_DETAILS_FOR_UPDATE({ key: 'features', value: e.target.value }),
                )
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => dispatch(HIDE_PRODUCTION_EDIT_MODAL())}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={updateProductionStatus}>
          Güncelle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductionEditModal;
