import Products from './Products';

import { Button } from 'react-bootstrap';
import AtolyeTable from '../../../components/AtolyeTable';
import ContentWrapper from '../../../components/shared/ContentWrapper';
import Footer from '../../../components/shared/Footer/Footer';
import Header from '../../../components/shared/Header';
import PageTitle from '../../../components/shared/PageTitle';
import PageWrapper from '../../../components/shared/PageWrapper';

import { useProducts, useUser } from '../../../hooks';

export default Products;

export {
  AtolyeTable,
  Button,
  ContentWrapper,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
  useProducts,
  useUser,
};
