import alert from 'js/sweetAlert';
import {
  SET_USER_DETAILS,
  SET_USER_PERMISSIONS,
  START_LOADING,
  STOP_LOADING,
} from 'pages/Settings/User/userSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useUserDetails = () => {
  // Params
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  // Redux
  const dispatch = useDispatch();

  const getUserDetails = async () => {
    dispatch(START_LOADING());
    try {
      const { results } = await AtolyeAPI.get('/admin/user/' + id);
      const { permissions } = results;

      dispatch(
        SET_USER_DETAILS({
          id: results.id,
          name: results.name,
          lastName: results.lastName,
          email: results.email,
          createdAt: results.createdAt,
          updatedAt: results.updatedAt,
          role: results.role.name,
          roles_id: results.roles_id,
          isActive: results.isActive,
        }),
      );
      dispatch(SET_USER_PERMISSIONS(permissions.map((item) => item.id)));
    } catch (error) {
      console.log(error);
      alert('error', 'Kullanıcı detayları getirilirken bir hata oluştu.');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  // Effects

  useEffect(() => {
    id && getUserDetails();
  }, [id]);

  return {
    getUserDetails,
  };
};

export default useUserDetails;
