import { useDispatch, useSelector } from 'react-redux';
import {
  SET_PRICE_OFFER_ID,
  // SET_PRODUCTS,
  SET_CUSTOMER,
  SET_DISCOUNT_RATE,
  RESET,
  ADD_PRODUCT_FROM_PRICE_OFFER,
  SET_ORDER_DESCRIPTION,
} from '../createOrderSlice';
import { Select } from '..';
import usePriceOfferOptions from 'hooks/usePriceOfferOptions';
import { START_LOADING, STOP_LOADING } from '../createOrderSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const FromPriceOffer = ({ title, customerOptions }) => {
  //Redux
  const { isLoading, order } = useSelector((state) => state.orders.createOrder);
  const dispatch = useDispatch();

  // Hooks
  const { isPriceOfferOptionsLoading, priceOfferOptions } = usePriceOfferOptions();

  //Functions
  const priceOfferOnChangeHandler = ({ value }) => {
    dispatch(SET_PRICE_OFFER_ID(value));
    getPriceOfferDetails(value);
  };

  const getPriceOfferDetails = async (id) => {
    dispatch(START_LOADING());
    try {
      const { results } = await AtolyeAPI.get(`/price_offers/for-create-order/${id}`);

      const products = results.products.map((item) => item.productDetails);

      dispatch(RESET());
      const priceOfferId = results.id;

      dispatch(SET_PRICE_OFFER_ID(priceOfferId));
      dispatch(SET_ORDER_DESCRIPTION(results.description));

      for (let index = 0; index < products.length; index++) {
        dispatch(
          ADD_PRODUCT_FROM_PRICE_OFFER({
            ...products[index],
            price: products[index].salePrice.toFixed(2),
            selectedVariant: {
              id: null,
              price: products[index].basePrice,
              imageLink: products[index].baseImageLink,
              stock_count: products[index].stocks[0].stock,
            },
            basePrice: products[index].salePrice.toFixed(2),
            baseImageLink: products[index].imageLink,
            isGivenByStock: false,
            quantity: products[index].quantity,
            glass: products[index].glass,
            lampholder: products[index].lampholder,
            branch: products[index].branch,
            color: products[index].color,
            material: products[index].material,
            features: products[index].features,
            specialNotes: products[index].specialNotes,
          }),
        );
      }

      const customer = customerOptions.find(
        (item) => item.value === results.customers_id,
      ).customerDetails;

      dispatch(SET_CUSTOMER(customer));
      dispatch(SET_DISCOUNT_RATE(results.discountRate));
      alert('success', 'Fiyat Teklifi verileri başarıyla getirildi');
    } catch (error) {
      alert('error', 'Fiyat Teklifi verileri getirilirken hata oluştu');
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <div className="card card-dark my-4 p-0">
      <div className="card-header bg-dark  font-weight-bold text-center">{title}</div>
      <form id="formGetPriceOffer" className=" bg-lightPrimary">
        <div className="form-group form-inline  col-md-4 mx-auto p-2">
          <label htmlFor="">Fiyat Teklifi Seçin : </label>
          <div className="w-100 mt-2">
            {isPriceOfferOptionsLoading ? (
              'Yükleniyor...'
            ) : (
              <Select
                options={priceOfferOptions}
                isLoading={isLoading}
                onChange={priceOfferOnChangeHandler}
                placeholder="Müşteri Adı veya Teklif No girin"
                value={priceOfferOptions.find((item) => item.value === order.priceOffersId) || ''}
              />
            )}
          </div>
        </div>
      </form>
      <div className="alert alert-primary my-3 mx-2 col-md-6 mx-auto">
        <p>
          Fiyat Teklifi bilgileri ile sipariş oluşturmak için lütfen yukarıdaki bölüme teklif
          numarasını girin.
        </p>
        <p>
          Fiyat Teklifi olmadan sipariş oluşturacaksınız bu bölümde herhangi bir seçim yapmadan
          aşağıdaki kısımları doldurunuz
        </p>
      </div>

      <hr />
      <form role="form"></form>
    </div>
  );
};

export default FromPriceOffer;
