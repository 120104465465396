import React from 'react';

import FadingBalls from 'react-cssfx-loading/lib/FadingBalls';

const Loading = ({ text }) => {
  return (
    <div className="myOverlay">
      <FadingBalls className="me-2" />
      {text || 'Lütfen Bekleyiniz...'}
    </div>
  );
};

export default Loading;
