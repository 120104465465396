import { useState, useEffect } from 'react';
import alert from '../js/sweetAlert';

import { useAtolyeAPI } from '.';

const useProductionList = ({ isOnlyPlanning = false }) => {
  // States
  const [productionList, setProductionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [isSortingUpdated, setIsSortingUpdated] = useState(false);
  const [areFiltersUpdated, setAreFiltersUpdated] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [productionStatusFilter, setProductionStatusFilter] = useState(null);
  const [manufacturerFilter, setManufacturerFilter] = useState(null);

  // Hooks
  const { postTo } = useAtolyeAPI();

  // Effects
  useEffect(() => {
    setIsSortingUpdated(false);
  }, [isSortingUpdated]);

  useEffect(() => {
    setAreFiltersUpdated(false);
  }, [areFiltersUpdated]);

  // Functions

  const getProductions = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const { data, totalCount, totalPages } = await postTo(
        `/productions/${isOnlyPlanning ? 'planning/' : ''}table`,
        {
          limit: pageSize,
          offset: isSortingUpdated ? 0 : pageIndex * pageSize,
          search: filters || {},
          dates: dateFilter || null,
          order: sorting.length === 0 ? [['id', 'DESC']] : sorting,
        },
      );
      setProductionList(data);
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Üretim kayıtları getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };
  return {
    loading,
    pageCount,
    productionList,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    getProductions,
    setFilters,
    setDateFilter,
    setSorting,
    productionStatusFilter,
    manufacturerFilter,
    setProductionStatusFilter,
    setManufacturerFilter,
  };
};

export default useProductionList;
