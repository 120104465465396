import { DatePicker, Select } from '..';
import { useDispatch } from 'react-redux';
import { SET_CREATED_AT, SET_USER } from '../createPriceOfferSlice';
import { useSelector } from 'react-redux';

const PriceOfferDetailsForm = ({ title, userOptions, isUserOptionsLoading }) => {
  // Redux
  const dispatch = useDispatch();
  const { priceOffer } = useSelector((state) => state.priceOffers.createPriceOffer);

  return (
    <div className="card card-dark my-4 p-0">
      <div className="card-header bg-dark  font-weight-bold text-center">Teklif Detayları</div>

      <form role="form">
        <div className="card-body row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">{title}</label>
              <Select
                options={userOptions.map((user) => ({ label: user.fullName, value: user.id }))}
                isLoading={isUserOptionsLoading}
                onChange={({ value }) => dispatch(SET_USER(value))}
                placeholder="Yetkili Seçin"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">Teklif Tarihi</label>
              <DatePicker
                selected={Date.parse(priceOffer.createdAt)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                placeholder="Tarih seçin"
                withPortal
                onChange={(date) => dispatch(SET_CREATED_AT(date))}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PriceOfferDetailsForm;
