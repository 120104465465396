import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const CategoryFilter = ({
  categorySearch,
  setCategorySearch,
  getComponents,
  componentCategories,
}) => {
  // Effects
  useEffect(() => {
    getComponents();
  }, [categorySearch]);
  return (
    <div className="mx-2 my-auto">
      <Form.Control
        as="select"
        size="sm"
        style={{ minWidth: 150, padding: 4 }}
        onChange={({ target }) => {
          setCategorySearch(target.value);
        }}
        value={categorySearch}>
        <option value="">Tüm Kategoriler</option>
        {componentCategories.map((item) => (
          <option key={'componentCategory' + item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
    </div>
  );
};

export default CategoryFilter;
