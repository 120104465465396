import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useTags = () => {
  // State
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [isTagsLoading, setIsTagsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getTags();
  }, []);

  // Functions
  const getTags = async () => {
    try {
      const { results } = await AtolyeAPI.get('/tags');
      setTags(results);
      setTagOptions(results.map((tag) => ({ label: tag.name, value: tag.id, color: tag.color })));
    } catch (error) {
      console.log(error);
    } finally {
      setIsTagsLoading(false);
    }
  };

  return {
    isTagsLoading,
    tags,
    tagOptions,
    getTags,
  };
};

export default useTags;
