import { useSelector } from 'react-redux';

import { ProgressBar } from '..';

const OrderProgress = () => {
  //Redux
  const { order } = useSelector((state) => state.orders.singleOrder);
  return (
    order && (
      <div className="mx-0 mt-5 mb-3">
        <div className="my-2 text-center h5 font-weight-normal font-italic">Tamamlanma Durumu</div>
        {order.progress.done == 0 ? (
          <ProgressBar
            animated
            variant={'danger'}
            now={100}
            label={`${order.progress.done} / ${order.progress.all} ürün hazır`}
          />
        ) : (
          <ProgressBar
            animated
            variant={'success'}
            now={(order.progress.done / order.progress.all) * 100}
            label={`${order.progress.done} / ${order.progress.all} ürün hazır`}
          />
        )}
      </div>
    )
  );
};

export default OrderProgress;
