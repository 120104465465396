import React, { useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const EditRequestQuantityModal = ({ show, onHide, getManufactureProduct, manufactureProduct }) => {
  // State
  const [quantity, setQuantity] = useState(manufactureProduct.requestQuantity);

  const handleEdit = async () => {
    const body = {
      requestQuantity: quantity,
    };

    try {
      await AtolyeAPI.post(`/manufacture_products/${manufactureProduct.id}`, body);
      alert('success', 'Talep Miktarı güncellendi');
      getManufactureProduct(manufactureProduct.id);
    } catch (error) {
      console.log(error);
      alert('error', 'Talep Miktarı güncellenemedi');
    } finally {
      onHide();
    }
  };

  const handleOnHide = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={handleOnHide}>
      <Modal.Header closeButton>
        <Modal.Title>Talep Miktarı Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="info">Talep miktarını güncellemek için adet giriniz.</Alert>

        {manufactureProduct && (
          <>
            <InputGroup size="sm" className="mt-3 mx-2">
              <InputGroup.Text>Talep Miktarı</InputGroup.Text>
              <Form.Control
                style={{ maxWidth: 65 }}
                type="number"
                placeholder="Talep miktarı giriniz"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                min={0}
              />
            </InputGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnHide}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={handleEdit}>
          Güncelle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditRequestQuantityModal;
