import { Button, Card, Col, Row } from 'react-bootstrap';
import '../ManufactureComponents.scss';
import alert, { alertDialog } from 'js/sweetAlert';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const ManufacturComponentCard = ({
  component,
  setSelectedComponent,
  setShowEditManufactureComponentModal,
  getComponents,
}) => {
  // Functions
  const handleEdit = () => {
    setShowEditManufactureComponentModal(true);
    setSelectedComponent(component);
  };

  const deleteHandler = () => {
    alertDialog({
      text: 'Parçayı silmek istediğinize emin misiniz?',
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
      onConfirm: onDelete,
    });
  };

  const onDelete = async () => {
    try {
      await AtolyeAPI.get(`/components/${component.id}/delete`);
      alert('success', 'Parça Silindi');
      getComponents();
    } catch (error) {
      alert('error', 'Parça Silinemedi. Bir hatayla karşılaşıldı');
      console.log(error);
    }
  };

  return (
    <Card className="manufacture-component-card">
      <Card.Header className="bg-atolye py-1 d-flex">
        <i className="fas fa-cogs me-2 my-auto"></i>
        <strong className="ms-auto my-auto">{component.code}</strong>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <img
              className="manufacture-component-card-image bg-black"
              src={component.imageLink}
              alt={component.name}
            />
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-center pt-3">
            <div>
              <i className="fas fa-tools me-1"></i>
              {component.component_category.name}
            </div>
            <hr />
            <div>
              <strong className="badge badge-danger font-14">
                {component.operations.reduce((accumulator, currentValue) => {
                  return accumulator + parseFloat(currentValue.cost);
                }, 0) +
                  component.subComponents.reduce((accumulator, currentValue) => {
                    return (
                      accumulator +
                      parseFloat(currentValue.cost) * currentValue.ComponentHasSubComponent.quantity
                    );
                  }, 0)}{' '}
                ₺
              </strong>
            </div>
            <hr />
            <div>
              <i className="fas fa-cubes fa-lg me-1"></i>
              {component.stock_count} Adet
            </div>
          </Col>
        </Row>
        {component.subComponents.length > 0 && (
          <>
            <hr />
            <Row className="mx-0 py-2 mt-3">
              {component.subComponents.map((item, index) => (
                <div
                  key={'manufacturComponentCardItem-SubComponent' + index}
                  className="d-flex justify-content-between my-2">
                  <div className="manufacture-component-card-text">
                    <span className="me-3">
                      <strong>A.P. - {index + 1}</strong>
                    </span>
                    <span
                      style={{ maxWidth: 100 }}
                      className="text-truncate d-inline-block align-middle">
                      {item.code}
                    </span>
                    <span className="font-12"> ({item.ComponentHasSubComponent.quantity})</span>
                  </div>
                  <div className="text-danger">
                    {(item.cost * item.ComponentHasSubComponent.quantity).toFixed(2)} ₺
                  </div>
                </div>
              ))}
            </Row>
          </>
        )}

        <hr />
        <Row className="mx-0 py-2 mt-3">
          {component.operations.map((item, index) => (
            <div
              key={'manufacturComponentCardItem' + index}
              className="d-flex justify-content-between my-2">
              <div className="manufacture-component-card-text">
                <span className="me-3">
                  <strong>İş - {index + 1}</strong>
                </span>
                <span>{item.name}</span>
              </div>
              <div className="text-danger">{item.cost} ₺</div>
            </div>
          ))}
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex">
        <Button variant="outline-danger" size="sm" onClick={deleteHandler}>
          <strong>Kaldır</strong>
        </Button>
        <Button className="ms-auto" variant="outline-primary" size="sm" onClick={handleEdit}>
          Düzenle
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ManufacturComponentCard;
