import useTags from 'hooks/useTags';

import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { SET_TAGS } from '../singleProductSlice';

const animatedComponents = makeAnimated();

const ProductTags = () => {
  // Redux
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.products.singleProduct.product);
  const { userPermissions } = useSelector((state) => state.general.user);
  // Hooks
  const { tagOptions, isTagsLoading } = useTags();

  // Functions

  return (
    <div className="m-3 mt-5">
      <Form.Group>
        <Form.Label>Ürün Etiketleri</Form.Label>
        {isTagsLoading ? (
          'Yükleniyor...'
        ) : (
          <Select
            isDisabled={!userPermissions?.includes(22)}
            closeMenuOnSelect={false}
            components={animatedComponents}
            placeholder="Etiketleri seçin"
            value={tags.map((item) => ({ label: item.name, value: item.id, color: item.color }))}
            onChange={(values) => {
              dispatch(
                SET_TAGS(
                  values.map((item) => ({ id: item.value, name: item.label, color: item.color })),
                ),
              );
            }}
            isMulti
            options={tagOptions}
          />
        )}
      </Form.Group>
      {tags.length < 1 && <p className="text-muted p-2">Ürün etiketi bulunmamaktadır</p>}
    </div>
  );
};

export default ProductTags;
