import './ProductionListFilter.scss';
import { filterOptions } from './utils';

const ProductionListFilter = ({ filter, setFilter, total, loading }) => {
  return (
    <div className="my-2 text-center w-100 hide-on-print">
      {filterOptions.map((option) => (
        <div
          key={option.id}
          className={`btn btn${filter?.production_status_id === option.id ? '' : '-outline'}-${
            option.variant
          } m-2 btn-sm`}
          onClick={() => setFilter({ production_status_id: option.id })}>
          {option.caption} {filter?.production_status_id === option.id && !loading && `(${total})`}
        </div>
      ))}

      <div
        className={`btn btn${!filter ? '' : '-outline'}-success m-2 btn-sm`}
        onClick={() => setFilter(null)}>
        Tümü {!filter && !loading && `(${total})`}
      </div>
    </div>
  );
};

export default ProductionListFilter;
