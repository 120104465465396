import ProductVariantsSection from './ProductVariantsSection';

import EditProductVariantModal from './EditProductVariantModal';
import NewProductVariantModal from './NewProductVariantModal';
import NewVariantOptionModal from './NewVariantOptionModal';
import ProductVariantDeleteModal from './ProductVariantDeleteModal';
import VariantOptions from './VariantOptions';
import VariantOptionDeleteModal from './VariantOptionDeleteModal';
import VariantsTable from './VariantsTable';

export default ProductVariantsSection;
export {
  EditProductVariantModal,
  NewProductVariantModal,
  NewVariantOptionModal,
  ProductVariantDeleteModal,
  VariantOptions,
  VariantOptionDeleteModal,
  VariantsTable,
};
