import { useState } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';

const ExportButton = ({ loading, exportExcel }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const DateRange = {
    Today: 'Bugün',
    Last7Days: 'Son 7 gün',
    Last15Days: 'Son 15 gün',
    Last30Days: 'Son 30 gün',
    Last3Months: 'Son 3 ay',
    Last6Months: 'Son 6 ay',
    LastYear: 'Son 1 yıl',
  };

  const convertDateRange = (range) => {
    switch (range) {
      case DateRange.Today:
        return { startDate: new Date(), endDate: new Date() };
      case DateRange.Last7Days:
        return {
          startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
          endDate: new Date(),
        };
      case DateRange.Last15Days:
        return {
          startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
          endDate: new Date(),
        };
      case DateRange.Last30Days:
        return {
          startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
          endDate: new Date(),
        };
      case DateRange.Last3Months:
        return {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
          endDate: new Date(),
        };
      case DateRange.Last6Months:
        return {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
          endDate: new Date(),
        };
      case DateRange.LastYear:
        return {
          startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
          endDate: new Date(),
        };
      default:
        return { startDate: null, endDate: null };
    }
  };

  const handleExport = async () => {
    if (!selectedDateRange) {
      return;
    }
    await exportExcel(convertDateRange(selectedDateRange));
    setModalOpen(false);
    setSelectedDateRange(null);
  };

  return (
    <>
      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Excel Oluştur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Tarih Aralığı Seçiniz</Form.Label>
          <Form.Select
            value={selectedDateRange}
            onChange={(e) => setSelectedDateRange(e.target.value)}>
            <option value={null}>Seçiniz</option>
            <option value={DateRange.Today}>Bugün</option>
            <option value={DateRange.Last7Days}>Son 7 gün</option>
            <option value={DateRange.Last15Days}>Son 15 gün</option>
            <option value={DateRange.Last30Days}>Son 30 gün</option>
            <option value={DateRange.Last3Months}>Son 3 ay</option>
            <option value={DateRange.Last6Months}>Son 6 ay</option>
            <option value={DateRange.LastYear}>Son 1 yıl</option>
          </Form.Select>
          <div className="text-center my-4">
            <button className="btn btn-sm btn-success mt-3 px-3 text-white" onClick={handleExport}>
              İndir
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <button
        className="btn btn-sm btn-success ms-auto mt-5 text-white"
        style={{ height: 30 }}
        onClick={() => {
          setModalOpen(true);
        }}>
        {loading ? (
          <Spinner size="sm" animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <i className="fas fa-file-excel me-2"></i>
            Siparişleri İndir (xlsx)
          </>
        )}
      </button>
    </>
  );
};

export default ExportButton;
