import { useSelector, useDispatch } from 'react-redux';
import {
  RESET_PRIVATE_NOTES_EDIT_TEXT,
  SET_PRIVATE_NOTES_EDIT_TEXT,
  START_LOADING,
  STOP_LOADING,
} from '../singleOrderSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const PrivateNotes = ({ fetchOrder }) => {
  //Redux
  const { order, privateNotesEditText } = useSelector((state) => state.orders.singleOrder);
  const { userPermissions } = useSelector((state) => state.general.user);
  const dispatch = useDispatch();

  // Functions
  const updatePrivateNotes = async () => {
    dispatch(START_LOADING());
    const body = { privateNotes: privateNotesEditText };
    try {
      await AtolyeAPI.put(`/orders/${order.id}`, body);
      fetchOrder(order.id);
      alert('success', 'Admin Notları Güncellendi');
    } catch (error) {
      alert('error', 'Admin Notları güncellenirken hata oluştu');
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    order && (
      <>
        <div className="mt-5 bg-dark p-2 rounded">
          <span className="h6 mt-1 text-center d-inline-block">Admin Notları</span>
        </div>

        <div className="row mx-2 mt-5 mb-2">
          <div className="w-100">
            <textarea
              className="border border-dark p-3 rounded w-100"
              rows="8"
              value={privateNotesEditText}
              disabled></textarea>
          </div>
        </div>
        <div className="mx-2 mb-5 px-2 d-flex">
          {userPermissions?.includes(9) && (
            <button
              className="btn btn-primary ms-auto"
              data-toggle="modal"
              data-target=".edit-private-notes-modal-lg">
              Düzenle
            </button>
          )}
        </div>

        <div className="modal fade edit-private-notes-modal-lg" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered ">
            <div
              className="modal-content"
              style={{
                backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
              }}>
              <div className="modal-header">
                <h5 className="modal-title">Admin Notları - Düzenle</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <textarea
                  name="description"
                  id="description"
                  rows="5"
                  className="form-control"
                  value={privateNotesEditText}
                  onChange={(event) =>
                    dispatch(SET_PRIVATE_NOTES_EDIT_TEXT(event.target.value))
                  }></textarea>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-toggle="modal"
                  data-dismiss="modal"
                  onClick={updatePrivateNotes}>
                  Kaydet
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => dispatch(RESET_PRIVATE_NOTES_EDIT_TEXT())}>
                  Vazgeç
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default PrivateNotes;
