import { useState, useEffect } from 'react';
import alert from 'js/sweetAlert';

import { useAtolyeAPI } from 'hooks';
import { nToS } from 'utils/calcUtils';
import { useDispatch } from 'react-redux';
import { SET_DEPOT_OPTIONS } from './productStockManagementSlice';

const useProductStocks = () => {
  // States
  const [productStocks, setProductStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState({});
  const [dateFilter, setDateFilter] = useState(null);
  const [isSortingUpdated, setIsSortingUpdated] = useState(false);
  const [areFiltersUpdated, setAreFiltersUpdated] = useState(false);
  const [totalRowsCount, setTotalRowsCount] = useState(0);

  // Redux
  const dispatch = useDispatch();

  // Hooks
  const { postTo, getFileFrom, fetchFrom } = useAtolyeAPI();

  // Effects
  useEffect(() => {
    getDepotOptions();
  }, []);
  useEffect(() => {
    setIsSortingUpdated(false);
  }, [isSortingUpdated]);

  useEffect(() => {
    setAreFiltersUpdated(false);
  }, [areFiltersUpdated]);

  useEffect(() => {
    getProductStocks(0, 10);
  }, [isSortingUpdated, areFiltersUpdated]);

  // Functions

  const getProductStocks = async (pageIndex, pageSize) => {
    setLoading(true);

    // Set endpoint url

    let endpointUrl = '/product_stocks/table';

    try {
      const { data, totalCount, totalPages } = await postTo(endpointUrl, {
        limit: pageSize,
        offset: isSortingUpdated ? 0 : pageIndex * pageSize,
        search: filters || {},
        dates: dateFilter || null,
        order: sorting.length === 0 ? [['createdAt', 'DESC']] : sorting,
      });
      setProductStocks(createExpandedRowsByUsingVariantsData(data));
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Ürün Stok Bilgileri getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };

  const getDepotOptions = async () => {
    try {
      const results = await fetchFrom('/depots');
      dispatch(SET_DEPOT_OPTIONS(results));
    } catch (error) {
      console.log(error);
      alert('error', 'Depo bilgileri getirilirken bir sorun oluştu!');
    }
  };

  const createExpandedRowsByUsingVariantsData = (data) => {
    const expandedData = data.map((row) => {
      const notDeletedVariants = row.productVariants.filter((variant) => !variant.isDeleted);
      const notDeletedVariantsStockCounts = notDeletedVariants.map((item) => item.stock_count);

      return row.productVariants.length > 0
        ? {
            ...row,
            stock_count:
              notDeletedVariants.length === 1
                ? parseInt(row.stock_count) + parseInt(notDeletedVariants[0].stock_count)
                : notDeletedVariants.length > 1
                ? parseInt(row.stock_count) +
                  notDeletedVariantsStockCounts.reduce((a, b) => parseInt(a) + parseInt(b), 0)
                : row.stock_count,
            subRows: [
              {
                ...row,
                description: '(Ana Ürün - Varyantsız)',
                isSubRowItem: true,
              },
              ...row.productVariants
                .filter((variant) => !variant.isDeleted)
                .map((variant) => ({
                  imageLink: variant.image_link,
                  id: row.id + '-' + nToS(variant.id),
                  description: variant.name,
                  product_category: { name: row.product_category.name },
                  stock_count: variant.stock_count,
                  depot: variant.depot,
                  updatedByUser: variant.updatedByUser,
                  updatedAt: variant.updatedAt,
                  isVariant: true,
                  isSubRowItem: true,
                  variantId: variant.id,
                })),
            ],
          }
        : row;
    });

    return expandedData;
  };

  const exportAllProductStocks = async () => {
    setLoading(true);
    try {
      await getFileFrom('/productStocks/export');
    } catch (error) {
      console.log(error);
      alert('error', 'Ürün Stok Bilgileri indirilirken bir sorun oluştu!');
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    pageCount,
    productStocks,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    getProductStocks,
    exportAllProductStocks,
    setFilters,
    setDateFilter,
    setSorting,
    setIsSortingUpdated,
    setAreFiltersUpdated,
  };
};

export default useProductStocks;
