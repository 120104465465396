import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import {
  HIDE_PDF_MODAL,
  SET_PDF_CURRENCY,
  SET_PDF_LANGUAGE,
  SET_PDF_TYPE,
  SHOW_PDF_MODAL,
} from '../singlePriceOfferSlice';

const PDFButtons = () => {
  // Redux
  const { pdfType, pdfLanguage, pdfCurrency, isPDFModalDisplayed } = useSelector(
    (state) => state.priceOffers.singlePriceOffer,
  );
  const dispatch = useDispatch();
  return (
    <>
      <div className="col-md-2 col-6 px-1 py-2 text-center">
        <div
          className="btn  btn-outline-danger order-button  m-1"
          onClick={() => dispatch(SHOW_PDF_MODAL())}>
          <span className="d-block mt-4 w-100">
            <i className="fas fa-file-pdf fa-2x mb-2"></i>
          </span>
          <div className=" w-100">PDF</div>
        </div>
      </div>

      <Modal show={isPDFModalDisplayed} onHide={() => dispatch(HIDE_PDF_MODAL())}>
        <Modal.Header closeButton>
          <Modal.Title>PDF Ayarları</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="pdfType">PDF Tipi</label>
            <select
              className="form-control"
              id="pdfType"
              value={pdfType}
              onChange={(e) => dispatch(SET_PDF_TYPE(e.target.value))}>
              <option value="default">Standart</option>
              <option value="priceless">Fiyatsız</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="pdfLanguage">PDF Dili</label>
            <select
              className="form-control"
              id="pdfLanguage"
              value={pdfLanguage}
              onChange={(e) => dispatch(SET_PDF_LANGUAGE(e.target.value))}>
              <option value="tr">Türkçe</option>
              <option value="en">İngilizce</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="pdfCurrency">PDF Para Birimi</label>
            <select
              className="form-control"
              id="pdfCurrency"
              value={pdfCurrency}
              onChange={(e) => dispatch(SET_PDF_CURRENCY(e.target.value))}>
              <option value="TL">Türk Lirası (TL)</option>
              <option value="USD">Dolar (USD)</option>
              <option value="EUR">Euro (EUR)</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => dispatch(HIDE_PDF_MODAL())}>
            Vazgeç
          </Button>
          <Link to="/price-offer-pdf">
            <Button variant="primary" onClick={() => dispatch(HIDE_PDF_MODAL())}>
              PDF Oluştur
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PDFButtons;
