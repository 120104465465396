import './Customers.scss';

import { useMemo } from 'react';
import { columnsData } from './utils';

import {
  AtolyeTable,
  ContentWrapper,
  Footer,
  Header,
  Link,
  PageTitle,
  PageWrapper,
  useCustomers,
  useUser,
} from '.';

const Customers = (props) => {
  // Hooks
  const { loading, pageCount, customers, totalRowsCount, getCustomers } = useCustomers();
  const { user, userPermissions } = useUser();

  // Declarations

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => customers, [customers]);
  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Müşteriler" />
        <PageWrapper>
          {!userPermissions?.includes(29) ? (
            <div className="alert alert-danger">
              <strong>Yetkiniz yok!</strong> Müşteri bilgilerini görüntülemek için yetkiniz
              bulunmamaktadır.
            </div>
          ) : (
            <>
              <div className="my-4 text-right pr-4">
                <Link to="/create-customer" className="text-success">
                  <strong>+ Yeni Müşteri</strong>
                </Link>
              </div>
              <AtolyeTable
                columns={columns}
                data={data}
                fetchData={getCustomers}
                loading={loading}
                pageCount={pageCount}
                total={totalRowsCount}
              />
            </>
          )}
        </PageWrapper>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default Customers;
