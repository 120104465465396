import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const alert = (status, message, showDenyButton = false, denyButtonText = 'Hayır') => {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    icon: status === 'success' ? 'success' : 'error',
    title: message,
    confirmButtonText: 'TAMAM',
    cancelButtonText: 'İPTAL',
    showDenyButton,
    denyButtonText,
    timer: 2500,
  });
};

export const alertDialog = ({ title, text, confirmButtonText, cancelButtonText, onConfirm }) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title,
    text,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      onConfirm();
    }
  });
};

export default alert;
