// Get Thumbnail
const getThumbnail = (value) => {
  let imgParts = value.split('.');
  let ext = imgParts.pop();
  let imgThumb = imgParts.join('.') + '-thumb.' + ext;
  imgThumb = imgThumb.replace(
    'https://dev.images.atolyestore.com/products/',
    'https://dev.images.atolyestore.com/product_thumbs/',
  );

  return imgThumb;
};

export default getThumbnail;
