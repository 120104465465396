import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CustomerLink = ({ value }) => (
  <Link to={`/single-customer?id=${value}`}>
    <Badge pill bg="warning" className="text-dark font-12 pointer">
      {value}
    </Badge>
  </Link>
);

export const columnsData = [
  {
    Header: 'MÜŞTERİ NO',
    accessor: 'id',
    Cell: CustomerLink,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ADI SOYADI',
    accessor: 'name',
    Cell: ({
      row: {
        original: { id, name, lastName },
      },
    }) => (
      <Link to={`/single-customer?id=${id}`}>
        {' '}
        <strong>
          {name} {lastName}
        </strong>
      </Link>
    ),
    disableSortBy: true,
    disableFilters: true,
  },

  {
    Header: 'FİRMA',
    accessor: 'company',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'E-POSTA',
    accessor: 'email',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'TELEFON',
    accessor: 'phone',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ADRES',
    accessor: 'address',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'FATURA ADRESİ',
    accessor: 'billingAddress',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'SON GÜNCELLEME',
    accessor: 'updatedAt',
    Cell: ({ value }) => new Date(value).toLocaleString(),
    disableSortBy: true,
    disableFilters: true,
  },
];
