import { Header, Footer, ContentWrapper, PageTitle, PageWrapper } from '.';
import useUser from 'hooks/useUser';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ResponsiveLoading from 'components/ResponsiveLoading';
import {
  RESET,
  SET_IS_EDITED,
  SET_OLD_TAGS,
  SET_PRODUCT,
  SET_PRODUCT_CODE,
  SET_PRODUCT_VARIANTS,
  SET_VARIANT_OPTIONS,
  START_LOADING,
  STOP_LOADING,
} from './singleProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import ProductGeneralDetails from './components/ProductGeneralDetails';
import ProductImage from './components/ProductImage';
import CreationInfo from './components/CreationInfo';
import ActionButtonsSection from './components/ActionButtonsSection';
import ProductSelect from './components/ProductSelect';
import ProductTags from './components/ProductTags';
import ProductVariantsSection from './components/ProductVariantsSection';

const AtolyeAPI = new API();

const SingleProduct = () => {
  //Redux
  const { isLoading, productCode, product } = useSelector((state) => state.products.singleProduct);
  const dispatch = useDispatch();

  // Hooks
  const { user, userPermissions } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();

  // Effects
  useEffect(() => {
    const code = searchParams?.get('code');
    if (code) {
      return dispatch(SET_PRODUCT_CODE(code));
    }

    return dispatch(RESET());
  }, [searchParams]);

  useEffect(() => {
    if (productCode) {
      return getProduct();
    }
  }, [productCode]);

  // Functions
  const getProduct = async () => {
    dispatch(START_LOADING());
    try {
      const { results } = await AtolyeAPI.get(`/products/${productCode}`);
      dispatch(SET_PRODUCT(results));
      dispatch(SET_IS_EDITED(false));
      dispatch(SET_OLD_TAGS(results.tags));
      dispatch(SET_PRODUCT_VARIANTS(results.productVariants));
      dispatch(SET_VARIANT_OPTIONS(results.variantOptions));
    } catch (err) {
      console.log(err);
      alert('error', 'Ürün detayları getirilirken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Ürün Sorgulama" />
        {!userPermissions?.includes(5) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> Ürün detaylarını görüntülemek için yetkiniz
            bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            <ProductSelect setSearchParams={setSearchParams} />
            {isLoading && <ResponsiveLoading />}
            {/* Alert */}
            {!productCode && (
              <Alert variant="info">
                <i className="fas fa-info-circle"></i> Lütfen detaylarını görüntülemek istediğiniz
                ürünü seçiniz
              </Alert>
            )}
            {/* Page Components */}
            {product && (
              <>
                <Row>
                  <Col md={8} className="border-right">
                    <ProductGeneralDetails product={product} />
                    <ProductTags />
                    <ProductVariantsSection getProduct={getProduct} userId={user?.id} />
                  </Col>
                  <Col md={4}>
                    <ProductImage imageSrc={product.imageLink} />
                    <CreationInfo product={product} />
                  </Col>
                </Row>
              </>
            )}
            <ActionButtonsSection reset={() => getProduct()} />
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default SingleProduct;
