import { DatePicker, Select } from '..';
import { useDispatch } from 'react-redux';
import {
  SET_DELIVERY_DATE,
  SET_DELIVERY_OPTION,
  SET_ORDER_MANAGER,
  SET_ORDER_STATUS,
  SET_SALES_CHANNEL,
  SET_SALE_DATE,
  SET_USER,
} from '../createOrderSlice';
import useOrderStatusOptions from 'hooks/useOrderStatusOptions';
import { deliveryOptions, salesChannelOptions } from '../util';
import { useSelector } from 'react-redux';

const OrderDetailsForm = ({ title, userOptions, isUserOptionsLoading }) => {
  // Redux
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.orders.createOrder);

  // Hooks
  const { isOrderStatusOptionsLoading, orderStatusOptions } = useOrderStatusOptions();

  return (
    <div className="card card-dark my-4 p-0">
      <div className="card-header bg-dark  font-weight-bold text-center">Sipariş Detayları</div>

      <form role="form">
        <div className="card-body row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">{title}</label>
              <Select
                options={userOptions.map((user) => ({ label: user.fullName, value: user.id }))}
                isLoading={isUserOptionsLoading}
                onChange={({ value }) => dispatch(SET_USER(value))}
                placeholder="Yetkili Seçin"
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Siparişi Durumu</label>
              <Select
                options={orderStatusOptions.map((status) => ({
                  label: status.name,
                  value: status.id,
                }))}
                isLoading={isOrderStatusOptionsLoading}
                placeholder="Durum Seçin"
                onChange={({ value }) => dispatch(SET_ORDER_STATUS(value))}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Teslimat Şekli</label>
              <Select
                options={deliveryOptions}
                placeholder="Seçiniz"
                onChange={({ value }) => dispatch(SET_DELIVERY_OPTION(value))}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">Satış Tarihi</label>
              <DatePicker
                selected={Date.parse(order.saleDate)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                placeholder="Tarih seçin"
                withPortal
                onChange={(date) => dispatch(SET_SALE_DATE(date))}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Tahmini Teslim Tarihi</label>
              <DatePicker
                selected={Date.parse(order.deliveryDate)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                placeholder="Tarih seçin"
                withPortal
                onChange={(date) => dispatch(SET_DELIVERY_DATE(date))}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Satış Kanalı</label>
              <Select
                options={salesChannelOptions}
                placeholder="Seçiniz"
                onChange={({ value }) => dispatch(SET_SALES_CHANNEL(value))}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">Sipariş Sorumlusu</label>
              <Select
                options={userOptions.map((user) => ({ label: user.fullName, value: user.id }))}
                isLoading={isUserOptionsLoading}
                onChange={({ value }) => dispatch(SET_ORDER_MANAGER(value))}
                placeholder="Sorumlu Kişi Seçin"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderDetailsForm;
