import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useOrderOptions = () => {
  // State
  const [orderOptions, setOrderOptions] = useState([]);
  const [isOrderOptionsLoading, setIsOrderOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getOrderOptions();
  }, []);

  // Functions
  const getOrderOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/orders/options');
      setOrderOptions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsOrderOptionsLoading(false);
    }
  };

  return {
    isOrderOptionsLoading,
    orderOptions,
  };
};

export default useOrderOptions;
