import React, { Component } from 'react';
import Header from '../../components/shared/Header';
import Footer from '../../components/shared/Footer/Footer';
import Loading from '../../components/shared/Loading';
import { API } from 'aws-amplify';
import Select from 'react-select';
import '../../js/sweetAlert';
import DatePicker from 'react-datepicker';
import { Auth } from 'aws-amplify';

class CreateRepair extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      order: null,
      customer: null,
      customers: null,
      user: '',
      userName: '',
      userEmail: '',
      userRole: 2,
      currentInputValue: null,
      options: null,
      repair: {
        repairType: null,
        status: null,
        customers_id: null,
        orders_id: null,
        products_code: null,
        users_id: null,
        description: '',
        consultingDate: null,
        predictedDeliveryDate: null,
      },
      id: '',
      productOptions: [],
      userOptions: null,
    };
  }

  async componentDidMount() {
    let user = await Auth.currentUserInfo();
    this.setState({
      user: user,
      userName: user.attributes.name,
      userEmail: user.attributes.email,
    });
    this.getOptions();
    await this.getCustomers().then((results) => {
      this.setState({
        customers: results,
      });
    });

    await this.getUsers().then((results) => {
      let userRole = results.filter((user) => user.email == this.state.userEmail)[0].roles_id;
      this.setState({
        userRole: userRole,
      });
      let options = [];
      results.map((user) => {
        if (user.roles_id !== 4) {
          options.push({
            value: user.id,
            label: user.fullName,
          });
        }
      });
      this.setState({
        userOptions: options,
      });
    });

    this.setState({
      loading: false,
    });
  }

  getOptions = () => {
    let apiName = 'adminAtolyeAPI';
    let path = '/items/orders';

    API.get(apiName, path)
      .then((response) => {
        let orders = response.results;

        let options = [];
        orders.map((order, index) => {
          options[index] = {
            value: order.id,
            label: order.customer + ' - ' + order.id,
          };
        });

        console.log(options);
        this.setState({
          options: options,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  createRepair = () => {
    this.setState({
      loading: true,
    });

    let apiName = 'adminAtolyeAPI';
    let path = '/items/repairs';

    let reqObj = this.state.repair;

    let myInit = {
      headers: {},
      response: true,
      body: reqObj,
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        console.log(response);
        if (response.data.status === 'SUCCESS') {
          alert('success', 'Tadilat / Montaj Kaydı oluşturuldu');
          setTimeout((window.location.pathname = '/repairs'), 3000);
        } else {
          alert('error', 'Bir hata oluştu. Kaydın daha önce oluşturulmadığından emin olunuz!');
          this.setState({
            loading: false,
            order: null,
            customer: null,
            productOptions: null,
            repair: {
              ...this.state.repair,
              status: null,
              repairType: null,
              orders_id: null,
              customers_id: null,
              products_code: null,
              description: null,
              predictedDeliveryDate: null,
              consultingDate: null,
              users_id: null,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alert('error', 'Bir hata oluştu.');
      });
  };

  getCustomers = () => {
    return new Promise((resolve, reject) => {
      let apiName = 'adminAtolyeAPI';
      let path = '/items/customers';

      API.get(apiName, path)
        .then((response) => {
          console.log(response);
          resolve(response.results);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  getProducts = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        loading: true,
      });
      let options = [];
      let apiName = 'adminAtolyeAPI';
      let path = '/items/orders_has_products';

      API.get(apiName, path)
        .then((response) => {
          response.results.map((product) => {
            if (product.orders_id === this.state.order.id) {
              options.push({
                value: product.products_code,
                label: product.products_code,
              });
            }
          });

          this.setState({
            productOptions: options,
          });

          console.log(response);
          resolve(response.results);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  getUsers = () => {
    return new Promise((resolve, reject) => {
      let apiName = 'adminAtolyeAPI';
      let path = '/items/users';

      API.get(apiName, path)
        .then((response) => {
          console.log(response);
          resolve(response.results);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  productsHandleOnChange = (options) => {
    let product;
    if (options !== null) {
      product = options.value;
      this.setState({
        repair: {
          ...this.state.repair,
          products_code: product,
        },
      });
    } else {
      this.setState({
        repair: {
          ...this.state.repair,
          products_code: null,
        },
      });
    }
  };

  userHandleOnChange = (options) => {
    let user;

    if (options !== null) {
      user = options.value;

      this.setState({
        repair: {
          ...this.state.repair,
          users_id: user,
        },
      });
    } else {
      console.log('Yetkili : Boş!');
      this.setState({
        repair: {
          ...this.state.repair,
          users_id: null,
        },
      });
    }
  };

  handleConsultingDateChange = (date) => {
    let d = new Date(date);
    d.setHours(3);
    d.setMinutes(30);
    this.setState({
      repair: {
        ...this.state.repair,
        consultingDate: d.toISOString().slice(0, 19),
      },
    });
  };

  handleDeliveryDateChange = (date) => {
    let d = new Date(date);
    d.setHours(3);
    d.setMinutes(30);
    this.setState({
      repair: {
        ...this.state.repair,
        predictedDeliveryDate: d.toISOString().slice(0, 19),
      },
    });
  };

  handleDescriptionChange = (event) => {
    this.setState({
      repair: { ...this.state.repair, description: event.target.value },
    });
  };

  getOrder = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let id = this.state.currentInputValue;
    let apiName = 'adminAtolyeAPI';
    let path = '/items/orders/' + id;

    await API.get(apiName, path)
      .then((response) => {
        let customer = this.state.customers.filter(
          (e) => e.id === response.results[0].customers_id,
        )[0];
        console.log(customer);

        this.setState({
          order: response.results[0],
          customer: customer,
          repair: {
            ...this.state.repair,
            orders_id: response.results[0].id,
            customers_id: customer.id,
          },
        });
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    await this.getProducts();

    this.setState({
      loading: false,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.order === null) {
      alert('error', 'Sipariş bulunamadı');
      return;
    }

    if (this.state.repair.users_id === null) {
      alert('error', 'Yetkili seçmediniz');
      return;
    }

    if (this.state.repair.products_code === null) {
      alert('error', 'İşlem yapılacak ürün seçmediniz');
      return;
    }

    if (this.state.repair.repairType === null) {
      alert('error', 'İşlem türü seçmediniz');
      return;
    }

    if (this.state.repair.status === null) {
      alert('error', 'Durum seçmediniz');
      return;
    }

    this.createRepair();
  };

  handleInputChange = (options) => {
    this.setState({
      currentInputValue: options.value,
    });
  };

  repairTypeChange = (event) => {
    event.preventDefault();
    this.setState({
      repair: {
        ...this.state.repair,
        repairType: event.target.value,
      },
    });
  };

  statusChange = (event) => {
    event.preventDefault();
    this.setState({
      repair: {
        ...this.state.repair,
        status: event.target.value,
      },
    });
  };

  render() {
    return (
      <>
        <Header userRole={this.state.userRole} {...this.props} />
        {this.state.loading === true ? (
          <Loading />
        ) : (
          <div className="content-wrapper p-2 bg-dark">
            <div className="page-title">
              <span>Yeni Tadilat / Montaj Kaydı</span>
            </div>

            <div className=" p-3 mt-3">
              <div className="col-md-6 mx-auto">
                <label htmlFor="">Ürün Kodu: </label>

                <Select
                  className="text-dark"
                  id="input-product"
                  options={this.state.options}
                  isLoading={this.state.loading}
                  onChange={this.handleInputChange}
                  value={this.state.currentInputValue && this.state.currentInputValue.value}
                  placeholder="Müşteri Adı veya Sipariş No girin"
                />
                <button
                  type="submit"
                  className="my-2 btn btn-primary h-100"
                  onClick={this.getOrder}>
                  Bilgileri Getir
                </button>
              </div>
            </div>

            <div className="mx-2 mx-md-4">
              <div className="card card-secondary col-lg-6 mx-auto my-4 p-0">
                <form
                  role="form"
                  id="formCustomer"
                  className="text-dark p-2"
                  style={{
                    backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
                  }}>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Müşteri Adı</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          name="lastName"
                          placeholder=""
                          disabled
                          value={
                            this.state.order !== null
                              ? this.state.customer.name + ' ' + this.state.customer.lastName
                              : ''
                          }
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>Telefon</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          disabled
                          value={this.state.order !== null ? this.state.customer.phone : ''}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Adres</label>
                      <textarea
                        type="text"
                        className="form-control"
                        rows="3"
                        disabled
                        value={
                          this.state.order !== null ? this.state.customer.address : ''
                        }></textarea>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label> İşlem Türü</label>
                        <select
                          className="form-control"
                          onChange={this.repairTypeChange}
                          defaultValue="Seçiniz">
                          <option value="Seçiniz" disabled>
                            Seçiniz
                          </option>
                          <option value="Tadilat">Tadilat</option>
                          <option value="Montaj">Montaj</option>
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <label>Durum</label>
                        <select
                          className="form-control"
                          onChange={this.statusChange}
                          defaultValue="Seçiniz">
                          <option value="Seçiniz" disabled>
                            Seçiniz
                          </option>
                          <option value="Planlandı">Planlandı</option>
                          <option value="Tamamlandı">Tamamlandı</option>
                          <option value="İptal">İptal</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label> Başvuru Tarihi</label>
                        <DatePicker
                          selected={Date.parse(this.state.repair.consultingDate)}
                          className="form-control"
                          onChange={(date) => this.handleConsultingDateChange(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholder="Tarih seçin"
                          withPortal
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>Tahmini Erişim Tarihi</label>
                        <DatePicker
                          selected={Date.parse(this.state.repair.predictedDeliveryDate)}
                          className="form-control"
                          onChange={(date) => this.handleDeliveryDateChange(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholder="Tarih seçin"
                          withPortal
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Yetkili</label>
                        <Select
                          options={this.state.userOptions}
                          isLoading={this.state.loading}
                          onChange={this.userHandleOnChange}
                          placeholder="Seçiniz"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>İşlm Uygulanacak Ürün</label>
                        <Select
                          options={this.state.productOptions && this.state.productOptions}
                          isLoading={this.state.loading}
                          onChange={this.productsHandleOnChange}
                          placeholder="Ürün seçiniz.."
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Özel Notlar</label>
                      <textarea
                        type="text"
                        className="form-control"
                        rows="4"
                        value={this.state.repair.description}
                        onChange={this.handleDescriptionChange}></textarea>
                    </div>
                  </div>

                  <div className="card-footer bg-none">
                    <button
                      type="submit"
                      id="createCustomerBtn"
                      className="btn btn-success"
                      onClick={this.onSubmit}>
                      Kaydet
                    </button>
                    <a href="/repairs" className="float-right mt-2">
                      <u>Tadilat / Montaj Kayıtları</u>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}

export default CreateRepair;
