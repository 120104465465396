import { useEffect, useRef } from 'react';
import {
  SET_DISCOUNT_RATE,
  SET_DISCOUNT_TOTAL,
  SET_PRODUCTS_TOTAL,
  SET_SUBTOTAL,
} from '../createOrderSlice';
import { useSelector, useDispatch } from 'react-redux';
import alert from 'js/sweetAlert';
import { roundTo2Decimals } from 'utils/calcUtils';

const OrderTotalDetails = ({ title }) => {
  // Redux
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.orders.createOrder);
  // const { priceOffersId } = useSelector((state) => state.orders.createOrder.order);
  const { productsTotal, subTotal, total, discountTotal, discountRate } = useSelector(
    (state) => state.orders.createOrder.order,
  );

  // Refs
  const discountRateInputRef = useRef();
  const subTotalInputRef = useRef();

  // Effects
  useEffect(() => {
    setDiscountRateInput();
    setProductsTotal();
  }, [products, discountRate]);

  // Functions
  const setProductsTotal = () => {
    const _tempProductsTotal = products.reduce((acc, product) => {
      return acc + parseFloat(product.price * parseInt(product.quantity));
    }, 0);
    const _tempDiscountTotal = _tempProductsTotal - _tempProductsTotal / (1 + discountRate / 100);
    const _tempSubTotal = _tempProductsTotal - _tempDiscountTotal;
    dispatch(SET_PRODUCTS_TOTAL(_tempProductsTotal));
    dispatch(SET_SUBTOTAL(_tempSubTotal));
    dispatch(SET_DISCOUNT_TOTAL(_tempDiscountTotal));
    subTotalInputRef.current.value = roundTo2Decimals(_tempSubTotal * 1.18);
  };

  const discountRateOnChangeHandler = (e) => {
    const _tempDiscountRate = roundTo2Decimals(parseFloat(e.target.value));
    if (_tempDiscountRate < 0) {
      discountRateInputRef.current.value = discountRate;
      return alert('error', "İndirim Oranı 0'dan küçük olamaz!");
    }
    if (_tempDiscountRate > 100) {
      discountRateInputRef.current.value = discountRate;
      return alert('error', "İndirim Oranı 100'den büyük olamaz!");
    }
    dispatch(SET_DISCOUNT_RATE(_tempDiscountRate));
    calcAfterDiscountRateChange(_tempDiscountRate);
  };

  const subTotalOnChangeHandler = (e) => {
    const _tempSubTotal = parseFloat(e.target.value) / 1.18;
    if (_tempSubTotal > productsTotal) {
      subTotalInputRef.current.value = roundTo2Decimals(subTotal * 1.18);
      return alert('error', 'Ara toplam, Ürünlerin Toplamından Fazla Olamaz!');
    }
    calcAfterSubTotalChange(_tempSubTotal);
    dispatch(SET_SUBTOTAL(_tempSubTotal));
  };

  const calcAfterDiscountRateChange = (_discountRate) => {
    const _tempDiscountTotal = productsTotal - productsTotal / (1 + _discountRate / 100);
    const _tempSubTotal = productsTotal - _tempDiscountTotal;
    dispatch(SET_DISCOUNT_TOTAL(_tempDiscountTotal));
    dispatch(SET_SUBTOTAL(_tempSubTotal));
    subTotalInputRef.current.value = roundTo2Decimals(_tempSubTotal * 1.18);
  };

  const calcAfterSubTotalChange = (_subTotal) => {
    const _tempDiscountTotal = productsTotal - _subTotal;
    const _tempDiscountRate = 100 * (productsTotal / (productsTotal - _tempDiscountTotal) - 1);
    dispatch(SET_DISCOUNT_TOTAL(_tempDiscountTotal));
    dispatch(SET_DISCOUNT_RATE(_tempDiscountRate));
    discountRateInputRef.current.value = roundTo2Decimals(_tempDiscountRate);
  };

  const setDiscountRateInput = () => {
    discountRateInputRef.current.value = discountRate;
  };

  return (
    <div className="card card-dark">
      <div className="card-header bg-dark  font-weight-bold text-center">{title}</div>
      <div className="card-body">
        <div className="col-md-4 mx-auto">
          <div className="row">
            <div className="col-6 bg-overlayPrimary d-flex border border-dark">
              <strong className="mx-auto my-auto">Ürünler Toplamı</strong>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control bg-light text-dark"
                value={roundTo2Decimals(productsTotal * 1.18) + ' ₺'}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 bg-overlayPrimary d-flex border border-dark">
              <strong className="mx-auto my-auto text-center">İskonto Oranı (%)</strong>
            </div>
            <div className="col-6">
              <input
                ref={discountRateInputRef}
                type="number"
                className="form-control"
                min={0}
                max={100}
                onChange={discountRateOnChangeHandler}
                defaultValue={discountRate}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 bg-overlayPrimary d-flex border border-dark">
              <strong className="mx-auto my-auto">İskonto Tutarı</strong>
            </div>
            <div className="col-6">
              <input
                className="form-control bg-light text-danger"
                type="text"
                disabled
                value={roundTo2Decimals(discountTotal * 1.18) + ' ₺'}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 bg-overlayPrimary d-flex border border-dark">
              <strong className="mx-auto my-auto">Ara Toplam</strong>
            </div>
            <div className="col-6">
              <input
                ref={subTotalInputRef}
                type="number"
                className="form-control text-dark"
                defaultValue={roundTo2Decimals(subTotal * 1.18)}
                onChange={subTotalOnChangeHandler}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 bg-dark d-flex border border-dark">
              <strong className="mx-auto my-auto">Toplam Tutar</strong>
            </div>
            <div className="col-6">
              <input
                className="form-control bg-info text-white"
                type="text"
                name="total"
                id="total"
                disabled
                value={roundTo2Decimals(total) + ' ₺'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTotalDetails;
