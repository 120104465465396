export const translations = {
  customerDetails: {
    en: 'Customer Details',
    tr: 'Müşteri Detayları',
  },
  company: {
    en: 'Company',
    tr: 'Firma',
  },
  nameSurname: {
    en: 'Customer Name',
    tr: 'Ad ve Soyad',
  },
  address: {
    en: 'Address',
    tr: 'Adres',
  },
  phone: {
    en: 'Phone',
    tr: 'Telefon',
  },
  email: {
    en: 'Email',
    tr: 'E-posta',
  },
  date: {
    en: 'Date',
    tr: 'Tarih',
  },
  productImage: {
    en: 'Product Image',
    tr: 'Ürün Görseli',
  },
  productCode: {
    en: 'Product Code',
    tr: 'Ürün Kodu',
  },
  description: {
    en: 'Description',
    tr: 'Açıklama',
  },
  quantity: {
    en: 'Qty.',
    tr: 'Adet',
  },
  price: {
    en: 'Price',
    tr: 'Birim Fiyat',
  },
  total: {
    en: 'Total',
    tr: 'Toplam',
  },
  productFeatures: {
    en: 'Product Features',
    tr: 'Ürün Özellikleri',
  },
  material: {
    en: 'Material',
    tr: 'Materyal',
  },
  glass: {
    en: 'Glass',
    tr: 'Cam',
  },
  branchCount: {
    en: 'Branch Count',
    tr: 'Kol Sayısı',
  },
  color: {
    en: 'Color',
    tr: 'Renk',
  },
  lampholderType: {
    en: 'Lampholder Type',
    tr: 'Duy Tipi',
  },
  orderNotes: {
    en: 'Order Notes',
    tr: 'Sipariş Notları',
  },
  productsTotal: {
    en: 'Products Total',
    tr: 'Ürün Toplamı',
  },
  discountTotal: {
    en: 'Discount Total',
    tr: 'İskonto Tutarı',
  },
  subTotal: {
    en: 'Sub Total',
    tr: 'Ara Toplam',
  },
  totalAmount: {
    en: 'Total Amount',
    tr: 'Toplam Tutar',
  },
  paymentDetails: {
    en: 'Payment Details',
    tr: 'Ödeme Detayları',
  },
  receivedPayment: {
    en: 'Received Payment',
    tr: 'Alınan Ödeme',
  },
  remainingPayment: {
    en: 'Remaining Payment',
    tr: 'Kalan Ödeme',
  },
  receiverPerson: {
    en: 'Receiver',
    tr: 'Alan Kişi',
  },
  sincerely: {
    en: 'Sincerely',
    tr: 'Saygılarımızla',
  },
  warning: {
    tr: '--- BU BELGE FATURA NİTELİĞİ TAŞIMAZ ---',
    en: '--- THIS DOCUMENT IS NOT AN INVOICE ---',
  },
};
