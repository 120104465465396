import { RESET, START_LOADING, STOP_LOADING } from '../createOrderSlice';
import alert from 'js/sweetAlert';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from 'utils/atolyeAPI';
import moment from 'moment';
const AtolyeAPI = new API();

const useCreateOrder = (user) => {
  // Redux
  const dispatch = useDispatch();
  const { order, products, editingCustomer, isLoading } = useSelector(
    (state) => state.orders.createOrder,
  );

  // Hooks
  const navigate = useNavigate();

  // Functions
  const createOrderHandler = async () => {
    const isValid = checkValidation();

    if (isValid) {
      const body = {
        createdByUser: user,
        price_offers_id: order.priceOffersId,
        customers_id: editingCustomer.id,
        users_id: order.users_id,
        orderManagerId: order.orderManagerId,
        saleDate: moment(order.saleDate).add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        deliveryDate: moment(order.deliveryDate).add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        deliveryOption: order.deliveryOption,
        salesChannel: order.salesChannel,
        description: order.description,
        order_status_id:
          products.filter((product) => product.isGivenByStock).length === products.length
            ? 3
            : order.order_status_id,
        discountRate: order.discountRate,
        discountTotal: order.discountTotal,
        productsTotal: order.productsTotal,
        subTotal: order.subTotal,
        KDV: order.kdv,
        KDVtotal: order.total - order.subTotal,
        total: order.total,
        bill: order.bill,
        billingAddress: order.billingAddress,
        balance: {
          received: order.balanceReceived,
          remainder: order.balanceRemainder,
          date: moment(order.balanceDate).add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          users_id: order.balanceUserId,
          paymentMethod: order.paymentMethod,
        },
        products: products.map((product) => ({
          products_code: product.id,
          quantity: product.quantity,
          status: 1,
          production_status_id: product.isGivenByStock ? 3 : 1,
          features: product.features,
          description: product.description,
          price: parseFloat(product.price),
          total: parseFloat(product.price) * parseInt(product.quantity),
          glass: product.glass,
          material: product.material,
          branch: product.branch,
          color: product.color,
          lampholder: product.lampholder,
          specialNotes: product.specialNotes,
          manufacturers_id: product?.manufacturers_id,
          variantId: product.selectedVariant.id ? product.selectedVariant.id : null,
          givenByStock: product.isGivenByStock ? product.quantity : null,
        })),
      };
      console.log({ body });

      dispatch(START_LOADING());

      try {
        const { id } = await AtolyeAPI.post(`/orders`, body);
        alert('success', 'Sipariş başarıyla oluşturuldu');
        navigate(`/single-order?id=${id}`);
        dispatch(RESET());
      } catch (error) {
        alert('error', 'Sipariş oluşturulurken bir hata oluştu');
        console.log(error);
      } finally {
        dispatch(STOP_LOADING());
      }
    }
  };

  const clearOrderHandler = () => {
    dispatch(RESET());
    alert('success', 'Sipariş bilgileri temizlendi');
  };

  const checkValidation = () => {
    if (!editingCustomer.id) {
      alert('error', 'Müşteri seçmediniz!!!');
      return false;
    }
    if (products.length < 1) {
      alert('error', 'Ürün eklemediniz!!!');
      return false;
    }
    if (!order.users_id) {
      alert('error', 'Siparişi Alan Yetkiliyi seçmediniz');
      return false;
    }
    if (!order.saleDate || !order.deliveryDate) {
      alert('error', 'Satış Tarihi ve Tahmini Teslim Tarihi boş olamaz!!!');
      return false;
    }
    if (!order.order_status_id) {
      alert('error', 'Sipariş Durumu boş bırakılamaz');
      return false;
    }
    if (!order.balanceDate) {
      alert('error', 'Ödeme Tarihi boş bırakılamaz');
      return false;
    }
    if (!order.balanceUserId) {
      alert('error', 'Ödemeyi alan yetkiliyi seçmediniz');
      return false;
    }
    if (!order.balanceRemainder) {
      alert('error', 'Ödeme tutarını giriniz!');
      return false;
    } else if (products.find((item) => !item.features)) {
      alert('error', 'Ürün özellikleri bölümü boş bırakılamaz!!!');
      return false;
    }
    return true;
  };

  return { createOrderHandler, clearOrderHandler, isLoading };
};

export default useCreateOrder;
