import { useSelector } from 'react-redux';

const OrderHeader = ({ title }) => {
  // Redux
  const { userPermissions } = useSelector((state) => state.general.user);
  return (
    <div className="mt-5 bg-dark p-2 rounded">
      <span className="h6 mt-1 text-center d-inline-block">{title}</span>
      {(userPermissions?.includes(9) || userPermissions?.includes(1)) && (
        <button
          className="btn btn-primary btn-sm float-right"
          data-toggle="modal"
          data-target=".edit-info-modal-lg">
          Düzenle
        </button>
      )}
    </div>
  );
};

export default OrderHeader;
