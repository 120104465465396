import { useState } from 'react';

import API from '../utils/atolyeAPI';

const AtolyeAPI = new API();

const useAtolyeAPI = () => {
  // STATES
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // FUNCTIONS

  // Fetch data from api
  const fetchFrom = async (path) => {
    setIsLoading(true);
    setIsError(false);

    if (path.includes('undefined')) return;

    try {
      const { results } = await AtolyeAPI.get(path);
      setIsLoading(false);
      return results;
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsLoading(false);
      return;
    }
  };

  // Get File from api

  // Fetch data from api
  const getFileFrom = async (path) => {
    setIsLoading(true);
    setIsError(false);

    if (path.includes('undefined')) return;

    try {
      await AtolyeAPI.getFile(path);
      setIsLoading(false);
      return;
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsLoading(false);
      return;
    }
  };

  // Get file with post from api
  const getFileWithPost = async (path, body) => {
    setIsLoading(true);
    setIsError(false);

    if (path.includes('undefined')) return;

    try {
      await AtolyeAPI.getFileWithPost(path, body);
      setIsLoading(false);
      return;
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsLoading(false);
      return;
    }
  };

  // Post data to api

  const postTo = async (path, body) => {
    setIsLoading(true);
    setIsError(false);

    if (path.includes('undefined')) return;

    try {
      const { results } = await AtolyeAPI.post(path, body);
      setIsLoading(false);
      return results;
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsLoading(false);
      return;
    }
  };

  return {
    isLoading,
    isError,
    fetchFrom,
    getFileFrom,
    getFileWithPost,
    postTo,
  };
};

export default useAtolyeAPI;
