import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SET_UPDATE_ALL_AS, CLEAR_ERROR, START_LOADING, STOP_LOADING } from '../singleOrderSlice';

import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const ProductionStatusBulkEdit = ({ fetchOrder }) => {
  //Redux
  const { order, updateAllAs } = useSelector((state) => state.orders.singleOrder);
  const dispatch = useDispatch();

  // Functions
  const updateAllProductionStatus = async () => {
    dispatch(CLEAR_ERROR());
    dispatch(START_LOADING());

    const body = {
      id: order.id,
      production_status_id: updateAllAs,
    };

    try {
      await AtolyeAPI.put(`/orders/bulkUpdateProductionStatus`, body);
      fetchOrder(order.id);
      alert('success', 'Üretim Durumu Güncellendi');
    } catch (error) {
      alert('error', 'Üretim Durumu güncellenirken hata oluştu');
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <div className="col-md-2 col-6 px-1 py-2 text-center">
      <button
        className="btn btn-warning order-button  m-1"
        type="button"
        data-toggle="modal"
        data-target="#updateStatusModal"
        // onClick={this.updateAllAsDelivered}
      >
        Üretim Durumunu <strong>TOPLU</strong> Güncelle
      </button>

      {order && (
        <div className="modal fade" id="updateStatusModal" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-warning">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Üretim Durumunu Toplu Güncelle
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="text-left ">Tüm ürünleri güncellemek için üretim durumu seçiniz:</p>
                <select
                  className="form-control"
                  value={updateAllAs}
                  onChange={(event) => dispatch(SET_UPDATE_ALL_AS(event.target.value))}>
                  <option value={0} disabled>
                    Seçiniz
                  </option>
                  <option value={1}>Planlamada</option>
                  <option value={2}>Üretimde</option>
                  <option value={3}>Kontrol ve Paketleme</option>
                  <option value={4}>Teslime Hazır</option>
                  <option value={5}>Teslim Edildi</option>
                </select>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Vazgeç
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={updateAllProductionStatus}>
                  Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductionStatusBulkEdit;
