import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { START_LOADING, STOP_LOADING, RESET_CURRENT_PERMISSIONS } from '../userSlice';
import { Link } from 'react-router-dom';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const ActionButtonsSection = ({ getPermissions }) => {
  // Redux
  const dispatch = useDispatch();
  const { permissionsToAdd, permissionsToRemove, userPermissions, currentPermissions } =
    useSelector((state) => state.settings.user);

  const { id } = useSelector((state) => state.settings.user.userDetails);

  // Functions
  const updatePermissions = async () => {
    dispatch(START_LOADING());

    const body = {
      userId: id,
      permissionsToAdd,
      permissionsToRemove,
    };

    try {
      const results = await AtolyeAPI.post('/users/permissions', body);

      if (results.status === 'SUCCESS') {
        alert('success', 'Kullanıcı yetkileri başarıyla güncellendi.');
      }
    } catch (error) {
      console.log(error);
      alert('error', 'Kullanıcı yetkileri güncellenirken bir hata oluştu.');
    } finally {
      dispatch(STOP_LOADING());
      getPermissions();
    }
  };

  return (
    <div className="w-100 fixed-bottom bg-white px-3 py-2 border-top d-flex justify-content-end">
      <Link to="/user-list" className="mx-2 mt-1">
        <i className="fas fa-chevron-left fa-sm me-1"></i> Tüm Kullanıcılar
      </Link>
      <Button
        variant="primary"
        size="sm"
        className="mx-2"
        onClick={() => dispatch(RESET_CURRENT_PERMISSIONS())}
        disabled={JSON.stringify(userPermissions) === JSON.stringify(currentPermissions)}>
        Değişiklikleri Geri Al
      </Button>
      <Button
        variant="success"
        size="sm"
        className="mx-2 text-white"
        onClick={() => updatePermissions()}
        disabled={JSON.stringify(userPermissions) === JSON.stringify(currentPermissions)}>
        Güncelle
      </Button>
    </div>
  );
};

export default ActionButtonsSection;
