import './SingleManufactureProduct.scss';

import React, { useEffect, useState } from 'react';
import { ContentWrapper, Footer, Header, PageTitle, PageWrapper, useUser } from '../ProductionList';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import alert, { alertDialog } from 'js/sweetAlert';
import API from 'utils/atolyeAPI';
import AddComponentOnProductModal from './components/AddComponentOnProductModal';
import getThumbnail from 'utils/getThumbnail';
import EditComponentQuantityModal from '../ManufactureProducts/components/EditComponentQuantityModal';
import AddStockOnProductModal from './components/AddStockOnProductModal';
import EditRequestQuantityModal from './components/EditRequestQuantityModal';
const AtolyeAPI = new API();

const SingleManufactureProduct = () => {
  // State
  const [manufactureProduct, setManufactureProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null); // For EditComponentQuantityModal
  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [showEditComponentQuantityModal, setShowEditComponentQuantityModal] = useState(false);
  const [showAddStockModal, setShowAddStockModal] = useState(false);
  const [showEditRequestQuantityModal, setShowEditRequestQuantityModal] = useState(false);
  const [showImageFullSize, setShowImageFullSize] = useState(false);
  const [showImageFullSizeLink, setShowImageFullSizeLink] = useState('');

  // Hooks
  const { user, userPermissions } = useUser();
  let [searchParams] = useSearchParams();

  // Effects
  useEffect(() => {
    const id = searchParams?.get('id');
    if (id) {
      getManufactureProductDetails(id);
    }
  }, [searchParams]);

  // Functions

  const showImageFullSizeOnClick = (imageLink) => {
    setShowImageFullSizeLink(imageLink);
    setShowImageFullSize(true);
  };

  const calculateCapacity = (components) => {
    if (components.length === 0) return 0;
    // Every manufacture product consists of components
    // First we need to find the minimum capacity of the components
    // Then we can calculate the capacity of the manufacture product
    // Component capacity and manufacture product capacity are different
    // Component capacity is the minimum of the stock count and quantity
    // Manufacture product capacity is the minimum of the component capacities
    // We need to find the minimum of the component capacities
    // And we need to find the minimum of the manufacture product capacitie

    let manufactureProductCapacity = 0;
    let componentCapacities = [];
    // Every component has a stock count and quantity
    // We need to calculate how many times the stock responds to the quantity
    // And we need to find the minimum of these values

    components.forEach((component) => {
      const { stock_count, manufactureProductHasComponent } = component;
      const { quantity } = manufactureProductHasComponent;
      const capacity = Math.floor(stock_count / quantity);
      componentCapacities.push(capacity);
    });
    manufactureProductCapacity = Math.min(...componentCapacities);
    return manufactureProductCapacity;
  };

  const calculateProductionCost = (components) => {
    if (components.length === 0) return 0;

    let productionCost = 0;
    components.forEach((component) => {
      const { cost, manufactureProductHasComponent } = component;
      const { quantity } = manufactureProductHasComponent;
      productionCost += cost * quantity;
    });
    return productionCost;
  };

  const getManufactureProductDetails = async (id) => {
    setIsLoading(true);
    try {
      const { results } = await AtolyeAPI.get(`/manufacture_products/${id}`);
      setManufactureProduct({
        id: results.id,
        name: results.product.description,
        hasVariant: results.variant ? true : false,
        stockCount: results.variant ? results.variant.stock_count : results.product.stock_count,
        variantName: results.variant ? results.variant.name : 'Ana Ürün',
        imageLink: results.variant
          ? results.variant.image_link ?? results.product.imageLink
          : results.product.imageLink,
        capacity: calculateCapacity(results.components),
        components: results.components,
        requestQuantity: results.requestQuantity,
        product: {
          id: results.product.id,
          description: results.product.description,
        },
        variant: results.variant,
      });
    } catch (err) {
      console.log(err);
      alert('error', 'Bir hata oluştu');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteComponent = async (manufactureProductId, componentId) => {
    try {
      await AtolyeAPI.get(
        `/manufacture_products/${manufactureProductId}/components/${componentId}/delete`,
      );
      getManufactureProductDetails(manufactureProduct.id);
    } catch (err) {
      console.log(err);
      alert('error', 'Bir hata oluştu');
    }
  };

  return (
    <>
      <Header user={user} />
      {!userPermissions?.includes(34) ? (
        <div className="alert alert-danger text-center mb-0" role="alert">
          Bu sayfaya erişim yetkiniz bulunmamaktadır.
        </div>
      ) : (
        <ContentWrapper>
          <PageTitle title="İmalat Ürünü" />
          <PageWrapper>
            {isLoading ? (
              <div className="text-center">Yükleniyor...</div>
            ) : (
              manufactureProduct && (
                <>
                  <Row>
                    <Col md={4}>
                      <img
                        className="manufacture-product-card-image mb-2"
                        src={manufactureProduct.imageLink}
                        alt={manufactureProduct.name}
                      />
                    </Col>
                    <Col md={8}>
                      {/* Manufacture Product General Data */}

                      <Table bordered className="shadow">
                        <tbody>
                          <tr>
                            <td className="bg-white">
                              <strong>Ürün Kodu</strong>
                            </td>
                            <td>{manufactureProduct.product.id}</td>
                          </tr>
                          <tr>
                            <td className="bg-white">
                              <strong>Ürün Açıklaması</strong>
                            </td>
                            <td>{manufactureProduct.name}</td>
                          </tr>
                          <tr>
                            <td className="bg-white">
                              <strong>Varyant</strong>
                            </td>
                            <td>{manufactureProduct.variantName}</td>
                          </tr>
                          <tr>
                            <td className="bg-white">
                              <strong>Mevcut Stok</strong>
                            </td>
                            <td>{manufactureProduct.stockCount}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mt-2"
                        onClick={() => setShowAddStockModal(true)}>
                        + İmal Edilen Ürünü Stoğa Ekle
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-5 mx-0 bg-white rounded border">
                    <Col md={4}>
                      <div className="p-3 rounded">
                        <h5 className="mb-0">
                          <span className="me-3 text-dark">Kapasite:</span>
                          <span
                            className={`badge ${
                              manufactureProduct.capacity > 0
                                ? 'badge-success'
                                : manufactureProduct.capacity === 0
                                ? 'badge-warning'
                                : 'badge-danger'
                            }`}>
                            {manufactureProduct.capacity}
                          </span>
                        </h5>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="p-3 rounded">
                        <h5 className="mb-0">
                          <span className="me-3 text-dark">Talep:</span>
                          <span className={`badge badge-primary`}>
                            {manufactureProduct.requestQuantity}
                          </span>
                          <Button
                            variant=""
                            size="sm"
                            className="mx-2 text-primary"
                            onClick={() => setShowEditRequestQuantityModal(true)}>
                            <i className="fas fa-edit"></i>
                          </Button>
                        </h5>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="p-3 rounded">
                        <h5 className="mb-0 text-danger">
                          <span className="me-3 text-dark">İmalat Maliyeti:</span>
                          <span className="badge badge-danger">
                            {calculateProductionCost(manufactureProduct.components).toFixed(2)} ₺
                          </span>
                        </h5>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-5 px-2">
                    {/* Manufacture Product Components */}
                    <Table className="shadow bg-white" responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Görsel</strong>
                          </th>
                          <th>Parça Kodu</th>
                          <th>Adet</th>
                          <th>Güncel Stok</th>
                          <th>Birim Maliyet</th>
                          <th>Maliyet</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody>
                        {manufactureProduct.components.map((item) => (
                          <tr key={item.id}>
                            <td className="m-0 p-0 text-center">
                              <img
                                style={{ width: 100 }}
                                className="img-thumbnail"
                                src={getThumbnail(item.imageLink)}
                                alt={item.label}
                                onClick={() => showImageFullSizeOnClick(item.imageLink)}
                              />
                            </td>
                            <td>{item.code}</td>
                            <td>
                              {item.manufactureProductHasComponent.quantity}
                              <Button
                                variant="outline-primary"
                                size="sm"
                                className="mx-2"
                                onClick={() => {
                                  setSelectedComponent({
                                    componentId: item.id,
                                    productId: manufactureProduct.id,
                                    quantity: item.manufactureProductHasComponent.quantity,
                                  });
                                  setShowEditComponentQuantityModal(true);
                                }}>
                                <i className="fas fa-edit"></i>
                              </Button>
                            </td>
                            <td>{item.stock_count}</td>
                            <td>{item.cost} ₺</td>
                            <td>
                              {(item.cost * item.manufactureProductHasComponent.quantity)
                                .toFixed(2)
                                .toLocaleString('tr')}{' '}
                              ₺
                            </td>
                            <td>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() =>
                                  alertDialog({
                                    text:
                                      item.code + ' parçasını silmek istediğinize emin misiniz?',
                                    confirmButtonText: 'Sil',
                                    cancelButtonText: 'Vazgeç',
                                    onConfirm: () =>
                                      deleteComponent(manufactureProduct.id, item.id),
                                  })
                                }>
                                Sil
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {/* {Add Button} */}
                        <tr>
                          <td colSpan={6} className="text-center">
                            <Button
                              variant="outline-success"
                              size="sm"
                              onClick={() => setShowAddComponentModal(true)}>
                              + Parça Ekle
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                  <AddComponentOnProductModal
                    show={showAddComponentModal}
                    onHide={() => setShowAddComponentModal(false)}
                    manufactureProduct={manufactureProduct}
                    getManufactureProduct={getManufactureProductDetails}
                  />
                  {selectedComponent && (
                    <EditComponentQuantityModal
                      show={showEditComponentQuantityModal}
                      setShow={setShowEditComponentQuantityModal}
                      selectedComponent={selectedComponent}
                      getManufactureProductDetails={getManufactureProductDetails}
                    />
                  )}
                  <AddStockOnProductModal
                    show={showAddStockModal}
                    onHide={() => setShowAddStockModal(false)}
                    manufactureProduct={manufactureProduct}
                    getManufactureProduct={getManufactureProductDetails}
                  />
                  <EditRequestQuantityModal
                    show={showEditRequestQuantityModal}
                    onHide={() => setShowEditRequestQuantityModal(false)}
                    manufactureProduct={manufactureProduct}
                    getManufactureProduct={getManufactureProductDetails}
                  />
                  {/* Image Full Size Overlay */}
                  {showImageFullSize && (
                    <div
                      className="image-full-size-overlay"
                      onClick={() => setShowImageFullSize(false)}>
                      <img className="image-full-size" src={showImageFullSizeLink} />
                    </div>
                  )}
                </>
              )
            )}
          </PageWrapper>
        </ContentWrapper>
      )}
      <Footer />
    </>
  );
};

export default SingleManufactureProduct;
