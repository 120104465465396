import { useAtolyeAPI } from 'hooks';
import alert from 'js/sweetAlert';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const CreateDepotModal = ({ getDepots }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  // Redux

  const { pageIndex, pageSize } = useSelector((state) => state.atolyeTable);

  // Hooks
  const { postTo } = useAtolyeAPI();

  const handleCreateDepot = async () => {
    setLoading(true);

    try {
      const { results } = await postTo('/depots', { name });
      console.log(results);
      setName('');
      setShow(false);
      setLoading(false);
      getDepots(pageIndex, pageSize);
    } catch (error) {
      console.log(error);
      alert('error', 'Depo kaydedilirken bir sorun oluştu!');
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center px-4">
        <Button
          variant="success"
          size="sm"
          className="mb-4 text-white ms-auto"
          onClick={() => {
            setShow(true);
          }}>
          + Yeni Depo
        </Button>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Depo Oluştur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="name">Depo Adı</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Depo Adı"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShow(false)}>
            Vazgeç
          </Button>
          <Button variant="primary" onClick={handleCreateDepot}>
            {loading ? 'Kaydediliyor...' : 'Kaydet'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateDepotModal;
