import { Alert, Button, Table } from 'react-bootstrap';
import { ContentWrapper, PageTitle, Header, Footer, useUser } from '../ProductionList';
import API from 'utils/atolyeAPI';
import { useEffect, useState } from 'react';
import { Loading } from 'pages/Dashboard';
import alert from 'js/sweetAlert';
import EditComponentCategoryModal from './components/EditComponentCategoryModal';
import AddComponentCategoryModal from './components/AddComponentCategoryModal';

const AtolyeAPI = new API();

const ComponentCategories = (props) => {
  // States
  const [loading, setLoading] = useState(true);
  const [componentCategories, setComponentCategories] = useState([]);
  const [showEditComponentCategoryModal, setShowEditComponentCategoryModal] = useState(false);
  const [showAddComponentCategoryModal, setShowAddComponentCategoryModal] = useState(false);
  const [selectedComponentCategory, setSelectedComponentCategory] = useState(null);
  // Hooks
  const { user, userPermissions } = useUser();

  const getComponentCategories = async () => {
    setLoading(true);
    try {
      const { results } = await AtolyeAPI.get('/component_categories');
      setComponentCategories(results);
    } catch (error) {
      console.log(error);
      alert('error', 'Parça Kategorileri getirilirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    getComponentCategories();
  }, []);

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Parça Kategorileri" />
        {!userPermissions?.includes(34) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> Parça Kategorilerini görüntülemek için yetkiniz
            bulunmamaktadır.
          </Alert>
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <div className="d-flex justify-content-end mx-4">
              <Button
                variant="success"
                size="sm"
                className="mt-5 font-14 text-white"
                onClick={() => setShowAddComponentCategoryModal(true)}>
                + Yeni Kategori Ekle
              </Button>
            </div>
            <div className="px-3">
              <Table size="sm" striped className="m-3 responsive">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Kategori Adı</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  {componentCategories.map((category, index) => (
                    <tr key={`category-${index}`}>
                      <td>{category.id}</td>
                      <td>{category.name}</td>
                      <td>
                        <Button
                          variant=""
                          size="sm"
                          className="me-2 text-primary"
                          onClick={() => {
                            setSelectedComponentCategory(category);
                            setShowEditComponentCategoryModal(true);
                          }}>
                          <i className="fas fa-edit"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        <EditComponentCategoryModal
          show={showEditComponentCategoryModal}
          setShow={setShowEditComponentCategoryModal}
          getComponentCategories={getComponentCategories}
          categoryName={selectedComponentCategory?.name || ''}
          id={selectedComponentCategory?.id || null}
        />
        <AddComponentCategoryModal
          show={showAddComponentCategoryModal}
          setShow={setShowAddComponentCategoryModal}
          getComponentCategories={getComponentCategories}
        />
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default ComponentCategories;
