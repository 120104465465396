import ReactDOM from 'react-dom';

import 'style/style.scss'; // Styles Import
import 'config/amplifyConfig'; // Amplify Configuration
import routing from 'app/routes'; // Routing Configuration
import * as serviceWorker from 'serviceWorker';

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
