import './User.scss';
import useUserDetails from './hooks/useUserDetails';
import { ContentWrapper, Footer, Header, PageTitle, PageWrapper, useUser } from '..';
import UserInfo from './components/UserInfo';
import UserPermissions from './components/UserPermissions';
import usePermissions from './hooks/usePermissions';
import ActionButtonsSection from './components/ActionButtonsSection';
import DeleteUserModal from './components/DeleteUserModal';
import EditUserStatusModal from './components/EditUserStatusModal';
import EditUserRoleModal from './components/EditUserRoleModal';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ResetUserPasswordModal from './components/ResetUserPasswordModal';
// import BasicTable from 'components/BasicTable';
// import { useEffect } from 'react';

const User = (props) => {
  // Get search params

  // Hooks
  const { user, userPermissions } = useUser();
  const { getUserDetails } = useUserDetails();
  const { permissions, isPermissionsLoading } = usePermissions();
  const { userDetails } = useSelector((state) => state.settings.user);

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Kullanıcı" />

        {!userPermissions?.includes(14) ? (
          <Alert variant="danger">Bu sayfaya erişim yetkiniz bulunmamaktadır.</Alert>
        ) : user.userId === userDetails.id ? (
          <Alert variant="danger">Kendi kullanıcı bilgilerinizi düzenleyemezsiniz.</Alert>
        ) : (
          <PageWrapper>
            {/* <BasicTable data={data} columns={columns} /> */}
            <div className="d-flex flex-column">
              <i className="fas fa-user fa-3x bg-white text-muted rounded-circle p-4 mx-auto border shadow mb-4"></i>
            </div>
            <UserInfo />
            <UserPermissions
              permissions={permissions}
              isPermissionsLoading={isPermissionsLoading}
            />
            <ActionButtonsSection getPermissions={getUserDetails} />
            <DeleteUserModal />
            <EditUserStatusModal />
            <EditUserRoleModal />
            <ResetUserPasswordModal />
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default User;
