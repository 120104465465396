import { useEffect, useState } from 'react';

import { useAtolyeAPI } from 'hooks';

const useComponentCategories = () => {
  // States
  const [isComponentCategoriesLoading, setComponentCategoriesLoading] = useState(true);
  const [componentCategories, setComponentCategories] = useState([]);
  // Hooks
  const { fetchFrom } = useAtolyeAPI();
  // Effects
  useEffect(() => {
    getComponentCategories();
  }, []);

  // Functions
  const getComponentCategories = async () => {
    setComponentCategoriesLoading(true);
    try {
      const results = await fetchFrom('/component_categories');
      // console.log(results);
      setComponentCategories(results);
    } catch (error) {
      console.log(error);
      alert('error', 'Parçalar getirilirken bir sorun oluştu!');
    }

    setComponentCategoriesLoading(false);
  };

  return { isComponentCategoriesLoading, componentCategories, getComponentCategories };
};

export default useComponentCategories;
