import './ProductionList.scss';

import { useMemo, useState, useEffect } from 'react';
import useManufacturerOptions from './hooks/useManufacturerOptions';
import { columnsData } from './utils';

import {
  AtolyeTable,
  ContentWrapper,
  Footer,
  Header,
  PageTitle,
  PageWrapper,
  ProductionListFilter,
  ManufacturerFilter,
  useProductionList,
  useUser,
} from '.';
import AtolyeDatePicker from 'components/AtolyeTable/components/AtolyeDatePicker';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

const ProductionList = (props) => {
  // Redux
  const { userPermissions } = useSelector((state) => state.general.user);
  // State
  const [customFilter, setCustomFilter] = useState({});
  // Hooks
  const {
    loading,
    pageCount,
    productionList,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    productionStatusFilter,
    manufacturerFilter,
    getProductions,
    setFilters,
    setDateFilter,
    setAreFiltersUpdated,
    setSorting,
    setIsSortingUpdated,
    setProductionStatusFilter,
    setManufacturerFilter,
  } = useProductionList({ isOnlyPlanning: false });

  const { user } = useUser();

  const { manufacturerOptions, optionsLoading } = useManufacturerOptions();

  // Effects
  useEffect(() => {
    setCustomFilter({ ...manufacturerFilter, ...productionStatusFilter });
  }, [manufacturerFilter, productionStatusFilter]);

  useEffect(() => {
    Object.keys(customFilter) < 1 ? setFilters({}) : setFilters({ ...filters, ...customFilter });
  }, [customFilter]);

  // Declarations

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => productionList, [productionList]);

  return (
    <>
      <Header user={user} {...props} />
      <div className="printable-table">
        <ContentWrapper>
          <PageTitle title="Üretim Durumu Takibi" />
          {!userPermissions?.includes(21) ? (
            <Alert variant="danger">Bu sayfaya erişim yetkiniz bulunmamaktadır.</Alert>
          ) : (
            <PageWrapper>
              <ProductionListFilter
                filter={productionStatusFilter}
                setFilter={setProductionStatusFilter}
                total={totalRowsCount}
                loading={loading}
              />
              <ManufacturerFilter
                filter={manufacturerFilter}
                setFilter={setManufacturerFilter}
                options={manufacturerOptions}
                loading={optionsLoading}
              />
              <button
                className="btn btn-sm btn-outline-success my-3 ms-2 hide-on-print"
                onClick={() => print()}>
                Yazdır
              </button>
              <div className="d-flex">
                <AtolyeDatePicker dateFilter={dateFilter} setDateFilter={setDateFilter} />
              </div>

              <AtolyeTable
                columns={columns}
                data={data}
                fetchData={getProductions}
                filters={filters}
                dateFilter={dateFilter}
                setFilters={setFilters}
                setAreFiltersUpdated={setAreFiltersUpdated}
                sorting={sorting}
                setSorting={setSorting}
                setIsSortingUpdated={setIsSortingUpdated}
                loading={loading}
                pageCount={pageCount}
                total={totalRowsCount}
              />
            </PageWrapper>
          )}
        </ContentWrapper>
      </div>
      <Footer />
    </>
  );
};

export default ProductionList;
