import { useAtolyeAPI } from 'hooks';
import alert from 'js/sweetAlert';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteDepotRecord = ({ getDepot, show, setShow, selectedStock }) => {
  //Delete Depot Record Modal

  const { postTo } = useAtolyeAPI();

  const [loading, setLoading] = useState(false);

  const handleDeleteDepotRecord = async () => {
    setLoading(true);

    try {
      const { results } = await postTo(`/depots/product-delete`, {
        isVariant: selectedStock?.isVariant,
        id: selectedStock?.id,
      });
      console.log(results);
      setShow(false);
      setLoading(false);
      getDepot();
    } catch (error) {
      console.log(error);
      alert('error', 'Depo kaydı silinirken bir sorun oluştu!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Depo Stok Kaydını Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="text-primary">{selectedStock?.name}</span> -{' '}
            <span className="text-danger">{selectedStock?.stock_count} adet</span> depo stok kaydını
            silmek istediğinize emin misiniz?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShow(false)}>
            Vazgeç
          </Button>
          <Button variant="danger" onClick={handleDeleteDepotRecord}>
            {loading ? 'Siliniyor...' : 'Sil'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteDepotRecord;
