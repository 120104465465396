import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useOrderStatusOptions = () => {
  // State
  const [orderStatusOptions, setOrderStatusOptions] = useState([]);
  const [isOrderStatusOptionsLoading, setIsOrderStatusOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getOrderStatusOptions();
  }, []);

  // Functions
  const getOrderStatusOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/order_status');
      setOrderStatusOptions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsOrderStatusOptionsLoading(false);
    }
  };

  return {
    isOrderStatusOptionsLoading,
    orderStatusOptions,
  };
};

export default useOrderStatusOptions;
