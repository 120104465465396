import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { NewVariantOptionModal, VariantOptionDeleteModal } from '.';
import {
  SET_EDIT_OPTION,
  SET_OPTION_TO_DELETE,
  SHOW_EDIT_OPTION_MODAL,
  SHOW_OPTION_DELETE_MODAL,
} from '../../singleProductSlice';
import EditVariantOptionModal from './EditVariantOptionModal';

const VariantOptions = ({ getProduct }) => {
  //Redux
  const {
    variantOptions,
    // variantOptionsToAdd
  } = useSelector((state) => state.products.singleProduct.variants);
  const { userPermissions } = useSelector((state) => state.general.user);
  const dispatch = useDispatch();

  // Functions
  const handleOptionDeleteClick = (option) => {
    dispatch(SET_OPTION_TO_DELETE(option));
    dispatch(SHOW_OPTION_DELETE_MODAL());
  };

  const handleOptionEditClick = (option) => {
    console.log({ option });
    dispatch(
      SET_EDIT_OPTION({
        id: option.id,
        name: option.name,
        values: option.values.map((item) => ({
          id: item.id,
          name: item.name,
          isAlreadySaved: true,
        })),
      }),
    );
    dispatch(SHOW_EDIT_OPTION_MODAL());
  };

  return (
    <div>
      {variantOptions.length > 0 ? (
        variantOptions.map((option) => (
          <div key={option.id} className="bg-light rounded p-2 my-2 d-flex">
            <div className="me-auto">
              <div className="my-2">
                <i className="fas fa-th me-2"></i> {option.name}
              </div>
              <div>
                {option.values.map((value) => (
                  <div key={value.id} className="badge bg-atolye text-muted me-1 font-12">
                    {value.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="ms-auto my-auto">
              {userPermissions?.includes(22) && (
                <>
                  <Button
                    variant=""
                    className="btn-sm text-primary font-12"
                    onClick={() => handleOptionEditClick(option)}>
                    Düzenle
                  </Button>
                  <Button
                    variant=""
                    className="btn-sm text-danger font-12"
                    onClick={() => handleOptionDeleteClick(option)}>
                    Sil
                  </Button>
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-muted">
          Bu ürüne ait <strong>Varyant Seçeneği</strong> bulunmamaktadır
        </p>
      )}

      {userPermissions?.includes(22) && (
        <>
          <NewVariantOptionModal getProduct={getProduct} />
          <EditVariantOptionModal getProduct={getProduct} />
          <VariantOptionDeleteModal getProduct={getProduct} />
        </>
      )}
    </div>
  );
};

export default VariantOptions;
