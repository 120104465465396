import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SET_IS_EDITED, START_LOADING, STOP_LOADING } from '../singleProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { Link } from 'react-router-dom';

const AtolyeAPI = new API();

const ActionButtonsSection = ({ reset }) => {
  // Redux
  const dispatch = useDispatch();
  const { isEdited, productCode, product, tagsToAdd, tagsToDelete } = useSelector(
    (state) => state.products.singleProduct,
  );
  const { userPermissions } = useSelector((state) => state.general.user);

  // Functions
  const updateProduct = async () => {
    dispatch(START_LOADING());

    const {
      description,
      product_categories_id,
      defaultManufacturerId,
      cost,
      salePrice,
      maxDiscountRate,
      imageLink,
      stock_count,
    } = product;

    const body = {
      description,
      product_categories_id,
      defaultManufacturerId,
      cost,
      salePrice,
      maxDiscountRate,
      imageLink,
      stock_count,
      tagsToAdd: tagsToAdd.map((tag) => tag.id),
      tagsToDelete: tagsToDelete.map((tag) => tag.id),
    };
    try {
      await AtolyeAPI.put(`/products/${productCode}`, body);
      reset();
      alert('success', 'Ürün güncellendi');
      dispatch(SET_IS_EDITED(false));
    } catch (error) {
      console.log({ error });
      alert('error', 'Ürün güncellenirken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <div className="w-100 fixed-bottom bg-white px-3 py-2 border-top d-flex justify-content-end">
      <Link to="/products" className="mx-2 my-auto">
        <a className="text-primary">Tüm Ürünler</a>
      </Link>

      {userPermissions?.includes(22) && (
        <>
          <Button variant="primary" size="sm" className="mx-2" disabled={!isEdited} onClick={reset}>
            Değişiklikleri Geri Al
          </Button>
          <Button
            variant="success"
            size="sm"
            className="mx-2 text-white"
            disabled={!isEdited}
            onClick={() => updateProduct()}>
            Değişiklikleri Kaydet
          </Button>
        </>
      )}
    </div>
  );
};

export default ActionButtonsSection;
