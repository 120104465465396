import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import alert from 'js/sweetAlert';
import API from 'utils/atolyeAPI';
import CreatableSelect from 'react-select/creatable';

import { useEffect, useState } from 'react';
import ProductImage from 'pages/Product/CreateProduct/components/ProductImage';
import useOperationOptions from '../hooks/useOperationOptions';
import useComponentOptions from 'pages/Production/SingleManufactureProduct/hooks/useComponentOptions';
import { Select } from 'pages/Settings';

const AtolyeAPI = new API();

const AddManufactureComponentModal = ({ show, onHide, getComponents, componentCategories }) => {
  // State
  const [code, setCode] = useState('');
  const [imageLink, setImageLink] = useState(null);
  const [stockCount, setStockCount] = useState(0);
  const [operations, setOperations] = useState([]);
  const [componentCategory, setComponentCategory] = useState(null);
  const [newOperationOptions, setNewOperationOptions] = useState([]);
  const [subComponents, setSubComponents] = useState([]);

  // Hooks
  const { isOperationOptionsLoading, operationOptions } = useOperationOptions();
  const { componentOptions, isComponentOptionsLoading } = useComponentOptions();

  // Effects
  useEffect(() => {
    // Set newOperationOptions
    if (operationOptions) {
      setNewOperationOptions(operationOptions);
    }
  }, [operationOptions]);

  // Functions
  const handleAdd = async () => {
    const body = {
      code,
      imageLink,
      stock_count: stockCount,
      component_categories_id: componentCategory,
      operations,
      subComponents: subComponents.map((item) => {
        return {
          id: item.value,
          quantity: item.quantity,
          cost: item.cost,
        };
      }),
    };

    // Validate
    if (!validateOperations()) {
      alert('error', 'İşlerin tamamını doldurunuz');
      return;
    }

    if (!validateSubComponents) {
      alert('error', 'Alt parça bilgilerini kontrol ediniz');
    }

    if (!code) {
      alert('error', 'Parça adı giriniz');
      return;
    }

    if (!componentCategory) {
      alert('error', 'Kategori seçiniz');
      return;
    }

    if (operations.length === 0) {
      alert('error', 'En az bir iş ekleyiniz');
      return;
    }

    // console.log({ body });

    try {
      await AtolyeAPI.post('/components', body);
      alert('success', 'Parça Eklendi');
      getComponents();
    } catch (error) {
      alert('error', 'Parça Eklenemedi. Bir hata oluştu');
      console.log(error);
    } finally {
      handleOnHide();
      //   setProduct(null);
    }
  };

  const handleOnHide = () => {
    // setProduct(null);
    setCode('');
    setImageLink(null);
    setStockCount(0);
    setOperations([]);
    setComponentCategory(null);
    setSubComponents([]);
    onHide();
  };

  const handleAddOperation = () => {
    setOperations((prev) => [
      ...prev,
      {
        name: '',
        cost: '',
      },
    ]);
  };

  const handleAddSubComponent = () => {
    setSubComponents((prev) => [
      ...prev,
      {
        id: '',
        quantity: 1,
        cost: '',
      },
    ]);
  };

  const handleDeleteLastOperation = () => {
    setOperations((prev) => {
      const temp = [...prev];
      temp.pop();
      return temp;
    });
  };

  const handleDeleteLastSubComponent = () => {
    setSubComponents((prev) => {
      const temp = [...prev];
      temp.pop();
      return temp;
    });
  };

  const validateOperations = () => {
    let isValid = true;
    operations.forEach((item) => {
      if (!item.name || !item.cost) {
        isValid = false;
      }
    });
    return isValid;
  };

  const validateSubComponents = () => {
    let isValid = true;
    subComponents.forEach((item) => {
      if (!item.id || !item.quantity || !item.cost) {
        isValid = false;
      }
    });
    return isValid;
  };

  return (
    <Modal fullscreen show={show} onHide={handleOnHide}>
      <Modal.Header className="bg-atolye" closeButton>
        <Modal.Title>Yeni Parça Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4}>
            <div style={{ width: '300px!important' }}>
              <ProductImage
                title="Görsel"
                imageSrc={imageLink}
                setImageLink={setImageLink}
                imageStyle={{ maxWidth: 300, background: '#efefef' }}
              />
            </div>
          </Col>
          <Col md={8}>
            <Form.Group className="m-3">
              <Form.Label>Adı</Form.Label>
              <Form.Control
                style={{ maxWidth: 400 }}
                type="text"
                placeholder="Parça Adı"
                value={code}
                onChange={({ target }) => setCode(target.value)}
              />
            </Form.Group>

            {/* Select for Component Category */}

            <Form.Group className="m-3">
              <Form.Label>Kategori</Form.Label>
              <Form.Control
                style={{ maxWidth: 400 }}
                as="select"
                onChange={({ target }) => {
                  setComponentCategory(target.value);
                }}
                value={componentCategory}>
                <option value={null}>Seçiniz</option>
                {componentCategories.map((item) => (
                  <option key={'componentCategory' + item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="m-3">
              <Form.Label>Stok Adedi</Form.Label>
              <Form.Control
                style={{ maxWidth: 100 }}
                type="number"
                placeholder="Stok Adedi"
                min={0}
                value={stockCount}
                onChange={({ target }) => setStockCount(parseFloat(target.value))}
              />
            </Form.Group>
            <h5 className="mt-4 bg-light text-center py-2 rounded">Alt Parçalar</h5>
            {/* Alt Parçalar */}
            {subComponents.map((item, index) => (
              <Row key={'subComponent-' + index} className="mx-0 my-2">
                <Col xs={1} className="justify-content-center align-items-center d-flex">
                  <strong className="text-white px-2 bg-danger rounded-circle">{index + 1}</strong>
                </Col>
                <Col xs={11}>
                  <Row className="mx-2">
                    <Form.Group as={Col}>
                      <Select
                        isDisabled={isComponentOptionsLoading}
                        isLoading={isComponentOptionsLoading}
                        onChange={(option) =>
                          setSubComponents((prev) => {
                            const temp = [...prev];
                            temp[index] = option;
                            temp[index].quantity = 1;
                            temp[index].cost = option.cost;
                            return temp;
                          })
                        }
                        options={componentOptions}
                        placeholder="Alt Parça Adı"
                        value={componentOptions?.find((option) => option.value === item.value)}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control
                        size="sm"
                        type="number"
                        placeholder="Parça Adedi"
                        onChange={({ target }) => {
                          setSubComponents((prev) => {
                            const temp = [...prev];
                            temp[index].quantity = target.value;
                            return temp;
                          });
                        }}
                        value={item.quantity}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control
                        style={{ width: 150 }}
                        readOnly
                        value={
                          `${(item.cost * item.quantity).toFixed(2)} ₺` || '0 ₺'
                        }></Form.Control>
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
            ))}
            <Button
              size="sm"
              variant="outline-success"
              className="mx-2 my-3"
              onClick={handleAddSubComponent}>
              + Alt Parça Ekle
            </Button>
            <Button
              size="sm"
              disabled={subComponents.length === 0}
              variant="outline-danger"
              className="mx-2 my-3"
              onClick={handleDeleteLastSubComponent}>
              - Son Alt Parçayı Sil
            </Button>

            <h5 className="mt-4 bg-light text-center py-2 rounded">İşler</h5>
            {/* İşler */}
            {operations.map((item, index) => (
              <Row key={'operation-' + index} className="mx-0 my-2">
                <Col xs={1} className="justify-content-center align-items-center d-flex">
                  <strong className="text-white px-2 bg-danger rounded-circle">{index + 1}</strong>
                </Col>
                <Col xs={11}>
                  <Row className="mx-2">
                    <Form.Group as={Col}>
                      <CreatableSelect
                        isClearable
                        formatCreateLabel={(inputValue) => (
                          <span>
                            <strong>Oluştur: </strong>
                            {inputValue}
                          </span>
                        )}
                        isDisabled={isOperationOptionsLoading}
                        isLoading={isOperationOptionsLoading}
                        onChange={(option) => {
                          if (!option) {
                            setOperations((prev) => {
                              const temp = [...prev];
                              temp[index].name = '';
                              return temp;
                            });
                            return;
                          }
                          setOperations((prev) => {
                            const temp = [...prev];
                            temp[index].name = option.value;
                            return temp;
                          });
                        }}
                        onCreateOption={(value) => {
                          setNewOperationOptions((prev) => {
                            const temp = [...prev];
                            temp.push({ value, label: value });
                            return temp;
                          });
                          setOperations((prev) => {
                            const temp = [...prev];
                            temp[index].name = value;
                            return temp;
                          });
                        }}
                        options={newOperationOptions}
                        placeholder="İş Adı"
                        value={
                          newOperationOptions?.find((option) => option.value === item.name) || null
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control
                        size="sm"
                        type="number"
                        placeholder="Maliyet (₺)"
                        onChange={({ target }) => {
                          setOperations((prev) => {
                            const temp = [...prev];
                            temp[index].cost = target.value;
                            return temp;
                          });
                        }}
                        value={item.cost}
                      />
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
            ))}
            <Button
              size="sm"
              variant="outline-success"
              className="mx-2 my-3"
              onClick={handleAddOperation}>
              + İş Ekle
            </Button>
            <Button
              size="sm"
              disabled={operations.length === 0}
              variant="outline-danger"
              className="mx-2 my-3"
              onClick={handleDeleteLastOperation}>
              - Son İşi Sil
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnHide}>
          Vazgeç
        </Button>
        <Button variant="success" disabled={!code} onClick={handleAdd}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddManufactureComponentModal;
