import CreateCustomer from './CreateCustomer';
import ContentWrapper from 'components/shared/ContentWrapper';
import Footer from 'components/shared/Footer/Footer';
import Header from 'components/shared/Header';
import { Loading } from 'pages/Dashboard';
import PageTitle from 'components/shared/PageTitle';
import PageWrapper from 'components/shared/PageWrapper';
import { Card, Form, ListGroup, Table, Tabs, Tab } from 'react-bootstrap';

export {
  Card,
  ContentWrapper,
  Footer,
  Form,
  Header,
  Loading,
  ListGroup,
  PageTitle,
  PageWrapper,
  Table,
  Tabs,
  Tab,
};
export default CreateCustomer;
