import useTags from 'hooks/useTags';

import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { SET_TAGS } from '../createProductSlice';

const animatedComponents = makeAnimated();

const ProductTags = () => {
  // Redux
  const dispatch = useDispatch();
  const { tagsToAdd } = useSelector((state) => state.products.createProduct.product);
  // Hooks
  const { tagOptions, isTagsLoading } = useTags();

  // Functions

  return (
    <div className="m-3 mt-5">
      <Form.Group>
        <Form.Label>Ürün Etiketleri</Form.Label>
        {isTagsLoading ? (
          'Yükleniyor...'
        ) : (
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            placeholder="Etiketleri seçin"
            value={tagsToAdd?.map((item) => ({
              label: item.name,
              value: item.id,
              color: item.color,
            }))}
            onChange={(values) => {
              dispatch(
                SET_TAGS(
                  values.map((item) => ({ id: item.value, name: item.label, color: item.color })),
                ),
              );
            }}
            isMulti
            options={tagOptions}
          />
        )}
      </Form.Group>
      {tagsToAdd?.length < 1 && <p className="text-muted p-2">Ürün etiketi bulunmamaktadır</p>}
    </div>
  );
};

export default ProductTags;
