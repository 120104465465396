import { useDispatch, useSelector } from 'react-redux';
import { SET_DESCRIPTION_EDIT_TEXT } from '../../singleOrderSlice';

import {
  OrderFinancialDetails,
  OrderNotesEditModal,
  OrderNotesInfo,
  ProductsInfoHeader,
  ProductsInfoSingleRow,
} from '.';
import useSingleOrder from '../../hooks/useSingleOrder';

const OrderDetails = ({ fetchOrder }) => {
  //Redux
  const dispatch = useDispatch();
  const { order, tempFinancialDetailsWithKDV, descriptionEditText } = useSelector(
    (state) => state.orders.singleOrder,
  );
  const { userPermissions } = useSelector((state) => state.general.user);

  // Hooks

  const { updateDescription, updateFinancialDetails } = useSingleOrder(fetchOrder);
  return (
    <>
      <div className="bg-dark p-2 rounded">
        <span className="h6 mt-1 text-center d-inline-block">Sipariş Detayları</span>
      </div>
      {userPermissions?.includes(8) && (
        <div className="offer-details mx-2 mt-5">
          <ProductsInfoHeader />
          {order.productions.length > 0
            ? order.productions.map((product) => {
                return <ProductsInfoSingleRow key={product.id} product={product} />;
              })
            : ''}
        </div>
      )}

      <div className="row mx-2 p-0 mb-5">
        <OrderNotesInfo description={order.description} />

        {order && (
          <OrderNotesEditModal
            descriptionEditText={descriptionEditText}
            descriptionOnChange={(event) => dispatch(SET_DESCRIPTION_EDIT_TEXT(event.target.value))}
            onUpdate={updateDescription}
          />
        )}
        {userPermissions?.includes(8) && (
          <OrderFinancialDetails
            order={order}
            tempFinancialDetails={tempFinancialDetailsWithKDV}
            updateFinancialDetails={updateFinancialDetails}
          />
        )}
      </div>
    </>
  );
};

export default OrderDetails;
