import { useSelector, useDispatch } from 'react-redux';
import { START_LOADING, STOP_LOADING } from '../singleOrderSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const DeleteBalanceModal = ({ fetchOrder }) => {
  //Redux
  const { order } = useSelector((state) => state.orders.singleOrder);
  const dispatch = useDispatch();

  const handleDeleteLastBalance = async () => {
    const lastBalanceId = order.order_balances[order.order_balances.length - 1].id;
    dispatch(START_LOADING());
    try {
      await AtolyeAPI.get(`/order_balances/${lastBalanceId}/delete`);
      fetchOrder(order.id);
      alert('success', 'Son Ödeme Kaydı Silindi');
    } catch (error) {
      alert('error', 'Son Ödeme Kaydı Silinirken Hata Oluştu');
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };
  return (
    <div
      className="modal fade delete-balance-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div
          className="modal-content"
          style={{
            backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
          }}>
          <div className="modal-header">
            <h5 className="modal-title">Son Ödeme Kaydını Sil</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <strong>{order.id}</strong> numaralı siparişe ait son ödeme kaydını silmek
              istediğinize emin misiniz?
            </p>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={handleDeleteLastBalance}>
              Sil
            </button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteBalanceModal;
