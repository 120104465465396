import { Alert } from 'react-bootstrap';
import DashboardItem from '../../components/DashboardItem';
import Footer from '../../components/shared/Footer/Footer';
import Header from '../../components/shared/Header';
import Loading from '../../components/shared/Loading';

import Dashboard from './Dashboard';

export default Dashboard;
export { Alert, DashboardItem, Footer, Header, Loading };
