import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  imageLink: '/images/placeholder-image.png',
  productionToDelete: null,
  order: null,
  editInfoDefaults: null,
  productOptions: null,
  balances: [],
  descriptionEditText: '',
  privateNotesEditText: '',
  addBalance: {
    balanceReceived: 0,
    balanceUser: null,
    balanceDate: null,
    paymentMethod: 'Belirlenmedi',
  },
  selectedOrderId: null,
  tempFinancialDetailsWithKDV: {
    productsTotalWithKDV: 0,
    discountTotalWithKDV: 0,
    discountRate: 0,
    subTotalWithKDV: 0,
    total: 0,
  },
  sendMailTo: '',
  lastDeliveryDate: null,
  updateAllAs: 0,
  showUpload: false,
  invoiceLink: null,
  progress: null,
  isProductionStatusEditModalDisplayed: false,
  isProductionDeleteModalDisplayed: false,
  isAddProductModalDisplayed: false,
  productionIdToUpdate: null,
  productionDetailsForUpdate: {
    price: null,
    quantity: null,
    manufacturers_id: null,
    features: '',
    production_status_id: null,
    glass: null,
    material: null,
    branch: null,
    lampholder: null,
    color: null,
    specialNotes: null,
  },
  pdfType: 'default',
  pdfLanguage: 'tr',
  pdfCurrency: 'TL',
  isPDFModalDisplayed: false,
  recalculatedOrder: {
    orders_id: null,
    productsTotal: null,
    discountTotal: null,
    subTotal: null,
    KDVtotal: null,
    total: null,
    totalDiff: null,
  },
  selectedProduct: null,
  productToAdd: null,
};

export const singleOrderSlice = createSlice({
  name: 'singleOrder',
  initialState,
  reducers: {
    START_LOADING: (state) => {
      return { ...state, isLoading: true };
    },
    STOP_LOADING: (state) => {
      return { ...state, isLoading: false };
    },
    SELECT_ORDER: (state, action) => {
      return { ...state, selectedOrderId: action.payload };
    },
    SET_ORDER: (state, action) => {
      return { ...state, order: action.payload };
    },
    SET_ERROR: (state) => {
      return { ...state, isError: true };
    },
    CLEAR_ERROR: (state) => {
      return { ...state, isError: false };
    },
    SET_UPDATE_ALL_AS: (state, action) => {
      return { ...state, updateAllAs: action.payload };
    },
    SET_MAIL_TO: (state, action) => {
      return { ...state, sendMailTo: action.payload };
    },
    SET_USER: (state, action) => {
      return { ...state, order: { ...state.order, users_id: action.payload } };
    },
    SET_ORDER_MANAGER: (state, action) => {
      return { ...state, order: { ...state.order, orderManagerId: action.payload } };
    },
    SET_ORDER_STATUS: (state, action) => {
      return { ...state, order: { ...state.order, order_status_id: action.payload } };
    },
    SET_SALE_DATE: (state, action) => {
      return { ...state, order: { ...state.order, saleDate: new Date(action.payload) } };
    },
    SET_DELIVERY_DATE: (state, action) => {
      return { ...state, order: { ...state.order, deliveryDate: new Date(action.payload) } };
    },
    SET_DELIVERY_OPTION: (state, action) => {
      return { ...state, order: { ...state.order, deliveryOption: action.payload } };
    },
    SET_SALES_CHANNEL: (state, action) => {
      return { ...state, order: { ...state.order, salesChannel: action.payload } };
    },
    SET_BILL: (state, action) => {
      return { ...state, order: { ...state.order, bill: action.payload } };
    },
    SET_BILLING_ADDRESS: (state, action) => {
      return { ...state, order: { ...state.order, billingAddress: action.payload } };
    },
    RESET_ORDER: (state) => {
      return { ...state, order: state.editInfoDefaults };
    },
    SET_PRIVATE_NOTES_EDIT_TEXT: (state, action) => {
      return { ...state, privateNotesEditText: action.payload };
    },
    RESET_PRIVATE_NOTES_EDIT_TEXT: (state) => {
      return { ...state, privateNotesEditText: state.order.privateNotes };
    },
    SET_EDITING_PRODUCT: (state, action) => {
      return {
        ...state,
        productEditing: action.payload,
      };
    },
    SET_PRODUCTION_TO_DELETE: (state, action) => {
      return {
        ...state,
        productionToDelete: action.payload,
      };
    },
    SET_DESCRIPTION_EDIT_TEXT: (state, action) => {
      return {
        ...state,
        descriptionEditText: action.payload,
      };
    },
    SET_PAYMENT_METHOD: (state, action) => {
      return {
        ...state,
        addBalance: { ...state.addBalance, paymentMethod: action.payload },
      };
    },
    ADD_BALANCE: (state, action) => {
      return {
        ...state,
        addBalance: { ...state.addBalance, balanceReceived: parseFloat(action.payload) },
      };
    },
    RESET_ADD_BALANCE: (state) => {
      return {
        ...state,
        addBalance: {
          balanceReceived: 0,
          balanceUser: null,
          balanceDate: null,
          paymentMethod: 'Belirlenmedi',
        },
      };
    },
    SET_BALANCE_USER: (state, action) => {
      return {
        ...state,
        addBalance: { ...state.addBalance, balanceUser: action.payload },
      };
    },
    SET_BALANCE_DATE: (state, action) => {
      return {
        ...state,
        addBalance: { ...state.addBalance, balanceDate: new Date(action.payload) },
      };
    },
    SET_EDIT_INFO_DEFAULTS: (state, action) => {
      return {
        ...state,
        editInfoDefaults: action.payload,
      };
    },
    SHOW_PRODUCTION_DELETE_MODAL: (state) => {
      return {
        ...state,
        isProductionDeleteModalDisplayed: true,
      };
    },
    HIDE_PRODUCTION_DELETE_MODAL: (state) => {
      return {
        ...state,
        isProductionDeleteModalDisplayed: false,
      };
    },
    SHOW_PRODUCTION_EDIT_MODAL: (state, action) => {
      return {
        ...state,
        productionIdToUpdate: action.payload.id,
        recalculatedOrder: {
          orders_id: state.order.id,
          productsTotal: state.order.productsTotal,
          discountTotal: state.order.discountTotal,
          subTotal: state.order.subTotal,
          KDVtotal: state.order.KDVtotal,
          total: state.order.total,
          totalDiff: 0,
        },
        isProductionStatusEditModalDisplayed: true,
      };
    },
    HIDE_PRODUCTION_EDIT_MODAL: (state) => {
      return {
        ...state,
        isProductionStatusEditModalDisplayed: false,
        recalculatedOrder: {
          orders_id: null,
          productsTotal: null,
          discountTotal: null,
          subTotal: null,
          KDVtotal: null,
          total: null,
          totalDiff: null,
        },
      };
    },
    SET_PRODUCTION_DETAILS_FOR_UPDATE: (state, action) => {
      return {
        ...state,
        productionDetailsForUpdate: {
          ...state.productionDetailsForUpdate,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    SET_PRODUCTION_DETAILS_FOR_UPDATE_ALL: (state, action) => {
      return {
        ...state,
        productionDetailsForUpdate: action.payload,
      };
    },
    RESET_PRODUCTION_DETAILS_FOR_UPDATE: (state) => {
      return {
        ...state,
        productionDetailsForUpdate: {
          price: null,
          quantity: null,
          manufacturers_id: null,
          features: '',
          production_status_id: null,
          glass: null,
          material: null,
          branch: null,
          lampholder: null,
          color: null,
          specialNotes: null,
        },
      };
    },
    SET_PDF_TYPE: (state, action) => {
      return {
        ...state,
        pdfType: action.payload,
      };
    },
    SET_PDF_LANGUAGE: (state, action) => {
      return {
        ...state,
        pdfLanguage: action.payload,
      };
    },
    SET_PDF_CURRENCY: (state, action) => {
      return {
        ...state,
        pdfCurrency: action.payload,
      };
    },
    SHOW_PDF_MODAL: (state) => {
      return {
        ...state,
        isPDFModalDisplayed: true,
      };
    },
    HIDE_PDF_MODAL: (state) => {
      return {
        ...state,
        isPDFModalDisplayed: false,
      };
    },
    SET_TEMP_FINANCIAL_DETAILS: (state, action) => {
      return {
        ...state,
        tempFinancialDetailsWithKDV: {
          productsTotalWithKDV: action.payload.productsTotal * 1.18,
          discountTotalWithKDV: action.payload.discountTotal * 1.18,
          discountRate: action.payload.discountRate,
          subTotalWithKDV: action.payload.subTotal * 1.18,
          total: action.payload.total,
        },
      };
    },
    SET_TEMP_FINANCIAL_DETAILS_AFTER_RECALCULATE: (state, action) => {
      return {
        ...state,
        tempFinancialDetailsWithKDV: {
          ...state.tempFinancialDetailsWithKDV,
          discountTotalWithKDV: action.payload.discountTotal,
          discountRate: action.payload.discountRate,
          subTotalWithKDV: action.payload.subTotal,
          total: action.payload.total,
        },
      };
    },
    SET_RECALCULATED_ORDER: (state, action) => {
      return {
        ...state,
        recalculatedOrder: {
          orders_id: state.order.id,
          productsTotal: action.payload.productsTotal,
          discountTotal: action.payload.discountTotal,
          subTotal: action.payload.subTotal,
          KDVtotal: action.payload.total - action.payload.subTotal,
          total: action.payload.total,
          totalDiff: action.payload.totalDiff,
        },
      };
    },
    RESET_RECALCULATED_ORDER: (state) => {
      return {
        ...state,
        recalculatedOrder: {
          orders_id: null,
          productsTotal: null,
          discountTotal: null,
          subTotal: null,
          KDVtotal: null,
          total: null,
          totalDiff: null,
        },
      };
    },
    PICK_PRODUCT: (state, action) => {
      return { ...state, selectedProduct: action.payload };
    },
    SET_IMAGE_LINK: (state, action) => {
      return { ...state, imageLink: action.payload };
    },
    SET_PRODUCT_TO_ADD: (state, action) => {
      return {
        ...state,
        productToAdd: {
          ...action.payload,
          quantity: 1,
          material: '',
          glass: '',
          branch: '',
          color: '',
          lampholder: '',
          features: '',
          specialNotes: '',
          total: 0,
        },
      };
    },
    SET_VALUE_ON_PRODUCT_TO_ADD: (state, action) => {
      return {
        ...state,
        productToAdd: {
          ...state.productToAdd,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    CLEAR_PRODUCT_TO_ADD: (state) => {
      return {
        ...state,
        productToAdd: null,
        selectedProduct: null,
        imageLink: initialState.imageLink,
      };
    },
    SHOW_ADD_PRODUCT_MODAL: (state) => {
      return {
        ...state,
        isAddProductModalDisplayed: true,
      };
    },
    HIDE_ADD_PRODUCT_MODAL: (state) => {
      return {
        ...state,
        isAddProductModalDisplayed: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  ADD_BALANCE,
  RESET_ADD_BALANCE,
  START_LOADING,
  STOP_LOADING,
  SELECT_ORDER,
  SET_ORDER,
  SET_ERROR,
  CLEAR_ERROR,
  SET_UPDATE_ALL_AS,
  SET_MAIL_TO,
  SET_USER,
  SET_ORDER_MANAGER,
  SET_SALE_DATE,
  SET_DELIVERY_DATE,
  SET_DELIVERY_OPTION,
  SET_SALES_CHANNEL,
  SET_BILL,
  SET_BILLING_ADDRESS,
  RESET_ORDER,
  SET_PRIVATE_NOTES_EDIT_TEXT,
  RESET_PRIVATE_NOTES_EDIT_TEXT,
  SET_EDITING_PRODUCT,
  SET_PRODUCT_TO_DELETE,
  SET_DESCRIPTION_EDIT_TEXT,
  SET_PAYMENT_METHOD,
  SET_BALANCE_USER,
  SET_BALANCE_DATE,
  SET_ORDER_STATUS,
  SET_EDIT_INFO_DEFAULTS,
  SET_PRODUCTION_TO_DELETE,
  SHOW_PRODUCTION_DELETE_MODAL,
  HIDE_PRODUCTION_DELETE_MODAL,
  SHOW_PRODUCTION_EDIT_MODAL,
  HIDE_PRODUCTION_EDIT_MODAL,
  SET_PRODUCTION_DETAILS_FOR_UPDATE,
  RESET_PRODUCTION_DETAILS_FOR_UPDATE,
  SET_PRODUCTION_DETAILS_FOR_UPDATE_ALL,
  SET_PDF_TYPE,
  SET_PDF_LANGUAGE,
  SET_PDF_CURRENCY,
  SHOW_PDF_MODAL,
  HIDE_PDF_MODAL,
  SET_TEMP_FINANCIAL_DETAILS,
  SET_TEMP_FINANCIAL_DETAILS_AFTER_RECALCULATE,
  SET_RECALCULATED_ORDER,
  RESET_RECALCULATED_ORDER,
  PICK_PRODUCT,
  SET_IMAGE_LINK,
  SET_PRODUCT_TO_ADD,
  CLEAR_PRODUCT_TO_ADD,
  SET_VALUE_ON_PRODUCT_TO_ADD,
  SHOW_ADD_PRODUCT_MODAL,
  HIDE_ADD_PRODUCT_MODAL,
} = singleOrderSlice.actions;

export default singleOrderSlice.reducer;
