import { Modal, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_DELETE_MODAL, START_LOADING, STOP_LOADING } from '../userSlice';

import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { useNavigate } from 'react-router-dom';

const AtolyeAPI = new API();

const DeleteUserModal = () => {
  // Redux
  const { isDeleteModalOpen } = useSelector((state) => state.settings.user);
  const { id, email, name, lastName } = useSelector((state) => state.settings.user.userDetails);
  const dispatch = useDispatch();

  // Hooks
  const navigate = useNavigate();

  // Functions

  const handleDelete = async () => {
    dispatch(START_LOADING());

    try {
      const response = await AtolyeAPI.post(`/admin/user/${id}/delete`, { email });

      if (response.status === 'ERROR') {
        return alert('error', 'Kullanıcı silinirken bir hata oluştu!');
      }

      alert('success', `${name + ' ' + lastName} kullanıcısı başarıyla silindi!`);
      navigate('/user-list');
    } catch (error) {
      console.log(error);
      alert('error', 'Kullanıcı silinirken bir hata oluştu!');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(HIDE_DELETE_MODAL());
    }
  };

  return (
    <Modal show={isDeleteModalOpen} onHide={() => dispatch(HIDE_DELETE_MODAL())}>
      <Modal.Header closeButton>
        <Modal.Title>Kullanıcı Sil</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-0">
          <i className="fas fa-exclamation-triangle me-1"></i>
          <span className="font-14">Kullanıcıyı silmek istediğinize emin misiniz?</span>
        </p>
        <Alert variant="info mt-3">
          Bir kullanıcıyı sildiğinizde o kullanıcı artık sisteme giriş yapamaz ve kullanıcıya yeni
          sipariş, fiyat teklifi vb. atayamazsınız. Fakat kullanıcının daha önce oluşturduğu
          siparişler, fiyat teklifleri vb. veriler silinmez.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary btn-sm" onClick={() => dispatch(HIDE_DELETE_MODAL())}>
          <span className="font-14">Vazgeç</span>
        </button>
        <button className="btn btn-danger btn-sm" onClick={handleDelete}>
          <i className="fas fa-trash-alt me-1"></i>
          <span className="font-14">Sil</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUserModal;
