import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const DepotEditModal = ({ data, onSave, userId }) => {
  // Redux
  const { pageIndex, pageSize } = useSelector((state) => state.atolyeTable);

  // States
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [selectedDepotID, setSelectedDepotID] = useState(null);
  const [isLoading, setIsLoading] = useState(0);

  // Redux

  const { depotOptions } = useSelector((state) => state.productStockManagement);

  // Declarations

  const currentDepotID = data.original?.depot?.id || null;

  // Functions

  const handleDismiss = () => {
    setSelectedDepotID(null);
    setIsModalDisplayed(false);
  };

  const handleShow = () => {
    setSelectedDepotID(currentDepotID);
    setIsModalDisplayed(true);
  };

  const updateSelectedDepotID = async () => {
    setIsLoading(true);

    const body = {
      depots_id: selectedDepotID,
      updatedBy: userId,
    };

    try {
      if (data.values.isVariant) {
        await AtolyeAPI.put(`/product_stocks/variant/${data.values.variantId}`, body);
      } else {
        await AtolyeAPI.put(`/product_stocks/${data.values.id}`, body);
      }

      onSave(pageIndex, pageSize);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleDismiss();
    }
  };

  return (
    <div>
      <i
        style={{ marginRight: 5, marginBottom: 5, color: '#859fc7' }}
        className="fas fa-pen ms-2"
        role="button"
        onClick={handleShow}></i>

      <Modal
        show={isModalDisplayed}
        onHide={handleDismiss}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-dark" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Depoyu Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mt-4">
            <h4 className="text-danger">Depo Seçin</h4>
            <div className="d-flex justify-content-center">
              <Form.Select
                className="font-16 d-inline-block mx-2"
                value={selectedDepotID}
                onChange={(e) => setSelectedDepotID(e.target.value)}>
                <option value={null}>Belirlenmedi</option>
                {depotOptions.map((depot) => (
                  <option key={depot.id} value={depot.id}>
                    {depot.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleDismiss}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={updateSelectedDepotID}>
            {isLoading ? 'Güncelleniyor...' : 'Güncelle'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DepotEditModal;
