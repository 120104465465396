import './ResponsiveLoading.scss';

import BarWave from 'react-cssfx-loading/lib/BarWave';

const ResponsiveLoading = () => {
  return (
    <div className="responsive-loading">
      <BarWave />
    </div>
  );
};

export default ResponsiveLoading;
