import { useEffect, useState } from 'react';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useRoleOptions = () => {
  const [roleOptions, setRoleOptions] = useState([]);
  const [isRoleOptionsLoading, setIsRoleOptionsLoading] = useState(true);

  const getRoleOptions = async () => {
    try {
      setIsRoleOptionsLoading(true);
      const { results } = await AtolyeAPI.get('/roles');
      setRoleOptions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRoleOptionsLoading(false);
    }
  };

  useEffect(() => {
    getRoleOptions();
  }, []);

  return {
    roleOptions,
    isRoleOptionsLoading,
  };
};

export default useRoleOptions;
