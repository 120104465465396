import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useManufacturerOptions = () => {
  // State
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [isManufacturerOptionsLoading, setIsManufacturerOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getManufacturerOptions();
  }, []);

  // Functions
  const getManufacturerOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/manufacturers/options');
      setManufacturerOptions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsManufacturerOptionsLoading(false);
    }
  };

  return {
    isManufacturerOptionsLoading,
    manufacturerOptions,
    getManufacturerOptions,
  };
};

export default useManufacturerOptions;
