export const menuItems = [
  { label: 'Yönetim Paneli', link: '/dashboard', iconClass: 'fas fa-truck-loading' },
  //   { label: 'Raporlar', link: '/reports', iconClass: 'fas fa-chart-line' },
  {
    label: 'Siparişler',
    link: '#',
    iconClass: 'fas fa-truck-loading',
    hasSubMenu: true,
    permission: 7,
    subMenus: [
      {
        label: 'Sipariş Sorgulama',
        link: '/single-order',
        iconClass: 'far fa-circle',
        permission: 1,
      },
      { label: 'Sipariş Listesi', link: '/orders', iconClass: 'far fa-circle', permission: 7 },
      {
        label: 'Sipariş Notu Taslakları',
        link: '/order-notes',
        iconClass: 'far fa-circle',
        permission: 19,
      },
      {
        label: 'Ödeme Durumu',
        link: '/payment-status',
        iconClass: 'far fa-circle',
        permission: 18,
      },
      { label: 'Yeni Sipariş', link: '/create-order', iconClass: 'far fa-circle', permission: 2 },
    ],
  },
  {
    label: 'Fiyat Teklifleri',
    link: '#',
    iconClass: 'fas fa-tags',
    hasSubMenu: true,
    permission: 30,
    subMenus: [
      {
        label: 'Fiyat Teklifi Sorgulama',
        link: '/single-price-offer',
        iconClass: 'far fa-circle',
        permission: 3,
      },
      {
        label: 'Fiyat Teklif Listesi',
        link: '/price-offers',
        iconClass: 'far fa-circle',
        permission: 30,
      },
      {
        label: 'Yeni Teklif',
        link: '/create-price-offer',
        iconClass: 'far fa-circle',
        permission: 4,
      },
    ],
  },
  {
    label: 'Üretim',
    link: '#',
    iconClass: 'fas fa-industry',
    hasSubMenu: true,
    permission: 32,
    subMenus: [
      {
        label: 'Üretim Durumu Takibi',
        link: '/production',
        iconClass: 'far fa-circle',
        permission: 21,
      },
      //   { label: 'Üretici Listesi', link: '/manufacturers', iconClass: 'far fa-circle' },
      { label: 'Planlama', link: '/planning', iconClass: 'far fa-circle', permission: 16 },
    ],
  },

  {
    label: 'Müşteriler',
    link: '#',
    iconClass: 'fas fa-users',
    hasSubMenu: true,
    permission: 29,
    subMenus: [
      { label: 'Müşteri Sorgulama', link: '/single-customer', iconClass: 'far fa-circle' },
      { label: 'Müşteri Listesi', link: '/customers', iconClass: 'far fa-circle' },
      { label: 'Yeni Müşteri', link: '/create-customer', iconClass: 'far fa-circle' },
    ],
  },
  {
    label: 'Ürünler',
    link: '#',
    iconClass: 'fas fa-clipboard-list',
    hasSubMenu: true,
    permission: 23,
    subMenus: [
      {
        label: 'Ürün Sorgulama',
        link: '/single-product',
        iconClass: 'far fa-circle',
        permission: 5,
      },
      { label: 'Ürün Listesi', link: '/products', iconClass: 'far fa-circle', permission: 23 },
      { label: 'Ürün Etiketleri', link: '/tags', iconClass: 'far fa-circle', permission: 26 },
      {
        label: 'Satış Adedi Sorgula',
        link: '/sold-products',
        iconClass: 'far fa-circle',
        permission: 33,
      },
      { label: 'Yeni Ürün', link: '/create-product', iconClass: 'far fa-circle', permission: 6 },
    ],
  },
  {
    label: 'Stok Yönetimi',
    link: '#',
    iconClass: 'fas fa-box-open',
    hasSubMenu: true,
    permission: 31,
    subMenus: [
      {
        label: 'Ürün Stok Yönetimi',
        link: '/product-stock-management',
        iconClass: 'far fa-circle',
        permission: 31,
      },
      {
        label: 'Depolar',
        link: '/product-stock-management/depots',
        iconClass: 'far fa-circle',
        permission: 31,
      },
    ],
  },
  {
    label: 'Ürün Kütüphanesi',
    link: '#',
    iconClass: 'fas fa-boxes',
    hasSubMenu: true,
    permission: 34,
    subMenus: [
      {
        label: 'İmal Edilen Ürünler',
        link: '/manufacture-products',
        iconClass: 'far fa-circle',
        permission: 34,
      },
      {
        label: 'Parçalar',
        link: '/manufacture-components',
        iconClass: 'far fa-circle',
        permission: 34,
      },
      {
        label: 'Parça Kategorileri',
        link: '/component-categories',
        iconClass: 'far fa-circle',
        permission: 34,
      },
      {
        label: 'İşler',
        link: '/operations',
        iconClass: 'far fa-circle',
        permission: 34,
      },
    ],
  },
  {
    label: 'Kullanıcı Ayarları',
    link: '#',
    iconClass: 'far fa-user',
    hasSubMenu: true,
    permission: 12,
    subMenus: [
      {
        label: 'Kullanıcı Listesi',
        link: '/user-list',
        iconClass: 'far fa-circle',
        permission: 12,
      },
      { label: 'Yeni Kullanıcı', link: '/create-user', iconClass: 'far fa-circle', permission: 13 },
    ],
  },
  //   {
  //     label: 'Ürün Ayarları',
  //     link: '#',
  //     iconClass: 'fas fa-lightbulb',
  //     hasSubMenu: true,
  //     subMenus: [
  //       { label: 'Ürün Kategorileri', link: '/product-categories', iconClass: 'far fa-circle' },
  //       { label: 'Ürün Özellikleri', link: '/product-features', iconClass: 'far fa-circle' }
  //     ]
  //   }
];
