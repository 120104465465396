import React, { useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const AddStockOnProductModal = ({ show, onHide, getManufactureProduct, manufactureProduct }) => {
  // State
  const [quantity, setQuantity] = useState(0);

  const handleAdd = async () => {
    const body = {
      isVariant: manufactureProduct.hasVariant,
      products_id: manufactureProduct.product.id,
      variants_id: manufactureProduct.variant?.id,
      stock_count: manufactureProduct.stockCount + parseInt(quantity),
      component_stock_counts: manufactureProduct.components.map((component) => {
        const { manufactureProductHasComponent } = component;

        return {
          id: component.id,
          stock_count:
            component.stock_count - manufactureProductHasComponent.quantity * parseInt(quantity),
        };
      }),
      requestQuantity: manufactureProduct.requestQuantity - parseInt(quantity),
    };

    try {
      await AtolyeAPI.put(`/product_stocks/manufacture_stock/${manufactureProduct.id}`, body);

      alert('success', 'Stok Kaydı Eklendi');
      getManufactureProduct(manufactureProduct.id);
    } catch (error) {
      console.log(error);
      alert('error', 'Stok Kaydı Eklenemedi');
    } finally {
      onHide();
      setQuantity(0);
    }
  };

  const handleOnHide = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={handleOnHide}>
      <Modal.Header closeButton>
        <Modal.Title>Stoğa Ürün Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="info">
          İmal edilen ürünü stoğa eklemek için adet giriniz. Stok Adedi, kapasite miktarından ve
          talep miktarından fazla olamaz.
        </Alert>

        {manufactureProduct && (
          <>
            <div className="mx-2 my-2">
              <span>Kapasite Miktarı: </span>
              <span>{manufactureProduct.capacity}</span>
            </div>
            <div className="mx-2 my-2">
              <span>Talep Miktarı: </span>
              <span>{manufactureProduct.requestQuantity}</span>
            </div>
            <hr />
            <InputGroup size="sm" className="mt-3 mx-2">
              <InputGroup.Text>Eklenecek Stok Adedi</InputGroup.Text>
              <Form.Control
                style={{ maxWidth: 65 }}
                type="number"
                placeholder="Stok adedi giriniz"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                min={0}
                max={Math.min(manufactureProduct.capacity, manufactureProduct.requestQuantity)}
              />
            </InputGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnHide}>
          Vazgeç
        </Button>
        <Button variant="success" disabled={quantity === 0} onClick={handleAdd}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStockOnProductModal;
