import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  text: {
    // margin: 12,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  infoText: {
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
    color: '#777',
  },
  image: {
    marginBottom: 5,
    marginHorizontal: 0,
    width: 150,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'red',
    fontFamily: 'Open Sans',
  },
  headerContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 10,
    paddingHorizontal: 5,
  },
  logoView: {
    width: '40%',
  },
  headerBlankView: {
    width: '25%',
  },
  customerView: {
    width: '35%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    borderWidth: 1,
    borderColor: '#eee',
    borderRadius: 5,
    fontSize: 8,
    color: '#666',
  },
  customerTitleRow: {
    width: '100%',
    paddingTop: '1px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomColor: '#eee',
    backgroundColor: '#f8f8f8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: '15px',
    textAlign: 'center',
  },
  customerTitleText: {
    textAlign: 'center',
    fontFamily: 'Open Sans',
    color: '#333',
  },
  customerRow: {
    width: '100%',
    paddingTop: '2px',
    flexDirection: 'row',
    borderBottomColor: '#eee',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 'auto',
  },
  customerInfoLeft: {
    width: '30%',
    height: '100%',
    paddingTop: '1px',
    paddingLeft: '3px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
    fontFamily: 'Open Sans',
    color: '#333',
  },
  customerInfoRight: {
    width: '70%',
    height: '100%',
    paddingTop: '1px',
    paddingLeft: '3px',
    fontFamily: 'Open Sans',
  },

  // Table
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
    borderWidth: 1,
    borderColor: '#eee',
    borderRadius: 5,
    fontSize: 8,
    color: '#666',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#eee',
    backgroundColor: '#f8f8f8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: '20px',
    textAlign: 'center',
    // fontStyle: "bold",
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#eee',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 'auto',
    fontFamily: 'Open Sans',

    // fontStyle: "bold",
  },
  offerInfoLeft: {
    width: '55%',
    height: '100%',
    backgroundColor: '#f8f8f8',
    borderRightColor: '#eee',
    borderRightWidth: 1,
    paddingVertical: 3,
    paddingLeft: 3,
  },
  offerInfoRight: {
    width: '45%',
    height: '100%',
    paddingVertical: 3,
    paddingLeft: 3,
  },

  offerInfoColumnRight: {
    width: '32.5%',
    height: '100%',

    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  offerInfoNotes: {
    width: '67.5%',
    height: '100%',
    paddingTop: '5px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  prodImageTitle: {
    width: '20%',
    height: '100%',
    paddingTop: '5px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  paymentDetails: {
    width: '25%',
    height: '100%',
    paddingTop: '5px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  paymentText: {
    width: '25%',
    height: '100%',
    paddingTop: '5px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  tableTitle: {
    marginTop: '5px',
    fontFamily: 'Open Sans',
    textAlign: 'center',
    color: '#333',
  },

  tableImage: {
    width: '100%',
    height: 'auto!important',
    textAlign: 'center',
  },
  tableText: {
    // marginTop: "px",
    fontFamily: 'Open Sans',
  },

  tableTextProdCode: {
    fontFamily: 'Open Sans',
    textAlign: 'center',

    color: '#333',
    borderRadius: '5px',
  },
  prodImage: {
    width: '100%',
    height: 'auto',
  },
  prodImageContainer: {
    alignSelf: 'center',
    width: '20%',
    height: 180,
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  prodCode: {
    width: '12.5%',
    height: '100%',
    paddingTop: '5px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  description: {
    width: '35%',
    height: '100%',
    paddingTop: '5px',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  qty: {
    width: '7.5%',
    height: '100%',
    paddingTop: '5px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  price: {
    width: '12.5%',
    height: '100%',
    paddingTop: '5px',
    borderRightColor: '#eee',
    borderRightWidth: 1,
  },
  total: {
    height: '100%',
    width: '12.5%',
    paddingTop: '5px',
  },
  footerText: {
    marginTop: '25px',
    marginLeft: '10px',
    fontSize: 8,
    color: '#666',
    fontFamily: 'Open Sans',
  },
});
