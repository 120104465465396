import AtolyeNumberInput from 'components/AtolyeNumberInput';
import { useAtolyeAPI } from 'hooks';
import alert from 'js/sweetAlert';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const EditDepotRecord = ({ getDepot, show, setShow, selectedStock }) => {
  //Edit Depot Record Modal

  const { postTo } = useAtolyeAPI();

  const [loading, setLoading] = useState(false);
  const [stockCount, setStockCount] = useState(0);

  const handleEditDepotRecord = async () => {
    setLoading(true);

    try {
      const { results } = await postTo(`/depots/product-edit`, {
        isVariant: selectedStock?.isVariant,
        id: selectedStock?.id,
        stock_count: stockCount,
      });
      console.log(results);
      setShow(false);
      setLoading(false);
      getDepot();
    } catch (error) {
      console.log(error);
      alert('error', 'Depo kaydı düzenlenirken bir sorun oluştu!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setStockCount(parseInt(selectedStock?.stock_count));
  }, [selectedStock]);

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title>Depo Stok Kaydını Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <span className="">{selectedStock?.name}</span>
          </div>
          {/* Input Stock Count */}

          <AtolyeNumberInput title={'Stok Adedi'} value={stockCount} setValue={setStockCount} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShow(false)}>
            Vazgeç
          </Button>
          <Button variant="success" className="text-white" onClick={handleEditDepotRecord}>
            {loading ? 'Kaydediliyor...' : 'Kaydet'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditDepotRecord;
