import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { roundTo2Decimals } from 'utils/calcUtils';
import {
  HIDE_PRODUCTION_DELETE_MODAL,
  RESET_RECALCULATED_ORDER,
  SET_PRODUCTION_TO_DELETE,
  SET_RECALCULATED_ORDER,
  START_LOADING,
  STOP_LOADING,
} from '../singleOrderSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const DeleteProductModal = ({ fetchOrder }) => {
  //Redux
  const dispatch = useDispatch();
  const { order, isProductionDeleteModalDisplayed, productionToDelete, recalculatedOrder } =
    useSelector((state) => state.orders.singleOrder);

  // Effects
  useEffect(() => {
    recalculateOrderBeforeDelete();
  }, [productionToDelete]);

  // Functions
  const deleteProductHandler = async () => {
    dispatch(START_LOADING());
    const body = { ...recalculatedOrder };
    try {
      await AtolyeAPI.post(`/productions/${productionToDelete.id}/delete`, body);
      fetchOrder(order.id);
      alert('success', 'Ürün silindi');
    } catch (error) {
      console.log(error);
      alert('error', 'Ürün Silinirken Hata Oluştu');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(HIDE_PRODUCTION_DELETE_MODAL());
      dispatch(RESET_RECALCULATED_ORDER());
    }
  };

  const recalculateOrderBeforeDelete = () => {
    const productsWithoutDeletingProduct = order?.productions?.filter(
      (product) => product.id !== productionToDelete?.id,
    );
    const productsOnlyWithFinancialDataWithoutDeletingProduct = productsWithoutDeletingProduct.map(
      (product) => ({ id: product.id, price: product.price, quantity: product.quantity }),
    );

    const _tempProductsTotal = productsOnlyWithFinancialDataWithoutDeletingProduct.reduce(
      (acc, product) => {
        return acc + parseFloat(product.price * parseInt(product.quantity));
      },
      0,
    );
    const _tempDiscountTotal =
      _tempProductsTotal - _tempProductsTotal / (1 + order.discountRate / 100);
    const _tempSubTotal = _tempProductsTotal - _tempDiscountTotal;
    const _tempTotal = _tempSubTotal * 1.18;
    const _tempKDVtotal = _tempTotal - _tempSubTotal;

    dispatch(
      SET_RECALCULATED_ORDER({
        orders_id: order.id,
        productsTotal: _tempProductsTotal,
        discountTotal: _tempDiscountTotal,
        subTotal: _tempSubTotal,
        KDVtotal: _tempKDVtotal,
        total: roundTo2Decimals(_tempTotal),
        totalDiff: roundTo2Decimals(order.total - _tempTotal),
      }),
    );
  };

  const withKdv = (value) => {
    const _tempValue = roundTo2Decimals(value * 1.18);
    return _tempValue.toLocaleString('tr');
  };

  return (
    productionToDelete && (
      <Modal
        className="production-details-edit-modal"
        centered
        show={isProductionDeleteModalDisplayed}
        onHide={() => {
          dispatch(HIDE_PRODUCTION_DELETE_MODAL());
          dispatch(SET_PRODUCTION_TO_DELETE(null));
        }}>
        <Modal.Header>
          <Modal.Title>Ürünü Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong className="text-danger">{withKdv(productionToDelete.total)} ₺</strong>{' '}
            tutarındaki <strong>{productionToDelete.products_code}</strong> kodlu{' '}
            <strong>{productionToDelete.quantity}</strong> adet{' '}
            <strong>{productionToDelete.description}</strong>,{' '}
            <strong>{productionToDelete.orders_id}</strong> numaralı siparişten silinecektir.
          </p>
          <p>Sipariş Finansal Bilgileri aşağıdaki şekilde güncellenecektir:</p>
          {recalculatedOrder.orders_id && (
            <table className="table mb-4">
              <thead>
                <tr>
                  <th scope="col">Alan</th>
                  <th scope="col">Tutar/Değer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>Ürünler Toplamı</strong>
                  </td>
                  <td>{withKdv(recalculatedOrder.productsTotal)} ₺</td>
                </tr>
                <tr>
                  <td>
                    <strong>İskonto Oranı</strong>
                  </td>
                  <td>% {order.discountRate}</td>
                </tr>
                <tr>
                  <td>
                    <strong>İskonto Tutarı</strong>
                  </td>
                  <td>{withKdv(recalculatedOrder.discountTotal)} ₺</td>
                </tr>
                <tr>
                  <td>
                    <strong>Ara Toplam</strong>
                  </td>
                  <td>{withKdv(recalculatedOrder.subTotal)} ₺</td>
                </tr>
                <tr>
                  <td>
                    <strong>Sipariş Toplamı</strong>
                  </td>
                  <td>{recalculatedOrder.total.toLocaleString('tr')} ₺</td>
                </tr>
                <tr>
                  <td>
                    <strong>Kalan Ödemelerden İndirilecek Tutar</strong>
                  </td>
                  <td>{recalculatedOrder.totalDiff.toLocaleString('tr')} ₺</td>
                </tr>
              </tbody>
            </table>
          )}
          <p className="lead text-danger text-center">
            <strong>Onaylıyor musunuz?</strong>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="light"
            onClick={() => {
              dispatch(HIDE_PRODUCTION_DELETE_MODAL());
              dispatch(SET_PRODUCTION_TO_DELETE(null));
            }}>
            Vazgeç
          </Button>
          <Button variant="danger" className="text-white" onClick={deleteProductHandler}>
            Sil
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default DeleteProductModal;
