import { styles } from '../styles';

import { Text, View } from '@react-pdf/renderer';

const CustomerDetailsSection = ({ customer, translations, lang }) => {
  return (
    <View style={styles.customerView}>
      <View style={styles.customerTitleRow}>
        <Text style={styles.customerTitleText}> {translations.customerDetails[lang]}</Text>
      </View>
      <View style={styles.customerRow}>
        <Text style={styles.customerInfoLeft}>{translations.company[lang]}</Text>
        <Text style={styles.customerInfoRight}>{customer.company}</Text>
      </View>
      <View style={styles.customerRow}>
        <Text style={styles.customerInfoLeft}>{translations.nameSurname[lang]}</Text>
        <Text style={styles.customerInfoRight}>{customer.name + ' ' + customer.lastName}</Text>
      </View>
      <View style={styles.customerRow}>
        <Text style={styles.customerInfoLeft}>{translations.address[lang]}</Text>
        <Text style={styles.customerInfoRight}>{customer.address}</Text>
      </View>
      <View style={styles.customerRow}>
        <Text style={styles.customerInfoLeft}>{translations.phone[lang]}</Text>
        <Text style={styles.customerInfoRight}>{customer.phone}</Text>
      </View>
      <View style={styles.customerRow}>
        <Text style={styles.customerInfoLeft}>{translations.email[lang]}</Text>
        <Text style={styles.customerInfoRight}>{customer.email}</Text>
      </View>
    </View>
  );
};

export default CustomerDetailsSection;
