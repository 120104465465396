import { Alert, Button, Modal } from 'react-bootstrap';
import alert from 'js/sweetAlert';
import API from 'utils/atolyeAPI';
import Select from 'react-select';
import useProductOptions from 'hooks/useProductOptions';
import { useState } from 'react';

const AtolyeAPI = new API();

const AddManufactureProductModal = ({ show, onHide, getManufactureProducts }) => {
  // State
  const [product, setProduct] = useState(null);

  // Hooks
  const { productOptionsWithVariants, isProductOptionsLoading } = useProductOptions();

  // Functions
  const handleAdd = async () => {
    const body = {
      products_id: product.products_id,
      variants_id: product.variants_id,
    };

    try {
      await AtolyeAPI.post('/manufacture_products', body);
      alert('success', 'İmalat Ürünü Eklendi');
      getManufactureProducts();
    } catch (error) {
      alert('error', 'İmalat Ürünü Eklenemedi');
      console.log(error);
    } finally {
      onHide();
      setProduct(null);
    }
  };

  const handleOnHide = () => {
    setProduct(null);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleOnHide}>
      <Modal.Header closeButton>
        <Modal.Title>Yeni İmalat Ürünü Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="info">
          Ürünler ve tüm varyantları listelenmektedir. İmalat ürünü olarak eklemek istediğiniz ürünü
          seçiniz.
        </Alert>
        <Select
          className="text-dark mx-2"
          id="input-product"
          //   components={{
          //     Option: ({ data }) =>
          //       search ? (
          //         <div>
          //           <img
          //             src={data.imageLink}
          //             alt={data.label}
          //             style={{ width: 50, marginRight: 10 }}
          //           />
          //           {data.label}
          //         </div>
          //       ) : null,
          //   }}
          options={productOptionsWithVariants}
          isLoading={isProductOptionsLoading}
          //   onInputChange={(value) => setSearch(value)}
          onChange={({ products_id, variants_id, imageLink, label }) => {
            setProduct({ products_id, variants_id, imageLink, label });
          }}
          value={productOptionsWithVariants?.find((item) => item.label === product?.label) || ''}
          placeholder="Ürün Kodu veya Açıklama girin"
        />
        {/* Image Preview */}
        {product && (
          <div className="text-center my-3">
            <img
              className="manufacture-product-card-image bg-light"
              src={product.imageLink}
              alt={product.label}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnHide}>
          Vazgeç
        </Button>
        <Button variant="primary" disabled={!product} onClick={handleAdd}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddManufactureProductModal;
