import PermissionSwitch from './PermissionSwitch';

const PermissionGroup = ({ label, permissionOptions }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="p-3">
        <h6 className="bg-light text-center text-dark rounded py-1 px-2">{label}</h6>
        {permissionOptions?.map((permission) => (
          <PermissionSwitch key={`permission-${permission.id}`} permission={permission} />
        ))}
      </div>
    </div>
  );
};

export default PermissionGroup;
