import alert from 'js/sweetAlert';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from 'utils/atolyeAPI';
import { START_LOADING, STOP_LOADING, SET_TEMP_FINANCIAL_DETAILS } from '../singleOrderSlice';

const AtolyeAPI = new API();

const useSingleOrder = (fetch) => {
  //Redux
  const dispatch = useDispatch();
  const { order, descriptionEditText, tempFinancialDetailsWithKDV } = useSelector(
    (state) => state.orders.singleOrder,
  );

  // Effects
  useEffect(() => {
    order &&
      dispatch(
        SET_TEMP_FINANCIAL_DETAILS({
          productsTotal: order.productsTotal,
          discountTotal: order.discountTotal,
          discountRate: order.discountRate,
          subTotal: order.subTotal,
          total: order.total,
        }),
      );
  }, [order]);

  // Functions
  const updateDescription = async () => {
    dispatch(START_LOADING());
    const body = { description: descriptionEditText };
    try {
      await AtolyeAPI.put(`/orders/${order.id}`, body);
      fetch(order.id);
      alert('success', 'Özel Notlar Güncellendi');
    } catch (error) {
      console.log(error);
      alert('error', 'Özel Notlar Güncellenirken Hata Oluştu');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const updateFinancialDetails = async () => {
    if (tempFinancialDetailsWithKDV.discountRate > 100) {
      return alert('error', 'İskonto oranı 100den büyük olamaz...');
    }

    if (!validateFinancialDetailsUpdate()) {
      return alert('error', 'Lütfen girilen değerlerin geçerli olduğundan emin olunuz...');
    }

    dispatch(START_LOADING());
    const body = {
      ...tempFinancialDetailsWithKDV,
      totalDiff: order.total - tempFinancialDetailsWithKDV.total,
    };
    try {
      const result = await AtolyeAPI.put(`/orders/${order.id}/update-financial-details`, body);
      if (!result) {
        throw new Error('Sipariş Finansal Detayları Güncellenirken Hata Oluştu');
      }
      alert('success', 'Sipariş Finansal Detayları Güncellendi');
    } catch (error) {
      console.log(error);
      alert('error', 'Sipariş Finansal Detayları Güncellenirken Hata Oluştu');
    } finally {
      await fetch(order.id);
      dispatch(STOP_LOADING());
    }
  };

  const validateFinancialDetailsUpdate = () => {
    const isAnyValueNull =
      Object.values(tempFinancialDetailsWithKDV).filter((item) => !item && item !== 0).length > 0;

    const isAnyValueNaN =
      Object.values(tempFinancialDetailsWithKDV).filter((item) => isNaN(item)).length > 0;

    const isNotValidated = isAnyValueNull || isAnyValueNaN;

    return !isNotValidated;
  };

  return { updateDescription, updateFinancialDetails };
};

export default useSingleOrder;
