import './PageWrapper.scss';

const PageWrapper = ({ children }) => {
  return (
    <div className="row mx-md-2 mx-0 mt-2 py-3 position-relative">
      <div className="col-12 mx-auto">{children}</div>
    </div>
  );
};

export default PageWrapper;
