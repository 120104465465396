import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isBulkEditPricesModalDisplayed: false,
  isError: false,
  tagsToAdd: [],
  tagProducts: [],
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    START_LOADING: (state) => {
      return { ...state, isLoading: true };
    },
    STOP_LOADING: (state) => {
      return { ...state, isLoading: false };
    },
    SHOW_BULK_EDIT_PRICES_MODAL: (state) => {
      return { ...state, isBulkEditPricesModalDisplayed: true };
    },
    HIDE_BULK_EDIT_PRICES_MODAL: (state) => {
      return { ...state, isBulkEditPricesModalDisplayed: false };
    },
    SET_TAGS: (state, action) => {
      return {
        ...state,
        tagsToAdd: action.payload,
      };
    },
    SET_TAG_PRODUCTS: (state, action) => {
      return {
        ...state,
        tagProducts: action.payload,
      };
    },
    SET_COST: (state, action) => {
      const updatedTagProducts = state.tagProducts.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, cost: action.payload.value, isTouched: true };
        }
        return item;
      });

      return {
        ...state,

        tagProducts: updatedTagProducts,
      };
    },
    SET_SALE_PRICE: (state, action) => {
      const updatedTagProducts = state.tagProducts.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, salePrice: action.payload.value, isTouched: true };
        }
        return item;
      });

      return {
        ...state,

        tagProducts: updatedTagProducts,
      };
    },
    RESET: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  RESET,
  START_LOADING,
  STOP_LOADING,
  SET_COST,
  SET_SALE_PRICE,
  SET_TAGS,
  SET_TAG_PRODUCTS,
  SHOW_BULK_EDIT_PRICES_MODAL,
  HIDE_BULK_EDIT_PRICES_MODAL,
} = productsSlice.actions;

export default productsSlice.reducer;
