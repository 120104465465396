import alert from 'js/sweetAlert';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const EditOperationModal = ({ show, setShow, getOperations, operation }) => {
  // State
  const [name, setName] = useState('');

  // Functions
  const saveHandler = async () => {
    const body = {
      oldName: operation.value,
      newName: name,
      ids: operation.ids,
    };

    if (!name) return alert('error', 'İş adı boş bırakılamaz');

    try {
      await AtolyeAPI.post(`/operations/update-name`, body);
      alert('success', 'İş Güncellendi');
      getOperations();
    } catch (error) {
      console.log(error);
      alert('error', 'İş Güncellenemedi');
    } finally {
      setShow(false);
    }
  };

  // Effects
  useEffect(() => {
    operation && setName(operation.value);
  }, [operation]);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title>İş Düzenle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>İş Adı</Form.Label>
          <Form.Control
            style={{ maxWidth: 200 }}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={() => setShow(false)}>
          Vazgeç
        </Button>
        <Button variant="success" className="text-white" size="sm" onClick={saveHandler}>
          Güncelle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOperationModal;
