import { Alert, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_NEW_ROLE_MODAL, SET_NEW_ROLE, START_LOADING, STOP_LOADING } from '../userSlice';
import useRoleOptions from '../hooks/useRoleOptions';

import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import useUserDetails from '../hooks/useUserDetails';

const AtolyeAPI = new API();

const EditUserRoleModal = () => {
  // Redux
  const { isNewRoleModalOpen, newRole, userDetails } = useSelector((state) => state.settings.user);
  const dispatch = useDispatch();

  // Hooks
  const { roleOptions, isRoleOptionsLoading } = useRoleOptions();
  const { getUserDetails } = useUserDetails();

  // Functions

  const handleNewRole = async () => {
    dispatch(START_LOADING());
    try {
      await AtolyeAPI.post(`/admin/user/${userDetails.id}/role`, {
        roles_id: newRole,
      });
      dispatch(HIDE_NEW_ROLE_MODAL());
      getUserDetails();
    } catch (error) {
      console.log(error);
      alert('error', 'Kullanıcı rolü güncellenirken bir hata oluştu.');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <Modal show={isNewRoleModalOpen} onHide={() => dispatch(HIDE_NEW_ROLE_MODAL())}>
      <Modal.Header closeButton>
        <Modal.Title>Kullanıcı Durumunu Güncelle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-0">
          <span className="font-14">Yeni Rol</span>
          {isRoleOptionsLoading ? (
            <span className="font-14">Yükleniyor...</span>
          ) : (
            <Form.Select
              value={newRole}
              onChange={(e) => dispatch(SET_NEW_ROLE(e.target.value))}
              className="form-select-sm mt-2">
              <option value="" disabled>
                Seçiniz
              </option>
              {roleOptions.map((role) =>
                [4, 5].includes(role.id) ? (
                  <option key={role.id} disabled value={role.id}>
                    {role.name}
                  </option>
                ) : (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ),
              )}
            </Form.Select>
          )}
        </p>
        {parseInt(newRole) !== userDetails?.roles_id && (
          <Alert variant="warning" className="mt-2 font-14" style={{ lineHeight: '1.5rem' }}>
            <i className="fas fa-exclamation-triangle me-1"></i>
            <span className="font-14">
              Kullanıcıya{' '}
              <strong>{roleOptions.find((role) => role.id === parseInt(newRole))?.name}</strong>{' '}
              rolüne göre standart yetkiler atanacaktır.
            </span>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light btn-sm" onClick={() => dispatch(HIDE_NEW_ROLE_MODAL())}>
          <span className="font-14">Vazgeç</span>
        </button>
        <button
          className="btn btn-success btn-sm text-white"
          disabled={parseInt(newRole) === userDetails?.roles_id}
          onClick={handleNewRole}>
          <i className="fas fa-check  me-1"></i>
          <span className="font-14">Güncelle</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserRoleModal;
