import { useUser } from 'hooks';
import { ContentWrapper, Footer, Header, PageWrapper } from '../../ProductStockManagement';

import DeleteDepotRecord from './components/DeleteDepotRecord';
import StocksTable from './components/StocksTable';
import DepotTitle from './components/DepotTitle';
import NoStocksMessage from './components/NoStocksMessage';
import useSingleDepot from './useSingleDepot';
import EditDepotRecord from './components/EditDepotRecord';
import { Button } from 'react-bootstrap';
import AddDepotRecord from './components/AddDepotRecord';
import { Link } from 'react-router-dom';

const SingleDepot = (props) => {
  // Hooks
  const { user } = useUser();
  const {
    depot,
    stocks,
    getDepot,
    showDeleteDepotRecordModal,
    setShowDeleteDepotRecordModal,
    selectedStock,
    showDeleteDepotRecord,
    showEditDepotRecordModal,
    setShowEditDepotRecordModal,
    showEditDepotRecord,
    setShowAddDepotRecordModal,
    showAddDepotRecordModal,
  } = useSingleDepot();

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageWrapper>
          <DepotTitle title={depot?.name} />
          <div className="d-flex my-4">
            <Button
              variant="success"
              size="sm"
              className="text-white  me-auto my-auto"
              onClick={() => setShowAddDepotRecordModal(true)}>
              + Depo Stok Kaydı Ekle
            </Button>
            <Link className="ms-auto my-auto" to="/product-stock-management/depots">
              Tüm Depolar
            </Link>
          </div>

          <StocksTable
            stocks={stocks}
            showDeleteDepotRecord={showDeleteDepotRecord}
            showEditDepotRecord={showEditDepotRecord}
          />
          {stocks?.length === 0 && <NoStocksMessage />}
          <DeleteDepotRecord
            show={showDeleteDepotRecordModal}
            setShow={setShowDeleteDepotRecordModal}
            selectedStock={selectedStock}
            getDepot={getDepot}
          />
          <EditDepotRecord
            show={showEditDepotRecordModal}
            setShow={setShowEditDepotRecordModal}
            selectedStock={selectedStock}
            getDepot={getDepot}
          />
          <AddDepotRecord
            depots_id={depot?.id}
            getDepot={getDepot}
            show={showAddDepotRecordModal}
            setShow={setShowAddDepotRecordModal}
          />
        </PageWrapper>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default SingleDepot;
