import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_DESCRIPTION_EDIT_TEXT } from 'pages/Order/SingleOrder/singleOrderSlice';

import API from 'utils/atolyeAPI';
const AtolyeAPI = new API();

const OrderNotesEditModal = ({ descriptionEditText, descriptionOnChange, onUpdate }) => {
  //States
  const [loading, setLoading] = useState(true);
  const [orderNotes, setOrderNotes] = useState([]);
  const [selectedOrderNote, setSelectedOrderNote] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.orders.singleOrder);

  // Effects
  useEffect(() => {
    getOrderNotes();
  }, []);

  useEffect(() => {
    selectedOrderNote != 0
      ? dispatch(
          SET_DESCRIPTION_EDIT_TEXT(
            orderNotes.find((item) => item.id == selectedOrderNote).description,
          ),
        )
      : dispatch(SET_DESCRIPTION_EDIT_TEXT(order.description));
  }, [selectedOrderNote]);

  // Functions

  const getOrderNotes = async () => {
    setLoading(true);
    try {
      const { results } = await AtolyeAPI.get('/order_notes');
      setOrderNotes(results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal fade edit-description-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div
          className="modal-content"
          style={{
            backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
          }}>
          <div className="modal-header bg-primary">
            <h5 className="modal-title">Özel Notlar - Düzenle</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="w-100">Sipariş Notu Taslağı seçin: </label>
              {loading ? (
                'Seçenekler yükleniyor...'
              ) : (
                <select
                  style={{ maxWidth: 400 }}
                  className="form-control"
                  value={selectedOrderNote}
                  onChange={(e) => setSelectedOrderNote(e.target.value)}>
                  <option value={0}>Mevcut Not</option>
                  {orderNotes.map((item, index) => (
                    <option key={`orderNote-${index}`} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              )}
            </div>

            <textarea
              style={{ fontSize: 14 }}
              name="description"
              id="description"
              rows="8"
              className="form-control"
              value={descriptionEditText}
              onChange={descriptionOnChange}></textarea>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={onUpdate}>
              Kaydet
            </button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderNotesEditModal;
