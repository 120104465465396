import { styles } from '../styles';

import { Text, View } from '@react-pdf/renderer';

const PaymentDetailsSection = ({ balances, translations, lang, user, currency }) => {
  return (
    <View wrap={false}>
      <Text style={[styles.tableTitle, { textAlign: 'center', fontSize: 14, marginTop: 20 }]}>
        {translations.paymentDetails[lang]}
      </Text>

      <View style={styles.tableContainer}>
        <View style={styles.container}>
          <Text style={styles.paymentDetails}>
            <Text style={styles.tableTitle}>{translations.date[lang]}</Text>
          </Text>
          <Text style={styles.paymentDetails}>
            <Text style={styles.tableTitle}>{translations.receivedPayment[lang]}</Text>
          </Text>
          <Text style={styles.paymentDetails}>
            <Text style={styles.tableTitle}>{translations.remainingPayment[lang]}</Text>
          </Text>
          <Text style={styles.paymentDetails}>
            <Text style={styles.tableTitle}>{translations.receiverPerson[lang]}</Text>
          </Text>
        </View>

        {balances.length !== 0 &&
          balances.map((balance) => {
            return (
              <View key={balance.id} style={styles.row}>
                <Text style={styles.paymentText}>
                  <Text style={{ textAlign: 'center' }}>
                    {new Date(balance.date).toLocaleDateString('tr')}
                  </Text>
                </Text>
                <Text style={styles.paymentText}>
                  <Text style={{ textAlign: 'center' }}>
                    {balance.received.toLocaleString()} {currency}
                  </Text>
                </Text>
                <Text style={styles.paymentText}>
                  <Text style={{ textAlign: 'center' }}>
                    {balance.remainder.toLocaleString()} {currency}
                  </Text>
                </Text>
                <Text style={styles.paymentText}>
                  <Text style={{ textAlign: 'center' }}>{user.fullName}</Text>
                </Text>
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default PaymentDetailsSection;
