import { Button, Modal, Spinner } from 'react-bootstrap';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { useState } from 'react';

const AtolyeAPI = new API();

const DeleteOrderNoteModal = ({ show, onDismiss, note, refresh }) => {
  // State

  const [isLoading, setIsLoading] = useState(false);

  // Functions

  const deleteOrderNote = async () => {
    setIsLoading(true);

    try {
      const { status } = await AtolyeAPI.get(`/order_notes/${note.id}/delete`);

      if (status && status === 'SUCCESS') {
        return alert('success', 'Sipariş Notu Taslağı başarıyla silindi');
      }
      return alert('error', 'Sipariş Notu Taslağı silinirken bir hata oluştu');
    } catch (error) {
      alert('error', 'Sipariş Notu Taslağı silinirken bir hata oluştu');
      console.log(error);
    } finally {
      setIsLoading(false);
      onDismiss();
      refresh(true);
    }
  };

  return (
    <Modal show={show} onHide={onDismiss}>
      <Modal.Header className="bg-danger" closeButton>
        <Modal.Title>Taslak No: {note.id} - Sil</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>{note.id}</strong> numaralı ve <strong>{note.name}</strong> adlı Sipariş Notu
          Taslağı silinecektir. Silmek istediğinize emin misiniz?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onDismiss}>
          Vazgeç
        </Button>
        <Button variant="danger" onClick={() => deleteOrderNote()}>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            'Sil'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteOrderNoteModal;
