import { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import 'react-dates/lib/css/_datepicker.css';

import 'react-dates/initialize';

const AtolyeDatePicker = ({ setDateFilter, isPriceOffersDateFilter = false }) => {
  // Constants
  const dateTypeFilterOptions = [
    { value: 'saleDate', label: 'Sipariş Tarihi' },
    { value: 'deliveryDate', label: 'Teslim Tarihi' },
  ];
  //State
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(null);
  const [datePickerValue, setDatePickerValue] = useState('Tüm Zamanlar');
  const [isDateTypeFirstSelected, setIsDateTypeFirstSelected] = useState(true);

  // Functions
  const setDatePickerHandler = (value) => {
    setDatePickerValue(value);
    switch (value) {
      case 'Tüm Zamanlar':
        setStartDate(moment('01-01-1970'));
        setEndDate(moment());
        break;
      case 'Bugün':
        setStartDate(moment());
        setEndDate(moment());
        break;

      case 'Yarın':
        setStartDate(moment().add(1, 'day'));
        setEndDate(moment().add(1, 'day'));
        break;

      case 'Dün':
        setStartDate(moment().add(-1, 'day'));
        setEndDate(moment().add(-1, 'day'));
        break;

      case 'Son 7 Gün':
        setStartDate(moment().add(-6, 'days'));
        setEndDate(moment());
        break;

      case 'Son 14 Gün':
        setStartDate(moment().add(-13, 'days'));
        setEndDate(moment());
        break;

      case 'Son 4 Hafta':
        // moment().add(-4, 'weeks').add(-1, 'day')
        setStartDate(moment().add(-4, 'weeks').add(-1, 'day'));
        setEndDate(moment());
        break;

      case 'Son 3 Ay':
        setStartDate(moment().add(-3, 'months').add(-1, 'day'));
        setEndDate(moment());
        break;

      case 'Son 12 Ay':
        setStartDate(moment().add(-12, 'months').add(-1, 'day'));
        setEndDate(moment());
        break;

      case 'Özel Tarih Aralığı':
        setStartDate(moment());
        setEndDate(moment());
        break;

      default:
        setStartDate(moment());
        setEndDate(moment());
        break;
    }
  };

  const customRangeOnClickHandler = () => {
    setDatePickerValue('Özel Tarih Aralığı');
    setFocusedInput('startDate');
  };

  // Effects

  useEffect(() => {
    // Before creating the request object check the date type
    const datePickerChangeReqObj = isPriceOffersDateFilter
      ? {
          startDate: startDate?.format('YYYY-MM-DD') ?? '',
          endDate: endDate?.format('YYYY-MM-DD') ?? '',
          type: 'createdAt',
        }
      : isDateTypeFirstSelected
      ? {
          startDate: startDate?.format('YYYY-MM-DD') ?? '',
          endDate: endDate?.format('YYYY-MM-DD') ?? '',
          type: dateTypeFilterOptions?.[0]?.value,
        }
      : {
          startDate: startDate?.format('YYYY-MM-DD') ?? '',
          endDate: endDate?.format('YYYY-MM-DD') ?? '',
          type: dateTypeFilterOptions?.[1]?.value,
        };

    setDateFilter(datePickerChangeReqObj);
  }, [startDate, endDate, isDateTypeFirstSelected]);

  return (
    <div className="p-3 hide-on-print">
      <div className="m-2 font-weight-bold">Tarihe Göre Filtrele :</div>

      <div className="d-flex mx-2">
        <DropdownButton className="table-button" id="date-picker-button" title={datePickerValue}>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Tüm Zamanlar
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Bugün
          </Dropdown.Item>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Yarın
          </Dropdown.Item>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Dün
          </Dropdown.Item>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Son 7 Gün
          </Dropdown.Item>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Son 14 Gün
          </Dropdown.Item>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Son 4 Hafta
          </Dropdown.Item>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Son 3 Ay
          </Dropdown.Item>
          <Dropdown.Item
            className="font-12"
            onClick={(e) => setDatePickerHandler(e.target.innerText)}>
            Son 12 Ay
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item className="font-12" onClick={customRangeOnClickHandler}>
            Özel Tarih Aralığı
          </Dropdown.Item>
        </DropdownButton>
        <DateRangePicker
          startDatePlaceholderText="İlk Tarih"
          endDatePlaceholderText="Son Tarih"
          noBorder
          small
          withPortal
          displayFormat="DD MMM YYYY"
          isOutsideRange={() => false}
          firstDayOfWeek={1}
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="id_start_date" // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="id_end_date" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([startDate, endDate]) or null,
          onFocusChange={(focusedInput) => {
            setFocusedInput(focusedInput);
            setDatePickerValue('Özel Tarih Aralığı');
          }} // PropTypes.func.isRequired,
        />
        {!isPriceOffersDateFilter && (
          <div className="date-type-switch my-auto d-flex align-content-center ms-3">
            <span className={`font-12 my-auto`}>{dateTypeFilterOptions?.[0]?.label}</span>
            <div className="form-check form-switch d-inline-block mx-1">
              <input
                className="form-check-input"
                type="checkbox"
                id="dateTypeSwitch"
                onChange={() => setIsDateTypeFirstSelected(!isDateTypeFirstSelected)}
              />
            </div>
            <span className={`font-12 my-auto`}>{dateTypeFilterOptions?.[1]?.label}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AtolyeDatePicker;
