import { useEffect, useState } from 'react';

import useAtolyeAPI from '../../hooks/useAtolyeAPI';
import useUser from '../../hooks/useUser';

import { Alert, Footer, Header, Loading } from '.';
import ContentWrapper from 'components/shared/ContentWrapper';
import InfoIndicators from './components/InfoIndicators';
import UpcomingOrdersTable from './components/UpcomingOrdersTable';
import UndeliveredOrdersTable from './components/UndeliveredOrdersTable';
import PageWrapper from 'components/shared/PageWrapper';

const Dashboard = (props) => {
  //States
  const [apiData, setApiData] = useState(null);
  const [upcomingOrders, setUpcomingOrders] = useState(null);
  const [undeliveredOrders, setUndeliveredOrders] = useState(null);
  // Hooks
  const { user, userFullName } = useUser();
  const { fetchFrom, isLoading, isError } = useAtolyeAPI();

  // Effects

  useEffect(() => {
    getDashboardData();
    getUpcomingAndUndeliveredOrders();
  }, []);

  const getDashboardData = async () => {
    const data = await fetchFrom('/dashboard');
    setApiData(data);
  };

  const getUpcomingAndUndeliveredOrders = async () => {
    const data = await fetchFrom('/orders/upcoming-and-undelivered');

    setUpcomingOrders(data.upcomingOrders);
    setUndeliveredOrders(data.undeliveredOrders);
  };

  return (
    <>
      <Header user={user} {...props} />
      {isLoading ? (
        <Loading />
      ) : (
        <ContentWrapper>
          <PageWrapper>
            <div className="page-title">
              <span>Yönetim Paneli</span>
            </div>
            {isError ? (
              <div className="m-5">
                <Alert variant="danger">Veriler getirilirken bir hata oluştu</Alert>
              </div>
            ) : (
              <>
                <div className="text-center mt-5">
                  <img width={125} src="/images/business-report.png" />
                </div>
                <div className="text-dark mt-5">
                  <p>
                    Merhaba <strong>{userFullName}</strong>,
                  </p>
                  <small className="text-muted">
                    Tarih: {new Date().toLocaleDateString('tr-TR')}
                  </small>
                </div>
                <UpcomingOrdersTable
                  upcomingOrders={upcomingOrders}
                  title={'Yaklaşan Siparişler'}
                  user={user}
                />
                <UndeliveredOrdersTable
                  undeliveredOrders={undeliveredOrders}
                  title={'Günü Geçen Siparişler'}
                  user={user}
                />
                <InfoIndicators apiData={apiData} title={'Anlık Durum Bilgileri'} />
              </>
            )}
          </PageWrapper>
        </ContentWrapper>
      )}
      <Footer />
    </>
  );
};

export default Dashboard;
