import './ContentWrapper.scss';

import { useSelector } from 'react-redux';

const ContentWrapper = ({ children }) => {
  //Redux
  const { isMenuOpen } = useSelector((state) => state.general);

  return (
    <div className={`content-wrapper menu-${isMenuOpen ? 'open' : 'close'} p-2`}>{children}</div>
  );
};

export default ContentWrapper;
