export const defaultDescription = `1- Termin Süresi : Sipariş onayını müteakip 2-3 Haftadır.                         
2- Siparişin toplam tutarı tahsil edilmeden ürün teslimatı yapılmaz.
3- Özel imalat ürünlerde iade kabul edilmez.
4- Teklifimiz, verildiği tarihten itibaren 10 gün geçerlidir.
5- Ampuller hediye olarak verilmektedir, iade ve değişim yapılmamaktadır.`;

export const deliveryOptions = [
  { value: 'Mağazadan Teslim', label: 'Mağazadan Teslim' },
  { value: 'Adrese Teslim', label: 'Adrese Teslim' },
  { value: 'Adrese Kargo', label: 'Adrese Kargo' },
  { value: 'Adrese Kasa', label: 'Adrese Kasa' },
  { value: 'Montaj', label: 'Montaj' },
];

export const salesChannelOptions = [
  { value: 'Belirlenmedi', label: 'Belirlenmedi' },
  { value: 'Münferit Mağaza', label: 'Münferit Mağaza' },
  { value: 'İnternet', label: 'İnternet' },
  { value: 'Proje', label: 'Proje' },
  { value: 'İhracat Satışı', label: 'İhracat Satışı' },
  { value: 'Diğer', label: 'Diğer' },
];
