import alert from 'js/sweetAlert';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const AddComponentCategoryModal = ({ getComponentCategories, show, setShow }) => {
  // State
  const [name, setName] = useState('');
  const saveHandler = async () => {
    const body = {
      name,
    };

    if (!name) return alert('error', 'Kategori adı boş bırakılamaz');

    try {
      await AtolyeAPI.post(`/component_categories`, body);
      alert('success', 'Parça Kategorisi Eklendi');
      getComponentCategories();
    } catch (error) {
      console.log(error);
      alert('error', 'Parça Kategorisi Eklenemedi');
    } finally {
      setShow(false);
      setName('');
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton className="bg-success">
        <Modal.Title>Parça Kategorisi Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Kategori Adı</Form.Label>
          <Form.Control
            style={{ maxWidth: 200 }}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={() => setShow(false)}>
          Vazgeç
        </Button>
        <Button variant="success" className="text-white" size="sm" onClick={saveHandler}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddComponentCategoryModal;
