import { useState } from 'react';
import { ContentWrapper, Footer, Form, Header, PageTitle, PageWrapper, useUser, Button } from '.';
import alert from 'js/sweetAlert';
import { Alert, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useRoleOptions from './User/hooks/useRoleOptions';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const CreateUser = (props) => {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isPassAgainVisible, setIsPassAgainVisible] = useState(false);

  // Hooks
  const { user, userPermissions } = useUser();
  const navigate = useNavigate();
  const { roleOptions } = useRoleOptions();

  // Functions
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate if all fields are filled
    if (!name || !lastName || !email || !password || !passwordAgain || !role) {
      return alert('error', 'Lütfen tüm alanları doldurun!');
    }
    // Validate if passwords are the same
    if (password !== passwordAgain) {
      return alert('error', 'Şifreler aynı değil!');
    }

    console.log(name, lastName, email, role, password);
    createUser();
  };

  const createUser = async () => {
    setIsLoading(true);

    const body = {
      name,
      lastName,
      fullName: `${name} ${lastName}`,
      email,
      roles_id: role,
      password,
    };

    try {
      const response = await AtolyeAPI.post('/admin/user', body);
      console.log(response);
      if (response.status === 'ERROR') {
        return alert('error', 'Kullanıcı oluşturulurken bir hata oluştu!');
      }
      alert('success', 'Kullanıcı başarıyla oluşturuldu!');
      navigate('/user-list');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      alert('error', 'Kullanıcı oluşturulurken bir hata oluştu!');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Yeni Kullanıcı Oluştur" />
        {!userPermissions?.includes(13) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong> Kullanıcı oluşturmak için yetkiniz bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            <Form className="col-md-4 mx-auto">
              <Form.Label className="mt-3">Adı</Form.Label>
              <Form.Control
                type="text"
                placeholder="Adı"
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Label className="mt-3">Soyadı</Form.Label>
              <Form.Control
                type="text"
                placeholder="Soyadı"
                onChange={(e) => setLastName(e.target.value)}
              />
              <Form.Label className="mt-3">E-posta</Form.Label>
              <Form.Control
                type="email"
                placeholder="E-posta"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Label className="mt-3">Kullanıcı Rolü</Form.Label>
              {/* Select User Role */}
              <Form.Select value={role} onChange={(e) => setRole(e.target.value)}>
                <option value="" disabled>
                  Seçiniz
                </option>
                {roleOptions.map((role) =>
                  [4, 5].includes(role.id) ? (
                    <option key={role.id} disabled value={role.id}>
                      {role.name}
                    </option>
                  ) : (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ),
                )}
              </Form.Select>
              <Form.Label className="mt-3">Geçici Şifre</Form.Label>
              <InputGroup>
                <Form.Control
                  type={isPassVisible ? 'text' : 'password'}
                  placeholder="Geçici Şifre"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputGroup.Text>
                  <i
                    onClick={() => setIsPassVisible(!isPassVisible)}
                    className={isPassVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                </InputGroup.Text>
              </InputGroup>
              <Form.Label className="mt-3">Geçici Şifre (tekrar)</Form.Label>

              <InputGroup>
                <Form.Control
                  type={isPassAgainVisible ? 'text' : 'password'}
                  placeholder="Geçici Şifre (tekrar)"
                  onChange={(e) => setPasswordAgain(e.target.value)}
                />
                <InputGroup.Text>
                  <i
                    onClick={() => setIsPassAgainVisible(!isPassAgainVisible)}
                    className={isPassAgainVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                </InputGroup.Text>
              </InputGroup>

              {/* Submit Button */}
              <Button
                type="submit"
                className="btn btn-success text-white mt-4"
                onClick={handleSubmit}>
                {isLoading ? <i className="fas fa-spinner fa-spin"></i> : 'Kullanıcıyı Oluştur'}
              </Button>
            </Form>
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default CreateUser;
