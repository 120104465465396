import { createSlice } from '@reduxjs/toolkit';
import { defaultDescription } from './util';

const initialState = {
  isLoading: false,
  isError: false,
  isCustomerModalDisplayed: false,
  priceOffer: {
    createdAt: null,
    description: defaultDescription,
    productsTotal: 0,
    discountRate: 0,
    kdv: 18,
    kdvTotal: 0,
    subTotal: 0,
    discountTotal: 0,
    total: 0,
    createdBy: null,
  },
  editingCustomer: {
    name: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    billingAddress: '',
  },
  products: [],
  selectedProduct: null,
  imageLink: 'https://via.placeholder.com/150',
};

export const createPriceOfferSlice = createSlice({
  name: 'createPriceOffer',
  initialState,
  reducers: {
    START_LOADING: (state) => {
      return { ...state, isLoading: true };
    },
    STOP_LOADING: (state) => {
      return { ...state, isLoading: false };
    },

    SET_ERROR: (state) => {
      return { ...state, isError: true };
    },
    CLEAR_ERROR: (state) => {
      return { ...state, isError: false };
    },

    SHOW_CUSTOMER_MODAL: (state) => {
      return { ...state, isCustomerModalDisplayed: true };
    },
    HIDE_CUSTOMER_MODAL: (state) => {
      return { ...state, isCustomerModalDisplayed: false };
    },
    SET_CUSTOMER_NAME: (state, action) => {
      return { ...state, editingCustomer: { ...state.editingCustomer, name: action.payload } };
    },
    SET_CUSTOMER_LAST_NAME: (state, action) => {
      return { ...state, editingCustomer: { ...state.editingCustomer, lastName: action.payload } };
    },
    SET_CUSTOMER_COMPANY: (state, action) => {
      return { ...state, editingCustomer: { ...state.editingCustomer, company: action.payload } };
    },
    SET_CUSTOMER_EMAIL: (state, action) => {
      return { ...state, editingCustomer: { ...state.editingCustomer, email: action.payload } };
    },
    SET_CUSTOMER_PHONE: (state, action) => {
      return { ...state, editingCustomer: { ...state.editingCustomer, phone: action.payload } };
    },
    SET_CUSTOMER_ADDRESS: (state, action) => {
      return { ...state, editingCustomer: { ...state.editingCustomer, address: action.payload } };
    },
    SET_CUSTOMER_BILLING_ADDRESS: (state, action) => {
      return {
        ...state,
        editingCustomer: { ...state.editingCustomer, billingAddress: action.payload },
      };
    },
    SET_CUSTOMER: (state, action) => {
      return {
        ...state,
        editingCustomer: action.payload,
        priceOffer: { ...state.priceOffer, billingAddress: action.payload.billingAddress },
      };
    },
    SET_USER: (state, action) => {
      return {
        ...state,
        priceOffer: { ...state.priceOffer, createdBy: action.payload },
      };
    },
    SET_CREATED_AT: (state, action) => {
      return {
        ...state,
        priceOffer: { ...state.priceOffer, createdAt: action.payload },
      };
    },
    SET_PRODUCTS: (state, action) => {
      return { ...state, products: action.payload };
    },
    PICK_PRODUCT: (state, action) => {
      return { ...state, selectedProduct: action.payload };
    },
    SET_IMAGE_LINK: (state, action) => {
      return { ...state, imageLink: action.payload };
    },
    ADD_PRODUCT: (state, action) => {
      return {
        ...state,
        products: [
          ...state.products,
          {
            ...action.payload,
            quantity: 1,
            glass: '',
            lampholder: '',
            branch: '',
            color: '',
            material: '',
            features: '',
            specialNotes: '',
          },
        ],
      };
    },
    REMOVE_PRODUCT: (state, action) => {
      return {
        ...state,
        products: state.products.filter((product, index) => index !== action.payload),
      };
    },
    SET_PRODUCT_DETAIL: (state, action) => {
      return {
        ...state,
        products: state.products.map((product, index) => {
          if (index === action.payload.index) {
            if (action.payload.field === 'price') {
              return {
                ...product,
                price: parseFloat(action.payload.value),
                total: parseFloat(action.payload.value) * product.quantity,
              };
            }
            if (action.payload.field === 'quantity') {
              return {
                ...product,
                quantity: parseInt(action.payload.value),
                total: parseFloat(product.price) * parseInt(action.payload.value),
              };
            }
            if (action.payload.field === 'selectedVariant') {
              if (action.payload.value.id) {
                return {
                  ...product,
                  imageLink: action.payload.value.image_link || product.baseImageLink,
                  price: parseFloat(action.payload.value.price),
                  total: parseFloat(action.payload.value.price) * parseInt(product.quantity),
                  [action.payload.field]: action.payload.value,
                };
              }
              return {
                ...product,
                [action.payload.field]: action.payload.value,
                price: action.payload.value.price,
                imageLink: action.payload.value.imageLink,
              };
            }
            return { ...product, [action.payload.field]: action.payload.value };
          }
          return product;
        }),
      };
    },
    SET_PRICE_OFFER_DESCRIPTION: (state, action) => {
      return { ...state, priceOffer: { ...state.priceOffer, description: action.payload } };
    },
    SET_PRODUCTS_TOTAL: (state, action) => {
      return {
        ...state,
        priceOffer: { ...state.priceOffer, productsTotal: parseFloat(action.payload) },
      };
    },
    SET_DISCOUNT_RATE: (state, action) => {
      return {
        ...state,
        priceOffer: {
          ...state.priceOffer,
          discountRate: parseFloat(action.payload),
        },
      };
    },
    SET_DISCOUNT_TOTAL: (state, action) => {
      return {
        ...state,
        priceOffer: {
          ...state.priceOffer,
          discountTotal: parseFloat(action.payload),
        },
      };
    },
    SET_KDV_TOTAL: (state, action) => {
      return {
        ...state,
        priceOffer: { ...state.priceOffer, kdvTotal: parseFloat(action.payload) },
      };
    },
    SET_SUBTOTAL: (state, action) => {
      return {
        ...state,
        priceOffer: {
          ...state.priceOffer,
          subTotal: parseFloat(action.payload),
          total: parseFloat(action.payload) * 1.18,
        },
      };
    },
    SET_TOTAL: (state, action) => {
      return {
        ...state,
        priceOffer: {
          ...state.priceOffer,
          total: parseFloat(action.payload),
        },
      };
    },

    RESET: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  START_LOADING,
  STOP_LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  SHOW_CUSTOMER_MODAL,
  HIDE_CUSTOMER_MODAL,
  SET_CUSTOMER,
  SET_CUSTOMER_NAME,
  SET_CUSTOMER_LAST_NAME,
  SET_CUSTOMER_COMPANY,
  SET_CUSTOMER_EMAIL,
  SET_CUSTOMER_PHONE,
  SET_CUSTOMER_ADDRESS,
  SET_CUSTOMER_BILLING_ADDRESS,
  SET_USER,
  SET_CREATED_AT,
  SET_PRODUCTS,
  PICK_PRODUCT,
  SET_IMAGE_LINK,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  SET_PRODUCT_DETAIL,
  SET_PRICE_OFFER_DESCRIPTION,
  SET_DISCOUNT_RATE,
  SET_DISCOUNT_TOTAL,
  SET_KDV_TOTAL,
  SET_PRODUCTS_TOTAL,
  SET_SUBTOTAL,
  SET_TOTAL,

  RESET,
} = createPriceOfferSlice.actions;

export default createPriceOfferSlice.reducer;
