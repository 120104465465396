import { useSelector, useDispatch } from 'react-redux';

import { DatePicker, Spinner } from '..';
import useUserOptions from 'hooks/useUserOptions';
import {
  SET_CREATED_BY,
  SET_CREATED_AT,
  RESET_PRICE_OFFER,
  START_LOADING,
  STOP_LOADING,
} from '../singlePriceOfferSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const EditPriceOfferModal = ({ fetchPriceOffer }) => {
  //Redux
  const { priceOffer, isLoading } = useSelector((state) => state.priceOffers.singlePriceOffer);
  const dispatch = useDispatch();

  // Hooks
  const { userOptions, isUserOptionsLoading } = useUserOptions();

  // Functions
  const updatePriceOffer = async () => {
    dispatch(START_LOADING());
    const body = {
      createdBy: priceOffer.createdBy,
      createdAt: priceOffer.createdAt,
    };
    try {
      await AtolyeAPI.put(`/price_offers/${priceOffer.id}`, body);
      fetchPriceOffer(priceOffer.id);
      alert('success', 'Fiyat Teklifi Güncellendi');
    } catch (error) {
      console.log(error);
      alert('error', 'Fiyat Teklifi Güncellenirken Hata Oluştu!');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <div
      className="modal fade edit-info-modal-lg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered ">
        <div
          className="modal-content"
          style={{
            backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
          }}>
          <div className="modal-header">
            <h5 className="modal-title">Teklif Genel Bilgi - Düzenle</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <>
                {' '}
                <div className="form-group">
                  <label htmlFor="">Yetkili: </label>
                  {isUserOptionsLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <select
                      name="createdBy"
                      id="createdBy"
                      className="form-control"
                      value={priceOffer.createdBy}
                      onChange={(event) => dispatch(SET_CREATED_BY(event.target.value))}>
                      {userOptions.map((user) => {
                        if (user.roles_id !== 4) {
                          return (
                            <option key={user.id} value={user.id}>
                              {user.fullName}
                            </option>
                          );
                        }
                      })}
                    </select>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="">Teklif Tarihi: </label>
                  <DatePicker
                    selected={Date.parse(priceOffer.createdAt)}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    minTime={'03:00:00'}
                    maxTime={'03:00:00'}
                    withPortal
                    onChange={(date) => dispatch(SET_CREATED_AT(date))}
                  />
                </div>
              </>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-dismiss="modal"
              onClick={updatePriceOffer}>
              Kaydet
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => dispatch(RESET_PRICE_OFFER())}>
              Vazgeç
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPriceOfferModal;
