import {
  OrderStatusFilter,
  DeliveryOptionFilter,
  SalesChannelFilter,
  BillFilter,
} from '../../../components/AtolyeTable/components/filters';

import {
  CustomerNameLink,
  CustomerLastNameLink,
  OrderLink,
  PriceOfferLink,
  DateCell,
  BillCell,
  DeliveryOptionCell,
  SalesChannelCell,
  Status,
} from 'components/AtolyeTable/components/customCellComponents';

export const columnsData = [
  {
    Header: 'SİPARİŞ NO',
    accessor: 'id',
    columnName: 'id',
    Cell: OrderLink,
  },
  {
    Header: 'FİYAT TEKLİFİ NO',
    accessor: 'price_offers_id',
    columnName: 'price_offers_id',
    Cell: PriceOfferLink,
  },

  {
    Header: 'MÜŞTERİ ADI',
    accessor: 'customer.name',
    columnName: 'customer.name',
    Cell: CustomerNameLink,
    disableSortBy: true,
    // disableFilters: true
  },

  {
    Header: 'MÜŞTERİ SOYADI',
    accessor: 'customer.lastName',
    columnName: 'customer.lastName',
    Cell: CustomerLastNameLink,
    disableSortBy: true,
    // disableFilters: true
  },

  {
    Header: 'SİPARİŞ DURUMU',
    accessor: 'order_status.name',
    columnName: 'order_status.name',
    Filter: OrderStatusFilter,
    disableSortBy: true,
    // disableFilters: true
  },
  {
    Header: 'DURUM',
    accessor: 'productions',
    columnName: 'productions',
    Cell: Status,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'SATIŞ TARİHİ',
    accessor: 'saleDate',
    columnName: 'saleDate',
    disableFilters: true,
    Cell: DateCell,
  },
  {
    Header: 'TAH.TES. TARİHİ',
    accessor: 'deliveryDate',
    columnName: 'deliveryDate',
    disableFilters: true,
    Cell: DateCell,
  },
  {
    Header: 'TESLİMAT',
    accessor: 'deliveryOption',
    columnName: 'deliveryOption',
    Filter: DeliveryOptionFilter,
    Cell: DeliveryOptionCell,
  },
  {
    Header: 'SATIŞ KANALI',
    accessor: 'salesChannel',
    columnName: 'salesChannel',
    Filter: SalesChannelFilter,
    Cell: SalesChannelCell,
  },
  {
    Header: 'FATURA',
    accessor: 'bill',
    columnName: 'bill',
    Filter: BillFilter,
    Cell: BillCell,
  },
  {
    Header: 'SATICI',
    accessor: 'user.fullName',
    columnName: 'user.fullName',
    disableSortBy: true,
    // disableFilters: true
  },
  {
    Header: 'SİPARİŞ SORUMLUSU',
    accessor: 'orderManager.fullName',
    columnName: 'orderManager.fullName',
    disableSortBy: true,
    // disableFilters: true
  },
  {
    Header: 'İRTİBAT NU.',
    accessor: 'customer.phone',
    disableSortBy: true,
    columnName: 'customer.phone',
    // disableFilters: true
  },
];
