import './PageTitle.scss';

const PageTitle = ({ title }) => {
  return (
    <div className="page-title hide-on-print">
      <span>{title}</span>
    </div>
  );
};

export default PageTitle;
