import './CreateOrder.scss';
import {
  ContentWrapper,
  CustomerSelection,
  Footer,
  FromPriceOffer,
  Header,
  PageTitle,
  PageWrapper,
  ProductSelection,
  OrderDetailsForm,
  OrderNotes,
  ManufacturerSelection,
  OrderTotalDetails,
  PaymentForm,
  BillSelection,
} from '.';
import ResponsiveLoading from 'components/ResponsiveLoading';

import { useUser } from 'hooks';
import useCustomerOptions from 'hooks/useCustomerOptions';
import useProductOptions from 'hooks/useProductOptions';
import useUserOptions from 'hooks/useUserOptions';

import useCreateOrder from './hooks/useCreateOrder';
import FloatingTotalBox from 'components/FloatingTotalBox/FloatingTotalBox';

import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

const CreateOrder = () => {
  //Redux

  const { total } = useSelector((state) => state.orders.createOrder.order);

  // Hooks
  const { user, userPermissions } = useUser();

  const { isProductOptionsLoading, productOptions, productOptionsOnlyInStock } =
    useProductOptions();
  const { isCustomerOptionsLoading, customerOptions, getCustomerOptions } = useCustomerOptions();
  const { isUserOptionsLoading, userOptions } = useUserOptions();

  const { createOrderHandler, clearOrderHandler, isLoading } = useCreateOrder(user);

  return (
    <>
      <Header user={user} />
      <ContentWrapper>
        <PageTitle title="Yeni Sipariş Oluştur" />
        {!userPermissions?.includes(2) ? (
          <Alert variant="danger" className="text-center">
            Bu sayfaya erişim yetkiniz bulunmamaktadır.
          </Alert>
        ) : (
          <PageWrapper>
            {isLoading ? (
              <ResponsiveLoading />
            ) : (
              <>
                <FloatingTotalBox title={'Sipariş Toplamı'} total={total} />
                <FromPriceOffer
                  title="Fiyat Teklifinden Oluştur"
                  productOptions={productOptions}
                  customerOptions={customerOptions}
                />
                <CustomerSelection
                  title="Kayıtlı Müşteri Seçin : "
                  isCustomerOptionsLoading={isCustomerOptionsLoading}
                  customerOptions={customerOptions}
                  getCustomerOptions={getCustomerOptions}
                />
                <OrderDetailsForm
                  title="Siparişi Alan Yetkili"
                  isUserOptionsLoading={isUserOptionsLoading}
                  userOptions={userOptions}
                />
                <ProductSelection
                  title="Ürün Ekle"
                  isProductOptionsLoading={isProductOptionsLoading}
                  productOptions={productOptions}
                  productOptionsOnlyInStock={productOptionsOnlyInStock}
                />
                <OrderNotes />
                <ManufacturerSelection />
                <OrderTotalDetails title="İskonto ve Toplam Tutar" />
                <PaymentForm
                  isUserOptionsLoading={isUserOptionsLoading}
                  userOptions={userOptions}
                />
                <BillSelection />

                <div className="text-center my-3 d-flex justify-content-center">
                  <div className="btn btn-success text-white mx-2" onClick={createOrderHandler}>
                    Siparişi Oluştur
                  </div>
                  <div className="btn btn-info text-white mx-2" onClick={clearOrderHandler}>
                    Temizle
                  </div>
                </div>
              </>
            )}
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default CreateOrder;
