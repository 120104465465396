import { useDispatch, useSelector } from 'react-redux';
import { ADD_PERMISSION, REMOVE_PERMISSION } from '../userSlice';
import { useEffect, useState } from 'react';

const PermissionSwitch = ({ permission }) => {
  // State
  const [checked, setChecked] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const { currentPermissions } = useSelector((state) => state.settings.user);

  // Effects
  useEffect(() => {
    if (currentPermissions.includes(permission.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [currentPermissions]);

  return (
    <div className="my-1" key={permission.id}>
      <div className="p-1 d-flex font-14">
        {/* Switch to enable or disable permission */}
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`customSwitch${permission.id}`}
            checked={checked}
            onChange={() =>
              !checked
                ? dispatch(ADD_PERMISSION(permission.id))
                : dispatch(REMOVE_PERMISSION(permission.id))
            }
          />
          <label className="custom-control-label" htmlFor={`customSwitch${permission.id}`}></label>
        </div>
        <p>{permission.name}</p>
      </div>
    </div>
  );
};

export default PermissionSwitch;
