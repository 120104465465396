import { useState } from 'react';
import alert from '../js/sweetAlert';

import { useAtolyeAPI } from 'hooks';

const useCustomers = () => {
  ///states

  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [editCustomer, setEditCustomer] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  // Hooks
  const { fetchFrom, postTo } = useAtolyeAPI();

  ///functions

  const getCustomers = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const { data, totalCount, totalPages } = await postTo('/customers/table', {
        limit: pageSize,
        offset: pageIndex * pageSize,
        // search: { id: 41546 },
        order: [
          // ['description', 'ASC'],
          ['id', 'DESC'],
        ],
      });
      setCustomers(data);
      setPageCount(totalPages);
      setTotalRowsCount(totalCount);
    } catch (error) {
      console.log(error);
      alert('error', 'Müşteri bilgileri getirilirken bir sorun oluştu!');
    }

    setLoading(false);
  };

  const handleChange = (event) =>
    setEditCustomer({ ...editCustomer, [event.target.name]: event.target.value });

  const showEdit = (event) => {
    let customerId = parseInt(event.target.id);

    let editCustomer = customers.filter((customer) => customer.id === customerId)[0];

    setCurrentCustomer(customerId);
    setEditCustomer(editCustomer);
  };

  const editCustomerHandler = async () => {
    setLoading(true);

    let reqObj = editCustomer;

    delete reqObj.updatedAt;

    try {
      await postTo('/customers/' + currentCustomer, { body: reqObj });
      alert('success', 'Müşteri bilgileri başarıyla güncellendi');
      await getCustomers();
    } catch (error) {
      console.log(error);
      alert('error', 'Müşteri bilgileri güncellenemedi!');
    }
    setLoading(false);
  };

  const deleteCustomerHandler = async () => {
    setLoading(true);
    try {
      await fetchFrom(`/customers/${currentCustomer}/delete`);
      alert('success', 'Müşteri başarıyla silindi');
      await getCustomers();
    } catch (error) {
      console.log(error);
      alert('error', 'Müşteri silinemedi!');
    }
    setLoading(false);
  };

  return {
    loading,
    customers,
    pageCount,
    totalRowsCount,
    editCustomer,
    currentCustomer,
    getCustomers,
    handleChange,
    showEdit,
    editCustomerHandler,
    deleteCustomerHandler,
  };
};

export default useCustomers;
