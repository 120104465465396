import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { useState } from 'react';

const AtolyeAPI = new API();

const CreateOrderNoteModal = ({ show, onDismiss }) => {
  // State

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  // Functions

  const createOrderNote = async () => {
    if (!name) {
      return alert('error', 'Taslak Adı boş bırakılamaz');
    }

    const body = {
      name,
      description,
    };

    setIsLoading(true);

    try {
      const { status } = await AtolyeAPI.post('/order_notes', body);

      if (status && status === 'SUCCESS') {
        return alert('success', 'Sipariş Notu Taslağı başarıyla oluşturuldu');
      }
      return alert('error', 'Sipariş Notu Taslağı oluşturulurken bir hata oluştu');
    } catch (error) {
      alert('error', 'Sipariş Notu Taslağı oluşturulurken bir hata oluştu');
      console.log(error);
    } finally {
      setIsLoading(false);
      setName('');
      setDescription('');
      onDismiss();
    }
  };

  return (
    <Modal show={show} onHide={onDismiss}>
      <Modal.Header className="bg-success" closeButton>
        <Modal.Title>Yeni Sipariş Notu Taslağı Oluştur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Taslak Adı</Form.Label>
          <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
          <Form.Text className="text-muted">Taslak Adı girin. Bu alan zorunludur</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Taslak Metni</Form.Label>
          <Form.Control
            as="textarea"
            rows={8}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Form.Text className="text-muted">Taslak içeriğini buraya yazın</Form.Text>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onDismiss}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={() => createOrderNote()}>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            'Oluştur'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateOrderNoteModal;
