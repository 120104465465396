import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';
import { menuItems } from 'config/menuItems';

import { useSelector, useDispatch } from 'react-redux';
import { MENU_TOGGLE, RESET } from 'app/state/generalSlice';
import alert from 'js/sweetAlert';

const SideNavbar = () => {
  //Redux
  const { isMenuOpen, user } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  // Sign Out
  const signOut = async () => {
    try {
      await Auth.signOut();
      dispatch(RESET());
      alert('success', 'Başarıyla çıkış yapıldı.');
      // Navigate to home page
    } catch (error) {
      console.log('error signing out: ', error);
      alert('error', 'Bir hata oluştu. Lütfen tekrar deneyiniz.');
    }
  };

  return (
    <aside className={`main-sidebar  shadow-sm menu-${isMenuOpen ? 'open' : 'close'}`}>
      <div className="sidebar pt-3 position-relative">
        <div className="d-flex d-md-none px-3" onClick={() => dispatch(MENU_TOGGLE())}>
          <i className="fas fa-times ms-auto fa-lg"></i>
        </div>
        <nav className="mt-3">
          <ul
            className="nav nav-pills nav-sidebar flex-column pb-5"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
            <li className="nav-header">ATOLYE AYDINLATMA</li>
            {menuItems.map((item, index) => {
              if (item.hasSubMenu) {
                return item?.permission && user.userPermissions?.includes(item.permission) ? (
                  <li key={index} className="nav-item">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <i className={`nav-icon ${item.iconClass}`}></i>
                          <p>{item.label}</p>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {item.subMenus.map((subMenu, index) =>
                              subMenu?.permission &&
                              user.userPermissions?.includes(subMenu.permission) ? (
                                <li key={`subMenu-${index}`}>
                                  <Link to={subMenu.link}>
                                    <i className={`nav-icon ${subMenu.iconClass}`}></i>
                                    <p>{subMenu.label}</p>
                                  </Link>
                                </li>
                              ) : subMenu?.permission &&
                                !user.userPermissions?.includes(subMenu.permission) ? null : (
                                <li key={`subMenu-${index}`}>
                                  <Link to={subMenu.link}>
                                    <i className={`nav-icon ${subMenu.iconClass}`}></i>
                                    <p>{subMenu.label}</p>
                                  </Link>
                                </li>
                              ),
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </li>
                ) : item?.permission && !user.userPermissions?.includes(item.permission) ? null : (
                  <li key={index} className="nav-item">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <i className={`nav-icon ${item.iconClass}`}></i>
                          <p>{item.label}</p>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {item.subMenus.map((subMenu, index) =>
                              subMenu?.permission &&
                              user.userPermissions?.includes(subMenu.permission) ? (
                                <li key={`subMenu-${index}`}>
                                  <Link to={subMenu.link}>
                                    <i className={`nav-icon ${subMenu.iconClass}`}></i>
                                    <p>{subMenu.label}</p>
                                  </Link>
                                </li>
                              ) : subMenu?.permission &&
                                !user.userPermissions?.includes(subMenu.permission) ? null : (
                                <li key={`subMenu-${index}`}>
                                  <Link to={subMenu.link}>
                                    <i className={`nav-icon ${subMenu.iconClass}`}></i>
                                    <p>{subMenu.label}</p>
                                  </Link>
                                </li>
                              ),
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </li>
                );
              }

              return item?.permission && user.userPermissions?.includes(item.permission) ? (
                <li key={`navItem-${index}`} className="nav-item">
                  <Link className="nav-link" to={item.link}>
                    <i className={`nav-icon ${item.iconClass}`}></i>
                    <p>{item.label}</p>
                  </Link>
                </li>
              ) : item?.permission && !user.userPermissions?.includes(item.permission) ? null : (
                <li key={`navItem-${index}`} className="nav-item">
                  <Link className="nav-link" to={item.link}>
                    <i className={`nav-icon ${item.iconClass}`}></i>
                    <p>{item.label}</p>
                  </Link>
                </li>
              );
            })}

            <li className="nav-header">İŞLEM</li>
            <li className="nav-item">
              <Button className="nav-link text-white" onClick={signOut}>
                <i className="nav-icon fas fa-sign-out-alt"></i>
                <p>Çıkış Yap</p>
              </Button>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideNavbar;
