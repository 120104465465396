import { useSelector } from 'react-redux';

const useUser = () => {
  const user = useSelector((state) => state.general.user);
  const {
    userName,
    userLastName,
    userFullName,
    userEmail,
    userRole,
    userId,
    userPermissions,
    cognitoAttributes,
  } = user;
  return {
    userName,
    userLastName,
    userFullName,
    userEmail,
    userRole,
    userId,
    userPermissions,
    cognitoAttributes,
    user,
  };
};

export default useUser;
