import React from 'react';

const InfoIndicators = ({ apiData, title }) => {
  return (
    <>
      <div className="text-center mt-5 pt-5">
        <h4 className="text-dark pb-3">{title}</h4>
      </div>
      <div className="row mt-3 mx-md-2 mx-0">
        <div className="col-md-3 col-6">
          <div className="small-box bg-danger">
            <div className="inner">
              <h3>{apiData?.orders.taken}</h3>

              <p>Sipariş alındı</p>
            </div>
            <div className="icon">
              <i className="ion ion-bag"></i>
            </div>
            <a href="/orders" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="small-box bg-purple2 text-white">
            <div className="inner">
              <h3>{apiData?.orders.onProduction}</h3>

              <p>Sipariş üretimde</p>
            </div>
            <div className="icon">
              <i className="ion ion-bag"></i>
            </div>
            <a href="/orders" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{apiData?.orders.readyToDeliver}</h3>

              <p>Sipariş depodan teslime hazır</p>
            </div>
            <div className="icon">
              <i className="ion ion-bag"></i>
            </div>
            <a href="/orders" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="small-box bg-success">
            <div className="inner">
              <h3>{apiData?.orders.completed}</h3>
              <p>Sipariş tamamlandı</p>
            </div>
            <div className="icon">
              <i className="ion ion-stats-bars"></i>
            </div>
            <a href="/orders" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-3 mx-md-2 mx-0">
        <div className="col-md-3 col-6">
          <div className="small-box bg-atolye">
            <div className="inner">
              <h3>{apiData?.production.planning}</h3>

              <p>Ürün planlamada</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-add"></i>
            </div>
            <a href="/production" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="small-box bg-warning">
            <div className="inner">
              <h3>{apiData?.production.inProgress}</h3>

              <p>Ürün üretimde</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-add"></i>
            </div>
            <a href="/production" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>

        <div className="col-md-3 col-6">
          <div className="small-box bg-lightBlue">
            <div className="inner">
              <h3>{apiData?.production.control}</h3>

              <p>Ürün kontrol ve paketlemede</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-add"></i>
            </div>
            <a href="/production" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>

        <div className="col-md-3 col-6">
          <div className="small-box bg-dark">
            <div className="inner">
              <h3>{apiData?.production.readyToDeliver}</h3>

              <p>Ürün teslime hazır</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-add"></i>
            </div>
            <a href="/production" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-3 mx-md-2 mx-0">
        <div className="col-md-3 col-6">
          <div className="small-box bg-secondary">
            <div className="inner">
              <h3>{apiData?.products.all}</h3>

              <p>Envanter Ürün Miktarı</p>
            </div>
            <div className="icon">
              <i className="ion ion-pie-graph"></i>
            </div>
            <a href="/products" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-3 mx-md-2 mx-0">
        <div className="col-md-3 col-6">
          <div className="small-box bg-success">
            <div className="inner">
              <h3>{apiData?.bills.notPrinted}</h3>

              <p>Faturası Kesilen Sipariş</p>
            </div>
            <div className="icon">
              <i className="ion ion-pie-graph"></i>
            </div>
            <a href="/orders" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
        <div className="col-md-3 col-6">
          <div className="small-box bg-warning">
            <div className="inner">
              <h3>{apiData?.bills.printed}</h3>

              <p>Faturası Kesilmemiş Sipariş</p>
            </div>
            <div className="icon">
              <i className="ion ion-pie-graph"></i>
            </div>
            <a href="/orders" className="small-box-footer">
              Detaylar <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <section className="quick-start">
        <p className="mt-5 mx-md-2 mx-2">Oluşturmaya başlayın ,</p>
        <div className="d-flex flex-wrap justify-content-start align-content-center mx-2">
          <a href="/create-customer" className="btn btn-primary m-2">
            Yeni Müşteri
          </a>
          <a href="/create-price-offer" className="btn btn-primary m-2">
            Yeni Fiyat Teklifi
          </a>
          <a href="/create-order" className="btn btn-primary m-2">
            Yeni Sipariş
          </a>
          <a href="/create-product" className="btn btn-primary m-2">
            Yeni Ürün
          </a>
        </div>
      </section>
    </>
  );
};

export default InfoIndicators;
