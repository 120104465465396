import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { START_LOADING, STOP_LOADING, RESET } from '../createProductSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';
import { useNavigate } from 'react-router-dom';

const AtolyeAPI = new API();

const ActionButtonsSection = () => {
  // Redux
  const dispatch = useDispatch();
  const { product, tagsToAdd } = useSelector((state) => state.products.createProduct);

  // Hooks
  const navigate = useNavigate();

  // Functions
  const createProduct = async () => {
    if (!checkValidation(product)) {
      return;
    }

    dispatch(START_LOADING());

    const body = {
      product: {
        ...product,
        createdBy: product.createdBy || 41,
        updatedBy: product.createdBy || 41,
      },
      tagsToAdd,
    };
    try {
      const {
        status,
        results: { id },
      } = await AtolyeAPI.post(`/products`, body);
      if (status === 'SUCCESS') {
        dispatch(RESET());
        navigate(`/single-product?code=${id}`);
        return alert('success', 'Ürün başarıyla oluşturuldu');
      }
      throw new Error('Ürün oluşturulurken bir hata oluştu');
    } catch (error) {
      console.log({ error });
      return alert('error', 'Ürün oluşturulurken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const checkValidation = (product) => {
    const { id, cost, salePrice, description, product_categories_id, maxDiscountRate } = product;

    if (!id || !description) {
      alert('error', 'Ürün Kodu ve Ürün Açıklaması boş bırakılamaz');
      return false;
    }

    if (isNaN(cost) || isNaN(salePrice) || isNaN(maxDiscountRate)) {
      alert(
        'error',
        'Maliyet, Satış Fiyatı ve Maksimum İskonto Oranı için geçerli birer sayı giriniz',
      );
      return false;
    }

    if (product_categories_id < 1) {
      alert('error', 'Ürün Kategorisi seçmediniz');
      return false;
    }

    return true;
  };

  return (
    <div className="w-100 fixed-bottom bg-white px-3 py-2 border-top d-flex justify-content-end">
      <Button variant="primary" size="sm" className="mx-2" onClick={() => dispatch(RESET())}>
        Sıfırla
      </Button>
      <Button
        variant="success"
        size="sm"
        className="mx-2 text-white"
        onClick={() => createProduct()}>
        Ürünü Oluştur
      </Button>
    </div>
  );
};

export default ActionButtonsSection;
