import { Table, Button } from '..';
import { alertDialog } from 'js/sweetAlert';

const CustomerDetailsTable = ({ customerDetails, onDelete }) => {
  const deleteHandler = () => {
    alertDialog({
      title:
        'Müşteri kaydını silmek istediğinize emin misiniz? Müşteri kaydının silinmesi mevcut sipariş kayıtlarının ve fiyat tekliflerinin görüntülenmesinde sorunlara yol açabilir.',
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
      onConfirm: onDelete,
    });
  };
  return (
    <>
      <div className="d-flex justify-content-center rounded">
        <Table striped bordered hover variant="" className="customer-details">
          <tbody>
            <tr>
              <td className="font-weight-bold">Müşteri No.</td>
              <td>{customerDetails.id}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Adı</td>
              <td>{customerDetails.name}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Soyadı</td>
              <td>{customerDetails.lastName}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Firma</td>
              <td>{customerDetails.company === '' ? '-' : customerDetails.company}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Telefon</td>
              <td>{customerDetails.phone}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">E-Posta</td>
              <td>{customerDetails.email === '' ? '-' : customerDetails.email}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Adres</td>
              <td>{customerDetails.address === '' ? '-' : customerDetails.address}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Fatura Adresi</td>
              <td>
                {customerDetails.billingAddress === '' ? '-' : customerDetails.billingAddress}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Kayıt Zamanı</td>
              <td>{new Date(customerDetails.createdAt).toLocaleString()}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Güncelleme Zamanı</td>
              <td>{new Date(customerDetails.updatedAt).toLocaleString()}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Button className="mt-3" variant="danger" size="sm" onClick={deleteHandler}>
        Müşteriyi Sil
      </Button>
    </>
  );
};

export default CustomerDetailsTable;
