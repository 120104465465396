import { SET_AUTH_STATUS, authStatusEnum } from 'app/state/generalSlice';
import { Auth } from 'aws-amplify';
import alert from 'js/sweetAlert';
import { useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const ResetPassword = () => {
  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.general);

  // State
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  // Functions
  const goToSignIn = () => {
    dispatch(SET_AUTH_STATUS(authStatusEnum.SignIn));
  };

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      alert('error', 'Şifreler eşleşmiyor.');
      return;
    }

    Auth.forgotPasswordSubmit(user.userName, verificationCode, password)
      .then(() => {
        alert('success', 'Şifreniz başarıyla değiştirildi.');
        dispatch(SET_AUTH_STATUS(authStatusEnum.SignIn));
      })
      .catch((err) => {
        console.log(err);
        alert('error', err.message);
      });
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo bg-dark p-2 rounded">
          <img
            src="https://cdn.shopify.com/s/files/1/0605/8056/1151/files/atolyestore_190x.png?v=1645870921"
            height="60"
            alt=""
          />
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            {/* Message including username*/}
            <p className="mb-1">
              Şifrenizi sıfırlamak için <strong>{user.userName}</strong> adresine gönderilen
              doğrulama kodunu girin.
            </p>

            <FormGroup className="my-3">
              {/* // Verification code */}
              <Form.Label>Doğrulama kodu</Form.Label>
              <Form.Control
                type="text"
                placeholder="Doğrulama kodu"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </FormGroup>

            <FormGroup className="my-3">
              {/* // New password */}
              <Form.Label>Yeni şifre</Form.Label>
              <Form.Control
                type="password"
                placeholder="Yeni şifre"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>

            <FormGroup className="my-3">
              {/* // Confirm new password */}
              <Form.Label>Yeni şifre tekrar</Form.Label>
              <Form.Control
                type="password"
                placeholder="Yeni şifre tekrar"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormGroup>

            <Button
              className="btn btn-primary btn-block mt-3"
              onClick={() => handleResetPassword()}>
              Şifremi sıfırla
            </Button>

            <p className="mt-3 mb-1">
              <button type="button" className="link-btn" onClick={goToSignIn}>
                <i className="fas fa-chevron-left"></i> Giriş
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
