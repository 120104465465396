import { useEffect, useState } from 'react';

import { useAtolyeAPI } from 'hooks';

const useComponents = () => {
  // States
  const [isComponentsLoading, setComponentsLoading] = useState(true);
  const [components, setComponents] = useState([]);
  const [codeSearch, setCodeSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  // Hooks
  const { fetchFrom } = useAtolyeAPI();
  // Effects
  useEffect(() => {
    getComponents();
  }, []);

  // Functions
  const getComponents = async () => {
    setComponentsLoading(true);
    try {
      const results = await fetchFrom(
        '/components' +
          (codeSearch
            ? `?code=${codeSearch}`
            : '' + (categorySearch ? `?category=${categorySearch}` : '')),
      );
      // console.log(results);
      setComponents(results);
    } catch (error) {
      console.log(error);
      alert('error', 'Parçalar getirilirken bir sorun oluştu!');
    }

    setComponentsLoading(false);
  };

  return {
    isComponentsLoading,
    components,
    getComponents,
    codeSearch,
    setCodeSearch,
    categorySearch,
    setCategorySearch,
  };
};

export default useComponents;
