const CreationInfo = ({ product }) => {
  return (
    <div className="font-12 m-3">
      <div>
        <strong>Oluşturan Yetkili: </strong>
        {product?.createdByUser?.fullName}
      </div>
      <div>
        <strong>Oluşturulma Tarihi: </strong>
        {new Date(product.createdAt).toLocaleString('tr')}
      </div>
      <hr />
      <div>
        <strong>Son Güncelleyen Yetkili: </strong>
        {product?.updatedByUser?.fullName}
      </div>
      <div>
        <strong>Son Güncelleme Tarihi: </strong>
        {new Date(product.updatedAt).toLocaleString('tr')}
      </div>
    </div>
  );
};

export default CreationInfo;
