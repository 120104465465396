import './PaymentStatus.scss';

import { useMemo } from 'react';
import { columnsData } from './utils';

import { AtolyeTable, ContentWrapper, Footer, Header, PageTitle, PageWrapper, useUser } from '.';

import usePaymentStatus from './hooks/usePaymentStatus';
import ExportButton from './components/ExportButton';
import AtolyeDatePicker from 'components/AtolyeTable/components/AtolyeDatePicker';
import { Alert } from 'react-bootstrap';

const PaymentStatus = (props) => {
  // Hooks
  const {
    loading,
    pageCount,
    rows,
    filters,
    dateFilter,
    sorting,
    totalRowsCount,
    getRows,
    exportAllPayments,
    setFilters,
    setAreFiltersUpdated,
    setDateFilter,
    setSorting,
    setIsSortingUpdated,
  } = usePaymentStatus();
  const { user, userPermissions } = useUser();

  // Declarations

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => rows, [rows]);

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="Ödeme Durumu" />
        {!userPermissions?.includes(18) ? (
          <Alert variant="danger">Bu sayfaya erişim yetkiniz bulunmamaktadır.</Alert>
        ) : (
          <PageWrapper>
            <div className="d-flex">
              <AtolyeDatePicker dateFilter={dateFilter} setDateFilter={setDateFilter} />
              {userPermissions?.includes(20) && (
                <ExportButton loading={loading} exportExcel={(range) => exportAllPayments(range)} />
              )}
            </div>
            <AtolyeTable
              columns={columns}
              data={data}
              fetchData={getRows}
              filters={filters}
              dateFilter={dateFilter}
              setFilters={setFilters}
              setAreFiltersUpdated={setAreFiltersUpdated}
              sorting={sorting}
              setSorting={setSorting}
              setIsSortingUpdated={setIsSortingUpdated}
              loading={loading}
              pageCount={pageCount}
              total={totalRowsCount}
            />
          </PageWrapper>
        )}
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default PaymentStatus;
