import { useEffect, useState } from 'react';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const usePermissions = () => {
  const [permissions, setPermissions] = useState([]);
  const [isPermissionLoading, setIsPermissionLoading] = useState(true);

  const getPermissions = async () => {
    try {
      setIsPermissionLoading(true);
      const { results } = await AtolyeAPI.get('/permissions');
      setPermissions(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsPermissionLoading(false);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return {
    permissions,
    isPermissionLoading,
    getPermissions,
  };
};

export default usePermissions;
