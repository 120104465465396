import './AtolyeTable.scss';

import { useEffect, useMemo } from 'react';

import { useTable, usePagination, useSortBy, useFilters, useExpanded } from 'react-table';

import { Table } from 'react-bootstrap';

import { ResponsiveLoading, TablePagination } from '.';
import { EditableCell } from 'pages/Production/ProductionPlanning';
import EditableManufacturerCell from 'pages/Production/ProductionPlanning/components/EditableManufacturerCell';
import { DefaultColumnFilter } from './components/filters';
import { SET_TABLE } from './atolyeTableSlice';
import { useDispatch } from 'react-redux';

const AtolyeTable = ({
  columns,
  filters: searchFilters,
  dateFilter,
  tagFilter = null,
  setFilters,
  setAreFiltersUpdated,
  sorting,
  setSorting,
  setIsSortingUpdated,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  total,
  options,
}) => {
  // Redux
  const dispatch = useDispatch();

  // Default Column Declaration
  const defaultColumn = useMemo(() => {
    return {
      // Default Filter component is assigned to all columns here.
      Filter: DefaultColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, filters }, // required for server-side rendering
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      manualSortBy: true,
      manualFilters: true,
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData(pageIndex, pageSize);
  }, [pageIndex, pageSize, searchFilters, sorting]);

  // Atolye Table State Changes
  useEffect(() => {
    dispatch(SET_TABLE({ field: 'pageIndex', value: pageIndex }));
    dispatch(SET_TABLE({ field: 'pageSize', value: pageSize }));
    dispatch(SET_TABLE({ field: 'pageRows', value: pageCount }));
    dispatch(SET_TABLE({ field: 'totalRows', value: total }));
    // console.log({ pageIndex, pageSize, pageCount, total });
  }, [pageIndex, pageSize, pageCount, total]);

  useEffect(() => {
    if (dateFilter?.endDate) {
      fetchData(pageIndex, pageSize);
    }
  }, [dateFilter]);

  useEffect(() => {
    tagFilter && fetchData(pageIndex, pageSize);
  }, [tagFilter]);

  // This effect is used for getting filter parameters of whole table and pass it to the parent
  useEffect(() => {
    let searchParams = {};
    filters.map((filter) => {
      const filterName = columns.find((item) => item.accessor === filter.id).columnName;
      searchParams[filterName] = filter.value;
      return null;
    });

    setFilters && setFilters(searchParams);
    setAreFiltersUpdated && setAreFiltersUpdated(true);
  }, [filters]);

  // This effect is used for getting sorting parameters of whole table and pass it to the parent
  useEffect(() => {
    let sortParams = {};
    sortBy.map((column) => {
      const sortName = columns.find((item) => item.accessor === column.id).columnName;
      const sortType = column.desc ? 'DESC' : 'ASC';
      sortParams[sortName] = sortType;
      return null;
    });

    setSorting && setSorting(formatSorting(sortParams));
    setIsSortingUpdated && setIsSortingUpdated(true);
  }, [sortBy]);

  //Functions

  const formatSorting = (params) => {
    const _tempArray = [];
    Object.keys(params).map((item) => {
      return _tempArray.push([item, params[item]]);
    });
    return _tempArray;
  };

  // Render the UI for your table
  return (
    <div className="atolye-table">
      <Table responsive variant="white" className="text-secondary" {...getTableProps()}>
        <thead className="border bg-white">
          {headerGroups.map((headerGroup, i) => (
            <tr key={`headerGroup-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column, i) =>
                  !column.isHidden && (
                    <th
                      className="position-relative"
                      scope="col"
                      key={`column-${i}`}
                      {...column.getHeaderProps()}>
                      <div className="column-header-title" {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        <span className="ms-2">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fas fa-sort-down text-primary"></i>
                            ) : (
                              <i className="fas fa-sort-up text-primary"></i>
                            )
                          ) : column.disableSortBy ? (
                            ''
                          ) : (
                            <i className="fas fa-sort"></i>
                          )}
                        </span>
                      </div>

                      <div>
                        {column.canFilter ? (
                          column.render('Filter')
                        ) : (
                          <div className="filter-blank"></div>
                        )}
                      </div>
                    </th>
                  ),
              )}
            </tr>
          ))}
        </thead>
        <tbody className="position-relative" {...getTableBodyProps()}>
          {loading && <ResponsiveLoading />}
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <tr
                key={`row-${i}`}
                {...row.getRowProps()}
                style={row.depth > 0 ? { backgroundColor: '#efefef', border: 'inset' } : {}}>
                {row.cells.map((cell, i) => {
                  // Render EditableManufacturerCell for Production Planning Page
                  if (cell.column?.key === 'manufacturer') {
                    return (
                      <td key={`cell-${i}`} {...cell.getCellProps()}>
                        <EditableManufacturerCell
                          value={cell.value}
                          column={cell.column}
                          row={cell.row}
                          onSave={() => fetchData(pageIndex, pageSize)}
                          manufacturerOptions={options.manufacturerOptions}
                        />
                      </td>
                    );
                  }

                  // Render EditableCell for Production Planning Page
                  if (cell.column?.key) {
                    return (
                      <td key={`cell-${i}`} {...cell.getCellProps()}>
                        {
                          <EditableCell
                            value={cell.value}
                            column={cell.column}
                            row={cell.row}
                            onSave={() => fetchData(pageIndex, pageSize)}
                          />
                        }
                      </td>
                    );
                  }

                  return (
                    <td key={`cell-${i}`} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        total={total}
      />
    </div>
  );
};

export default AtolyeTable;
