import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DepotLink = ({ value }) => (
  <Link to={`/product-stock-management/depots/depot?id=${value}`}>
    <Badge pill className="bg-success font-12 pointer">
      {value}
    </Badge>
  </Link>
);

const DepotName = ({
  row: {
    original: { id, name },
  },
}) => (
  <Link to={`/product-stock-management/depots/depot?id=${id}`}>
    {' '}
    <strong>{name}</strong>
  </Link>
);

export const columnsData = [
  {
    Header: 'DEPO ID',
    accessor: 'id',
    columnName: 'id',
    Cell: DepotLink,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'DEPO ADI',
    accessor: 'name',
    columnName: 'name',
    Cell: DepotName,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'STOK TOPLAMI',
    accessor: 'stocksTotal',
    columnName: 'stocksTotal',
    Cell: ({ value }) => (
      <span>
        <strong className="text-danger">{value} </strong>adet
      </span>
    ),
    disableFilters: true,
    disableSortBy: true,
  },

  {
    Header: 'SON GÜNCELLEME',
    accessor: 'updatedAt',
    columnName: 'updatedAt',
    Cell: ({ value }) => new Date(value).toLocaleString('tr'),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'OLUŞTURULMA',
    accessor: 'createdAt',
    columnName: 'createdAt',
    Cell: ({ value }) => new Date(value).toLocaleString('tr'),
    disableFilters: true,
    disableSortBy: true,
  },
];
