import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const EditableManufacturerCell = ({
  value,
  column,
  row: {
    original: { id },
  },
  onSave,
  manufacturerOptions,
}) => {
  // Redux
  const { userPermissions } = useSelector((state) => state.general.user);
  // State
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(value?.id || '');

  // Effects
  useEffect(() => {
    setSelectedId(value?.id || '');
  }, [value]);

  // Components
  const Loading = () => (
    <div className="text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  // Functions

  const saveHandler = async () => {
    setIsLoading(true);

    const body = {
      manufacturers_id: selectedId,
    };

    try {
      await AtolyeAPI.put(`/productions/${id}`, body);
      onSave();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShow(false);
    }
  };
  return (
    column && (
      <div>
        {value?.name || ''}
        {userPermissions?.includes(17) && (
          <Button variant="" size="sm" className="mx-2 py-1" onClick={() => setShow(true)}>
            <i className="fas fa-edit text-primary font-12"></i>
          </Button>
        )}
        {/* // Edit Modal */}
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Düzenle - {column.Header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Loading />
            ) : manufacturerOptions.isLoading ? (
              <Loading />
            ) : (
              <Form.Select value={selectedId || ''} onChange={(e) => setSelectedId(e.target.value)}>
                <option value="">Seçiniz</option>
                {manufacturerOptions.options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="sm" onClick={() => setShow(false)}>
              Vazgeç
            </Button>
            <Button variant="success" className="text-white" size="sm" onClick={saveHandler}>
              Güncelle
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};

export default EditableManufacturerCell;
