import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const PriceOfferNotesInfo = ({ description }) => {
  // Redux
  const { userPermissions } = useSelector((state) => state.general.user);

  return (
    <div className="col-md-6 border p-0 order-2 order-md-1">
      <span className="ms-2 badge badge-warning my-3">Özel Notlar:</span>
      {userPermissions?.includes(11) && (
        <button
          data-toggle="modal"
          data-target=".edit-description-modal-lg"
          className="btn btn-outline-primary btn-sm ms-2">
          Düzenle
        </button>
      )}

      <Form.Control
        style={{ fontSize: 14 }}
        type="text"
        as="textarea"
        rows={6}
        readOnly
        value={description}
      />
    </div>
  );
};

export default PriceOfferNotesInfo;
