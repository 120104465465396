import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProductImage = ({ value }) => {
  if (!value) {
    return <img src="http://placehold.jp/100x100.png" className="rounded" alt="" width={100} />;
  }
  let imgParts = value.split('.');
  let ext = imgParts.pop();
  let imgThumb = imgParts.join('.') + '-thumb.' + ext;
  imgThumb = imgThumb.replace(
    'https://dev.images.atolyestore.com/products/',
    'https://dev.images.atolyestore.com/product_thumbs/',
  );
  return (
    <img
      src={imgThumb}
      className="rounded"
      alt=""
      width={100}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = 'http://placehold.jp/100x100.png';
      }}
    />
  );
};

const OrderLink = ({ value }) => (
  <Link to={`/single-order?id=${value}`}>
    <Badge pill className="font-12 pointer bg-info">
      {value}
    </Badge>
  </Link>
);

const DateCell = ({ value }) => {
  const cell = new Date(value).toLocaleDateString('tr');
  return cell;
};

export const columnsData = [
  {
    Header: 'SİPARİŞ NO',
    accessor: 'orders_id',
    columnName: 'orders_id',
    Cell: OrderLink,
    disableSortBy: true,
    // disableFilters: true
  },
  {
    Header: 'GÖRSEL',
    accessor: 'product.imageLink',
    columnName: 'product.imageLink',
    Cell: ProductImage,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'İMALATÇI',
    accessor: 'manufacturer',
    columnName: 'manufacturer',
    key: 'manufacturer',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'RENK',
    accessor: 'color',
    columnName: 'color',
    key: 'color',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ÖZELLİKLER',
    accessor: 'features',
    columnName: 'features',
    key: 'features',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'MATERYAL',
    accessor: 'material',
    columnName: 'material',
    key: 'material',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'CAM',
    accessor: 'glass',
    columnName: 'glass',
    key: 'glass',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'KOL SAYISI',
    accessor: 'branch',
    columnName: 'branch',
    key: 'branch',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'DUY TİPİ',
    accessor: 'lampholder',
    columnName: 'lampholder',
    key: 'lampholder',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ADET',
    accessor: 'quantity',
    columnName: 'quantity',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ÖZEL NOTLAR',
    accessor: 'specialNotes',
    columnName: 'specialNotes',
    key: 'specialNotes',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'TESLİM TARİHİ',
    accessor: 'order.deliveryDate',
    columnName: 'order.deliveryDate',
    Cell: DateCell,
    disableSortBy: true,
    disableFilters: true,
  },
];
