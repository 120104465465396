import './Authentication.scss';

import { ForgotPassword, NewPassRequired, ResetPassword, SignIn } from '.';
import {
  CLEAR_USER,
  SET_AUTH_STATUS,
  SET_IS_AUTH_LOADING,
  SET_USER,
  authStatusEnum,
} from 'app/state/generalSlice';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from 'utils/atolyeAPI';
import { Loading } from 'pages/Dashboard';
import NightBlockingMessage from './components/NightBlockingMessage';

const AtolyeAPI = new API();

const Authentication = ({ children }) => {
  // Redux
  const dispatch = useDispatch();
  const { authStatus, isAuthLoading } = useSelector((state) => state.general);
  const { userRole } = useSelector((state) => state.general.user);

  // Effects
  useEffect(() => {
    isAuthLoading &&
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user) {
            return getCurrentUser();
          } else {
            dispatch(CLEAR_USER());
            dispatch(SET_AUTH_STATUS(authStatusEnum.SignIn));
            dispatch(SET_IS_AUTH_LOADING(false));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(CLEAR_USER());
          dispatch(SET_AUTH_STATUS(authStatusEnum.SignIn));
          dispatch(SET_IS_AUTH_LOADING(false));
        });
  }, [isAuthLoading]);

  // Functions

  const getCurrentUser = async () => {
    console.log('Getting current user...');
    try {
      const cognitoUserData = await Auth.currentUserInfo();

      const body = {
        email: cognitoUserData.attributes.email,
      };

      const { results } = await AtolyeAPI.post(`/users/email`, body);

      const userData = results;

      const { name, lastName, fullName, email, roles_id, id, permissions } = userData;

      const userPermissions = await permissions.map((permission) => permission.id);

      dispatch(
        SET_USER({
          userName: name,
          userLastName: lastName,
          userFullName: fullName,
          userEmail: email,
          userRole: roles_id,
          userId: id,
          userPermissions: userPermissions,
          cognitoAttributes: cognitoUserData.attributes,
        }),
      );
      dispatch(SET_AUTH_STATUS(authStatusEnum.LoggedIn));

      // Set timeout for 1 second to prevent flashing
    } catch (error) {
      console.log(error);
      dispatch(CLEAR_USER());
      dispatch(SET_AUTH_STATUS(authStatusEnum.SignIn));
    } finally {
      dispatch(SET_IS_AUTH_LOADING(false));
    }
  };

  const isUserAuthenticatedAtNight = () => {
    // Users who are not System Admin or Admin cannot login at night (between 20:00 - 08:00)
    // Time must be in Europe/Istanbul, locale must be tr-TR

    const isUserAdmin = userRole === 1 || userRole === 4;

    const now = new Date().toLocaleTimeString('tr-TR', {
      timeZone: 'Europe/Istanbul',
      hour12: false,
    });

    const isNightTime = now >= '20:00' || now <= '08:00';

    return isUserAdmin || !isNightTime;
  };

  return isAuthLoading ? (
    <Loading />
  ) : authStatus === authStatusEnum.LoggedIn ? (
    isUserAuthenticatedAtNight() ? (
      <>{children}</>
    ) : (
      <NightBlockingMessage />
    )
  ) : authStatus === authStatusEnum.ForgotPassword ? (
    <ForgotPassword />
  ) : authStatus === authStatusEnum.NewPassRequired ? (
    <NewPassRequired />
  ) : authStatus === authStatusEnum.ResetPassword ? (
    <ResetPassword />
  ) : (
    <SignIn />
  );
};

export default Authentication;
