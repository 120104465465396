import { useSelector } from 'react-redux';
import {
  EditProductVariantModal,
  NewProductVariantModal,
  ProductVariantDeleteModal,
  VariantOptions,
  VariantsTable,
} from '.';

const ProductVariantsSection = ({ getProduct, userId }) => {
  // Redux
  const { userPermissions } = useSelector((state) => state.general.user);
  return (
    <div className="my-4 p-3 bg-white rounded shadow-sm">
      <h5 className="text-danger text-center">Varyantlar</h5>
      <VariantOptions getProduct={getProduct} />
      <hr />
      <VariantsTable />
      {userPermissions?.includes(22) && (
        <>
          <NewProductVariantModal getProduct={getProduct} userId={userId} />
          <EditProductVariantModal getProduct={getProduct} userId={userId} />
          <ProductVariantDeleteModal getProduct={getProduct} userId={userId} />
        </>
      )}
    </div>
  );
};

export default ProductVariantsSection;
