import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  HIDE_CUSTOMER_MODAL,
  SET_CUSTOMER,
  SET_CUSTOMER_ADDRESS,
  SET_CUSTOMER_BILLING_ADDRESS,
  SET_CUSTOMER_COMPANY,
  SET_CUSTOMER_PHONE,
  SET_CUSTOMER_EMAIL,
  SET_CUSTOMER_LAST_NAME,
  SET_CUSTOMER_NAME,
  START_LOADING,
  STOP_LOADING,
} from '../createOrderSlice';
import API from 'utils/atolyeAPI';
import alert from 'js/sweetAlert';

const AtolyeAPI = new API();

const CustomerModal = ({ getCustomerOptions, customerOptions }) => {
  // Redux
  const dispatch = useDispatch();
  const { isLoading, isCustomerModalDisplayed, editingCustomer } = useSelector(
    (state) => state.orders.createOrder,
  );

  // Functions
  const updateCustomer = async () => {
    dispatch(START_LOADING());
    try {
      await AtolyeAPI.post(`/customers/${editingCustomer.id}`, {
        ...editingCustomer,
      });
      await getCustomerOptions();
      dispatch(SET_CUSTOMER(editingCustomer));
      alert('success', 'Müşteri bilgileri güncellendi');
    } catch (error) {
      console.log(error);
      alert('error', 'Müşteri bilgileri güncellenirken bir hata oluştu');
    } finally {
      dispatch(STOP_LOADING());
      dispatch(HIDE_CUSTOMER_MODAL());
    }
  };

  const dismissHandler = () => {
    dispatch(HIDE_CUSTOMER_MODAL());
    dispatch(
      SET_CUSTOMER(
        customerOptions.find((item) => item.value === editingCustomer?.id).customerDetails,
      ),
    );
  };
  return (
    <Modal show={isCustomerModalDisplayed} onHide={dismissHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Müşteri Bilgilerini Düzenle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form role="form">
          <div className="form-group">
            <label htmlFor="">Adı</label>
            <input
              type="text"
              name="name"
              id="name"
              className="form-control"
              value={editingCustomer.name}
              onChange={(e) => dispatch(SET_CUSTOMER_NAME(e.target.value))}
            />
          </div>

          <div className="form-group">
            <label htmlFor="">Soyadı</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              className="form-control"
              value={editingCustomer.lastName}
              onChange={(e) => dispatch(SET_CUSTOMER_LAST_NAME(e.target.value))}
            />
          </div>

          <div className="form-group">
            <label htmlFor="">Firma</label>
            <input
              type="text"
              name="company"
              id="company"
              className="form-control"
              value={editingCustomer.company}
              onChange={(e) => dispatch(SET_CUSTOMER_COMPANY(e.target.value))}
            />
          </div>

          <div className="form-group">
            <label htmlFor="">E-Posta</label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control"
              value={editingCustomer.email}
              onChange={(e) => dispatch(SET_CUSTOMER_EMAIL(e.target.value))}
            />
          </div>

          <div className="form-group">
            <label htmlFor="">Telefon</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              className="form-control"
              value={editingCustomer.phone}
              onChange={(e) => dispatch(SET_CUSTOMER_PHONE(e.target.value))}
            />
          </div>

          <div className="form-group">
            <label htmlFor="">Adres</label>
            <textarea
              type="text"
              className="form-control"
              rows="3"
              id="address"
              name="address"
              value={editingCustomer.address}
              onChange={(e) => dispatch(SET_CUSTOMER_ADDRESS(e.target.value))}></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="">Fatura Adresi</label>
            <textarea
              type="text"
              className="form-control"
              rows="3"
              id="billingAddress"
              name="billingAddress"
              value={editingCustomer.billingAddress}
              onChange={(e) => dispatch(SET_CUSTOMER_BILLING_ADDRESS(e.target.value))}></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={dismissHandler}>
          Vazgeç
        </Button>
        <Button variant="success" onClick={updateCustomer}>
          {isLoading ? 'Güncelleniyor...' : 'Güncelle'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerModal;
