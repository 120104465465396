import './SingleOrder.scss';

import { useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import API from 'utils/atolyeAPI';
const AtolyeAPI = new API();

import {
  ContentWrapper,
  // EmailSending,
  Header,
  Footer,
  OrderHeader,
  OrderInfo,
  OrderSelect,
  PageTitle,
  PageWrapper,
  PDFButtons,
  useUser,
  OrderProgress,
  ProductionStatusBulkEdit,
  EditOrderModal,
  PrivateNotes,
  DeleteProductModal,
  PaymentInfo,
  AddBalanceModal,
  ProductsDetails,
  OrderDetails,
  DeleteBalanceModal,
  DeleteOrderModal,
  ResponsiveLoading,
  AddProductModal,
  AddProductButton,
} from '.';
import { useSelector, useDispatch } from 'react-redux';
import useManufacturerOptions from 'hooks/useManufacturerOptions';
import useOrderOptions from 'hooks/useOrderOptions';

import {
  CLEAR_ERROR,
  SELECT_ORDER,
  SET_DESCRIPTION_EDIT_TEXT,
  SET_EDIT_INFO_DEFAULTS,
  SET_ERROR,
  SET_ORDER,
  SET_PRIVATE_NOTES_EDIT_TEXT,
  START_LOADING,
  STOP_LOADING,
} from './singleOrderSlice';
import { Alert } from 'react-bootstrap';
// import alert from 'js/sweetAlert';

const SingleOrder = () => {
  //Redux
  const { isLoading, isError, order } = useSelector((state) => state.orders.singleOrder);

  const dispatch = useDispatch();

  // Hooks
  const { user, userPermissions } = useUser();
  const { isManufacturerOptionsLoading, manufacturerOptions } = useManufacturerOptions();
  const { isOrderOptionsLoading, orderOptions } = useOrderOptions();

  let [searchParams, setSearchParams] = useSearchParams();
  // const navigate = useNavigate();

  // Effects
  useEffect(() => {
    const id = searchParams?.get('id');
    if (id) {
      dispatch(START_LOADING());
      getOrderDetails(id);
      dispatch(SELECT_ORDER(id));
    }
  }, [searchParams]);

  // Functions

  const getOrderDetails = async (id) => {
    dispatch(CLEAR_ERROR());
    dispatch(START_LOADING());

    try {
      const { results } = await AtolyeAPI.get(`/orders/${id}`);
      dispatch(SET_ORDER(results));
      dispatch(SET_EDIT_INFO_DEFAULTS(results));
      dispatch(SET_PRIVATE_NOTES_EDIT_TEXT(results.privateNotes || ''));
      dispatch(SET_DESCRIPTION_EDIT_TEXT(results.description));
    } catch (err) {
      console.log(err);
      dispatch(SET_ERROR());
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <>
      <Header user={user} />
      {!userPermissions?.includes(1) ? (
        <div className="alert alert-danger text-center mb-0" role="alert">
          Bu sayfaya erişim yetkiniz bulunmamaktadır.
        </div>
      ) : (
        <>
          <OrderSelect
            options={orderOptions}
            isLoading={isOrderOptionsLoading}
            onChange={(option) => setSearchParams(option ? { id: option.value } : {})}
            placeholder="Müşteri Adı veya Sipariş No girin"
          />
          <ContentWrapper>
            <PageTitle title="Sipariş Sorgulama" />
            <PageWrapper>
              {isLoading && <ResponsiveLoading />}
              {isError ? (
                <Alert variant="danger">
                  <strong>{searchParams?.get('id')}</strong> numaralı sipariş bilgilerinin
                  getirilmesi sırasında hata oluştu. Siparişin silinmediğinden emin olun.
                </Alert>
              ) : order && !isLoading ? (
                <div className="order-info mt-5">
                  <OrderHeader title="Sipariş Genel Bilgi" />
                  <OrderProgress />
                  <OrderInfo />
                  <hr />
                  <div className="row mx-0 mt-5">
                    {(userPermissions?.includes(9) || userPermissions?.includes(1)) && (
                      <ProductionStatusBulkEdit fetchOrder={getOrderDetails} />
                    )}

                    {/* <EmailSending /> */}
                    {userPermissions?.includes(8) && <PDFButtons />}
                  </div>
                  {(userPermissions?.includes(9) || userPermissions?.includes(1)) && (
                    <EditOrderModal fetchOrder={getOrderDetails} user={user} />
                  )}
                  <PrivateNotes fetchOrder={getOrderDetails} />
                  <ProductsDetails
                    fetchOrder={getOrderDetails}
                    isManufacturerOptionsLoading={isManufacturerOptionsLoading}
                    manufacturerOptions={manufacturerOptions}
                  />
                  {userPermissions?.includes(8) && userPermissions?.includes(9) && (
                    <>
                      <AddProductButton />
                      <DeleteProductModal fetchOrder={getOrderDetails} />
                      <AddProductModal fetchOrder={getOrderDetails} />
                    </>
                  )}

                  <OrderDetails fetchOrder={getOrderDetails} />
                  {userPermissions?.includes(8) && <PaymentInfo />}
                  <AddBalanceModal fetchOrder={getOrderDetails} />
                  <DeleteBalanceModal fetchOrder={getOrderDetails} />
                  <DeleteOrderModal />
                  <div className="text-right mx-3">
                    <Link to="/orders" className="text-primary m-2">
                      Tüm Siparişler
                    </Link>
                    {userPermissions?.includes(9) && (
                      <a
                        href=""
                        className=" text-danger m-2"
                        data-toggle="modal"
                        data-target=".delete-order-modal-lg">
                        Siparişi Sil
                      </a>
                    )}
                  </div>
                </div>
              ) : (
                <Alert variant="info">
                  <strong>{searchParams?.get('id')}</strong> Sipariş detaylarını görüntülemek için
                  bir sipariş seçiniz.
                </Alert>
              )}
            </PageWrapper>
          </ContentWrapper>
        </>
      )}
      <Footer />
    </>
  );
};

export default SingleOrder;
