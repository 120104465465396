import { configureStore } from '@reduxjs/toolkit';
import generalReducer from './generalSlice';
import orderReducer from 'pages/Order/reducer';
import priceOfferReducer from 'pages/PriceOffer/reducer';
import productReducer from 'pages/Product/reducer';
import atolyeTableReducer from 'components/AtolyeTable/atolyeTableSlice';
import productStockManagementReducer from 'pages/StockManagement/ProductStockManagement/productStockManagementSlice';
import settingsReducer from 'pages/Settings/reducer';

export default configureStore({
  reducer: {
    general: generalReducer,
    atolyeTable: atolyeTableReducer,
    orders: orderReducer,
    priceOffers: priceOfferReducer,
    products: productReducer,
    productStockManagement: productStockManagementReducer,
    settings: settingsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
