import { styles } from '../styles';

import { Text, View, Image } from '@react-pdf/renderer';

const ProductDetailsItem = ({
  product,
  translations,
  lang,
  index,
  images,
  isPriceless,
  currency,
}) => {
  return (
    <View key={product.id} style={[styles.row, { maxHeight: '185px' }]} wrap={false}>
      <View style={[styles.prodImageContainer, { backgroundColor: '#f8f8f8' }]}>
        {images[index].includes('s3') ? (
          <Image
            style={styles.prodImage}
            src={
              {
                uri: images[index],
                method: 'GET',
                headers: { 'cache-control': 'no-cache' },
                body: '',
              } || null
            }></Image>
        ) : (
          <Image style={styles.prodImage} src={images[index] || null}></Image>
        )}
        {/* <Image style={styles.prodImage} src={images[index] || null}></Image>
        <Image
          style={styles.prodImage}
          src={
            {
              uri: images[index],
              method: 'GET',
              headers: { 'cache-control': 'no-cache' },
              body: '',
            } || null
          }></Image> */}
      </View>

      <Text style={styles.prodCode}>
        <Text style={styles.tableTextProdCode}>{product.products_code}</Text>
      </Text>

      <View style={[styles.description, { width: isPriceless ? '47.5%' : '35%' }]}>
        <View>
          <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
            {translations.description[lang]} :
          </Text>
        </View>
        <View>
          <Text style={styles.tableText}>
            {product.description} {product.productVariant && `(${product.productVariant.name})`}
          </Text>
        </View>

        <View>
          <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
            {translations.productFeatures[lang]} :
          </Text>
        </View>
        <View>
          <Text style={styles.tableText}>{product.features}</Text>
        </View>

        <View>
          <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
            {translations.material[lang]} :
          </Text>
        </View>
        <View>
          <Text style={styles.tableText}>{product.material}</Text>
        </View>

        <View>
          <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
            {translations.glass[lang]} :
          </Text>
        </View>
        <View>
          <Text style={styles.tableText}>{product.glass}</Text>
        </View>

        <View>
          <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
            {translations.branchCount[lang]} :
          </Text>
        </View>
        <View>
          <Text style={styles.tableText}>{product.branch}</Text>
        </View>

        <View>
          <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
            {translations.color[lang]} :
          </Text>
        </View>
        <View>
          <Text style={styles.tableText}>{product.color}</Text>
        </View>

        <View>
          <Text style={[styles.tableText, { color: '#333' }, { textDecoration: 'underline' }]}>
            {translations.lampholderType[lang]} :
          </Text>
        </View>
        <View>
          <Text style={styles.tableText}>{product.lampholder}</Text>
        </View>
      </View>
      <Text style={[styles.qty, { textAlign: 'center', width: isPriceless ? '20%' : '7.5%' }]}>
        <Text style={styles.tableText}>{product.quantity}</Text>
      </Text>
      {!isPriceless && (
        <>
          <Text style={[styles.price, { textAlign: 'center' }]}>
            <Text style={styles.tableText}>
              {(product.price * 1.18).toFixed(2).toLocaleString()} {currency}
            </Text>
          </Text>
          <Text style={[styles.total, { textAlign: 'center' }]}>
            <Text style={styles.tableText}>
              {(product.total * 1.18).toFixed(2).toLocaleString()} {currency}
            </Text>
          </Text>
        </>
      )}
    </View>
  );
};

export default ProductDetailsItem;
