import {
  BoldText,
  DateCell,
  OrderLink,
  ProductImage,
  ProductLink,
} from 'components/AtolyeTable/components/customCellComponents';

export const columnsData = [
  {
    Header: 'ÜRETİM NU.',
    accessor: 'id',
    columnName: 'id',
    Cell: BoldText,
    // disableSortBy: true
    // disableFilters: true
  },
  {
    Header: 'SİPARİŞ NU.',
    accessor: 'orders_id',
    columnName: 'orders_id',
    Cell: OrderLink,
    // disableSortBy: true
    // disableFilters: true
  },

  {
    Header: 'GÖRSEL',
    accessor: 'product.imageLink',
    columnName: 'product.imageLink',
    Cell: ProductImage,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ÜRÜN',
    accessor: 'product.id',
    columnName: 'product.id',
    Cell: ProductLink,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'MÜŞTERİ',
    accessor: 'order.customer',
    columnName: 'order.customer',
    Cell: ({ value }) => `${value?.name} ${value?.lastName}`,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'AÇIKLAMA',
    accessor: 'product.description',
    columnName: 'product.description',
    disableSortBy: true,
    // disableFilters: true,
  },
  {
    Header: 'ÖZELLİKLER',
    accessor: 'features',
    columnName: 'features',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'ADET',
    accessor: 'quantity',
    columnName: 'quantity',
    // disableSortBy: true
    // disableFilters: true
  },
  {
    Header: 'ÜRETİM DURUMU',
    accessor: 'productionStatus.name',
    columnName: 'productionStatus.name',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'İMALATHANE',
    accessor: 'manufacturer.name',
    columnName: 'manufacturer.name',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'SİPARİŞ TARİHİ',
    accessor: 'order.saleDate',
    columnName: 'order.saleDate',
    Cell: DateCell,
    // disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'TESLİM TARİHİ',
    accessor: 'order.deliveryDate',
    columnName: 'order.deliveryDate',
    Cell: DateCell,
    // disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'SATIŞ YETKİLİSİ',
    accessor: 'order.user.fullName',
    columnName: 'order.user.fullName',
    disableSortBy: true,
    disableFilters: true,
  },
];
