import { Alert, Button, Table } from 'react-bootstrap';
import { ContentWrapper, PageTitle, Header, Footer, useUser } from '../ProductionList';
import API from 'utils/atolyeAPI';
import { useEffect, useState } from 'react';
import { Loading } from 'pages/Dashboard';
import alert from 'js/sweetAlert';
import EditOperationModal from './components/EditOperationModal';

const AtolyeAPI = new API();

const Operations = (props) => {
  // States
  const [loading, setLoading] = useState(true);
  const [operations, setOperations] = useState([]);
  const [showEditOperationModal, setShowEditOperationModal] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);
  // Hooks
  const { user, userPermissions } = useUser();

  const getOperations = async () => {
    setLoading(true);
    try {
      const { results } = await AtolyeAPI.get('/operations/options');
      setOperations(results);
    } catch (error) {
      console.log(error);
      alert('error', 'İşler getirilirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    getOperations();
  }, []);

  return (
    <>
      <Header user={user} {...props} />
      <ContentWrapper>
        <PageTitle title="İşler" />
        {!userPermissions?.includes(34) ? (
          <Alert variant="danger">
            <strong>Yetkiniz yok!</strong>İşleri görüntülemek için yetkiniz bulunmamaktadır.
          </Alert>
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <div className="px-3">
              <Table size="sm" striped className="m-3 responsive">
                <thead>
                  <tr>
                    <th>İş Adı</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  {operations.map((operation, index) => (
                    <tr key={`operation-${index}`}>
                      <td>{operation.value}</td>
                      <td>
                        <Button
                          variant=""
                          size="sm"
                          className="me-2 text-primary"
                          onClick={() => {
                            setSelectedOperation(operation);
                            setShowEditOperationModal(true);
                          }}>
                          <i className="fas fa-edit"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        <EditOperationModal
          show={showEditOperationModal}
          setShow={setShowEditOperationModal}
          getOperations={getOperations}
          operation={selectedOperation}
        />
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default Operations;
