import { useState, useEffect } from 'react';

import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useSingleCustomer = () => {
  // States
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getCustomerOptions();
  }, []);

  // Functions
  const getCustomerOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/customers/options');
      const options = results.map((item) => ({
        value: item.id,
        label: `${item.name} ${item.lastName}`,
      }));
      setCustomerOptions(options);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    customerOptions,
  };
};

export default useSingleCustomer;
