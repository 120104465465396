import { Table } from '..';
import { Link } from 'react-router-dom';

const CustomerPriceOffersTable = ({ priceOffers }) => {
  return (
    <Table striped bordered hover size="sm" variant="secondary">
      <thead>
        <tr>
          <th style={{ width: 80 }}>#</th>
          <th>Tarih</th>
          <th>Tutar</th>
        </tr>
      </thead>
      <tbody>
        {priceOffers.map((priceOffer) => (
          <tr key={`price-offer-${priceOffer.id}`}>
            <td className="text-center">
              <Link to={`/single-price-offer?id=${priceOffer.id}`}>
                <span className="badge badge-warning text-dark  px-2">{priceOffer.id}</span>
              </Link>
            </td>
            <td> {new Date(priceOffer.createdAt).toLocaleDateString('tr')}</td>
            <td> {priceOffer.total.toLocaleString()} ₺</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CustomerPriceOffersTable;
