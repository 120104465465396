import { useState, useEffect } from 'react';
import API from 'utils/atolyeAPI';

const AtolyeAPI = new API();

const useUserOptions = () => {
  // State
  const [userOptions, setUserOptions] = useState([]);
  const [isUserOptionsLoading, setIsUserOptionsLoading] = useState(true);

  // Effects
  useEffect(() => {
    getUserOptions();
  }, []);

  // Functions
  const getUserOptions = async () => {
    try {
      const { results } = await AtolyeAPI.get('/users/options');
      setUserOptions(results.filter((user) => user.roles_id !== 4));
    } catch (error) {
      console.log(error);
    } finally {
      setIsUserOptionsLoading(false);
    }
  };

  return {
    isUserOptionsLoading,
    userOptions,
  };
};

export default useUserOptions;
